import React, { useEffect, useState } from "react";

function BOM({
  formik,
  tableClass,
  tdClass,
  trClass,
  otherNetWeight,
  index
}) {
  const bold = {
    fontWeight: "bold",
  };

  // const [isLoading, setIsLoading] = useState(false);

  const radiographyValue = formik.values.radiography?.value;
  const radiographyLabel = formik.values.radiography?.label;


  const flangeTypeForInlet = formik?.values?.nozzle[0]?.flangeType;


  // useEffect(() => {
  //   const updateCableGlandPrice = () => {
  //     const { heaterterminalboxijb, multistackvessel, installedCapacity, divideEqually, voltage, cableGland } = formik.values;

  //     // Ensure voltage and cableGland are valid numbers and not zero
  //     if (!voltage || !cableGland) {
  //       formik.setFieldValue("price.cableGland", 0);
  //       return;
  //     }

  //     const capacityPerVessel = ((installedCapacity * 1000) / multistackvessel)

  //     if (capacityPerVessel === 0) {
  //       formik.setFieldValue("price.cableGland", 0);
  //       return;
  //     }

  //     const cableGlandPrice =
  //       ((((heaterterminalboxijb.toLowerCase() === "ex'd" ? 2 : 1) *
  //         (3 * (capacityPerVessel / voltage))) /
  //         cableGland) *
  //         cableGland *
  //         2.5).toFixed(2);

  //     formik.setFieldValue("price.cableGland", cableGlandPrice);
  //   };

  //   updateCableGlandPrice();
  // }, [formik.values.cableGland, formik.values.heaterterminalboxijb, formik.values.installedCapacity, formik.values.divideEqually, formik.values.voltage, formik.values.multistackvessel]);
  // BAFFLE Weight for terminal box active flp(EX'D or IEC 'Xd


  // useEffect(() => {
  //   const result = calculateBaffleResult();
  //   formik.setFieldValue("baffleBomValue", result);
  // }, [formik.values.netWeight?.baffle[0], formik.values.numberofHotBaffles, formik.values.baffleplatethickness]);

  // const updateNetWeightAndGroundWeight = (netWeight, netWeightInch, groundWeight) => {
  //   // ---TubeSheet
  //   formik.setFieldValue(
  //     "netWeight.tubeSheet", netWeight?.tubeSheet
  //   );

  //   // ---Shell Ring
  //   formik.setFieldValue(
  //     "netWeight.shellRing", netWeight?.shellRing
  //   );

  //   // ---Shell/Vessel
  //   formik.setFieldValue(
  //     "netWeight.shellVessel", netWeight?.shellVessel
  //   );

  //   // ---Baffle
  //   // const calculateBaffleNetWeight = (
  //   //   flangeSize,
  //   //   bafflePlatethickness,
  //   //   numberOfbaffles,
  //   //   shellThickness,
  //   //   baffleType,
  //   //   flangeWeightForInch
  //   // ) => {
  //   //   const indexValue = parseFloat(flangeWeightForInch?.baffle);

  //   //   const AA18 =
  //   //     (3.14 *
  //   //       (Math.pow(indexValue - 10 - 2 * shellThickness, 2) *
  //   //         bafflePlatethickness *
  //   //         numberOfbaffles *
  //   //         8)) /
  //   //     1000000 /
  //   //     4;
  //   //   const result =
  //   //     (3.14 / 4) *
  //   //     AA18 *
  //   //     (baffleType.toLowerCase() === "rod baffle"
  //   //       ? 1.5
  //   //       : baffleType.toLowerCase() === "strip baffle"
  //   //         ? 1.7
  //   //         : baffleType.toLowerCase() === "guide flow baffle"
  //   //           ? 2.25
  //   //           : baffleType.toLowerCase() === "wire mesh"
  //   //             ? 2
  //   //             : 1);

  //   //   return result.toFixed(2);
  //   // };

  //   // Calculate baffle net weight
  //   // const baffleNetWeight = calculateBaffleNetWeight(
  //   //   formik?.values?.flangeSize,
  //   //   formik?.values?.baffleplatethickness,
  //   //   formik?.values?.numberofHotBaffles,
  //   //   formik?.values?.shellThickness,
  //   //   formik?.values?.baffletype,
  //   //   data?.flangeWeightForInch
  //   // );

  //   // Set the calculated baffle net weight
  //   formik.setFieldValue("netWeight.baffle", netWeightInch.baffle);

  //   // ---Dish End
  //   formik.setFieldValue("netWeight.dishEnd", netWeightInch.dishEnd
  //   );

  //   // ---Inlet Nozzle
  //   formik.setFieldValue(
  //     "netWeight.inletNozzle",
  //     netWeightInch?.inletNozzle
  //   );

  //   // ---Inlet Flange
  //   formik.setFieldValue(
  //     "netWeight.inletFlange",
  //     netWeightInch?.inletFlange
  //   );

  //   // ---Outlet Nozzle
  //   formik.setFieldValue(
  //     "netWeight.outletNozzle",
  //     netWeightInch?.outletNozzle
  //   );

  //   // ---Outlet Flange
  //   formik.setFieldValue(
  //     "netWeight.outletFlange",
  //     netWeightInch?.outletFlange
  //   );

  //   // ---Drain Nozzle
  //   formik.setFieldValue(
  //     "netWeight.drainNozzle",
  //     netWeightInch?.drainNozzle
  //   );

  //   // ---Drain Flange
  //   formik.setFieldValue(
  //     "netWeight.drainFlange",
  //     netWeightInch?.drainFlange
  //   );

  //   // ---Vent Nozzle
  //   formik.setFieldValue(
  //     "netWeight.ventNozzle",
  //     netWeightInch?.ventNozzle
  //   );

  //   // ---Vent Flange
  //   formik.setFieldValue(
  //     "netWeight.ventFlange",
  //     netWeightInch?.ventFlange
  //   );

  //   // ---Process Nozzle
  //   formik.setFieldValue(
  //     "netWeight.processNozzle",
  //     netWeightInch?.processNozzle
  //   );

  //   // ---Process Flange
  //   formik.setFieldValue(
  //     "netWeight.processFlange",
  //     netWeightInch?.processFlange
  //   );

  //   // ---Dummy Nozzle
  //   formik.setFieldValue(
  //     "netWeight.dummyNozzle",
  //     netWeightInch?.dummyNozzle
  //   );

  //   // ---Dummy Flange
  //   formik.setFieldValue(
  //     "netWeight.dummyFlange",
  //     netWeightInch?.dummyFlange
  //   );

  //   // Tie rod with sleeves
  //   // formik.setFieldValue(
  //   //   "netWeight.tieRodWithSleeves",
  //   //   (
  //   //     ((4 * (parseFloat(formik?.values?.developedlength) / 2 - 100) -
  //   //       parseFloat(formik?.values?.maxactivehotlength) /
  //   //       2 /
  //   //       parseFloat(formik?.values?.numberofHotBaffles)) *
  //   //       (parseFloat(formik?.values?.tieroddia) + 4) *
  //   //       (parseFloat(formik?.values?.tieroddia) + 4) *
  //   //       8 *
  //   //       3.14) /
  //   //     4 /
  //   //     1000000
  //   //   ).toFixed(2)
  //   // );
  //   formik.setFieldValue(
  //     "netWeight.tieRodWithSleeves", (netWeightInch?.tieRodWithSleeves)
  //   );


  //   // ---Junction Box
  //   formik.setFieldValue(
  //     "netWeight.junctionBox", netWeightInch?.junctionBox
  //   );

  //   // ---Terminal Box
  //   formik.setFieldValue(
  //     "netWeight.terminalBoxBasePlate", netWeightInch.terBoxBasePlate
  //   );
  //   formik.setFieldValue(
  //     "netWeight.lugsSupport", netWeightInch?.lugSupport
  //   );

  //   // ------------------------------------------
  //   // GROUND WEIGHT
  //   // =============

  //   // ---TubeSheet
  //   formik.setFieldValue(
  //     "groundWeight.tubeSheet", groundWeight.tubeSheet
  //   );

  //   // // ---Shell Ring
  //   formik.setFieldValue(
  //     "groundWeight.shellRing", groundWeight.shellRing
  //   );

  //   // // ---Shell/Vessel
  //   formik.setFieldValue(
  //     "groundWeight.shellVessel", groundWeight.shellVessel
  //   );

  //   // // ---Baffle
  //   formik.setFieldValue(
  //     "groundWeight.baffle", groundWeight.baffle
  //   );

  //   // // ---Dish End
  //   formik.setFieldValue(
  //     "groundWeight.dishEnd", groundWeight.dishEnd
  //   );

  //   // // ---Inlet Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.inletNozzle", groundWeight.inletNozzle
  //   );

  //   // // ---Inlet Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.inletFlange", groundWeight.inletFlange
  //   );

  //   // // ---Outlet Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.outletNozzle", groundWeight.outletNozzle
  //   );

  //   // // ---Outlet Flange
  //   formik.setFieldValue(
  //     "groundWeight.outletFlange", groundWeight.outletFlange
  //   );

  //   // // ---Drain Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.drainNozzle", groundWeight.drainNozzle
  //   );

  //   // // ---Drain Flange
  //   formik.setFieldValue(
  //     "groundWeight.drainFlange", groundWeight.drainFlange
  //   );

  //   // // ---Vent Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.ventNozzle", groundWeight.ventNozzle
  //   );

  //   // // ---Vent Flange
  //   formik.setFieldValue(
  //     "groundWeight.ventFlange", groundWeight.ventFlange
  //   );

  //   // // ---Dummy Nozzle
  //   formik.setFieldValue(
  //     "groundWeight.dummyNozzle", groundWeight.dummyNozzle
  //   );

  //   // // ---Dummy Flange
  //   formik.setFieldValue(
  //     "groundWeight.dummyFlange", groundWeight.dummyFlange
  //   );

  //   // // Tie rod with sleeves
  //   formik.setFieldValue(
  //     "groundWeight.tieRodWithSleeves", groundWeight.tieRodWithSleeves
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.lugsSupport", groundWeight.lugSupport
  //   );
  //   // // ---Junction Box
  //   formik.setFieldValue(
  //     "groundWeight.junctionBox", groundWeight.junctionBox
  //   );

  //   // // ---Terminal Box base plate
  //   formik.setFieldValue(
  //     "groundWeight.terminalBoxBasePlate", groundWeight.terBoxBasePlate
  //   );


  //   // totalCosting();
  //   // fetchPrices();
  // };



  // // update prices
  // function updatePrices(pricesData, finalCosting, finalUnitBundle) {
  //   const setRoundedFieldValue = (field, value) => {
  //     if (!isNaN(value) && value !== null && value !== undefined) {
  //       formik.setFieldValue(field, Math.ceil(value));
  //     } else {
  //       console.error(`Invalid value for ${field}:`, value);
  //     }
  //   };

  //   const setPriceField = (field, value) => {
  //     setRoundedFieldValue(`price.${field}`, value);
  //   };

  //   // Material
  // const materialPrice =
  //   formik?.values?.fixingofheatingelements.toLowerCase() === "sleeves"
  //     ? pricesData?.fixingheatingelementprice *
  //     formik?.values?.groundWeight?.material
  //     : formik?.values?.fixingofheatingelements.toLowerCase() === "nut"
  //       ? formik?.values?.nutsize * 3
  //       : formik?.values?.fixingofheatingelements.toLowerCase() === "bush"
  //         ? formik?.values?.totalnumberofelements * 200
  //         : formik?.values?.fixingofheatingelements.toLowerCase() === "direct"
  //           ? formik?.values?.totalnumberofelements * 500
  //           : formik?.values?.fixingofheatingelements.toLowerCase() ===
  //             "tig + sleeves"
  //             ? pricesData?.heatingelement * 2 * formik?.values?.groundWeight?.material +
  //             formik?.values?.totalnumberofelements * 500
  //             : 0;
  // setPriceField("material", materialPrice);

  // Tubesheet
  // const tubesheetPrice =
  //   formik?.values?.tubeSheetCostType.toLowerCase() === "costing"
  //     ? pricesData?.tubeSheet
  //     : parseFloat(formik?.values?.tubeSheetCost);
  // setPriceField("tubeSheet", tubesheetPrice);


  //   // Tubesheet Drilling
  //   // const tubesheetDrillingPrice =
  //   //   formik?.values?.fixingofheatingelements === "NUT"
  //   //     ? formik?.values?.nutsize * 3 * 300
  //   //     : formik?.values?.fixingofheatingelements === "Direct"
  //   //       ? formik?.values?.totalnumberofelements *
  //   //       2 *
  //   //       formik?.values?.flangeSize *
  //   //       5 +
  //   //       formik?.values?.totalnumberofelements *
  //   //       2 *
  //   //       (formik?.values?.materialofsleevesbush === "INCOLOY800"
  //   //         ? 3
  //   //         : formik?.values?.materialofsleevesbush === "GR11"
  //   //           ? 2.3
  //   //           : formik?.values?.materialofsleevesbush === "CS"
  //   //             ? 1.5
  //   //             : 1.8) *
  //   //       (formik?.values?.fixingofheatingelements === "NUT"
  //   //         ? 600 *
  //   //         (formik?.values?.flangeSize < 11
  //   //           ? 0.7
  //   //           : formik?.values?.flangeSize > 17
  //   //             ? 1.3
  //   //             : 1)
  //   //         : 75 * (formik?.values?.heatersheathtubedia < 12 ? 0.7 : 1)) *
  //   //       (formik?.values?.flangeSize < 11
  //   //         ? 0.7
  //   //         : formik?.values?.flangeSize > 23
  //   //           ? 1.3
  //   //           : 1) *
  //   //       (formik?.values?.FlangeClass < 200
  //   //         ? 0.7
  //   //         : formik?.values?.FlangeClass > 500
  //   //           ? 1.3
  //   //           : 1)
  //   //       : 0;
  //   // setPriceField("tubeSheetDrilling", tubesheetDrillingPrice);
  //   // tube sheet drilling
  // setPriceField("tubeSheetDrilling", pricesData?.tubesheetDrilling);


  //   // Shell Ring
  //   const shellRingPrice =
  //     formik?.values?.shellFlangeCostType === "costing"
  //       ? pricesData?.shellRing
  //       : parseFloat(formik?.values?.shellFlangeCost);
  //   setPriceField("shellRing", shellRingPrice);

  //   // Shell Vessel
  //   const shellVesselPrice =
  //     formik?.values?.vesselCostType === "costing"
  //       ? pricesData?.shellVessel
  //       : parseFloat(formik?.values?.vesselCost);
  //   setPriceField("shellVessel", shellVesselPrice);

  //   // Dish End
  //   setPriceField("dishEnd", pricesData?.dishEnd);

  //   // Heating Element
  //   setPriceField("heatingElement", pricesData?.heatingElement);

  //   // Inlet Flange
  //   if (formik?.values?.nozzle[0]?.inletNozzle === true) {
  //     setPriceField("inletFlange", pricesData?.inletFlange);
  //   } else {
  //     setPriceField("inletFlange", 0);
  //   }

  //   // Inlet Nozzle
  //   if (formik?.values?.nozzle[0]?.inletNozzle === true) {
  //     setPriceField("inletNozzle", pricesData?.inletNozzle);
  //   } else {
  //     setPriceField("inletNozzle", 0);
  //   }

  //   // Outlet Flange
  //   if (formik?.values?.nozzle[1]?.outletNozzle === true) {
  //     setPriceField("outletFlange", pricesData?.outletFlange);
  //   } else {
  //     setPriceField("outletFlange", 0);
  //   }

  //   // Outlet Nozzle
  //   if (formik?.values?.nozzle[1]?.outletNozzle === true) {
  //     setPriceField("outletNozzle", pricesData?.outletNozzle);
  //   } else {
  //     setPriceField("outletNozzle", 0);
  //   }

  //   // Drain Flange
  //   if (formik?.values?.nozzle[3]?.drain === true) {
  //     setPriceField("drainFlange", pricesData?.drainFlange);
  //   } else {
  //     setPriceField("drainFlange", 0);
  //   }

  //   // Drain Nozzle
  //   if (formik?.values?.nozzle[3]?.drain === true) {
  //     setPriceField("drainNozzle", pricesData?.drainNozzle);
  //   } else {
  //     setPriceField("drainNozzle", 0);
  //   }

  //   // Vent Flange
  //   if (formik?.values?.nozzle[2]?.vent === true) {
  //     setPriceField("ventFlange", pricesData?.ventFlange);
  //   } else {
  //     setPriceField("ventFlange", 0);
  //   }

  //   // Vent Nozzle
  //   if (formik?.values?.nozzle[2]?.vent === true) {
  //     setPriceField("ventNozzle", pricesData?.ventNozzle);
  //   } else {
  //     setPriceField("ventNozzle", 0);
  //   }

  //   // Process Flange
  //   if (formik?.values?.nozzle[4]?.process === true) {
  //     setPriceField("processFlange", pricesData?.processFlange);
  //   } else {
  //     setPriceField("processFlange", 0);
  //   }

  //   // Process Nozzle
  //   if (formik?.values?.nozzle[4]?.process === true) {
  //     setPriceField("processNozzle", pricesData?.processNozzle);
  //   } else {
  //     setPriceField("processNozzle", 0);
  //   }

  //   // Dummy Flange
  //   if (formik?.values?.nozzle[5]?.dummy === true) {
  //     setPriceField("dummyFlange", pricesData?.dummyFlange);
  //   } else {
  //     setPriceField("dummyFlange", 0);
  //   }

  //   // Dummy Nozzle
  //   if (formik?.values?.nozzle[5]?.dummy === true) {
  //     setPriceField("dummyNozzle", pricesData?.dummyNozzle);
  //   } else {
  //     setPriceField("dummyNozzle", 0);
  //   }


  //   // Baffle
  //   const bafflePrice =
  //     formik?.values?.baffleCostType === "costing"
  //       ? pricesData?.baffle
  //       : parseFloat(formik?.values?.baffleCost);
  //   setPriceField("baffle", bafflePrice);

  //   // Tie Rod With Sleeve
  //   setPriceField("tieRodWithSleeves", pricesData?.tieRodWithSleeves);

  //   // Lugs Support
  //   const lugsSupportPrice = pricesData?.lugSupport +
  //     parseFloat(formik?.values?.flangeSize) * 10;
  //   setPriceField("lugsSupport", lugsSupportPrice);
  //   // Junction box price
  //   setPriceField("junctionBox", pricesData?.junctionBox);
  //   // Terminal Box Base Plate
  //   setPriceField("terminalBoxBasePlate", formik?.values?.terminalBoxCost);
  //   // Cable Gland
  //   setPriceField("cableGland", pricesData?.cableGland);
  //   // Connection Price
  //   setPriceField("priceOfConnections", pricesData?.connection);
  //   // Hardware Price
  //   setPriceField("priceOfHardware", pricesData?.hardware);
  //   // safetyThermocouple
  //   const seathTcRtdThPrice =
  //     formik?.values?.sensor[0].quantity || formik?.values?.sensor[1].quantity
  //       ? (parseInt(formik?.values?.sensor[1].quantity) +
  //         parseInt(formik?.values?.sensor[0].quantity)) *
  //       formik?.values?.rateOfSeathTcRtdTh
  //       : 0;
  //   setPriceField("safetyThermocouple", seathTcRtdThPrice);

  //   const rateOfProcessTcRtdTh = formik?.values?.sensor[2].quantity
  //     ? parseFloat(formik?.values?.sensor[2].quantity) *
  //     parseFloat(formik?.values?.rateOfProcessTcRtdTh)
  //     : 0;
  //   setPriceField("processThermocouple", rateOfProcessTcRtdTh);
  //   setPriceField("insulationCost", pricesData?.insulationPrice);
  //   setPriceField("radiographyCost", (formik?.values?.flangeSize) * 4500 * (formik.values.radiography?.value) / 100)
  //   // Final costing for unit bundle
  //   setRoundedFieldValue("unitBundle.COGS.unit", finalUnitBundle.COGS.unit);
  //   setRoundedFieldValue("unitBundle.COGS.bundle", finalUnitBundle.COGS.bundle);

  //   // Selling price of unit bundle
  //   setRoundedFieldValue("unitBundle.sellingPrice.unit", finalUnitBundle.sellingPrice.unit);
  //   setRoundedFieldValue("unitBundle.sellingPrice.bundle", finalUnitBundle.sellingPrice.bundle);

  //   // Quoted price for unit bundle
  //   setRoundedFieldValue("unitBundle.quotedPrice.unit", finalUnitBundle.quotedPrice.unit);
  //   setRoundedFieldValue("unitBundle.quotedPrice.bundle", finalUnitBundle.quotedPrice.bundle);

  //   // Final costing for right side
  //   setRoundedFieldValue("finalCosting.unitCogs", finalCosting.unitCogs);
  //   setRoundedFieldValue("finalCosting.bundleCogs", finalCosting.bundleCogs);
  //   setRoundedFieldValue("finalCosting.unitSellingPrice", finalCosting.unitSellingPrice);
  //   setRoundedFieldValue("finalCosting.bundleSellingPrice", finalCosting.bundleSellingPrice);
  //   setRoundedFieldValue("finalCosting.unitQuotedPrice", finalCosting.unitQuotedPrice);
  //   setRoundedFieldValue("finalCosting.bundleQuotedPrice", finalCosting.bundleQuotedPrice);
  //   // totalCosting();
  //   // COGSCost();
  // }


  const updateTableRows = () => {
    const checkup = formik?.values?.vesselSelection === "yes";
    const totalRows = 34 + formik?.values?.miscellaneous.length; // Calculate total rows dynamically

    // Iterate through each stack
    for (let stackIndex = 0; stackIndex < formik.values.multistackvessel; stackIndex++) {
      let visibleRowCount = 0; // Reset the serial number for each stack
      for (let i = 0; i < totalRows; i++) {
        const row = document.getElementById(`row${i + 1}_stack${stackIndex}`);
        if (!row) continue;
        if (checkup) {
          row.style.display = "";
        } else {
          if (i >= 7 && i < 17) row.style.display = "none";
          else row.style.display = "";
        }
        if (row.style.display !== "none") {
          visibleRowCount++;
          row.innerHTML = visibleRowCount;
        }
      }
    }
  };

  // Costing Total of Unit and Bundle


  // API CALLS
  // =========
  // const fetchBomFooterPrices = async () => {
  //   const footerPayload = {
  //     manufacturingCost: formik?.values?.manufacturingCost
  //       ? Number(formik?.values?.manufacturingCost)
  //       : 0,
  //     margin: formik?.values?.margin ? Number(formik?.values?.margin) : 0,
  //     contingency: formik?.values?.contingency
  //       ? Number(formik?.values?.contingency)
  //       : 0,
  //     unitMaterialCost: formik?.values?.finalCosting?.unitMaterialCost
  //       ? Number(formik?.values?.finalCosting?.unitMaterialCost)
  //       : 0,
  //     bundleMaterialCost: formik?.values?.finalCosting?.bundleMaterialCost
  //       ? Number(formik?.values?.finalCosting?.bundleMaterialCost)
  //       : 0,
  //   };
  //   try {
  //     const response = await axiosPrivate.post(
  //       "/bom-prices/total-of-all",
  //       footerPayload
  //     );
  //     const prices = response?.data?.data;

  //     formik.setFieldValue(
  //       "finalCosting.unitCogs",
  //       data?.finalCosting?.unitCogs
  //     );
  //     formik.setFieldValue(
  //       "finalCosting.bundleCogs",
  //       data?.finalCosting?.bundleCogs
  //     );
  //     formik.setFieldValue(
  //       "finalCosting.unitSellingPrice",
  //       data?.finalCosting?.unitSellingPrice
  //     );
  //     formik.setFieldValue(
  //       "finalCosting.bundleSellingPrice",
  //       data?.finalCosting?.bundleSellingPrice
  //     );
  //     formik.setFieldValue(
  //       "finalCosting.unitQuotedPrice",
  //       data?.finalCosting?.unitQuotedPrice
  //     );
  //     formik.setFieldValue(
  //       "finalCosting.bundleQuotedPrice",
  //       data?.finalCosting?.bundleQuotedPrice
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.COGS.unit",
  //       data?.unitBundle?.COGS?.unit
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.COGS.bundle",
  //       data?.unitBundle?.COGS?.bundle
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.sellingPrice.unit",
  //       data?.unitBundle?.sellingPrice?.unit
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.sellingPrice.bundle",
  //       data?.unitBundle?.sellingPrice?.bundle
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.quotedPrice.unit",
  //       data?.unitBundle?.quotedPrice?.unit
  //     );
  //     formik.setFieldValue(
  //       "unitBundle.quotedPrice.bundle",
  //       data?.unitBundle?.quotedPrice?.bundle
  //     );
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // Fetch prices according to payload
  // const fetchPrices = async () => {
  //   try {
  //     const response = await axiosPrivate.post(
  //       "/bom-prices/get-values",
  //       pricePayload
  //     );
  //     const data = response.data?.data;
  //     updatePrices(data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };


  // Usage of totalCosting in your BOM component

  // Fetch net weight according to payload

  // const fetchNetWeight = async () => {
  //   // await totalCosting(); // Ensure totalCosting runs before the API call

  //   await axiosPrivate
  //     .post("/fetchAllWeights", otherNetWeight)
  //     .then((response) => {
  //       const netWeight = response.data?.data.netWeight;
  //       const netWeightInch = response.data?.data.netWeight.flangeWeightForInch;
  //       const groundWeight = response.data?.data.groundWeight;
  //       const pricesData = response.data?.data.prices.data;
  //       const finalCosting = response.data?.data?.prices.data.totalOfAll.finalCosting;
  //       const finalUnitBundle = response.data.data.prices.data.totalOfAll.unitBundle;
  //       updateNetWeightAndGroundWeight(netWeight, netWeightInch, groundWeight);
  //       updatePrices(pricesData, finalCosting, finalUnitBundle);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  // useEffect(() => {
  //   materialNetWeight();
  //   fetchPricesFromMaster();
  //   // fetchPrices();
  //   fetchNetWeight();
  // }, []);



  // Fetch all price from master
  // const fetchPricesFromMaster = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axiosPrivate.get("/bom-prices");
  //     if (response.status === 200) {
  //       setPrices(response.data?.data);
  //     }
  //   } catch (error) {
  //     showErrorToast(error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   // Trigger ground weight calculations whenever netWeight changes
  //   const netWeight = formik.values.netWeight;

  //   formik.setFieldValue(
  //     "groundWeight.tubeSheet",
  //     (parseFloat(netWeight.tubeSheet) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.shellRing",
  //     (parseFloat(netWeight.shellRing) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.shellVessel",
  //     parseFloat(netWeight.shellVessel).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.baffle",
  //     (
  //       parseFloat(netWeight.baffle) *
  //       (formik.values.baffletype.toLowerCase() === "segmental" ? 1.4 : 1.1)
  //     ).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.dishEnd",
  //     parseFloat(netWeight.dishEnd).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.inletNozzle",
  //     parseFloat(netWeight.inletNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.inletFlange",
  //     (parseFloat(netWeight.inletFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.outletNozzle",
  //     parseFloat(netWeight.outletNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.outletFlange",
  //     (parseFloat(netWeight.outletFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.drainNozzle",
  //     parseFloat(netWeight.drainNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.drainFlange",
  //     (parseFloat(netWeight.drainFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.ventNozzle",
  //     parseFloat(netWeight.ventNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.ventFlange",
  //     (parseFloat(netWeight.ventFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.processNozzle",
  //     parseFloat(netWeight.processNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.processFlange",
  //     (parseFloat(netWeight.processFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.dummyNozzle",
  //     parseFloat(netWeight.dummyNozzle).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.dummyFlange",
  //     (parseFloat(netWeight.dummyFlange) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.tieRodWithSleeves",
  //     parseFloat(netWeight.tieRodWithSleeves).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.junctionBox",
  //     (parseFloat(netWeight.junctionBox) * 1.4).toFixed(2)
  //   );
  //   formik.setFieldValue(
  //     "groundWeight.terminalBoxBasePlate",
  //     (parseFloat(netWeight.terminalBoxBasePlate) * 1.4).toFixed(2)
  //   );
  // }, [formik.values.netWeight]);

  useEffect(() => {
    updateTableRows();
  }, [formik?.values?.vesselSelection, formik?.values?.multistackvessel, formik?.values?.miscellaneous]);

  // useEffect(() => {
  //   materialNetWeight();
  //   fetchPricesFromMaster();
  //   // fetchPrices();
  //   fetchNetWeight();
  // }, []);


  return (
    <div style={{ margin: "10px 0" }}>
      <table style={tableClass}>
        <tr style={trClass}>
          <th colSpan="8" style={{ ...tdClass, fontSize: "20px" }}>
            <div align="center">HEATING UNIT BILL OF MATERIAL </div>
          </th>
          <th colSpan="8" style={{ ...tdClass, fontSize: "20px", }}>
            <div align="center">RATES OF MATERIAL TAKEN FOR COSTING </div>
          </th>
        </tr>
        <tr style={trClass}>
          <td
            colSpan="8"
            style={{ ...tdClass, ...bold, }}
            align="center"
          >
            <span style={{ padding: "0px 20px" }}>
              {formik?.values?.installedCapacity || "N/A"} KW
            </span>
            <span style={{ padding: "0px 20px" }}>
              {formik?.values?.voltage || "N/A"} V
            </span>
            <span style={{ padding: "0px 20px" }}>HEATING UNIT</span>
          </td>
          <td rowSpan={4} colSpan={7} style={{ padding: "0px" }}>
            <table className="tableInsideTable" style={{ borderCollapse: "collapse", width: "100%", border: "0px", padding: "0px" }}>
              {/* Row 1 */}
              <tr style={trClass}>
                <td width="15%" style={{ ...tdClass, borderLeft: "0px", borderTop: "0px" }}><div>Powder</div></td>
                <td width="14%" style={{ ...tdClass, borderTop: "0px" }}><div>Mgo</div></td>
                <td width="6%" style={{ ...tdClass, borderTop: "0px" }}><div>{"-"}</div></td>
                <td width="4%" style={{ ...tdClass, minWidth: "60px", borderTop: "0px" }}><div>@' RS</div></td>
                <td width="3%" style={{ ...tdClass, borderTop: "0px" }}><div>{formik?.values?.price?.indPrice?.powderIndPrice || "N/A"}</div></td>
                <td width="7%" style={{ ...tdClass, borderTop: "0px" }}><div>/KG</div></td>
              </tr>

              {/* Row 2 */}
              <tr style={trClass}>
                <td style={{ ...tdClass, borderLeft: "0px" }}><div>Tube</div></td>
                <td style={{ ...tdClass }}><div>{formik.values.sheathmaterial}</div></td>
                <td style={{ ...tdClass }}><div>{formik?.values?.heatersheathtubedia || "N/A"}</div></td>
                <td style={{ ...tdClass, minWidth: "60px" }}><div>@' RS</div></td>
                <td style={{ ...tdClass }}><div><span>{formik?.values?.price?.indPrice?.tubeIndPrice || "N/A"}</span></div></td>
                <td style={{ ...tdClass, }}><div>/M</div></td>
              </tr>

              {/* Row 3 */}
              <tr style={trClass}>
                <td style={{ ...tdClass, borderLeft: "0px" }}><div>FABRICATION(PLATE)</div></td>
                <td style={{ ...tdClass }}><div>{formik?.values?.bafflematerial || "N/A"}</div></td>
                <td style={{ ...tdClass }}><div>{"-"}</div></td>
                <td style={{ ...tdClass, minWidth: "60px" }}><div>@' RS</div></td>
                <td style={{ ...tdClass }}><div><span>{formik?.values?.price?.indPrice?.fabricationPlateIndPrice1 || "N/A"}</span></div></td>
                <td style={{ ...tdClass, }}><div>/KG</div></td>
              </tr>

              {/* Row 4 */}
              <tr style={trClass}>
                <td style={{ ...tdClass, borderLeft: "0px" }}><div>FABRICATION(FLANGE)</div></td>
                <td style={{ ...tdClass }}><div>{formik?.values?.FlangeMaterialCode || "N/A"}</div></td>
                <td style={{ ...tdClass }}><div>{"-"}</div></td>
                <td style={{ ...tdClass, minWidth: "60px" }}><div>@' RS</div></td>
                <td style={{ ...tdClass }}><div><span>{formik?.values?.price?.indPrice?.fabricationPlateIndPrice2 || "N/A"}</span></div></td>
                <td style={{ ...tdClass, }}><div>/KG</div></td>
              </tr>

              {/* Row 5 */}
              <tr style={trClass}>
                <td style={{ ...tdClass, borderLeft: "0px" }}><div>{formik?.values?.fixingofheatingelements || "N/A"}</div></td>
                <td style={{ ...tdClass }}><div>{formik?.values?.materialofsleevesbush || "N/A"}</div></td>
                <td style={{ ...tdClass }}><div>{formik?.values?.heatersheathtubedia || "N/A"}</div></td>
                <td style={{ ...tdClass, minWidth: "60px" }}><div>@' RS</div></td>
                <td style={{ ...tdClass }}><div><span>{formik?.values?.price?.indPrice?.sleevesIndPrice || "N/A"}</span></div></td>
                <td style={{ ...tdClass, }}><div>/EACH</div></td>
              </tr>

              {/* Row 6 */}
              <tr style={trClass}>
                <td style={{ ...tdClass, borderBottom: "0px", borderLeft: "0px" }}><div>{formik.values.fixingofheatingelements || "N/A"}</div></td>
                <td style={{ ...tdClass, borderBottom: "0px" }}><div>{formik?.values?.bafflematerial || "N/A"}</div></td>
                <td style={{ ...tdClass, borderBottom: "0px" }}><div>{formik.values.fixingofheatingelements === "NUT" ? "3" : "-"}</div></td>
                <td style={{ ...tdClass, borderBottom: "0px", minWidth: "60px" }}><div>@' RS</div></td>
                <td style={{ ...tdClass, borderBottom: "0px" }}><div><span>{formik?.values?.price?.indPrice?.nutIndPrice || "N/A"}</span></div></td>
                <td style={{ ...tdClass, borderBottom: "0px" }}><div>/EACH</div></td>
              </tr>
            </table>
          </td>
          {/* <td width="15%" rowSpan="4" style={{...tdClass}}>
            <div>Tube</div>
            <div>Tube</div>
            <div>FABRICATION(PLATE)</div>
            <div>FABRICATION(FLANGE)</div>
            <div>{formik?.values?.fixingofheatingelements || "N/A"}</div>
            <div>{formik.values.fixingofheatingelements === "NUT" ? "3 NUT" : formik.values.fixingofheatingelements}</div>
          </td>
          <td width="14%" rowSpan="4" style={tdClass}>
            <div>Powder</div>
            <div>TubeDia</div>
            <div>{formik?.values?.bafflematerial || "N/A"}</div>
            <div>{formik?.values?.FlangeMaterialCode || "N/A"}
            </div><div>{formik?.values?.materialofsleevesbush || "N/A"}</div>
            <div>N/A</div>
          </td>
          <td width="6%" rowSpan="4" style={tdClass}>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>{formik?.values?.nutsize || "N/A"}</div>
            <div>{formik?.values?.nutsize || "N/A"}</div>
          </td>
          <td width="4%" rowSpan="4" style={{ ...tdClass, minWidth: "70px" }}>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
          </td>
          <td width="3%" rowSpan="4" style={tdClass}>
            <div>
              <span>{formik?.values?.price?.indPrice?.powderIndPrice || "N/A"}</span>
            </div>
            <div>
              <span>{formik?.values?.price?.indPrice?.tubeIndPrice || "N/A"}</span>
            </div>
            <div>
              <span>{formik?.values?.price?.indPrice?.fabricationPlateIndPrice1 || "N/A"}</span>
            </div>
            <div>
              <span>{formik?.values?.price?.indPrice?.fabricationPlateIndPrice2 || "N/A"}</span>
            </div>
            <div>
              <span>{formik?.values?.price?.indPrice?.sleevesIndPrice || "N/A"}</span>
            </div>
            <div>
              <span >{formik.values.price.indPrice.nutIndPrice || "N/A"}</span>
            </div>
          </td>
          <td width="7%" rowSpan="4" style={tdClass}>
            <div>/KG</div>
            <div>/M</div>
            <div>/KG</div>
            <div>/KG</div>
            <div>/EACH</div>
            <div>/EACH</div>
          </td> */}

          {/* Rest of the rows remain unchanged */}
        </tr>
        <tr style={trClass}>
          <th
            colSpan="8"
            style={{
              ...tdClass, textAlign: "center",
            }}
          >
            <span style={{ paddingRight: "15px" }}>Connection Type -</span>
            <span style={{ padding: "0px 12px" }}>
              {formik?.values?.connection || "N/A"}
            </span>
            <span style={{ padding: "0px 12px" }}>HOT'U'</span>
            <span style={{ padding: "0px 12px" }}>
              {formik?.values?.maxactivehotlength || "N/A"}
            </span>
            <span style={{ padding: "0px 12px" }}>MM</span>
          </th>
        </tr>
        <tr style={trClass}>
          <td
            rowSpan="2"
            colSpan="7"
            style={{
              ...tdClass,
              ...bold,
            }}
            align="center"
          >
            <div style={{ borderBottom: "2px solid black", width: "70%" }}>
              <span style={{ padding: "0 4px" }}>
                {formik?.values?.totalnumberofelements || "N/A"} X R
              </span>&nbsp;&nbsp;
              <span style={{ padding: "0 4px" }}>
                <span>{formik?.values?.totalDevelopedLengthForBom || "N/A"}</span>
              </span>&nbsp;&nbsp;
              <span style={{ padding: "0 4px" }}>
                <span>{formik?.values?.maxactivecoldlength || "N/A"}</span>
              </span>&nbsp;&nbsp;
              <span style={{ padding: "0 4px" }}>
                <span>{formik?.values?.hotLengthCalculatedForBom || "N/A"}</span>
              </span>&nbsp;&nbsp;
              <span style={{ padding: "0 4px" }}>
                {formik?.values?.sheathmaterial || "N/A"}
              </span>
            </div>
            <div>
              <span>{formik?.values?.voltage || "N/A"} V </span>
              <span>  {formik?.values?.bundleHeatLoad.map((item, index) => (
                formik?.values?.multistackvessel > 1 ? (
                  <td colSpan={index === 0 ? 2 : 1}>
                    {Math.round(
                      (formik?.values?.bundleHeatLoad[index] /
                        formik?.values?.numberofelementsconnected) *
                      1000
                    )}
                  </td>
                ) : index === 0 ? (
                  <td colSpan={2}>
                    {Math.round(
                      (formik?.values?.bundleHeatLoad[index] /
                        formik?.values?.numberofelementsconnected) *
                      1000
                    )}
                  </td>
                ) : null
              ))} W </span>
            </div>
          </td>
          <td
            width="10%"
            rowSpan="2"
            style={{
              ...tdClass,
            }}
          >
            {formik?.values?.heatersheathtubedia || "N/A"} MM
            <span> DIA</span>
          </td>
          {/* <td
            style={{ ...tdClass, borderLeft: "none", borderTop: "none" }}
            rowSpan="2"
          >
            DIA
          </td> */}
        </tr>
        <tr style={trClass}></tr>

        <tr style={trClass}>
          <td style={{ ...tdClass, width: "60px" }} align="center">
            <strong>S.NO</strong>
          </td>
          <td colSpan="4" style={tdClass} align="center">
            <strong>NAME OF ITEM</strong>
          </td>
          <td colSpan="4" style={tdClass} align="center">
            <strong>SPECIFICATION</strong>
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }} align="center">
            <strong>MATERIAL</strong>
          </td>
          <td style={tdClass} align="center">
            <strong>QTY</strong>
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }} align="center">
            <strong>NET WT.</strong>
          </td>
          <td style={tdClass} align="center">
            <strong>G WT.</strong>
          </td>
          <td style={tdClass} align="center">
            <strong>UNIT</strong>
          </td>
          <td colSpan="2" style={tdClass} align="center">
            <strong>PRICE(RS)</strong>
          </td>

        </tr>
        {/* --1 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row1_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.fixingofheatingelements || "N/A"}
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.heatingElementType || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElementValue || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElementUnit || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElememtAddUnit} &nbsp;&nbsp;
            {formik?.values?.heatingElementAddValue || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.materialofsleevesbush || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "NUT"
              ? formik?.values?.nutsize || "N/A"
              : formik?.values?.totalnumberofelements * 2 || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.material}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.material}
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "Sleeves"
              ? "KG"
              : "No."}
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.material || "0.00"}
          </td>
        </tr>
        {/* --2 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row2_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            HEATING ELEMENT (FIXING INCLUDED)
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.bundleHeatLoad
              .map((item, index) =>
                Math.round(
                  (item / formik?.values?.numberofelementsconnected) * 1000
                ) + " W"
              )
              .join(" | ")}
            &nbsp;&nbsp;&nbsp;
            {formik?.values?.heatersheathtubedia} &nbsp;&nbsp;&nbsp;
            {formik?.values?.sheathmaterial || "N/A"}
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }} >
            <div align="center">{formik?.values?.sheathmaterial || "N/A"}</div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "NUT"
              ? formik?.values?.nutsize * 3
              : formik?.values?.totalnumberofelements}
          </td>
          <td style={tdClass} align="right">
            N/A
          </td>
          <td style={tdClass} align="right">
            N/A
          </td>
          <td style={tdClass} align="center">
            RS
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span>{formik?.values?.price.heatingElement}</span>
            </div>
          </td>
        </tr>
        {/* --3 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row3_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            TUBE SHEET (HEATER FLANGE)
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.flangeSize || "N/A"}&nbsp;" BLIND, AS PER ANSI
            16.5,&nbsp;&nbsp;{formik?.values?.FlangeClass || "N/A"} lb
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.FlangeMaterialCode || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.tubeSheet}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.tubeSheet}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.tubeSheet || "0.00"}
          </td>
        </tr>
        {/* --4 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row4_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            TUBESHEET DRILLING, SLEEVE TIG WELDING, DIRECTWELD GROOVING/COLLAR
            FOR NUT
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.heatingElementTubeSheet}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.FlangeMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "NUT"
              ? formik?.values?.nutsize
              : formik?.values?.totalnumberofelements * 2}
          </td>
          <td style={tdClass} align="right"></td>
          <td style={tdClass} align="right"></td>
          <td style={tdClass} align="center">
            &nbsp; No.
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.tubesheetDrilling || "0.00"}
          </td>
        </tr>
        {/* --5 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row5_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            SHELL RING
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;{formik?.values?.flangeSize || "N/A"}&nbsp;" BLIND, AS PER
            ANSI 16.5, &nbsp;&nbsp; {formik?.values?.FlangeClass || "N/A"} lb
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.FlangeMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.shellRing || "N/A"}
          </td>

          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.shellRing || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.shellRing || "0.00"}
          </td>
        </tr>
        {/* --6 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row6_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            SHELL / VESSEL
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;{formik?.values?.flangeSize || "N/A"} " NB &nbsp;&nbsp; THK.
            {formik?.values?.shellThickness || "N/A"} MM&nbsp;&nbsp;
            {formik?.values?.schedule || "N/A"} Sch.
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center">
              {formik?.values?.vesselMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.shellVessel}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.shellVessel}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>

          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.shellVessel || "0.00"}
          </td>
        </tr>
        {/* --7 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row7_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            DISH END
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;FOR A &nbsp; &nbsp; {formik?.values?.flangeSize || "N/A"} "
            &nbsp;DIA SHELL
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center">{formik?.values?.reducers || "N/A"}</div>
          </td>
          <td style={tdClass} align="center">
            1
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.dishEnd || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.dishEnd || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>

          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.dishEnd || "0.00"}
          </td>
        </tr>

        {formik?.values?.vesselSelection === "yes" && (
          <>
            {/* --8 inlet Nozzle */}
            {formik?.values?.nozzle[0]?.inletNozzle === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row8_stack${index}`}
                  className="conditionalRow"
                ></td>

                <td colSpan="4" style={tdClass}>
                  INLET NOZZEL
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp;{formik?.values?.nozzle[0]?.size || "N/A"} &nbsp;" NB, SCH
                  &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[0]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[0]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[0].inletNozzle === true ? "1" : "0"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.inletNozzle[index] || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.inletNozzle[index] || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>

                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.inletNozzle[index] || "0.00"}
                </td>
              </tr>
            )}

            {/* --9 inlet flange*/}
            {formik?.values?.nozzle[0]?.inletNozzle === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row9_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  INLET FLANGES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[0]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[0]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[0]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[0].inletNozzle === true
                    ? formik?.values?.nozzle[0].counterFlange === true
                      ? "2"
                      : "1"
                    : "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.inletFlange[index] || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.inletFlange[index] || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>

                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.inletFlange[index] || "0.00"}
                </td>
              </tr>
            )}
            {/* --10 outlet nozzle*/}
            {formik?.values?.nozzle[1]?.outletNozzle === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row10_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  OUTLET NOZZEL
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[1]?.size || "N/A"} &nbsp;" NB,
                  SCH &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[1]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[1]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[1].outletNozzle === true ? "1" : "0"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.outletNozzle[index] || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.outletNozzle[index] || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>

                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.outletNozzle[index] || "0.00"}
                </td>
              </tr>
            )}

            {/* --11 outlet Flange*/}
            {formik?.values?.nozzle[1]?.outletNozzle === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row11_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  OUTLET FLANGES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[1]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[1]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[1]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[1].outletNozzle === true
                    ? formik?.values?.nozzle[1].counterFlange === true
                      ? "2"
                      : "1"
                    : "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.outletFlange[index] || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.outletFlange[index] || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.outletFlange[index] || "0.00"}
                </td>
              </tr>
            )}
            {/* --12 vent nozzle */}
            {formik.values.nozzle[2].vent === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row12_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  VENT NOZZLE
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[2]?.size || "N/A"} &nbsp;" NB,
                  SCH &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[2]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[2]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[2].vent === true ? "2" : "0"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.ventNozzle || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.ventNozzle || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price.ventNozzle || "0.00"}
                </td>
              </tr>
            )}
            {/* --13 vent flange*/}
            {(formik.values.nozzle[2].vent === true && formik?.values?.nozzle[2]?.counterFlange === true) && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row13_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  VENT NOZZLE FLANGE
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[2]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[2]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[2]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[2].vent === true
                    ? formik?.values?.nozzle[2].counterFlange === true
                      ? "3"
                      : "2"
                    : "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.ventFlange || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.ventFlange || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.ventFlange || "0.00"}
                </td>
              </tr>
            )}
            {/* --14 drain nozzle*/}
            {formik.values.nozzle[3].drain === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row14_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  DRAIN NOZZLE
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[3]?.size || "N/A"} &nbsp;" NB,
                  SCH &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[3]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[3]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[3].drain === true ? "2" : "0"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.drainNozzle || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.drainNozzle || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.drainNozzle || "0.00"}
                </td>
              </tr>
            )}
            {/* --15 drain flange*/}
            {(formik.values.nozzle[3].drain === true && formik.values.nozzle[3].counterFlange === true) && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row15_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  DRAIN NOZZLE FLANGE
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[3]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[3]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[3]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[3].drain === true
                    ? formik?.values?.nozzle[3].counterFlange === true
                      ? "3"
                      : "2"
                    : "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.drainFlange || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.drainFlange || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.drainFlange || "0.00"}
                </td>
              </tr>
            )}
            {/* --16 Process nozzle*/}
            {formik.values.nozzle[4].process === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row16_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  PROCESS NOZZLES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[4]?.size || "N/A"} &nbsp;" NB,
                  SCH &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[4]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[4]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[4].process === true ? "2" : "0"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.processNozzle || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.processNozzle || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.processNozzle || "0.00"}
                </td>
              </tr>
            )}
            {/* --17 Process flange */}
            {(formik.values.nozzle[4].process === true && formik.values.nozzle[4].counterFlange === true) && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row17_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  PROCESS NOZZLE FLANGES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[4]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[4]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[4]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[4].process === true
                    ? formik?.values?.nozzle[4].counterFlange === true
                      ? "3"
                      : "2"
                    : "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.processFlange || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.processFlange || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.processFlange || "0.00"}
                </td>
              </tr>
            )}
            {/* --18 dummy nozzle*/}
            {formik?.values?.nozzle[5]?.dummy === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row18_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  DUMMY NOZZLES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[5]?.size || "N/A"} &nbsp;" NB,
                  SCH &nbsp;&nbsp;&nbsp;{" "}
                  {formik?.values?.nozzle[5]?.schedules || "N/A"}
                  &nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[5]?.flangeType || "N/A"}
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.vesselMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[5].quantity}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.dummyNozzle || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.dummyNozzle || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.dummyNozzle || "0.00"}
                </td>
              </tr>
            )}
            {/* --19 dummy flange */}
            {formik?.values?.nozzle[5]?.dummy === true && (
              <tr style={trClass}>
                <td
                  style={tdClass}
                  align="center"
                  id={`row19_stack${index}`}
                  className="conditionalRow"
                ></td>
                <td colSpan="4" style={tdClass}>
                  DUMMY NOZZLE FLANGES
                </td>
                <td colSpan="4" style={tdClass}>
                  &nbsp; {formik?.values?.nozzle[5]?.size || "N/A"} &nbsp; "
                  &nbsp;
                  {formik?.values?.nozzle[5]?.size > 24
                    ? "AS PER ANSI 16.47"
                    : "AS PER ANSI 16.5"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {formik?.values?.nozzle[5]?.flangeRating || "N/A"} &nbsp; lb
                </td>
                <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
                  <div align="center">
                    {formik?.values?.FlangeMaterialCode || "N/A"}
                  </div>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.nozzle[5].quantity}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.netWeight?.dummyFlange || "N/A"}
                </td>
                <td style={tdClass} align="right">
                  {formik?.values?.groundWeight?.dummyFlange || "N/A"}
                </td>
                <td style={tdClass} align="center">
                  KG
                </td>
                <td colSpan="2" style={tdClass} align="right">
                  {formik?.values?.price?.dummyFlange || "0.00"}
                </td>
              </tr>
            )}
          </>
        )}
        {/* --20 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row20_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            BAFFLE
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.baffleOD || "N/A"}&nbsp;MM
            &nbsp;DIA,&nbsp;PLATE &nbsp;
            {formik?.values?.baffleplatethickness || "N/A"} MM THICK &nbsp;
            {formik?.values?.bafflematerial || "N/A"}
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center"> {formik?.values?.bafflematerial || "N/A"}</div>
          </td>
          <td style={tdClass} align="center">
            {
              formik.values.multistackvessel === 1 || index === 0
                ? formik?.values?.numberofHotBaffles
                : formik.values.multiStackVesselFormData?.[index]?.baffle?.numberofHotBaffles || "N/A"
            }
          </td>
          <td style={tdClass} align="right">
            {(formik?.values?.netWeight?.baffle[index]) || "N/A"}{" "}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.baffle[index] || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.baffle[index] || "0.00"}
          </td>
        </tr>
        {/* --21 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row21_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            TIE ROD WITH SLEEVES
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.tieroddia || "N/A"}&nbsp;MM DIA, &nbsp;&nbsp;&nbsp;
            {formik?.values?.bafflematerial || "N/A"}
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <p align="center">{formik?.values?.tiematerial || "N/A"}</p>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.tierodquantity || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.tieRodWithSleeves || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.tieRodWithSleeves || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.tieRodWithSleeves || "0.00"}
          </td>
        </tr>
        {/* --22 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row22_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            LUGS SUPPORT
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.installationPosition || "N/A"}
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center">{formik?.values?.sadlemoc || "N/A "}</div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.lugsSupport || "N/A"}
          </td>

          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.lugsSupport || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.lugsSupport || "0.00"}
          </td>
        </tr>
        {/* --23 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row23_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            JUNCTION BOX
          </td>
          <td colSpan="4" style={{ ...tdClass, whiteSpace: "nowrap" }}>
            {formik?.values?.instrumentjunctionboxmaterial || "N/A"}
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center">
              {formik?.values?.heaterterminalboxmaterial || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.netWeight?.junctionBox}
          </td>
          <td style={tdClass} align="right">
            {formik?.values?.groundWeight?.junctionBox}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.price?.junctionBox || "N/A"}
            </div>
          </td>
        </tr>
        {/* --24 */}
        {formik.values.areaClassification === "hazardous" && (
          <tr style={trClass}>
            <td style={tdClass} align="center" id={`row24_stack${index}`}></td>

            <td colSpan="4" style={tdClass}>
              TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP
            </td>
            <td colSpan="4" style={tdClass}>
              {formik?.values?.terminalBoxIfFlpOrNonFlp?.toLowerCase() === "yes"
                ? "20 MM THICK BASE PLATE WITH SLEEVE WELDING"
                : "150 MM LONG 3 MM THICK TERMINAL COVER"}
            </td>
            <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
              <div align="center">
                {formik?.values?.heaterterminalboxmaterial || "N/A"}
              </div>
            </td>
            <td style={tdClass} align="center">
              {formik?.values?.multistackvessel || "N/A"}
            </td>
            <td style={tdClass} align="right">
              {formik?.values?.netWeight?.terminalBoxBasePlate || "N/A"}
            </td>
            <td style={tdClass} align="right">
              {formik?.values?.groundWeight?.terminalBoxBasePlate || "N/A"}
            </td>
            <td style={tdClass} align="center">
              KG
            </td>
            <td colSpan="2" style={tdClass}>
              <div align="right">
                {formik?.values?.price?.terminalBoxBasePlate || "0.00"}
              </div>
            </td>
          </tr>)}
        {/* --25 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row25_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            CABLE GLANDS
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.cableGland || "N/A"} &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CABLE GLAND
          </td>
          <td style={{ ...tdClass, whiteSpace: "nowrap" }}>
            <div align="center">{formik?.values?.cableGlandMaterial}</div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.cableGland}
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.price?.cableGland || "0.00"}

            {/* FORMULA :::::::: (terminal_box_type === "Ex'D" ? 2 : 1) * ROUND (((3 * each_bundle_wattage / electricity_supply) / cable_gland qty), (1)) * cable_gland qty * 2.5  */}
          </td>
        </tr>
        {/* --26 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row26_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            THERMOWELL
          </td>
          <td colSpan="4" style={{ ...tdClass, whiteSpace: "nowrap" }}>
            {formik?.values?.thermowellMaterial || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.thermowellMaterial || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            1
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.price?.priceOfThermowell || "0.00"}
            </div>
          </td>
        </tr>
        {/* --27 */}
        {formik?.values?.sensor[0].selected === "yes" && (
          <tr style={trClass}>
            <td style={tdClass} align="center" id={`row27_stack${index}`}></td>

            <td colSpan="4" style={tdClass}>
              SAFETY THERMOCOUPLE
            </td>
            <td colSpan="4" style={tdClass} align="center"></td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              {parseInt(formik?.values?.sensor[1].quantity) +
                parseInt(formik?.values?.sensor[0].quantity) || "N/A"}
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              No.
            </td>
            <td colSpan="2" style={tdClass}>
              <div align="right">
                {formik?.values?.price?.safetyThermocouple || "0.00"}
              </div>
            </td>
          </tr>
        )}

        {/* --28 */}
        {formik?.values?.sensor[2].selected === "yes" && (
          <tr style={trClass}>
            <td style={tdClass} align="center" id={`row28_stack${index}`}></td>

            <td colSpan="4" style={tdClass}>
              PROCESS THERMOCOUPLE
            </td>
            <td colSpan="4" style={tdClass}>
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              {formik?.values?.sensor[2].quantity}
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              No.
            </td>

            <td colSpan="2" style={tdClass}>
              {/* process sensor quantity * rate of process tc rtd th */}
              <div align="right">
                {formik?.values?.price?.processThermocouple || "0.00"}
              </div>
            </td>
          </tr>
        )}
        {/* --29 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row29_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            CONNECTION
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.connection || "N/A"} &nbsp; &nbsp;
            {formik?.values?.bank || "N/A"}
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.price?.priceOfConnections || "0.00"}
            </div>
          </td>
        </tr>
        {/* --30 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row30_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            HARDWARE
          </td>
          <td colSpan="4" style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.price?.priceOfHardware || "0.00"}
            </div>
          </td>
        </tr>
        {/* --31 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row31_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            GASKETS
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.gaskets || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.gaskets === "NEOPRENE"
                ? "NEOPRENE"
                : formik?.values?.gaskets.trim().split(" ")[0]}{" "}
            </div>
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.price?.priceOfGaskets || "0.00"}
            </div>
          </td>
        </tr>
        {/* --32 */}
        {formik.values.insulation.insulationThickness &&
          <tr style={trClass}>
            <td style={tdClass} align="center" id={`row32_stack${index}`}></td>

            <td colSpan="4" style={tdClass}>
              INSULATION
            </td>
            <td colSpan="4" style={tdClass}>
              {formik?.values?.insulation?.insulationThickness || "N/A"} &nbsp; MM &nbsp;
              &nbsp; &nbsp; THICK &nbsp; &nbsp; &nbsp; GLASS WOOL
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              &nbsp;
            </td>
            <td style={tdClass} align="center">
              M3
            </td>
            <td colSpan="2" style={tdClass} align="right">
              {formik?.values?.price?.insulationCost || "0.00"}
              {/* <div align="right">
              {prices.find((item) => item.id === 34)?.price * 1.2 || "0.00"}
            </div> */}
            </td>
          </tr>
        }
        {/* --33 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row33_stack${index}`}></td>
          <td colSpan="4" style={tdClass}>
            RADIOGRAPHY
          </td>
          <td colSpan="4" style={tdClass}>
            {radiographyLabel || "N/A"}
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            {radiographyValue}%
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik.values.price.radiographyCost}
            </div>
          </td>
        </tr>
        {/* --34 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id={`row34_stack${index}`}></td>

          <td colSpan="4" style={tdClass}>
            PAINTING
          </td>
          <td colSpan="4" style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            %
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik.values?.price?.paintingCost || "0.00"}
          </td>
        </tr>

        {formik?.values?.miscellaneous.map((item, miscIndex) => (
          <tr key={miscIndex} style={trClass}>
            {/* Empty First Column */}
            <td style={tdClass} align="center" id={`row${35 + miscIndex}_stack${index}`}></td>

            {/* Miscellaneous Count */}
            <td colSpan="4" style={tdClass}>
              MISC {miscIndex + 1}
            </td>

            {/* Name */}
            <td colSpan="4" style={tdClass} align="left">
              {item.name}
            </td>

            {/* Material */}
            <td style={tdClass} align="center">
              {item.material}
            </td>

            {/* Quantity */}
            <td style={tdClass} align="center">
              {item.qty}
            </td>

            {/* Net Weight */}
            <td style={tdClass}>{item.netWeight}</td>

            {/* Empty Columns */}
            <td style={tdClass}>{item.groundWeight}</td>
            <td style={tdClass} align="center">
              {item.unit}
            </td>
            <td style={tdClass} colSpan={2} align="right">
              {item.price}
            </td>
          </tr>
        ))}

        <tr style={trClass}>
          <td colSpan="5" rowSpan="2" style={tdClass} align="center">
            <strong>TOTAL MATERIAL COST </strong>
          </td>
          <td colSpan="2" rowSpan="2" style={tdClass}>
            Prepared By
          </td>
          <td colSpan="2" rowSpan="2" style={tdClass}>
            Approved By
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT MATERIAL COST
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td
            colSpan="2"
            style={{ ...tdClass, }}
            align="right"
          >
            {formik?.values?.finalCosting?.unitMaterialCost[index]}
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" style={tdClass}>
            BUNDLE MATERIAL COST
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass} align="right">
            {formik?.values?.finalCosting?.bundleMaterialCost[index] || "0.00"}
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">MANUFACTURING COST @</div>
          </td>
          <td width="6%" colSpan="1" rowSpan="2" style={tdClass} align="center">
            {formik?.values?.manufacturingCost || 0}%
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.COGS?.unit[index] || "0.00"}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT COGS
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {" "}
              {formik?.values?.finalCosting?.unitCogs[index] || "0.00"}{" "}
            </div>
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.COGS?.bundle[index] || "0.00"}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE COGS
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.finalCosting?.bundleCogs[index] || "0.00"}
            </div>
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">MARGIN @ </div>
          </td>
          <td rowSpan="2" style={tdClass} align="center">
            {formik?.values?.margin || 0}%
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.sellingPrice?.unit[index] || "0.00"}{" "}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT SELLING PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.finalCosting?.unitSellingPrice[index] || "0.00"}{" "}
            </div>
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.sellingPrice?.bundle[index] || "0.00"}{" "}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE SELLING PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.finalCosting?.bundleSellingPrice[index] || "0.00"}
            </div>
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">CONTINGENCY @ </div>
          </td>
          <td rowSpan="2" style={tdClass} align="center">
            {formik?.values?.contingency || 0}%
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.quotedPrice?.unit[index] || "0.00"}{" "}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT QUOTED PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.finalCosting?.unitQuotedPrice[index] || "0.00"}{" "}
            </div>
          </td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.unitBundle?.quotedPrice?.bundle[index] || "0.00"}{" "}
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE QUOTED PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              {formik?.values?.finalCosting?.bundleQuotedPrice[index] || "0.00"}{" "}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}
export default BOM;

// function fetchPineSize() {
//   axiosPrivate
//     .post("/get/flange-weight", {
//       flange_rating: formik?.values?.FlangeClass,
//       nps: formik?.values?.flangeSize.toString(),
//     })
//     .then((response) => {
//       set_tube_size_nt_wt(response.data?.data);
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// }

// function fetchShellRingNetWt() {
//   axiosPrivate
//     .post("/get/flange-weight-in-kg", {
//       flange_type: formik?.values?.vesselFlangeType,
//       pipe_size: formik?.values?.flangeSize,
//       flange_rating: formik?.values?.FlangeClass.toString(),
//     })
//     .then((response) => {
//       set_shell_ring_nt_wt(response.data?.data);
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// }

// function fetchShellVesselNetWt() {
//   axiosPrivate
//     .post("/get/pipe-weight", {
//       sch: parseInt(formik?.values?.schedule),
//       nps: parseFloat(formik?.values?.flangeSize),
//     })
//     .then((response) => {
//       set_shell_vessel_nt_wt(response.data?.data);
//     })
//     .catch((error) => {
//       console.error("Error", error);
//     });
// }

// const calculateNetWeightTerminalBox = (
//   installationPosition = "horizontal",
//   flangeSize = 10
// ) => {
//   const designData = [
//     23.69, 29.74, 36.86, 44.48, 51.09, 58.85, 68.13, 78.01, 92.32, 106.54,
//     108.9, 124.21, 140.53, 157.84, 160.5, 178.92, 198.33,
//   ];

//   let designValue;
//   if (flangeSize > 24) {
//     const index = flangeSize - 25;
//     designValue = designData[index + 9];
//   } else {
//     const mapping = { 1.5: 2, 2.5: 3, 3.5: 4 };
//     const index = mapping[flangeSize] ?? flangeSize - 1;
//     designValue = designData[index];
//   }

//   return installationPosition.toLowerCase() === "horizontal"
//     ? designValue
//     : designValue / 2;
// };

// const calculateBaffleNetWeight = (
//   flangeSize = formik?.values?.flangeSize,
//   bafflePlatethickness = formik?.values?.bafflePlatethickness,
//   numberOfbaffle = formik?.values?.numberofHotBaffles,
//   schedule = formik?.values?.schedule,
//   shellThickness = formik?.values?.shellThickness,
//   baffleType = formik?.values?.baffletype,
//   shellThicknessIfNonStandard = 0
// ) => {
//   const BOMV15 =
//     schedule === 0 ? shellThicknessIfNonStandard : shellThickness;
//   const AA18 =
//     (3.14 *
//       (((flangeSize - 10 - 2 * BOMV15) *
//         bafflePlatethickness *
//         numberOfbaffle *
//         8) /
//         1000000)) /
//     4;
//   const result =
//     Math.round((3.14 / 4) * AA18, 2) * baffleType === "ROD BAFFLE"
//       ? 1.5
//       : baffleType === "STRIP BAFFLE"
//       ? 1.7
//       : baffleType === "GUIDE FLOW BAFFLE"
//       ? 2.25
//       : baffleType === "WIRE MESH"
//       ? 2
//       : 1;
//   return result;
// };

{
  /* --33 */
}
{
  /* <tr style={trClass}>
          <td style={tdClass} align="center" id="row33"></td>

          <td colSpan="4" style={tdClass}>
            MISC 1
          </td>
          <td colSpan="4" style={tdClass}>
            MISC (DESIGN CALN, SPECIAL TESTINGS ETC.){" "}
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            &nbsp;
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 1</td>
        </tr> */
}
