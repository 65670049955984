const customerLogin = {
  customer: "",
  projectName: "",
  itemDescription: "",
  customerName: "",
  address: "",
  contactNo: "",
  email: "",
  contactPersonName: "",
  enqNo: "",
  enqDate: "",
  offerNo: "",
  offerDate: "",
  offerRevNo: "",
  tagNo: "",
};
export default customerLogin;
