import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";
import { flangeRatingOptions, heaterSheathTubeDiaOptions, priceUnitOptions, sheathTubeTypeOptions } from "../../utils/DropdownOptions";
import { customStyles } from "../../styles/CustomStylesForSelect";

function PriceEdit() {
  const [item, setItem] = useState({
    id: null,
    name: "",
    material_category: "",
    material: "",
    unit: null,
    price: null,
    type: null,
    size: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !item.id === "" ||
      !item.name === "" ||
      !item.material_category === "" ||
      !item.material === "" ||
      !item.size === "" ||
      !item.class === "" ||
      !item.type === "" ||
      !item.unit === "" ||
      !item.price === ""
    ) {
      showErrorToast("Fill all fields");
      return;
    }

    setIsLoading(true);

    try {
      const payload = {
        id: item.id,
        name: item.name,
        material_category: item.material_category,
        material: item.material,
        size: item.size,
        type: item.type,
        unit: item.unit,
        price: item.price,
      };

      await axiosPrivate.put(`/bom-prices/${id}`, payload);
      showSuccessToast("Item updated successfully");
      navigate("/price");
    } catch (error) {
      showErrorToast("Error updating Item ");
    } finally {
      setIsLoading(false);
    }
  };

  const { id } = useParams();
  const [nameOptions, setNameOptions] = useState([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchPriceData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/bom-prices/${id}`);
      if (response.status === 200) {
        const data = response.data.data;
        setItem({
          id: data?.id,
          name: data?.name,
          material_category: data?.material_category,
          material: data?.material,
          unit: data?.unit,
          price: data?.price,
          size: data?.size,
          type: data?.type,
        });
      }
    } catch (error) {
      showErrorToast("Error fetching price data");
      navigate("/price");
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialsList = async () => {
    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        setNameOptions(
          [...new Set(data.map((dataItem) => dataItem.name))].map((name) => ({
            value: name,
            label: name,
          }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleNameChange = async (selectedName) => {
    setItem({
      ...item,
      name: selectedName.value,
      material_category: "",
      material: "",
    });

    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        const filteredData = data.filter(
          (dataItem) => dataItem.name === selectedName.value
        );

        setMaterialCategoryOptions(
          [
            ...new Set(
              filteredData.map((dataItem) => dataItem.material_category)
            ),
          ].map((material_category) => ({
            value: material_category,
            label: material_category,
          }))
        );

        setMaterialOptions(
          [...new Set(filteredData.map((dataItem) => dataItem.material))].map(
            (material) => ({
              value: material,
              label: material,
            })
          )
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleMaterialCategoryChange = async (selectedCategory) => {
    setItem({
      ...item,
      material_category: selectedCategory.value,
    });

    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        const filteredMaterials = data.filter(
          (material) =>
            material.material_category === selectedCategory.value &&
            material.name === item.name
        );

        setMaterialOptions(
          filteredMaterials.map((dataItem) => ({
            value: dataItem.material,
            label: dataItem.material,
          }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    fetchPriceData();
    getMaterialsList();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit Price</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="col">
                        <div className="w-100">
                          <div className="w-100 row mb-15">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="name">Name</label>
                              <Select
                                name="name"
                                id="name"
                                className="mr-3"
                                options={nameOptions}
                                styles={customStyles}
                                placeholder="Select Name"
                                value={
                                  item.name && {
                                    value: item.name,
                                    label: item.name,
                                  }
                                }
                                onChange={(option) => handleNameChange(option)}
                              />
                            </div>
                            {/* Material Category */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="material_category">Material Category</label>
                              <Select
                                name="material_category"
                                id="material_category"
                                className="mr-3"
                                options={materialCategoryOptions}
                                styles={customStyles}
                                placeholder="Select material category"
                                value={
                                  item.material_category && {
                                    value: item.material_category,
                                    label: item.material_category,
                                  }
                                }
                                onChange={(option) => handleMaterialCategoryChange(option)}
                                isDisabled={false}
                              />
                            </div>
                            {/* Material Code */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="material">Material</label>
                              <Select
                                name="material"
                                id="material"
                                className="mr-3"
                                options={materialOptions}
                                styles={customStyles}
                                placeholder="Select material"
                                value={
                                  item.material && {
                                    value: item.material,
                                    label: item.material,
                                  }
                                }
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    material: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="sub_type">Unit</label>
                              <Select
                                name="unit"
                                id="unit"
                                className="mr-3"
                                options={priceUnitOptions}
                                styles={customStyles}
                                value={
                                  item.unit && {
                                    value: item.unit,
                                    label: item.unit,
                                  }
                                }
                                placeholder="Select unit"
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    unit: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>
                          </div>
                          {item?.name == "TUBE" && (
                            <div className="row mb-30">
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <label htmlFor="type">Type</label>
                                <Select
                                  name="type"
                                  id="type"
                                  className="mr-3"
                                  options={sheathTubeTypeOptions}
                                  styles={customStyles}
                                  value={
                                    item.type
                                      ? {
                                        value: item.type,
                                        label: item.type,
                                      }
                                      : null
                                  }
                                  placeholder="Select Type"
                                  onChange={(option) =>
                                    setItem({
                                      ...item,
                                      type: option?.value ?? option,
                                    })
                                  }
                                  isDisabled={false}
                                />
                              </div>

                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <label htmlFor="size">Size</label>
                                <Select
                                  name="size"
                                  id="size"
                                  className="mr-3"
                                  options={heaterSheathTubeDiaOptions}
                                  styles={customStyles}
                                  value={
                                    item.size
                                      ? { value: item.size, label: `${item.size}` }
                                      : null
                                  }
                                  placeholder="Select Size"
                                  onChange={(option) =>
                                    setItem({
                                      ...item,
                                      size: option?.value ?? option,
                                    })
                                  }
                                  isDisabled={false}
                                />
                              </div>
                            </div>
                          )}

                          <div className="w-100 row mb-30">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="price">Price</label>
                              <input
                                name="price"
                                type="text"
                                className="form-control mr-3"
                                placeholder="Item price"
                                value={item.price}
                                onChange={(e) =>
                                  setItem({ ...item, price: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/price">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceEdit;
