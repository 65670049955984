import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HeaterBundleWithoutVessel from "../../DataSheet/HeaterBundleWithoutVessel";
import HeaterBundleWithVessel from "../../DataSheet/HeaterBundleWithVessel";

function Screen7({ formik }) {
  const { values, setFieldValue } = formik;
  const [template, setTemplate] = useState("");
  const [data, setData] = useState({ name: "John Doe" });
  const [previewUrl, setPreviewUrl] = useState(null);
  const templateDir = "../../assets/heaterbundle.html";
  const handleRadioChange = (e) => {
    setFieldValue("selectedDrawing", e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik?.setFieldValue("drawingFile", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUnselect = () => {
    setFieldValue("selectedDrawing", "");
  };
  const tableParentDiv = {
    display: "flex",
    margin: "10px 0px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid black",
  };

  const trClass = {
    height: "22px",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await fetch(templateDir);
      const text = await response.text();
      setTemplate(text);
    };
    fetchTemplate();
  }, []);

  const replaceVariables = (html, data) => {
    const regex = /\{\{([^}]+)\}\}/g;
    return html.replace(regex, (match, variable) => data[variable] || "");
  };

  const printPDF = (elementId) => {
    const input = document.getElementById(elementId);
    const scale = 2 / window.devicePixelRatio;

    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  const renderedHtml = replaceVariables(template, data);

  return (
    <>
      {formik?.values?.vesselSelection === "no" ? (
        <div>
          <button
            className="btn btn-outline-danger"
            style={{ margin: "10px 70px" }}
            onClick={(e) => {
              e.preventDefault();
              printPDF("no-vessel"); // Use "no-vessel" for no vessel selection
            }}
          >
            Print PDF
          </button>
          <div id="no-vessel" style={{ color: "black", padding: "20px 70px" }}>
            <HeaterBundleWithoutVessel
              formik={formik}
              trClass={trClass}
              tdClass={tdClass}
              tableClass={tableClass}
              tableParentDiv={tableParentDiv}
            />
          </div>
        </div>
      ) : formik?.values?.vesselSelection === "yes" ? (
        <div>
          <button
            className="btn btn-outline-danger"
            style={{ margin: "10px 70px" }}
            onClick={(e) => {
              e.preventDefault();
              printPDF("yes-vessel"); // Use "yes-vessel" for yes vessel selection
            }}
          >
            Print PDF
          </button>
          <div id="yes-vessel" style={{ color: "black", padding: "20px 70px" }}>
            <HeaterBundleWithVessel
              formik={formik}
              trClass={trClass}
              tdClass={tdClass}
              tableClass={tableClass}
              tableParentDiv={tableParentDiv}
            />
            {/* <ControlPanel formik={formik} /> */}
          </div>
          <hr />
        </div>
      ) : (
        <h1>Please Select first </h1>
      )}
      <div className="mt-5 pl-70">
        <label htmlFor="drawingSelection" style={{ fontWeight: "bold" }}>
          Drawing Selection
        </label>
        <div id="drawingSelection">
          <div className="d-flex align-items-center mt-30">
            <input
              type="radio"
              id="SafeAreaHeaterBundle"
              name="drawing"
              value="SafeAreaHeaterBundle"
              checked={values.selectedDrawing === "SafeAreaHeaterBundle"}
              onChange={handleRadioChange}
            />
            <label htmlFor="SafeAreaHeaterBundle" className="ml-3 flex-grow-1">
              SAFE AREA HEATER BUNDLE
            </label>
          </div>
          <div className="d-flex align-items-center mt-2">
            <input
              type="radio"
              id="SafeAreaHeaterBundleWithVessel"
              name="drawing"
              value="SafeAreaHeaterBundleWithVessel"
              checked={
                values.selectedDrawing === "SafeAreaHeaterBundleWithVessel"
              }
              onChange={handleRadioChange}
            />
            <label
              htmlFor="SafeAreaHeaterBundleWithVessel"
              className="ml-3 flex-grow-1"
            >
              SAFE AREA BUNDLE WITH VESSEL
            </label>
          </div>
          <div className="d-flex align-items-center mt-2">
            <input
              type="radio"
              id="HazardousAreaHeaterBundle"
              name="drawing"
              value="HazardousAreaHeaterBundle"
              checked={values.selectedDrawing === "HazardousAreaHeaterBundle"}
              onChange={handleRadioChange}
            />
            <label
              htmlFor="HazardousAreaHeaterBundle"
              className="ml-3 flex-grow-1"
            >
              HAZARDOUS AREA HEATER BUNDLE
            </label>
          </div>
          <div className="d-flex align-items-center mt-2">
            <input
              type="radio"
              id="HazardousAreaHeaterBundleWithVessel"
              name="drawing"
              value="HazardousAreaHeaterBundleWithVessel"
              checked={
                values.selectedDrawing === "HazardousAreaHeaterBundleWithVessel"
              }
              onChange={handleRadioChange}
            />
            <label
              htmlFor="HazardousAreaHeaterBundleWithVessel"
              className="ml-3 flex-grow-1"
            >
              HAZARDOUS AREA BUNDLE WITH VESSEL
            </label>
          </div>

          <div>
            <div className="d-flex align-items-center mt-2">
              <input
                type="radio"
                id="SpecialUpload"
                name="drawing"
                value="SpecialUpload"
                checked={values.selectedDrawing === "SpecialUpload"}
                onChange={handleRadioChange}
              />
              <label htmlFor="SpecialUpload" className="ml-3 flex-grow-1">
                Any Special Upload Files
              </label>
              {values.selectedDrawing === "SpecialUpload" && (
                <>
                  <input
                    type="file"
                    id="drawingFile"
                    name="drawingFile"
                    className="ml-2"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleUnselect}
            >
              Unselect
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Screen7;
