import React, { useState, useEffect } from "react";
import logo2 from "../../assets/Logos/logo.png";
function HeaterBundleWithoutVessel({
  formik,
  tableParentDiv,
  tableClass,
  tdClass,
  trClass,
}) {
  const [template, setTemplate] = useState("");
  const [data, setData] = useState({ name: "John Doe" });
  const [previewUrl, setPreviewUrl] = useState(null);
  const templateDir = "../../assets/heaterbundle.html";
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await fetch(templateDir);
      const text = await response.text();
      setTemplate(text);
    };

    fetchTemplate();
  }, []);

  const replaceVariables = (html, data) => {
    const regex = /\{\{([^}]+)\}\}/g;
    return html.replace(regex, (match, variable) => data[variable] || "");
  };
  const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;
  const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
  const bundleHeatLoad = installedCapacity / effectiveVessel;
  const areaClassification = formik?.values?.areaClassification;
  const NoOfStack = formik?.values?.multistackvessel;

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = value;

    const total =
      bundleHeatLoad - updatedTableData[rowIndex].bundle[colIndex].bank;
    const bank = formik.values.bank - 1;
    const eachData = total / bank;
    updatedTableData[rowIndex].bundle.map((data, index) => {
      if (index !== colIndex) {
        data.bank = eachData;
      }
    });

    formik.setFieldValue("bundleTableData", updatedTableData);
  };
  const renderedHtml = replaceVariables(template, data);

  const handleSurfaceArea = (e) => {
    const power = parseFloat(formik?.values?.installedCapacity) || 0;
    const multistackvessel = parseFloat(formik?.values?.multistackvessel) || 1;
    const wattDensity = parseFloat(formik?.values?.wattdensity) || 0;

    if (wattDensity === 0) {
      formik.setFieldValue("totalsurfacearea", "0.00");
      return;
    }

    const individualElementWattage = (power / multistackvessel) * 1000;
    const totalElementWattage =
      individualElementWattage / (wattDensity * 10000);
    formik.setFieldValue("totalsurfacearea", totalElementWattage.toFixed(2));
  };

  const heattransfercoefficient = (e) => {
    const installedCapacity =
      parseFloat(formik?.values?.installedCapacity) || 0;
    const multistackvessel = parseFloat(formik?.values?.multistackvessel) || 1;
    const wattdensity = parseFloat(formik?.values?.wattdensity) || 0;
    const sheathtemp = parseFloat(formik?.values?.sheathtemp) || 0;
    const outletTemp = parseFloat(formik?.values?.outletTemp) || 0;

    // Ensure the denominator is not zero to avoid division by zero
    const temperatureDifference = Math.max(sheathtemp - outletTemp, 0);
    const denominator = wattdensity * temperatureDifference;

    let heatTransferCoefficient = 0;
    if (denominator > 0) {
      heatTransferCoefficient =
        ((installedCapacity / multistackvessel) * 1000) / denominator;
    }

    formik.setFieldValue(
      "heatTransferCoefficient",
      Math.max(heatTransferCoefficient, 0).toFixed(2)
    );
  };

  useEffect(() => {
    handleSurfaceArea();
    heattransfercoefficient();
  }, [
    formik?.values?.heatDutyKW,
    formik?.values?.multistackvessel,
    formik?.values?.wattdensity,
    formik.values.outletTemp,
  ]);

  const getImageSrc = () => {
    switch (formik?.values.selectedDrawing) {
      case "HazardousAreaHeaterBundle":
        return "/assets/images/hazardous.png";
      case "HazardousAreaHeaterBundleWithVessel":
        return "/assets/images/hazardousvessel.png";
      case "SafeAreaHeaterBundleWithVessel":
        return "/assets/images/safevessel.png";
      case "SafeAreaHeaterBundle":
        return "/assets/images/safe.png";
      case "SpecialUpload":
        return previewUrl;
      default:
        return "";
    }
  };

  const radiographyValue = formik.values.radiography?.value;
  const radiographyLabel = formik.values.radiography?.label;

  useEffect(() => {
    if (
      formik?.values.drawingFile &&
      formik?.values.drawingFile instanceof Blob
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(formik?.values.drawingFile);
    }
  }, [formik?.values.drawingFile]);

  useEffect(() => {
    handleDrawingSelection();
  }, [formik?.values?.selectedDrawing]);
  const tableHeight = tableData.length * 50;
  const handleDrawingSelection = (e) => {
    const drawingType = formik?.values?.selectedDrawing;

    let rows = [];
    setTableData(rows);

    if (drawingType === "HazardousAreaHeaterBundle") {
      rows = [
        {
          code: "A",
          partName: "Bundle Immersion Length",
          size: `${formik?.values?.bundleimmersionlength || 0} mm`,
        },
        {
          code: "B",
          partName: "Active Length",
          size: `${formik?.values?.maxactivehotlength || 0} mm`,
        },
        {
          code: "C",
          partName: "Bundle Diameter",
          size: `${formik?.values?.baffleOD || 0} mm`,
        },
        {
          code: "D",
          partName: "Stand Off",
          size: `${formik?.values?.distbwheaterflangetoterminalbox || 0} mm`,
        },
      ];
    } else if (drawingType === "HazardousAreaHeaterBundleWithVessel") {
      rows = [
        {
          code: "A",
          partName: "Approx Vessel Length",
          size: `${formik?.values?.approxvessellength || 0} mm`,
        },
        {
          code: "B",
          partName: "Stand Off",
          size: `${formik?.values?.distbwheaterflangetoterminalbox || 0} mm`,
        },
      ];
    } else if (drawingType === "SafeAreaHeaterBundleWithVessel") {
      rows = [
        {
          code: "A",
          partName: "Approx Vessel Length",
          size: `${formik?.values?.approxvessellength || 0} mm`,
        },
      ];
    } else if (drawingType === "SafeAreaHeaterBundle") {
      rows = [
        {
          code: "A",
          partName: "Bundle Immersion Length",
          size: `${formik?.values?.bundleimmersionlength || 0} mm`,
        },
        {
          code: "B",
          partName: "Active Length",
          size: `${formik?.values?.maxactivehotlength || 0} mm`,
        },
        {
          code: "C",
          partName: "Bundle Diameter",
          size: `${formik?.values?.baffleOD || 0} mm`,
        },
      ];
    }
    setTableData(rows);
  };

  // useEffect(() => {
  //   if (
  //     formik?.values?.multistackvessel === 2 &&
  //     formik?.values?.selectedDrawing === "safe"
  //   ) {
  //     formik?.setFieldValue("/assets/images/twoStagesSafe.png");
  //   } else if (
  //     formik?.values?.multistackvessel === 2 &&
  //     formik?.values?.selectedDrawing === "hazardous"
  //   ) {
  //     formik?.setFieldValue("/assets/images/twoStagesHazardous.png");
  //   }
  // }, []);

  return (
    <div>
      <div
        style={{
          border: "1px solid black",
          margin: "10px 0",

          position: "relative",
        }}
      >
        <img
          src={logo2}
          alt="abc"
          style={{ height: "auto", width: "200px", margin: "10px " }}
        />
        <h1
          style={{
            fontSize: "25px",
            position: "absolute",
            top: "25%",
            left: "27%",
            margin: "10px 0",
          }}
        >
          TECHNICAL DATA SHEET - ELECTRIC HEATER BUNDLE
        </h1>
      </div>

      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>CUSTOMER</strong>
            </td>
            <td style={tdClass}>{formik?.values?.customer || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>ADDRESS</strong>
            </td>
            <td style={tdClass}>{formik?.values?.address || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>PROJECT NAME</strong>
            </td>
            <td style={tdClass}>{formik?.values?.projectName || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>ITEM DESCRIPTION</strong>
            </td>
            <td style={tdClass}>{formik?.values?.itemDescription || "N/A"}</td>
          </tr>
        </table>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>ENQ. NO & DATE</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.enqNo || "N/A"}&nbsp;
              {formik?.values?.enqDate || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>OFFER NO & DATE</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.offerNo || "N/A"}&nbsp;
              {formik?.values?.offerDate || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>OFFER REV NO .</strong>
            </td>
            <td style={tdClass}>{formik?.values?.offerRevNo || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>TAG NO .</strong>
            </td>
            <td style={tdClass}>{formik?.values?.tagNo || "N/A"}</td>
          </tr>
        </table>
      </div>

      {/* PROCESS PARAMETERS */}
      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td
              colSpan={formik?.values?.multiStackVesselFormData.length + 5}
              bgcolor="#CCCCCC"
              style={tdClass}
            >
              <div align="center">
                <strong>PROCESS PARAMETERS</strong>
              </div>
            </td>
          </tr>
          <tr bgcolor="#CCCCCC" style={trClass}>
            <td style={tdClass}>
              <strong>Specification</strong>
            </td>
            <td style={tdClass}>
              <strong>
                {formik?.values?.multiStackVesselFormData?.length === 1
                  ? null
                  : "Stage 1"}
              </strong>
            </td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  <strong>Stage {index + 1}</strong>
                </td>
              )
            )}
            <td style={tdClass}>
              <strong>Unit</strong>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Process Fluid</strong>
            </td>
            <td style={tdClass}>{formik?.values?.processFluid || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>{formik?.values?.processFluid}</td>
              )
            )}
            <td style={tdClass}> </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Mass Flow Rate</strong>
            </td>
            <td style={tdClass}>{formik?.values?.massFlowRate || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>{formik?.values?.massFlowRate || "N/A"}</td>
              )
            )}
            <td style={tdClass}>KG/HR</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Sp. Heat</strong>
            </td>
            <td style={tdClass}>{formik?.values?.specificHeatKJ || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.specificHeatKJ || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>kj/kg.°C</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Viscosity</strong>
            </td>
            <td style={tdClass}>{formik?.values?.viscosity || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>{formik?.values?.viscosity || "N/A"}</td>
              )
            )}
            <td style={tdClass}>centipose</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Thermal Conductivity</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.thermalConductivity || "N/A"}
            </td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.thermalConductivity || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>w/m°k</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Mol. Weight</strong>
            </td>
            <td style={tdClass}>{formik?.values?.molecularweight || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.molecularweight || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>mol(g)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Inlet Temperature</strong>
            </td>
            <td style={tdClass}>{formik?.values?.startingTemp || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik.values.multiStackVesselFormData[index]?.temp
                    ?.inlettemp || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>{formik?.values?.unit || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Outlet Temperature</strong>
            </td>
            <td style={tdClass}>{formik?.values?.outletTemp || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik.values.multiStackVesselFormData[index]?.temp
                    ?.outlettemp || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>{formik?.values?.unit || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Design temperature</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.designTemperature || "N/A"}
            </td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.designTemperature || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>{formik?.values?.unit || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Inlet Pressure</strong>
            </td>
            <td style={tdClass}>{formik?.values?.inletPressure || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.multiStackVesselFormData[index]
                    ?.inletPressure || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>
              {formik?.values?.inletPressureUnit || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Outlet Pressure</strong>
            </td>
            <td style={tdClass}>{formik?.values?.outletPressure || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.multiStackVesselFormData[index]
                    ?.outletPressure || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>
              {formik?.values?.outletPressureUnit || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Pressure Drop (Allowable)</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.allowedPressureDrop || "N/A"}
            </td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.multiStackVesselFormData[index]
                    ?.allowedPressureDrop || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>
              {formik?.values?.allowedPressureDropUnit || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Design Pressure </strong>
            </td>
            <td style={tdClass}>{formik?.values?.designPressure || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.designPressure || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>
              {formik?.values?.allowedPressureDropUnit || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>MDMT</strong>
            </td>
            <td style={tdClass}>{formik?.values?.mdmt || "N/A"}</td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>{formik?.values?.mdmt || "N/A"}</td>
              )
            )}
            <td style={tdClass}>{formik?.values?.unit || "N/A"} </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Corrosion allowance</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.corrosionAllowance || "N/A"}
            </td>
            {formik?.values?.multiStackVesselFormData.map((item, index) =>
              index === 0 ? null : (
                <td style={tdClass}>
                  {formik?.values?.corrosionAllowance || "N/A"}
                </td>
              )
            )}
            <td style={tdClass}>mm</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Radiography</strong>
            </td>
            <td
              style={tdClass}
              colSpan={formik?.values?.multiStackVesselFormData.length}
              align="left"
            >
              {radiographyValue} %
            </td>
            <td style={tdClass}>&nbsp;</td>
          </tr>
        </table>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <table style={tableClass}>
            <tr style={trClass}>
              <td
                colSpan={formik?.values?.multiStackVesselFormData.length + 6}
                bgcolor="#CCCCCC"
                style={tdClass}
              >
                <div align="center">
                  <strong>ELECTRICAL PARAMETERS</strong>
                </div>
              </td>
            </tr>
            <tr bgcolor="#CCCCCC" style={trClass}>
              <td style={tdClass}> </td>
              <td colSpan="2" style={tdClass}>
                <strong>
                  {formik?.values?.multiStackVesselFormData?.length === 1
                    ? null
                    : "Bundle 1"}
                </strong>
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>
                    <strong>Bundle {index + 1}</strong>
                  </td>
                )
              )}
              <td style={tdClass}>
                <strong>UOM</strong>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Total Power</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.installedCapacity * 1000 || "N/A"}
              </td>
              <td style={tdClass} colSpan={3}>
                W
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Supply Voltage</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.voltage || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>{formik?.values?.voltage}</td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                V
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Connection Type</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.connection || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>{formik?.values?.connection}</td>
                )
              )}
              <td style={tdClass} colSpan={3}></td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Total No. of Elements</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.totalnumberofelements || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>
                    {formik?.values?.totalnumberofelements}
                  </td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                No&#39;s
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Connected Elements</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.numberofelementsconnected || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>
                    {formik?.values?.numberofelementsconnected}
                  </td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                No&#39;s
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Spare Elements</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.numberofelementsspare || "0"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>
                    {formik?.values?.numberofelementsspare}
                  </td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                No&#39;s
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Individual Element Rating</strong>
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) => (
                <td style={tdClass} colSpan={index === 0 ? 2 : 1}>
                  {Math.round(
                    (formik?.values?.installedCapacity /
                      formik?.values?.numberofelementsconnected) *
                    1000
                  )}
                </td>
              ))}
              <td style={tdClass} colSpan={3}>
                W
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Frequency</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.frequency || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>{formik?.values?.frequency}</td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                Hz
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Individual Element design voltage</strong>
              </td>
              <td colSpan="2" style={tdClass}>
                {formik?.values?.connection === "Star Connection"
                  ? formik?.values?.voltage_calculation
                  : formik?.values?.voltage || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>
                    {formik?.values?.connection === "Star Connection"
                      ? formik?.values?.voltage_calculation
                      : formik?.values?.voltage || "N/A"}
                  </td>
                )
              )}
              <td style={tdClass} colSpan={3}>
                V
              </td>
            </tr>
            <tr
              style={{
                borderCollapse: "collapse",
                height: "22px",
                border: "none",
              }}
            >
              <td rowSpan="2" style={tdClass}>
                <strong>Watt Density ( w/cm2)</strong>
              </td>
              <td style={tdClass} colSpan={2}>
                {parseFloat(formik?.values?.wattdensity).toFixed(2) || "N/A"}
              </td>
              {formik?.values?.multiStackVesselFormData.map((item, index) =>
                index === 0 ? null : (
                  <td style={tdClass}>{formik?.values?.wattdensity}</td>
                )
              )}
              <td style={tdClass}>&nbsp;</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td
                style={tdClass}
                colSpan={formik.values.bank + 1}
                bgcolor="#cccccc"
              >
                <div align="center">
                  <strong>Power Break-up (KW)</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Bundle</strong>
              </td>
              {Array.from({ length: totalBank }).map((_, i) => (
                <td key={i} style={tdClass}>
                  <strong>Bank {i + 1}</strong>
                </td>
              ))}
            </tr>
            <tbody>
              {[
                ...Array(
                  Math.max(5, formik?.values?.bundleTableData?.length || 0)
                ),
              ].map((_, rowIndex) => {
                const row = formik?.values?.bundleTableData?.[rowIndex] || {
                  bundle: [],
                }; // Default to an empty row if no data exists
                return (
                  <tr key={rowIndex} style={trClass}>
                    <td style={tdClass}>
                      <strong>
                        {" "}
                        {formik?.values?.multiStackVesselFormData.length - 1 >=
                          rowIndex && `Bundle - ${rowIndex + 1}`}
                      </strong>
                    </td>
                    {row?.bundle?.length > 0
                      ? row.bundle.map((col, colIndex) => (
                        <td key={colIndex} style={tdClass}>
                          <div>{col?.bank || "N/A"}</div>
                        </td>
                      ))
                      : [
                        ...Array(
                          formik?.values?.bundleTableData[0]?.bundle?.length
                        ),
                      ]?.map(
                        (
                          _,
                          colIndex // Default to 3 columns if no data exists
                        ) => (
                          <td key={colIndex} style={tdClass}>
                            <div>&nbsp;</div>
                          </td>
                        )
                      )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
              <div align="center">
                <strong>MATERIAL OF CONSTRUCTION</strong>
              </div>
              <div align="center"></div>
              <div align="center"></div>
              <div align="center"></div>
              <div align="center"></div>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Part Description</strong>
            </td>
            <td style={tdClass}>
              <strong>MOC</strong>
            </td>
            <td style={tdClass} align="center">
              <strong>QTY</strong>
            </td>
            <td style={tdClass}>
              <strong>UOM</strong>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Heater flange / Tube sheet</strong>
            </td>

            <td style={tdClass}>
              {formik?.values?.FlangeMaterialCode || "N/A"}
            </td>
            <td style={tdClass} align="center">
              1
            </td>
            <td style={tdClass}>Pc</td>
          </tr>

          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Baffles</strong>
            </td>
            <td style={tdClass}>{formik?.values?.bafflematerial || "N/A"}</td>
            <td style={tdClass} align="center">
              {formik?.values?.numberofHotBaffles || "N/A"}
            </td>
            <td style={tdClass}>Pc</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Tie Rods</strong>
            </td>
            <td style={tdClass}>{formik?.values?.tiematerial || "N/A"}</td>
            <td style={tdClass} align="center">
              {formik?.values?.tierodquantity || "N/A"}
            </td>
            <td style={tdClass}>Pc</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Element Outer sheath</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.sheathmaterial || "N/A"} /{" "}
              {formik?.values?.sheathtubetype || "N/A"}
            </td>
            <td style={tdClass} align="center">
              {formik?.values?.totalnumberofelements || "N/A"}
            </td>
            <td style={tdClass}>Pc</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Termial Enclosure</strong>
            </td>
            <td style={tdClass}>{formik?.values?.heaterterminalboxmaterial}</td>
            <td style={tdClass} align="center">
              1
            </td>
            <td style={tdClass}>Set</td>
          </tr>
        </table>
        <table style={tableClass}>
          <tr style={trClass}>
            <td colSpan="6" bgcolor="#CCCCCC" style={tdClass}>
              <div align="center">
                <strong>DIMENSIONAL DETAILS</strong>
              </div>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Flange Identification</strong>
            </td>
            <td style={tdClass}>
              <strong>Size</strong>
            </td>
            <td style={tdClass} align="center">
              <strong>QTY</strong>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Heater Flange</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.flangeSize || "N/A"}" NB, Sch.
              {formik?.values?.schedule || "N/A"}, &nbsp;
              {formik?.values?.FlangeClass || "N/A"}#
            </td>
            <td style={tdClass} align="center">
              2
            </td>
          </tr>
          <tr style={trClass}>
            <td bgcolor="#CCCCCC" colSpan="3" style={tdClass}>
              <div align="center">
                <strong>OTHER DIMENSIONS &amp; CONSTRUCTION DETAILS</strong>
              </div>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Element Diameter</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heatersheathtubedia || "N/A"}
            </td>
            <td style={tdClass}>mm</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Bundle Immersion Length</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.bundleimmersionlength || "N/A"}
            </td>
            <td style={tdClass}>mm</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Active Length</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.maxactivehotlength || "N/A"}
            </td>
            <td style={tdClass}>mm</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Tube sheet to Terminal box air gap</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.distbwheaterflangetoterminalbox || "N/A"}
            </td>
            <td style={tdClass}>mm</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Element fixing method</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.fixingofheatingelements || "N/A"}
            </td>
            <td style={tdClass}> </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Baffle Plate Type</strong>
            </td>
            <td style={tdClass}>{formik.values.baffletype} </td>
            <td style={tdClass}> </td>
          </tr>
        </table>
      </div>

      <div style={tableParentDiv}>
        {(formik?.values?.areaClassification === "safe" &&
          formik?.values?.sensorSelection === "yes") ||
          formik?.values?.areaClassification === "hazardous" ? (
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>INSTRUMENTS &amp; TEMPERATURE SENSORS DETAILS</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Description</strong>
              </td>
              <td style={tdClass}>
                <strong>QTY</strong>
              </td>
              <td style={tdClass}>
                <strong>Type</strong>
              </td>
              <td style={tdClass}>
                <strong>MOC</strong>
              </td>
              <td style={tdClass}>
                <strong>Certificate</strong>
              </td>
            </tr>
            {formik?.values.sensor[0].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Element skin Sensors</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[0]?.quantity || "N/A"}
                </td>{" "}
                <td style={tdClass}>
                  {formik?.values?.sensor[0]?.sensorsubtype || "N/A"} - {formik?.values?.sensor[0]?.sensorrange || "N/A"}
                </td>
                <td style={tdClass}>{formik?.values?.sensor[0]?.material}</td>
                <td style={tdClass}>
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
            {formik?.values.sensor[1].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Tube Sheet Sensors</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[1]?.quantity || "N/A"}
                </td>{" "}
                <td style={tdClass}>
                  {formik?.values?.sensor[1]?.sensorsubtype || "N/A"} - {formik?.values?.sensor[1]?.sensorrange || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.sensor[1]?.material || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
            {formik?.values.sensor[2].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Process Temp. Sensor</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[2]?.quantity || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.sensor[2]?.sensorsubtype || "N/A"} - {formik?.values?.sensor[2]?.sensorrange || "N/A"}
                </td>
                <td style={tdClass}>{formik?.values?.sensor[2]?.material}</td>
                <td style={tdClass}>
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
            {formik?.values.sensor[0].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Temp Transmitter - Element skin</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[0]?.quantity || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.temptransmitter || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransBoxMaterial || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
            {formik?.values.sensor[1].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Temp Transmitter - Tubesheet</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[1]?.quantity || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.temptransmitter || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransBoxMaterial || "N/A"}
                </td>
                <td style={tdClass}>
                  {" "}
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
            {formik?.values.sensor[2].selected === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Temp Transmitter - Process</strong>
                </td>
                <td style={tdClass} align="center">
                  {formik?.values?.sensor[2]?.quantity || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.temptransmitter || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransBoxMaterial || "N/A"}
                </td>
                <td style={tdClass}>
                  {formik?.values?.tempTransCertificationRequired || "N/A"}
                </td>
              </tr>
            )}
          </table>
        ) : null}
        <table style={tableClass}>
          <tr style={trClass}>
            <td bgcolor="#CCCCCC" colSpan="6" style={tdClass}>
              <div align="center">
                <strong>HEAT TRANSFER DATA</strong>
              </div>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Total Surface Area</strong>
            </td>
            <td style={tdClass}>{formik?.values?.totalsurfacearea || "N/A"}</td>
            <td style={tdClass}>m2</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Heat Transfer Co-Eff</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heatTransferCoefficient || "N/A"}
            </td>
            <td style={tdClass}>m2</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>LMTD</strong>
            </td>
            <td style={tdClass}>
              {Math.max(
                formik?.values?.sheathtemp -
                parseFloat(formik?.values?.outletTemp),
                0
              ) || "N/A"}
            </td>
            <td style={tdClass}>Deg °C</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Ambient Temperature</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.ambienttemperature || "N/A"}
            </td>
            <td style={tdClass}>Deg °C</td>
          </tr>
        </table>
      </div>

      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
              <div align="center">
                <strong>DESIGN STANDARDS &amp; CERTIFICATIONS</strong>
              </div>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Design</strong>
            </td>
            <td style={tdClass}>{formik?.values?.standard || "N/A"}</td>
          </tr>
          {formik?.values?.heaterterminalbox === "yes" && (
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Heater Terminal Box Certificate</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.areaClassification === "hazardous" ? (
                  <>
                    {formik?.values?.heaterterminalboxcertificationrequired ||
                      ""}{" "}
                    &nbsp;
                    {formik?.values?.heaterterminalboxijb || " "} &nbsp;
                    {formik?.values?.heaterterminalboxzone || ""} &nbsp;
                    {formik?.values?.heaterterminalboxgasgroup || ""} &nbsp;
                    {formik?.values?.heaterterminalboxtempcode || ""} &nbsp;
                    {formik?.values?.heaterterminalboxiprating || ""}
                  </>
                ) : (
                  formik?.values?.heaterterminalboxiprating || ""
                )}
              </td>
            </tr>
          )}
          {formik?.values?.instrument === "yes" ? (
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Instrument Junction Box Certificate</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.areaClassification === "hazardous" ? (
                  <>
                    {formik?.values?.certificationrequired || ""} &nbsp;
                    {formik?.values?.ijb || " "} &nbsp;
                    {formik?.values?.zone || ""} &nbsp;
                    {formik?.values?.gasgroup || ""} &nbsp;
                    {formik?.values?.tempCode || ""} &nbsp;
                    {formik?.values?.ipRating || ""}
                  </>
                ) : (
                  formik?.values?.ipRating || ""
                )}
              </td>
            </tr>
          ) : null}
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Zone</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heaterterminalboxzone || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Gas group</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heaterterminalboxgasgroup || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>
                Temperature classification
                <br />
                (Hazards will not ignite below)
              </strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heaterterminalboxtempcode || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>IP Rating</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.heaterterminalboxiprating || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Other Certification -I</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.speccertification || "N/A"}
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}>
              <strong>Other Certification -II</strong>
            </td>
            <td style={tdClass}>
              {formik?.values?.materialCertification || "N/A"}
            </td>
          </tr>
        </table>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <table
            className="h-100"
            style={{
              ...tableClass,
              ...(formik?.values?.multiStackVesselFormData.length > 1 && {
                borderBottom: "none",
              }),
            }}
          >
            <tr style={trClass}>
              <td bgcolor="#CCCCCC" style={tdClass} colSpan={8}>
                <div align="center">
                  <strong>PERFORMANCE PARAMETERS</strong>
                </div>
              </td>
            </tr>

            <tr style={{ height: "20px" }}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td
                rowSpan="6"
                colSpan="4"
                style={{
                  width: "300px",
                  height: "70px",
                }}
              >
                <img
                  src="/assets/images/performanceparameters.png"
                  alt="Performance Parameters"
                />
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td align="left" style={{ paddingLeft: "15px" }}>
                <strong>
                  P in bar ({formik?.values?.allowedPressureDropUnit || "N/A"})
                </strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.inletPressure || "N/A"}
                </span>
              </td>
              <td align="left" style={{ paddingLeft: "" }}>
                <strong>T in °{formik?.values?.unit || "N/A"}</strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.startingTemp || "N/A"}
                </span>
              </td>
              <td align="right" style={{ paddingRight: "15px" }}>
                <strong>T out °{formik?.values?.unit || "N/A"}</strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.outletTemp || "N/A"}
                </span>
              </td>
              <td align="right" style={{ paddingRight: "15px" }}>
                <strong>
                  P out bar ({formik?.values?.allowedPressureDropUnit || "N/A"})
                </strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.outletPressure || "N/A"}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                }}
              >
                &nbsp;Press. Drop.- &nbsp;
                {formik?.values?.allowedPressureDrop || "N/A"} &nbsp;{" "}
                {formik?.values?.allowedPressureDropUnit || "N/A"}
              </td>

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2} align="right" style={{ fontSize: "12px" }}>
                Sheath Temp.°C: &nbsp;
                {parseInt(formik?.values?.sheathtemp || "N/A")}&nbsp;
              </td>
            </tr>
          </table>
          {formik?.values?.multiStackVesselFormData.length > 1 && (
            <table style={{ ...tableClass, height: "100%" }}>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>&nbsp;</strong>
                </td>
                <td style={tdClass}>
                  <strong>Inlet Temp (T °C)</strong>
                </td>
                <td style={tdClass}>
                  <strong>Outlet Temp (T °C)</strong>
                </td>
                <td style={tdClass}>
                  <strong>Inlet Pressure bar (a)</strong>
                </td>
                <td style={tdClass}>
                  <strong>Outlet Pressure bar (a)</strong>
                </td>
                <td style={tdClass}>
                  <strong>Pressure Drop (bar)</strong>
                </td>
              </tr>
              {formik?.values?.multiStackVesselFormData?.map((item, index) => (
                <tr style={trClass}>
                  <td style={tdClass} width={150}>
                    {index === 0
                      ? "Stage 1 (Cold End)"
                      : index ===
                        formik?.values?.multiStackVesselFormData.length - 1
                        ? `Stage ${index + 1} (Hot End)`
                        : `Stage ${index + 1}`}
                  </td>
                  <td style={tdClass}>
                    {index === 0 && formik?.values?.startingTemp
                      ? formik?.values?.startingTemp
                      : item.temp.inlettemp
                        ? item.temp.inlettemp
                        : "N/A"}
                  </td>
                  <td style={tdClass}>
                    {index === 0 && formik?.values?.outletTemp
                      ? formik?.values?.outletTemp
                      : item.temp.outlettemp
                        ? item.temp.outlettemp
                        : "N/A"}
                  </td>
                  <td style={tdClass}>
                    {index === 0 && formik?.values?.inletPressure
                      ? formik?.values?.inletPressure
                      : item.inletPressure
                        ? item.inletPressure
                        : "N/A"}
                  </td>
                  <td style={tdClass}>
                    {index === 0 && formik?.values?.outletPressure
                      ? formik?.values?.outletPressure
                      : item.outletPressure
                        ? item.outletPressure
                        : "N/A"}
                  </td>
                  <td style={tdClass}>
                    {index === 0 && formik?.values?.allowedPressureDrop
                      ? formik?.values?.allowedPressureDrop
                      : item.allowedPressureDrop
                        ? item.allowedPressureDrop
                        : "N/A"}
                  </td>
                </tr>
              ))}
            </table>
          )}
        </div>
      </div>

      <div
        style={{
          ...tableParentDiv,
          width: "100%",
          border: "1px solid black",
          padding: "10px",
          gap: "10px",
        }}
      >
        <div
          className=" align-content-center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "start",
            padding: "10px",
            minHeight: "250px",
            paddingBottom: "0px",
          }}
        >
          {formik?.values?.selectedDrawing === "SpecialUpload" &&
            !previewUrl ? (
            <h2>Please Upload or select an Image </h2>
          ) : (
            <>
              <div className="d-flex flex-column">
                {formik?.values?.multistackvessel === 1 && (
                  <img
                    src={getImageSrc()}
                    alt={formik?.values?.selectedDrawing}
                    style={{ maxHeight: "230px", width: "900px" }}
                  />
                )}
                {formik?.values?.multistackvessel !== 1 && (
                  <img
                    src={
                      NoOfStack === 2 && areaClassification === "safe"
                        ? "/assets/images/twoStagesSafe.png"
                        : NoOfStack === 2 && areaClassification === "hazardous"
                          ? "/assets/images/twoStagesHazardous.png"
                          : NoOfStack === 3 && areaClassification === "safe"
                            ? "/assets/images/threeStagesSafe.png"
                            : NoOfStack === 3 && areaClassification === "hazardous"
                              ? "/assets/images/threeStagesHazardous.png"
                              : NoOfStack === 4 && areaClassification === "safe"
                                ? "/assets/images/fourStagesSafe.png"
                                : NoOfStack === 4 && areaClassification === "hazardous"
                                  ? "/assets/images/fourStagesHazardous.png"
                                  : null
                    }
                    alt={
                      NoOfStack === 2 && areaClassification === "safe"
                        ? "Stage 2 Safe Area"
                        : NoOfStack === 2 && areaClassification === "hazardous"
                          ? "Stage 2 hazardous Area"
                          : NoOfStack === 3 && areaClassification === "safe"
                            ? "Stage 3 Safe Area"
                            : NoOfStack === 3 && areaClassification === "hazardous"
                              ? "Stage 3 hazardous Area"
                              : NoOfStack === 4 && areaClassification === "safe"
                                ? "Stage 4 Safe Area"
                                : NoOfStack === 4 && areaClassification === "hazardous"
                                  ? "Stage 4 hazardous Area"
                                  : NoOfStack === 5 && areaClassification === "safe"
                                    ? "Stage 5 Safe Area"
                                    : NoOfStack === 5 && areaClassification === "hazardous"
                                      ? "Stage 5 hazardous Area"
                                      : null
                    }
                    style={{ width: "1000px" }}
                  />
                )}
              </div>
              <div className="d-flex flex-column justify-content-start">
                <p className="m-0 p-0 " style={{ fontSize: "14px" }}>
                  <strong>Note:</strong> All the dimensions are in mm.
                </p>
                <p className="m-0 p-0 " style={{ fontSize: "14px" }}>
                  This is general representation purpose only. Dimensions may
                  change during detailed engineering.
                </p>
              </div>
            </>
          )}
        </div>
        <div
          className="align-content-center"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "center",
            padding: "10px",
            minHeight: "250px",
          }}
        >
          {formik?.values?.selectedDrawing !== "" &&
            formik?.values?.selectedDrawing !== "SpecialUpload" ? (
            <table
              className="table"
              style={{
                height: `${tableHeight}px`,
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black" }}>Code</th>
                  <th style={{ border: "1px solid black" }}>Part Name</th>
                  <th style={{ border: "1px solid black" }}>Size</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index} style={{ border: "1px solid black" }}>
                    <td style={{ border: "1px solid black" }}>{item.code}</td>
                    <td style={{ border: "1px solid black" }}>
                      {item.partName}
                    </td>
                    <td style={{ border: "1px solid black" }}>{item.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaterBundleWithoutVessel;
