import { useEffect, useState } from "react";
import Select from "react-select";
import { BsInfoCircleFill } from "react-icons/bs";
import CreatableSelect from "react-select/creatable";
// import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate.js";
import { showErrorToast } from "../../../utils/Toaster.js";
import "../../../styles/Screen2styles.css";
import {
  sensorsubtypeoptions,
  headMaterialOptions,
  sensorMaterialOptions,
  terminalBoxMaterialOptions,
  sensorRangeOptions,
  anySpecificationOption,
  tempCodeOptions,
  gasGroupOptions,
  certificationOptions,
  zoneOptions,
  ijbOptions,
  thermowellMaterialOptions,
  tempTransmitterOptions,
  noOfBankOptions,
  frequency,
  connectionType,
  multiStackVesselOptions,
  yesNoOption,
  installationPositionOptions,
  standardOptions,
  distances,
} from "../../../utils/S2Options.jsx";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import BankBundleTable from "../../datatable/BankBundleTable.jsx";
import { sensors } from "../../../utils/DropdownOptions.jsx";

function Screen2({ formik, isPageComplete, updateMultiStackVesselFormData }) {
  const [totalCount, setTotalCount] = useState(12);
  const [actualCount, setActualCount] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState("°C");
  const [originalTemps, setOriginalTemps] = useState({
    startingTemp: "",
    outletTemp: "",
  });
  const [processFluids, setProcessFluids] = useState([]);
  const [units, setUnits] = useState([]);
  // const [sensorVisibility, setSensorVisibility] = useState([null, null, null]);
  const [selectedArea, setSelectedArea] = useState(
    formik.values.areaClassification || ""
  );
  const [selectedInstrument, setSelectedInstrument] = useState(
    formik.values.instrument || ""
  );
  const [heaterTerminalBox, setHeaterTerminalBox] = useState(
    formik.values.heaterterminalbox || ""
  );
  const [selectedTemperatureType, setSelectedTemperatureType] = useState(
    formik.values.temperatureType || ""
  );
  const { values, setFieldValue, handleBlur, touched, errors } = formik;

  const totalBank = parseInt(values.bank) || 0;
  const bundleHeatLoad =
    parseFloat(values.installedCapacity) /
    (parseInt(values.multistackvessel) || "N/A");

  const handleInputValue = (value, count) => {
    if (value === "") return count > 0 ? count - 1 : count;
    if (value === undefined) return count;
    return count + 1;
  };

  const handleSensorChange = (index, value) => {
    const sensorTypes = ["Skin Sensor", "Tube Sheet Sensor", "Process Sensor"];
    setFieldValue(`sensor[${index}].selected`, value);
    validateSensors(sensorTypes[index], value);
    // const updatedVisibility = [...sensorVisibility];
    // updatedVisibility[index] = value === "yes";
    // setSensorVisibility(updatedVisibility);
  };

  const validateSensors = (sensorType, value) => {
    if (values?.areaClassification === "hazardous" && value === "no") {
      showErrorToast(
        `Sensors are selected to "no" for Hazardous Area: ${sensorType}`
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("process-fluids");
      if (response?.status === 200) setProcessFluids(response.data?.data || []);
    } catch (error) {
      showErrorToast(error?.message || "Error fetching process fluids");
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axiosPrivate.get("units");
      if (response?.status === 200) setUnits(response.data?.data || []);
    } catch (error) {
      showErrorToast(error?.message || "Error fetching units");
    }
  };

  const sameAsCheckbox = (e) => {
    if (e.target.checked) {
      setFieldValue("ipRating", values.heaterterminalboxiprating);
      setFieldValue("ijb", values.heaterterminalboxijb);
      setFieldValue("tempCode", values.heaterterminalboxtempcode);
      setFieldValue(
        "certificationrequired",
        values.heaterterminalboxcertificationrequired
      );
      setFieldValue("zone", values.heaterterminalboxzone);
      setFieldValue("gasgroup", values.heaterterminalboxgasgroup);
      setFieldValue(
        "instrumentjunctionboxmaterial",
        values.heaterterminalboxmaterial
      );
    } else {
      setFieldValue("ipRating", "");
      setFieldValue("ijb", "");
      setFieldValue("tempCode", "");
      setFieldValue("certificationrequired", "");
      setFieldValue("zone", "");
      setFieldValue("gasgroup", "");
      setFieldValue("instrumentjunctionboxmaterial", "");
    }
  };

  const sameAsCheckboxforTempTransmitter = (e) => {
    if (e.target.checked) {
      setFieldValue("tempTransIp", values.heaterterminalboxiprating);
      setFieldValue("tempTransIJB", values.heaterterminalboxijb);
      setFieldValue("tempTransTempCode", values.heaterterminalboxtempcode);
      setFieldValue(
        "tempTransCertificationRequired",
        values.heaterterminalboxcertificationrequired
      );
      setFieldValue("tempTransZone", values.heaterterminalboxzone);
      setFieldValue("tempTransGasGroup", values.heaterterminalboxgasgroup);
      setFieldValue("tempTransBoxMaterial", values.heaterterminalboxmaterial);
    } else {
      setFieldValue("tempTransIp", "");
      setFieldValue("tempTransIJB", "");
      setFieldValue("tempTransTempCode", "");
      setFieldValue("tempTransCertificationRequired", "");
      setFieldValue("tempTransZone", "");
      setFieldValue("tempTransGasGroup", "");
      setFieldValue("tempTransBoxMaterial", "");
    }
  };

  const generateTableHtml = () => {
    const installedCapacity = parseFloat(values.installedCapacity) || 0;
    const effectiveVessel = parseInt(values.multistackvessel) || 1;
    const bundleHeatLoad = installedCapacity / effectiveVessel;
    const bundleHeatLoadArray = Array.from({ length: effectiveVessel }, () =>
      (installedCapacity / effectiveVessel).toFixed(2)// Default values
    )
    const initialData = Array.from({ length: effectiveVessel }, () => ({
      bundle: Array.from({ length: totalBank }, () => ({
        bank: values.divideEqually
          ? (bundleHeatLoad / totalBank).toFixed(2)
          : "",
      })),
    }));
    setFieldValue("bundleTableData", initialData);
    setFieldValue("bundleHeatLoad", bundleHeatLoadArray);
  };

  const installedCapacityChanged = (newValue) => {
    const installedCapacity = parseFloat(newValue) || 0; // Use the new value
    const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
    const bundleHeatLoadArray = Array.from(
      { length: effectiveVessel },
      () => (installedCapacity / effectiveVessel).toFixed(2) // Default values
    );
    formik.setFieldValue("bundleHeatLoad", bundleHeatLoadArray);
  };


  const handleTerminalBox = (e) => {
    const newTerminalBox = e.target.value;
    setFieldValue("heaterterminalbox", newTerminalBox);
    setHeaterTerminalBox(newTerminalBox);
  };

  const handleTemperatureType = (e) => {
    const newTemperatureType = e.target.value;
    setFieldValue("temperatureType", newTemperatureType);
    setSelectedTemperatureType(newTemperatureType);
  };

  const handleFluidChange = (option) => {
    setFieldValue("processFluid", option?.value);
    const selectedFluid = processFluids.find(
      (fluid) => fluid?.name === option?.value
    );
    if (selectedFluid) {
      const specificHeatBTU = (
        parseFloat(selectedFluid?.initial_value) * 0.2388
      ).toFixed(4);
      setFieldValue("specificHeatKJ", selectedFluid?.initial_value);
      setFieldValue("specificHeatBTU", specificHeatBTU);
      setFieldValue("viscosity", selectedFluid?.viscosity);
      setFieldValue("molecularweight", selectedFluid?.molecular_weight);
      setFieldValue(
        "density",
        parseFloat(selectedFluid?.density_kg_m3).toFixed(2)
      );
      setFieldValue("wattdensity", parseFloat(selectedFluid?.density_kg_m3).toFixed(4));
      setFieldValue(
        "thermalConductivity",
        selectedFluid?.thermal_conductivity_in_c
      );
    } else {
      const fields = [
        "specificHeatKJ",
        "specificHeatBTU",
        "viscosity",
        "molecularweight",
        "density",
        "wattdensity",
        "thermalConductivity",
      ];
      fields.forEach((field) => setFieldValue(field, ""));
    }
  };

  const handleHeatDutyChange = (e) => {
    e.preventDefault();
    const massFlowRate = parseFloat(values.massFlowRate) || "";
    const tempChange = parseFloat(values.tempChange) || "";
    const specificHeatKJ = parseFloat(values.specificHeatKJ) || "";
    const timeDuration = parseFloat(values.timeDuration) || 3600;
    const unit = values.unit;
    const tempChangeC =
      unit === "°F" && tempChange !== "" ? (tempChange * 5) / 9 : tempChange;
    if (massFlowRate !== "" && tempChangeC !== "" && specificHeatKJ !== "") {
      const heatDutyKW =
        (massFlowRate * tempChangeC * specificHeatKJ) / timeDuration;
      setFieldValue("heatDutyKW", heatDutyKW.toFixed(2));
      setFieldValue("heatDutyBTU", (heatDutyKW * 3412.142).toFixed(2));
    } else {
      setFieldValue("heatDutyKW", "");
      setFieldValue("heatDutyBTU", "");
    }
  };

  const handlePressureDropChange = (e) => {
    e?.preventDefault();
    const inletPressure = parseFloat(values.inletPressure);
    const outletPressure = parseFloat(values.outletPressure);
    setFieldValue("outletPressureUnit", values.inletPressureUnit);
    setFieldValue("allowedPressureDropUnit", values.inletPressureUnit);
    if (!isNaN(inletPressure) || !isNaN(outletPressure)) {
      const pressureDrop = inletPressure - outletPressure;
      setFieldValue("allowedPressureDrop", pressureDrop.toFixed(2));
    } else {
      setFieldValue("allowedPressureDrop", "");
    }
  };

  const handleCapacityChange = (e) => {
    e.preventDefault();
    const heatDutyKw = parseFloat(values.heatDutyKW);
    const designmargin = parseFloat(values.designmargin);
    const multiStackVessel = parseInt(values.multistackvessel);
    if (!isNaN(heatDutyKw)) {
      if (values.connection === "Star Connection") {
        setFieldValue(
          "voltage_calculation",
          (values.voltage / 1.732).toFixed(2)
        );
      } else {
        setFieldValue("voltage_calculation", "");
      }
      let capacity = heatDutyKw;
      if (!isNaN(designmargin)) {
        capacity += heatDutyKw * (designmargin / 100);
      }
      setFieldValue("calculatedcapacity", capacity.toFixed(2));
      const effectiveVessel = multiStackVessel > 0 ? multiStackVessel : 1;
      const bundleHeatLoad = values?.installedCapacity / effectiveVessel;
      setFieldValue("bundleheatload", bundleHeatLoad.toFixed(2));
      if (!isNaN(totalBank) && totalBank > 0) {
        generateTableHtml(totalBank, effectiveVessel, bundleHeatLoad);
      }
    } else {
      setFieldValue("installedCapacity", "");
      setFieldValue("calculatedcapacity", "");
      setFieldValue("bundleheatload", "");
      setFieldValue("bundleTableData", []);
    }
  };

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = parseFloat(value) || 0;

    if (values.divideEqually) {
      const totalBundles = updatedTableData.length;
      const equalValue = (bundleHeatLoad / (totalBank * totalBundles)).toFixed(2);
      updatedTableData.forEach((row) => {
        row.bundle.forEach((col) => {
          col.bank = parseFloat(equalValue) || 0;
        });
        row.rowTotal = row.bundle.reduce(
          (sum, col) => sum + parseFloat(col.bank) || 0,
          0
        );
        row.remainingHeatLoad = bundleHeatLoad - row.rowTotal;
      });
    } else {
      updatedTableData.forEach((row) => {
        row.rowTotal = row.bundle.reduce(
          (sum, col) => sum + parseFloat(col.bank) || 0,
          0
        );
        row.remainingHeatLoad = bundleHeatLoad - row.rowTotal;
      });
    }

    setFieldValue("bundleTableData", updatedTableData);
  };

  const convertCtoF = (celsius) =>
    celsius === "" ? "" : ((celsius * 9) / 5 + 32).toFixed(2);
  const convertFtoC = (fahrenheit) =>
    fahrenheit === "" ? "" : (((fahrenheit - 32) * 5) / 9).toFixed(2);

  const handleDeltaTempChange = () => {
    const startingTemp = parseFloat(values.startingTemp);
    const outletTemp = parseFloat(values.outletTemp);
    if (!isNaN(startingTemp) && !isNaN(outletTemp)) {
      setFieldValue("tempChange", (outletTemp - startingTemp).toFixed(2));
    } else {
      setFieldValue("tempChange", "");
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setSelectedUnit(newUnit);
    if (newUnit === "°C") {
      setFieldValue("startingTemp", convertFtoC(originalTemps.startingTemp));
      setFieldValue("outletTemp", convertFtoC(originalTemps.outletTemp));
    } else {
      setFieldValue("startingTemp", convertCtoF(originalTemps.startingTemp));
      setFieldValue("outletTemp", convertCtoF(originalTemps.outletTemp));
    }
    setFieldValue("unit", newUnit);
    handleDeltaTempChange();
  };

  const handleAreaChange = (e) => {
    const value = e.target.value;
    setSelectedArea(value);
    setFieldValue("areaClassification", value);
  };

  useEffect(() => {
    const installedCapacity = parseFloat(values.installedCapacity) || 0;
    const multistackvessel = parseInt(values.multistackvessel) || 1;
    const totalBank = parseInt(values.bank) || 1;

    // Recalculate bundleHeatLoad
    const bundleHeatLoadArray = Array.from({ length: multistackvessel }, () =>
      (installedCapacity / multistackvessel).toFixed(2)
    );

    // Update bundleHeatLoad in formik state if not already set
    if (!values.bundleHeatLoad.length) {
      setFieldValue("bundleHeatLoad", bundleHeatLoadArray);
    }

    // Recalculate bundleTableData
    const initialData = values.bundleTableData.length
      ? values.bundleTableData
      : Array.from({ length: multistackvessel }, () => ({
        bundle: Array.from({ length: totalBank }, () => ({
          bank: values.divideEqually
            ? (installedCapacity / multistackvessel / totalBank).toFixed(2)
            : "",
        })),
      }));

    // Update bundleTableData in formik state if not already set
    if (!values.bundleTableData.length) {
      setFieldValue("bundleTableData", initialData);
    }
  }, [values.installedCapacity, values.multistackvessel, values.divideEqually, values.bank]);

  const getIPRatingOptions = (area) => {
    const start = area === "safe" ? 42 : area === "hazardous" ? 54 : 0;
    const end = 67;
    return Array.from({ length: end - start + 1 }, (_, i) => {
      const ipValue = start + i;
      return {
        value: `IP${ipValue.toString().padStart(2, "0")}`,
        label: `IP${ipValue.toString().padStart(2, "0")}`,
      };
    });
  };

  const handleTempChange = (e) => {
    const outletTemp = parseInt(values?.outletTemp);

    const newDesignTemp = !isNaN(outletTemp)
      ? (outletTemp + 50).toFixed(2)
      : "";

    setFieldValue("designTemperature", newDesignTemp);
  };

  const handleDesignPressure = (e) => {
    const newDesignPressure = !isNaN(parseFloat(values?.inletPressure))
      ? (parseFloat(values?.inletPressure) * 1.3).toFixed(2)
      : "";
    setFieldValue("designPressure", newDesignPressure);
    setFieldValue("elementDiameter", values?.heatersheathtubedia);
  };

  const updateSensorQuantity = () => {
    const multiStackVessel = parseInt(values.multistackvessel) || 0;
    const quantity =
      multiStackVessel *
      totalBank *
      (values?.areaClassification === "hazardous" ? 2 : 3);
    setFieldValue("sensor[0].quantity", quantity);
    setFieldValue("cp_skinTemperatureController", quantity);
  };

  const handleEqualDivide = (event) => {
    const isChecked = event.target.checked;
    setFieldValue("divideEqually", isChecked);
    if (values.bundleTableData) {
      values.bundleTableData.forEach((row, rowIndex) => {
        if (row.bundle) {
          row.bundle.forEach((bundle, colIndex) => {
            const fieldName = `bundleTableData[${rowIndex}].bundle[${colIndex}].bank`;
            setFieldValue(
              fieldName,
              isChecked ? (bundleHeatLoad / totalBank).toFixed(2) : ""
            );
          });
        }
      });
    }
  };

  useEffect(() => {
    let count = 0;
    const fields = [
      "processFluid",
      "specificHeatKJ",
      "tempChange",
      "massFlowRate",
      "startingTemp",
      "outletTemp",
      "heatDutyKW",
      "multistackvessel",
      "connection",
      "inletPressure",
      "outletPressure",
      "allowedPressureDrop",
    ];
    fields.forEach((field) => {
      count = handleInputValue(values?.[field], count);
    });
    setActualCount(count);
  }, [values]);

  useEffect(() => {
    isPageComplete(2, totalCount, actualCount);
  }, [actualCount, totalCount]);

  const handleTempCodeChange = (option) => {
    const heaterterminalboxtempcode = option ? option.value : "";
    setFieldValue("heaterterminalboxtempcode", heaterterminalboxtempcode);
    setFieldValue(
      "distbwheaterflangetoterminalbox",
      distances[heaterterminalboxtempcode] || ""
    );
  };

  useEffect(() => {
    const power = values.installedCapacity * 1000;
    setFieldValue("power", power || "");
    setFieldValue("outletPressureUnit", values.inletPressureUnit);
    setFieldValue("allowedPressureDropUnit", values.inletPressureUnit);
    setFieldValue("inletPressureUnit", values.inletPressureUnit);
    fetchData();
    fetchUnits();
    if (values.areaClassification) setSelectedArea(values.areaClassification);
  }, [values.areaClassification, values.inletPressureUnit]);

  useEffect(() => {
    if (values.specificHeatKJ) {
      const specificHeatBTU = (
        parseFloat(values.specificHeatKJ) * 0.2388
      ).toFixed(4);
      setFieldValue("specificHeatBTU", specificHeatBTU);
    } else {
      setFieldValue("specificHeatBTU", "");
    }
  }, [values.specificHeatKJ]);

  useEffect(() => {
    handlePressureDropChange();
  }, [values.inletPressure, values.outletPressure]);

  useEffect(() => {
    handleDeltaTempChange();
  }, [values.startingTemp, values.outletTemp]);

  useEffect(() => {
    setOriginalTemps({
      startingTemp: values.startingTemp,
      outletTemp: values.outletTemp,
    });
  }, [values.startingTemp, values.outletTemp]);

  // useEffect(() => {
  //   updateSensorQuantity();
  // }, [values.areaClassification, values.multistackvessel, values.bank]);

  const handleInstrumentBoxChange = (e) => {
    const value = e.target.value;
    setSelectedInstrument(value);
    if (value === "no") {
      setFieldValue("ipRating", "");
      setFieldValue("ijb", "");
      setFieldValue("tempCode", "");
      setFieldValue("certificationrequired", "");
      setFieldValue("zone", "");
      setFieldValue("gasgroup", "");
      setFieldValue("instrumentjunctionboxmaterial", "");
    }
  };

  const handleHeaterTerminalBoxChange = (e) => {
    const value = e.target.value;
    setHeaterTerminalBox(value);
    if (value === "no") {
      setFieldValue("heaterterminalboxiprating", "");
      setFieldValue("heaterterminalboxijb", "");
      setFieldValue("heaterterminalboxtempcode", "");
      setFieldValue("heaterterminalboxcertificationrequired", "");
      setFieldValue("heaterterminalboxzone", "");
      setFieldValue("heaterterminalboxgasgroup", "");
      setFieldValue("heaterterminalboxmaterial", "");
    }
  };

  const handleCombinedChange = (e) => {
    handleHeaterTerminalBoxChange(e);
    handleTerminalBox(e);
  };

  const handleInstrumentBox = (e) => {
    const newInstrument = e.target.value;
    setFieldValue("instrument", newInstrument);
    setSelectedInstrument(newInstrument);
  };

  const handleCombinedChangeForInstrument = (e) => {
    handleInstrumentBoxChange(e);
    handleInstrumentBox(e);
  };

  useEffect(() => {
    if (formik) {
      updateMultiStackVesselFormData(formik?.values?.multistackvessel);
    }
  }, []);




  const renderTable = () => {
    if (
      !values.installedCapacity ||
      values.installedCapacity <= 0 ||
      totalBank < 1
    ) {
      return null;
    }

    return (
      <div className="d-flex col-md-12 mt-15 mb-30">
        <table className="table border border-secondary">
          <thead>
            <tr style={{ border: "1px solid #ccc", padding: "8px" }}>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                <strong>Bundle</strong>
              </td>
              {Array.from({ length: totalBank }, (_, i) => (
                <td
                  key={i}
                  style={{ border: "1px solid #ccc", padding: "8px" }}
                >
                  <strong>Bank {i + 1}</strong>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {formik?.values?.bundleTableData.map((row, rowIndex) => {
              const totalHeatLoad = formik.values.divideEqually
                ? bundleHeatLoad
                : row.bundle.reduce(
                  (sum, col) => sum + parseFloat(col.bank || 0),
                  0
                );
              let remainingHeatLoad = 0;
              if (values?.bundleHeatLoad) {
                remainingHeatLoad =
                  values?.bundleHeatLoad[rowIndex] - totalHeatLoad;
              }

              return (
                <tr
                  key={rowIndex}
                  style={{ border: "1px solid #ccc", padding: "8px" }}
                >
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    <p className="font-weight-bold">{rowIndex + 1}.</p>
                    <input
                      name={`bundleHeatLoad[${rowIndex}]`}
                      value={values?.bundleHeatLoad[rowIndex]}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    &nbsp;/&nbsp;
                    {remainingHeatLoad.toFixed(2)}
                    {remainingHeatLoad < 0 ? (
                      <div className="error">Limit exceeded</div>
                    ) : null}
                  </td>
                  {row.bundle.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ border: "1px solid #ccc", padding: "8px" }}
                    >
                      <input
                        style={{
                          width: "100%",
                          minWidth: "30px",
                          boxSizing: "border-box",
                          padding: "5px",
                        }}
                        name={`bundleTableData[${rowIndex}].bundle[${colIndex}].bank`}
                        id={`bundleTableData${rowIndex}.bundle${colIndex}.bank`}
                        type="number"
                        className="form-control"
                        value={
                          formik.values.divideEqually
                            ? (bundleHeatLoad / totalBank).toFixed(2)
                            : formik.values.bundleTableData?.[rowIndex]
                              ?.bundle?.[colIndex]?.bank || ""
                        }
                        onChange={(e) =>
                          !formik.values.divideEqually &&
                          handleInputChange(e, rowIndex, colIndex)
                        }
                        readOnly={formik.values.divideEqually}
                        disabled={formik.values.divideEqually}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="">
      <div className="parentdiv">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="processfluid">Process Fluid</label>
              <CreatableSelect
                styles={customStyles}
                name="processFluid"
                id="processFluid"
                placeholder="Select Process Fluid"
                options={processFluids?.map((fluid) => ({
                  label: fluid?.name,
                  value: fluid?.name,
                }))}
                onChange={handleFluidChange}
                onBlur={() => formik.setFieldTouched("processFluid", true)}
                value={
                  formik?.values?.processFluid
                    ? {
                      value: formik?.values?.processFluid,
                      label: formik?.values?.processFluid,
                    }
                    : null
                }
                isClearable
              />
              {formik.touched.processFluid && formik.errors.processFluid ? (
                <div className="error">{formik.errors.processFluid}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="specificheatkj">Specific Heat (KJ/Kg K)</label>
              <input
                name="specificHeatKJ"
                type="text"
                id="specificHeatKJ"
                className="form-control"
                placeholder="Specific Heat (KJ/Kg K)"
                value={formik?.values.specificHeatKJ}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.specificHeatKJ &&
                formik &&
                formik?.errors.specificHeatKJ ? (
                <div className="error">{formik?.errors.specificHeatKJ}</div>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6">
              <label htmlFor="specificheatbtu">Specific Heat (BTU/lb F)</label>
              <input
                name="specificHeatBTU"
                id="specificHeatBTU"
                readOnly
                type="text"
                className="form-control"
                placeholder="Specific Heat (BTU/lb F)"
                value={formik?.values.specificHeatBTU}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.specificHeatBTU &&
                formik &&
                formik?.errors.specificHeatBTU ? (
                <div className="error">{formik?.errors.specificHeatBTU}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Temperature</label>
              <div className="row d-flex px-2 py-2">
                <div className="row gap-3">
                  <input
                    type="radio"
                    id="celsius"
                    name="unit"
                    value="°C"
                    checked={selectedUnit === "°C"}
                    onChange={handleUnitChange}
                    className="form-control"
                  />
                  <label htmlFor="celsius">Celsius</label>
                </div>
                <div className="row gap-3 ml-5">
                  <input
                    type="radio"
                    id="fahrenheit"
                    name="unit"
                    value="°F"
                    className="form-control"
                    checked={selectedUnit === "°F"}
                    onChange={handleUnitChange}
                  />
                  <label htmlFor="fahrenheit">Fahrenheit</label>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12 ">
              <label htmlFor="startingTemp">
                Inlet Temperature({selectedUnit})
              </label>
              <input
                type="text"
                placeholder={`Starting Temperature (${selectedUnit})`}
                name="startingTemp"
                id="startingTemp"
                value={formik?.values?.startingTemp || ""}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                className="form-control"
              />
              {formik &&
                formik?.touched.startingTemp &&
                formik &&
                formik?.errors.startingTemp ? (
                <div className="error">{formik?.errors.startingTemp}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12 ">
              <label htmlFor="outletTemp">
                Outlet Temperature({selectedUnit})
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={`Outlet Temperature (${selectedUnit})`}
                name="outletTemp"
                id="outletTemp"
                value={formik?.values?.outletTemp || ""}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleTempChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleTempChange(e);
                }}
              />
              {formik &&
                formik?.touched.outletTemp &&
                formik &&
                formik?.errors.outletTemp ? (
                <div className="error">{formik?.errors.outletTemp}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="tempchange" className="tooltip">
                Temperature Change {"(Δ T)"}
                <BsInfoCircleFill className="info" />
                <span className="tooltip-text">
                  Outlet&nbsp;Temperature - Starting&nbsp;Temperature
                </span>
              </label>
              <input
                name="tempChange"
                id="tempChange"
                type="text"
                readOnly
                className="form-control"
                placeholder="Temperature Change"
                value={formik?.values?.tempChange || ""}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.tempChange &&
                formik &&
                formik?.errors.tempChange ? (
                <div className="error">{formik?.errors.tempChange}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="massFlowRate">Mass Flow Rate (kg/h)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mass Flow Rate (kg/h)"
                name="massFlowRate"
                id="massFlowRate"
                value={formik?.values?.massFlowRate}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik.touched.massFlowRate && formik.errors?.massFlowRate ? (
                <div className="error">{formik.errors?.massFlowRate}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="timeduration">Time Duration (seconds)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Time Duration (seconds)"
                name="timeDuration"
                id="timeDuration"
                value={formik?.values.timeDuration}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.timeDuration &&
                formik &&
                formik?.errors.timeDuration ? (
                <div className="error">{formik?.errors.timeDuration}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12 d-flex align-items-end">
              <button
                className="btn btn-primary bg-primary form-control d-flex align-items-center justify-content-center"
                onClick={handleHeatDutyChange}
              >
                <h6>Calculate</h6>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="heatdutykw">Heat Duty (KW)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Heat Duty (KW)"
                name="heatDutyKW"
                id="heatdutykw"
                value={formik?.values.heatDutyKW}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.heatDutyKW &&
                formik &&
                formik?.errors.heatDutyKW ? (
                <div className="error">{formik?.errors.heatDutyKW}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="heatdutybtu">Heat Duty (BTU/hr)</label>
              <input
                type="text"
                className="form-control"
                readOnly
                placeholder="Heat Duty (BTU/hr)"
                name="heatDutyBTU"
                id="heatdutybtu"
                value={formik?.values.heatDutyBTU}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.heatDutyBTU &&
                formik &&
                formik?.errors.heatDutyBTU ? (
                <div className="error">{formik?.errors.heatDutyBTU}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Panel Requirements</label>
              <Select
                styles={customStyles}
                name="panelrequirements"
                isClearable
                id="panelrequirements"
                placeholder="Panel Requirements"
                options={yesNoOption}
                onChange={(option) =>
                  formik &&
                  formik?.setFieldValue(
                    "panelrequirements",
                    option ? option.value : ""
                  )
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.panelrequirements
                    ? {
                      label: formik.values.panelrequirements,
                      value: formik.values.panelrequirements,
                    }
                    : null
                }
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Heater Installation Position</label>
              <Select
                styles={customStyles}
                isClearable
                name="installationPosition"
                id="installationPosition"
                placeholder="Installation Position"
                options={installationPositionOptions}
                onChange={(option) =>
                  formik?.setFieldValue(
                    "installationPosition",
                    option ? option.value : ""
                  )
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.installationPosition
                    ? {
                      label: formik.values.installationPosition,
                      value: formik.values.installationPosition,
                    }
                    : null
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="speccertification" className="">
                Any Specific Certification
              </label>
              <Select
                styles={customStyles}
                isClearable
                name="speccertification"
                id="speccertification"
                placeholder="Any Specific Certification"
                options={anySpecificationOption}
                onChange={(option) =>
                  formik?.setFieldValue(
                    "speccertification",
                    option ? option.value : ""
                  )
                }
                onBlur={formik?.handleBlur}
                value={
                  formik?.values.speccertification
                    ? {
                      label: formik?.values.speccertification,
                      value: formik?.values.speccertification,
                    }
                    : null
                }
              />
              {formik &&
                formik?.touched.speccertification &&
                formik &&
                formik?.errors.speccertification ? (
                <div className="error">{formik?.errors.speccertification}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="thermalConductivity">Thermal Conductivity</label>
              <input
                type="text"
                className="form-control"
                placeholder="Thermal Conductivity"
                name="thermalConductivity"
                id="thermalConductivity"
                value={formik?.values.thermalConductivity}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.thermalConductivity &&
                formik &&
                formik?.errors.thermalConductivity ? (
                <div className="error">
                  {formik?.errors.thermalConductivity}
                </div>
              ) : null}
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6">
                <label htmlFor="corrosionAllowance">Corrosion Allowance</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Corrosion Allowance"
                  name="corrosionAllowance"
                  id="corrosionAllowance"
                  value={formik?.values.corrosionAllowance}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.corrosionAllowance &&
                  formik &&
                  formik?.errors.corrosionAllowance ? (
                  <div className="error">
                    {formik?.errors.corrosionAllowance}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12">
                <label htmlFor="viscosity">Viscosity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Viscosity"
                  name="viscosity"
                  id="viscosity"
                  value={formik?.values.viscosity}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.viscosity &&
                  formik &&
                  formik?.errors.viscosity ? (
                  <div className="error">{formik?.errors.viscosity}</div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <label htmlFor="mdmt">MDMT</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Minimum Design Metal Temperature"
                  name="mdmt"
                  id="mdmt"
                  value={formik?.values.mdmt}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.mdmt &&
                  formik &&
                  formik?.errors.mdmt ? (
                  <div className="error">{formik?.errors.mdmt}</div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12">
                <label htmlFor="">Density</label>
                <input
                  type="text"
                  name="density"
                  id="density"
                  className="form-control"
                  placeholder="Density"
                  value={formik?.values.density}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.density &&
                  formik &&
                  formik?.errors.density ? (
                  <div className="error">{formik?.errors.density}</div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-15">
              {/* <div className="col-md-6 col-sm-12">
                <label htmlFor="areaClassification" className="">
                  Area Classification
                </label>
                <div className="row d-flex px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="safe"
                      name="areaClassification"
                      value="safe"
                      checked={selectedArea === "safe"}
                      onChange={handleAreaChange}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="safe">Safe Area</label>
                  </div>
                  <div className="row ml-2">
                    <input
                      type="radio"
                      id="hazardous"
                      name="areaClassification"
                      value="hazardous"
                      checked={selectedArea === "hazardous"}
                      onChange={handleAreaChange}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="hazardous">Hazardous Area</label>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-sm-12 mb-15">
                <label htmlFor="molecularweight">
                  Ambient Temperature (°C)
                </label>
                <input
                  type="text"
                  name="ambienttemperature"
                  id="ambienttemperature"
                  className="form-control"
                  placeholder="Ambient Temperature"
                  value={formik?.values.ambienttemperature}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.ambienttemperature &&
                  formik &&
                  formik?.errors.ambienttemperature ? (
                  <div className="error">
                    {formik?.errors.ambienttemperature}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12 mb-15">
                <label htmlFor="molecularweight">Molecular Weight</label>
                <input
                  type="text"
                  name="molecularweight"
                  id="molecularweight"
                  className="form-control"
                  placeholder="Molecular Weight"
                  value={formik?.values.molecularweight}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                  formik?.touched.molecularweight &&
                  formik &&
                  formik?.errors.molecularweight ? (
                  <div className="error">{formik?.errors.molecularweight}</div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <label htmlFor="areaClassification" className="">
                  Area Classification
                </label>
                <div className="row d-flex px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="safe"
                      name="areaClassification"
                      value="safe"
                      checked={selectedArea === "safe"}
                      onChange={(e) => {
                        handleAreaChange(e);
                        updateSensorQuantity();
                        formik?.setFieldValue("coldBaffle", 1);
                        formik.values.multiStackVesselFormData.forEach(
                          (item, index) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.coldBaffle`,
                              1
                            );
                          }
                        );
                        formik?.setFieldValue(
                          "instrumentjunctionboxmaterial",
                          formik?.initialValues?.instrumentjunctionboxmaterial
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxmaterial",
                          formik?.initialValues?.heaterterminalboxmaterial
                        );
                        formik?.setFieldValue(
                          "zone",
                          formik?.initialValues?.zone
                        );
                        formik?.setFieldValue(
                          "ijb",
                          formik?.initialValues?.ijb
                        );
                        formik?.setFieldValue(
                          "certificationType",
                          formik?.initialValues?.certificationType
                        );
                        formik?.setFieldValue(
                          "ipRating",
                          formik?.initialValues?.ipRating
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxiprating",
                          formik?.initialValues?.heaterterminalboxiprating
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxcertificationrequired",
                          formik?.initialValues
                            ?.heaterterminalboxcertificationrequired
                        );
                        formik?.setFieldValue(
                          "tempCode",
                          formik?.initialValues?.tempCode
                        );
                        formik?.setFieldValue(
                          "certificationrequired",
                          formik?.initialValues?.certificationrequired
                        );
                        formik?.setFieldValue(
                          "gasgroup",
                          formik?.initialValues?.gasgroup
                        );
                        formik?.setFieldValue(
                          "heaterterminalbox",
                          formik?.initialValues?.heaterterminalbox
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxijb",
                          formik?.initialValues?.heaterterminalboxijb
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxtempcode",
                          formik?.initialValues?.heaterterminalboxtempcode
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxzone",
                          formik?.initialValues?.heaterterminalboxzone
                        );
                        formik?.setFieldValue(
                          "heaterterminalboxgasgroup",
                          formik?.initialValues?.heaterterminalboxgasgroup
                        );
                        formik?.setFieldValue(
                          "tempTransIp",
                          formik?.initialValues?.tempTransIp
                        );
                        formik?.setFieldValue(
                          "tempTransIJB",
                          formik?.initialValues?.tempTransIJB
                        );
                        formik?.setFieldValue(
                          "tempTransTempCode",
                          formik?.initialValues?.tempTransTempCode
                        );
                        formik?.setFieldValue(
                          "tempTransCertificationRequired",
                          formik?.initialValues?.tempTransCertificationRequired
                        );
                        formik?.setFieldValue(
                          "tempTransZone",
                          formik?.initialValues?.tempTransZone
                        );
                        formik?.setFieldValue(
                          "tempTransGasGroup",
                          formik?.initialValues?.tempTransGasGroup
                        );
                        formik?.setFieldValue(
                          "tempTransBoxMaterial",
                          formik?.initialValues?.tempTransBoxMaterial
                        );
                      }}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="safe">Safe Area</label>
                  </div>
                  <div className="row ml-2">
                    <input
                      type="radio"
                      id="hazardous"
                      name="areaClassification"
                      value="hazardous"
                      checked={selectedArea === "hazardous"}
                      onChange={(e) => {
                        handleAreaChange(e);
                        updateSensorQuantity();
                        formik?.setFieldValue("sensorSelection", "yes");
                        formik?.setFieldValue("coldBaffle", 2);
                        formik.values.multiStackVesselFormData.forEach(
                          (item, index) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.coldBaffle`,
                              2
                            );
                          }
                        );
                      }}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="hazardous">Hazardous Area</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-15">
                <label htmlFor="standard">Standard</label>
                <Select
                  styles={customStyles}
                  id="standard"
                  name="standard"
                  placeholder="Standard"
                  options={standardOptions}
                  value={
                    formik?.values?.standard
                      ? {
                        value: formik?.values?.standard,
                        label: formik?.values?.standard,
                      }
                      : null
                  }
                  onChange={(option) =>
                    formik?.setFieldValue("standard", option?.label)
                  }
                  isClearable
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <div>
                  <label htmlFor="heaterterminalbox" className="">
                    Heater Terminal Box
                  </label>
                  <div className="row d-flex px-2 py-2">
                    <div className="row">
                      <input
                        type="radio"
                        id="heaterterminalbox"
                        name="heaterterminalbox"
                        value="yes"
                        checked={heaterTerminalBox === "yes"}
                        onChange={handleCombinedChange}
                        onBlur={formik?.handleBlur}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="row ml-4">
                      <input
                        type="radio"
                        id="heaterterminalbox"
                        name="heaterterminalbox"
                        value="no"
                        checked={heaterTerminalBox === "no"}
                        onChange={handleCombinedChange}
                        onBlur={formik?.handleBlur}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
                {/* Heater Terminal Box Ip Rating  */}
                {(selectedArea === "safe" || selectedArea === "hazardous") &&
                  heaterTerminalBox === "yes" && (
                    <div className="mb-15">
                      <label htmlFor="heaterterminalboxiprating">
                        Heater Terminal Box IP Rating
                      </label>
                      <Select
                        styles={customStyles}
                        isClearable
                        name="heaterterminalboxiprating"
                        placeholder="Heater Terminal Box IP"
                        id="heaterterminalboxiprating"
                        options={getIPRatingOptions(values.areaClassification)}
                        onChange={(option) =>
                          setFieldValue(
                            "heaterterminalboxiprating",
                            option ? option.value : ""
                          )
                        }
                        value={
                          values.heaterterminalboxiprating
                            ? {
                              label: values.heaterterminalboxiprating,
                              value: values.heaterterminalboxiprating,
                            }
                            : null
                        }
                        onBlur={handleBlur}
                      />
                    </div>
                  )}
                {selectedArea === "hazardous" &&
                  heaterTerminalBox === "yes" && (
                    <div>
                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxijb">
                          Heater Terminal Box
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          placeholder="Select HTB"
                          name="heaterterminalboxijb"
                          id="heaterterminalboxijb"
                          options={ijbOptions}
                          onChange={(option) =>
                            setFieldValue(
                              "heaterterminalboxijb",
                              option ? option.value : ""
                            )
                          }
                          onBlur={handleBlur}
                          value={
                            values.heaterterminalboxijb
                              ? {
                                label: values.heaterterminalboxijb,
                                value: values.heaterterminalboxijb,
                              }
                              : null
                          }
                        />
                        {touched.ijb && errors.ijb && (
                          <div className="error">{errors.ijb}</div>
                        )}
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxtempcode">
                          Heater Terminal Box Temp. Code
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="heaterterminalboxtempcode"
                          placeholder="Select Temp. Code"
                          id="heaterterminalboxtempcode"
                          options={tempCodeOptions}
                          onChange={(option) => {
                            setFieldValue(
                              "heaterterminalboxtempcode",
                              option ? option.value : ""
                            );
                            handleTempCodeChange(option);
                          }}
                          value={
                            values.heaterterminalboxtempcode
                              ? {
                                label: values.heaterterminalboxtempcode,
                                value: values.heaterterminalboxtempcode,
                              }
                              : null
                          }
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxcertificationrequired">
                          Certification Required
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="heaterterminalboxcertificationrequired"
                          placeholder="Select Certification"
                          id="heaterterminalboxcertificationrequired"
                          options={certificationOptions}
                          onChange={(option) =>
                            setFieldValue(
                              "heaterterminalboxcertificationrequired",
                              option ? option.value : ""
                            )
                          }
                          value={
                            values.heaterterminalboxcertificationrequired
                              ? {
                                label:
                                  values.heaterterminalboxcertificationrequired,
                                value:
                                  values.heaterterminalboxcertificationrequired,
                              }
                              : null
                          }
                        />
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxzone">Zone</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="heaterterminalboxzone"
                          placeholder="Select Zone"
                          id="heaterterminalboxzone"
                          options={zoneOptions}
                          onChange={(option) =>
                            setFieldValue(
                              "heaterterminalboxzone",
                              option ? option.value : ""
                            )
                          }
                          value={
                            values.heaterterminalboxzone
                              ? {
                                label: values.heaterterminalboxzone,
                                value: values.heaterterminalboxzone,
                              }
                              : null
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxgasgroup">
                          Gas Group
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="heaterterminalboxgasgroup"
                          placeholder="Select Gas Group"
                          id="heaterterminalboxgasgroup"
                          options={gasGroupOptions}
                          onChange={(option) =>
                            setFieldValue(
                              "heaterterminalboxgasgroup",
                              option ? option.value : ""
                            )
                          }
                          value={
                            values.heaterterminalboxgasgroup
                              ? {
                                label: values.heaterterminalboxgasgroup,
                                value: values.heaterterminalboxgasgroup,
                              }
                              : null
                          }
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxmaterial">
                          Heater Terminal Box Material
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="heaterterminalboxmaterial"
                          placeholder="Select Material"
                          id="heaterterminalboxmaterial"
                          options={terminalBoxMaterialOptions}
                          onChange={(option) =>
                            setFieldValue(
                              "heaterterminalboxmaterial",
                              option ? option.value : ""
                            )
                          }
                          value={
                            values.heaterterminalboxmaterial
                              ? {
                                label: values.heaterterminalboxmaterial,
                                value: values.heaterterminalboxmaterial,
                              }
                              : null
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  )}
                <div className="mt-10">
                  <label htmlFor="cableGland">Cable Gland</label>
                  <input
                    type="number"
                    name="cableGland"
                    id="cableGland"
                    className="form-control"
                    placeholder="Cable Gland"
                    value={formik?.values.cableGland}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                  {formik &&
                    formik?.touched.cableGland &&
                    formik &&
                    formik?.errors.cableGland ? (
                    <div className="error">{formik?.errors.cableGland}</div>
                  ) : null}
                </div>
              </div>

              <div className="col">
                {/* Area Classfication  */}

                <div className="">
                  <label htmlFor="seperateInstrumentbox" className="">
                    Instrument Junction Box
                  </label>

                  <div className="row px-2 py-2 d-flex align-items-center justify-content-start">
                    <div className="row mt-1">
                      <input
                        type="radio"
                        id="instrument"
                        name="instrument"
                        value="yes"
                        checked={selectedInstrument === "yes"}
                        onChange={handleCombinedChangeForInstrument}
                        onBlur={formik?.handleblur}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="row ml-3 mt-1">
                      <input
                        type="radio"
                        id="instrument"
                        name="instrument"
                        value="no"
                        checked={selectedInstrument === "no"}
                        onChange={handleCombinedChangeForInstrument}
                        onBlur={formik?.handleblur}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                    <br />
                    {selectedInstrument === "yes" && (
                      <div className="ml-3 row form-check d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="same_as"
                          className="same_as form-check-input my-0 py-0 "
                          onChange={sameAsCheckbox}
                        />
                        <label htmlFor="same_as" className="form-check-label">
                          Same as Heater Terminal Box
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                {/* IP Rating  */}
                {(selectedArea === "safe" || selectedArea === "hazardous") &&
                  selectedInstrument === "yes" && (
                    <div className="mb-15">
                      <label htmlFor="iprating">
                        Instrument Junction Box IP
                      </label>
                      <Select
                        styles={customStyles}
                        isClearable
                        name="ipRating"
                        placeholder="Instrument Junction Box IP"
                        id="ipRating"
                        options={getIPRatingOptions(
                          formik.values.areaClassification
                        )}
                        onChange={(option) =>
                          formik?.setFieldValue(
                            "ipRating",
                            option ? option.value : ""
                          )
                        }
                        value={
                          formik.values.ipRating
                            ? {
                              label: formik.values.ipRating,
                              value: formik.values.ipRating,
                            }
                            : null
                        }
                        onBlur={formik?.handleBlur}
                      />
                    </div>
                  )}
                {/* IJB  */}
                {selectedArea === "hazardous" &&
                  selectedInstrument === "yes" && (
                    <div>
                      <div className="mb-15">
                        <label htmlFor="material">IJB</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          placeholder="Select IJB"
                          name="ijb"
                          id="ijb"
                          options={ijbOptions}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "ijb",
                              option ? option.value : ""
                            )
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik.values.ijb
                              ? {
                                label: formik.values.ijb,
                                value: formik.values.ijb,
                              }
                              : null
                          }
                        />
                        {formik &&
                          formik?.touched.ijb &&
                          formik &&
                          formik?.errors.ijb ? (
                          <div className="error">{formik?.errors.ijb}</div>
                        ) : null}
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempcode">Temp. Code</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempCode"
                          placeholder="Select Temp. Code"
                          id="tempCode"
                          options={tempCodeOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempCode",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempCode
                              ? {
                                label: formik.values.tempCode,
                                value: formik.values.tempCode,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="certificationrequired">
                          Certification Required
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="certificationrequired"
                          placeholder="Select Certification"
                          id="certificationrequired"
                          options={certificationOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "certificationrequired",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.certificationrequired
                              ? {
                                label: formik.values.certificationrequired,
                                value: formik.values.certificationrequired,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="zone">Zone</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="zone"
                          placeholder="Select Zone"
                          id="zone"
                          options={zoneOptions}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "zone",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.zone
                              ? {
                                label: formik.values.zone,
                                value: formik.values.zone,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="gasgroup">Gas Group</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="gasgroup"
                          placeholder="Select Gas Group"
                          id="gasgroup"
                          options={gasGroupOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "gasgroup",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.gasgroup
                              ? {
                                label: formik.values.gasgroup,
                                value: formik.values.gasgroup,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>

                      <div>
                        <label htmlFor="instrumentjunctionboxmaterial">
                          Instrument Junction Box Material
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="instrumentjunctionboxmaterial"
                          placeholder="Select Material"
                          id="instrumentjunctionboxmaterial"
                          options={terminalBoxMaterialOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "instrumentjunctionboxmaterial",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.instrumentjunctionboxmaterial
                              ? {
                                label:
                                  formik.values.instrumentjunctionboxmaterial,
                                value:
                                  formik.values.instrumentjunctionboxmaterial,
                              }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-md-12">
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="multistackvessel">
                No. of Multi Stack Vessel
              </label>
              <Select
                styles={customStyles}
                isClearable
                placeholder="No. of Multi Stack Vessel"
                name="multistackvessel"
                id="multistackvessel"
                options={multiStackVesselOptions}
                onChange={(option) => {
                  updateSensorQuantity();
                  if (option) {
                    formik?.setFieldValue("multistackvessel", option.value);
                    updateMultiStackVesselFormData(option.value);
                    if (option.value !== 1) {
                      formik?.setFieldValue("vesselSelection", "yes");
                    }
                  } else {
                    formik?.setFieldValue("multistackvessel", 1);
                    updateMultiStackVesselFormData(1);
                    formik?.setFieldValue("vesselSelection", "no");
                  }
                }}
                value={
                  formik.values.multistackvessel
                    ? {
                      label: formik.values.multistackvessel,
                      value: formik.values.multistackvessel,
                    }
                    : null
                }
              />
              {formik &&
                formik?.touched.multistackvessel &&
                formik &&
                formik?.errors.multistackvessel ? (
                <div className="error">{formik?.errors.multistackvessel}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="designmargin">Design Margin(%)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Design Margin (%)"
                name="designmargin"
                id="designmargin"
                value={formik?.values.designmargin}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (isNaN(value)) value = "";
                  else if (value < 0) value = 0;
                  else if (value > 100) value = 100;
                  formik.setFieldValue("designmargin", value);
                }}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.designmargin &&
                formik &&
                formik?.errors.designmargin ? (
                <div className="error">{formik?.errors.designmargin}</div>
              ) : null}
            </div>
          </div>
          {/* <div className="d-flex justify-content-between mb-15">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="voltage">Voltage(V) (TPS)</label>
              <div
                className="btn btn-primary"
                style={{ height: "40px" }}
              >
                Stack 1
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="voltage">Voltage(V)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Voltage(V)"
                name="voltage"
                id="voltage"
                value={formik?.values?.voltage}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.voltage &&
                formik &&
                formik?.errors.voltage ? (
                <div className="error">{formik?.errors.voltage}</div>
              ) : null}
            </div>

            <div className="col-md-3 col-sm-12">
              <label htmlFor="voltage" className="tooltip">
                Voltage(V) (TPS) <BsInfoCircleFill className="info" />
                <span className="tooltip-text">
                  If Connection is "Star Connection" then value is [Voltage(V) /
                  1.732]
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Star Connection"
                name="voltage_calculation"
                id="voltage_calculation"
                value={formik?.values?.voltage_calculation}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                readOnly
              />
              {formik &&
                formik?.touched.voltage &&
                formik &&
                formik?.errors.voltage ? (
                <div className="error">{formik?.errors.voltage}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="inbuildspare">Inbuild Spare (%)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Inbuild Spare (%)"
                name="inbuildspare"
                id="inbuildspare"
                value={formik?.values.inbuildspare}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (isNaN(value)) value = "";
                  else if (value < 0) value = 0;
                  else if (value > 100) value = 100;
                  formik.setFieldValue("inbuildspare", value);
                }}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.inbuildspare &&
                formik &&
                formik?.errors.inbuildspare ? (
                <div className="error">{formik?.errors.inbuildspare}</div>
              ) : null}
            </div>
          </div>

          {/* right div 2nd column */}
          <div className="d-flex justify-content-between mb-15 pb-1">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="connection">Connection</label>
              <Select
                styles={customStyles}
                isClearable
                name="connection"
                id="connection"
                placeholder="Select Connection"
                options={connectionType}
                onChange={(option) =>
                  formik &&
                  formik?.setFieldValue(
                    "connection",
                    option ? option.value : ""
                  )
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.connection
                    ? {
                      label: formik.values.connection,
                      value: formik.values.connection,
                    }
                    : null
                }
              />
              {formik &&
                formik?.touched.connection &&
                formik &&
                formik?.errors.connection ? (
                <div className="error">{formik?.errors.connection}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="frequency">Frequency (Hz)</label>
              <Select
                styles={customStyles}
                isClearable
                name="frequency"
                id="frequency"
                placeholder="Select Frequency"
                options={frequency}
                onChange={(option) =>
                  formik &&
                  formik?.setFieldValue("frequency", option ? option.value : "")
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.frequency
                    ? {
                      label: formik.values.frequency,
                      value: formik.values.frequency,
                    }
                    : null
                }
              />
              {formik &&
                formik?.touched.frequency &&
                formik &&
                formik?.errors.frequency ? (
                <div className="error">{formik?.errors.frequency}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="bank">Total Bank</label>
              <Select
                styles={customStyles}
                isClearable
                placeholder="Total No. of bank"
                name="bank"
                id="bank"
                options={noOfBankOptions}
                onChange={(option) => {
                  updateSensorQuantity();
                  formik &&
                    formik?.setFieldValue("bank", option ? option.value : "");
                }}
                value={
                  formik.values.bank
                    ? {
                      label: formik.values.bank,
                      value: formik.values.bank,
                    }
                    : null
                }
              />
              {formik &&
                formik?.touched.bank &&
                formik &&
                formik?.errors.bank ? (
                <div className="error">{formik?.errors.bank}</div>
              ) : null}
            </div>
            <div className="col-md-6 mt-1">
              <div>
                <input
                  type="checkbox"
                  id="equalDivide"
                  name="divideEqually"
                  className="equalDivide mr-2"
                  onChange={(e) => {
                    handleEqualDivide(e);
                  }}
                  checked={formik?.values.divideEqually}
                  value={
                    formik?.values.divideEqually ? "divideEqually" : "notDivide"
                  }
                />
                <label className="mb-1">Divide bundle Heat Load equally?</label>
              </div>
              <button
                className="btn btn-primary bg-primary form-control"
                onClick={(e) => {
                  handleCapacityChange(e);
                  formik?.values?.divideEqually
                    ? formik?.values?.multiStackVesselFormData.map(
                      (item, index) => {
                        formik?.setFieldValue(
                          `multiStackVesselFormData[${index}].installedCapacity`,
                          formik?.values?.installedCapacity /
                          formik?.values?.multiStackVesselFormData.length
                        );
                      }
                    )
                    : formik?.values?.multiStackVesselFormData.map(
                      (item, index) => {
                        formik?.setFieldValue(
                          `multiStackVesselFormData[${index}].installedCapacity`,
                          0
                        );
                      }
                    );
                }}
              >
                <h6>Calculate</h6>
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="calculatedcapacity" className="tooltip">
                Calculated Capacity <BsInfoCircleFill className="mb-1 info" />
                <span className="tooltip-text">
                  Heat Duty (KW) * (Design Margin(%) / 100)
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Calculated Capacity"
                readOnly
                name="calculatedcapacity"
                id="calculatedcapacity"
                value={formik?.values.calculatedcapacity}
                onChange={(e) => e.preventDefault}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.cal &&
                formik &&
                formik?.errors.calculatedcapacity ? (
                <div className="error">{formik?.errors.calculatedcapacity}</div>
              ) : null}
            </div>
            <div className="col-md-3 col-sm-12">
              <label htmlFor="installedcapacity">Installed Capacity</label>
              <input
                type="text"
                className="form-control"
                placeholder={formik?.values.installedCapacity}
                name="installedCapacity"
                id="installedCapacity"
                value={formik?.values.installedCapacity}
                onChange={(e) => {
                  const newValue = e.target.value;
                  installedCapacityChanged(newValue);
                  formik.setFieldValue("installedCapacity", newValue);

                  // Use newValue directly in the conditional logic
                  if (newValue >= 0 && newValue <= 30) {
                    formik.setFieldValue("cp_enclosureSizeHeight", 2100);
                    formik.setFieldValue("cp_enclosureSizeWidth", 800);
                    formik.setFieldValue("cp_enclosureSizeDepth", 400);
                  } else if (newValue > 30 && newValue <= 100) {
                    formik.setFieldValue("cp_enclosureSizeHeight", 2100);
                    formik.setFieldValue("cp_enclosureSizeWidth", 800);
                    formik.setFieldValue("cp_enclosureSizeDepth", 500);
                  } else if (newValue > 100 && newValue <= 250) {
                    formik.setFieldValue("cp_enclosureSizeHeight", 2100);
                    formik.setFieldValue("cp_enclosureSizeWidth", 1200);
                    formik.setFieldValue("cp_enclosureSizeDepth", 600);
                  } else if (newValue > 250 && newValue <= 600) {
                    formik.setFieldValue("cp_enclosureSizeHeight", 2100);
                    formik.setFieldValue("cp_enclosureSizeWidth", 1500);
                    formik.setFieldValue("cp_enclosureSizeDepth", 800);
                  } else {
                    formik.setFieldValue("cp_enclosureSizeHeight", "N/A");
                    formik.setFieldValue("cp_enclosureSizeWidth", "N/A");
                    formik.setFieldValue("cp_enclosureSizeDepth", "N/A");
                  }
                }}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.installedCapacity &&
                formik &&
                formik?.errors.installedCapacity ? (
                <div className="error">{formik?.errors.installedCapacity}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="bundleheatload" className="tooltip">
                Ind Bundle Heat Load&nbsp;
                <BsInfoCircleFill className="mb-1 info" />
                <span className="tooltip-text">
                  Installed Capacity / No. of Multi Stack Vessel
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder=" Ind Bundle Heat Load"
                readOnly
                name="bundleheatload"
                id="bundleheatload"
                value={formik?.values.bundleheatload}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.bundleheatload &&
                formik &&
                formik?.errors.bundleheatload ? (
                <div className="error">{formik?.errors.bundleheatload}</div>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            {/* <div className="col-12">
              <BankBundleTable
                formik={formik}
                // currentIndex={index}
                divideEqually={formik?.values?.divideEqually}
                setDivideEqually={`divideEqually`}
                totalBank={formik?.values?.bank}
                installedCapacity={formik?.values?.installedCapacity}
                setInstalledCapacity={`installedCapacity`}
                bundleTableData={formik?.values?.bundleTableData}
                setBundleTableData={`bundleTableData`}
                heatDutyKW={formik?.values?.heatDutyKW}
                voltage={formik?.values?.voltage}
                setVoltageCalculation={`voltage_calculation`}
                designmargin={formik?.values?.designmargin}
                multiStackVessel={formik?.values?.multistackvessel}
                calculateButtonVisibility={false}
                calculateCalculatedCapacity={true}
                calculateBundleHeatLoad={true}
              />
            </div> */}
          </div>

          {/* Sensor Selection  */}

          {/* <div
            dangerouslySetInnerHTML={{ __html: bankHtml }}
            className="d-flex col-md-12 mt-15 mb-30"
          ></div> */}
          {renderTable()}

          <div className="d-flex justify-content-between">
            {/* Inlet Pressure */}
            <div className="col-md-6 col-sm-12">
              <label htmlFor="inletPressure">Inlet Pressure</label>
              <div className="mb-15 d-flex row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="inletPressure"
                    id="inletPressure"
                    placeholder="Inlet Pressure"
                    value={formik?.values.inletPressure}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      handleDesignPressure(e);
                    }}
                    onBlur={(e) => {
                      formik?.handleBlur(e);
                      handleDesignPressure(e);
                    }}
                  />
                  {formik &&
                    formik?.touched.inletPressure &&
                    formik &&
                    formik?.errors.inletPressure ? (
                    <div className="error">{formik?.errors.inletPressure}</div>
                  ) : null}
                </div>
                <Select
                  styles={customStyles}
                  isClearable
                  name="inletPressureUnit"
                  id="inletPressureUnit"
                  placeholder="Unit"
                  options={units.map((unit) => ({
                    label: unit.name,
                    value: unit.name,
                  }))}
                  value={
                    formik.values.inletPressureUnit
                      ? {
                        label: formik.values.inletPressureUnit,
                        value: formik.values.inletPressureUnit,
                      }
                      : null
                  }
                  onChange={(option) =>
                    formik?.setFieldValue(
                      "inletPressureUnit",
                      option ? option.value : ""
                    )
                  }
                  onBlur={formik?.handleBlur}
                  className="col"
                />
                {/* {formik &&
                      formik?.touched.inletPressure &&
                      formik &&
                      formik?.errors.inletPressure ? (
                        <div className="error">
                          {formik?.errors.inletPressure}
                        </div>
                      ) : null} */}
              </div>
            </div>
            {/* Allowed Pressure */}
            <div className="col-md-6">
              <label htmlFor="allowedPressureDrop">Allowed Pressure Drop</label>
              <div className="row d-flex">
                <div className="col">
                  <input
                    type="text"
                    className="form-control mb-15"
                    readOnly
                    name="allowedPressureDrop"
                    id="allowedPressureDrop"
                    placeholder="Allowed Pressure"
                    value={formik?.values.allowedPressureDrop}
                    onChange={formik?.handleChange}
                  />
                  {formik &&
                    formik?.touched.allowedPressureDrop &&
                    formik &&
                    formik?.errors.allowedPressureDrop ? (
                    <div className="error">
                      {formik?.errors.allowedPressureDrop}
                    </div>
                  ) : null}
                </div>
                <Select
                  isClearable
                  styles={customStylesForDisabled}
                  name="allowedPressureDropUnit"
                  id="allowedPressureDropUnit"
                  placeholder="Unit"
                  value={
                    formik.values.allowedPressureDropUnit
                      ? {
                        label: formik.values.allowedPressureDropUnit,
                        value: formik.values.allowedPressureDropUnit,
                      }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                  className="col"
                  isDisabled
                />
                {/* {formik &&
                    formik?.touched.allowedPressureDropUnit &&
                    formik &&
                    formik?.errors.allowedPressureDropUnit ? (
                      <div className="error">
                        {formik?.errors.allowedPressureDropUnit}
                      </div>
                    ) : null} */}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            {/* Outlet Pressure */}
            <div className="col-md-6">
              <label htmlFor="outletPressure" className="tooltip">
                Outlet Pressure <BsInfoCircleFill className="mb-1 info" />
                <span className="tooltip-text">
                  Outlet Pressure Unit = Inlet Pressure Unit
                </span>
              </label>
              <div className="row d-flex">
                <div className="mb-15 col">
                  <input
                    type="text"
                    className="form-control"
                    name="outletPressure"
                    id="outletPressure"
                    placeholder="Outlet Pressure"
                    value={formik?.values.outletPressure}
                    onChange={formik?.handleChange}
                  />
                  {formik &&
                    formik?.touched.outletPressure &&
                    formik &&
                    formik?.errors.outletPressure ? (
                    <div className="error">{formik?.errors.outletPressure}</div>
                  ) : null}
                </div>
                <Select
                  isClearable
                  placeholder="Unit"
                  styles={customStylesForDisabled}
                  value={
                    formik.values.outletPressureUnit
                      ? {
                        label: formik.values.outletPressureUnit,
                        value: formik.values.outletPressureUnit,
                      }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                  isDisabled
                  className="col"
                />
              </div>
            </div>
          </div>
          {/* Thermowell Section  */}
          <div className="d-flex justify-content-between">
            <div className="col-md-6 mb-15">
              <label htmlFor="thermowell">Thermowell</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter No. of Thermowell"
                name="thermowell"
                id="thermowell"
                value={formik?.values.thermowell}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
                formik?.touched.thermowell &&
                formik &&
                formik?.errors.thermowell ? (
                <div className="error">{formik?.errors.thermowell}</div>
              ) : null}
            </div>
            <div className="col-md-6 mb-15">
              <label htmlFor="thermowellMaterial">Thermowell Material</label>
              <Select
                styles={customStyles}
                isClearable
                id="thermowellMaterial"
                name="thermowellMaterial"
                options={thermowellMaterialOptions}
                onChange={(option) =>
                  formik.setFieldValue(
                    "thermowellMaterial",
                    option ? option.value : ""
                  )
                }
                value={
                  formik.values.thermowellMaterial
                    ? {
                      value: formik.values.thermowellMaterial,
                      label: formik.values.thermowellMaterial,
                    }
                    : null
                }
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {selectedArea === "safe" && (
            <div className="col-md-6" style={{ gap: "20px" }}>
              <div className="mb-15 mt-30">
                <b>
                  <label htmlFor="sensorselection">Sensor Selection</label>
                </b>

                <div className="d-flex row px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="sensorYes"
                      name="sensorSelection"
                      value="yes"
                      checked={formik?.values.sensorSelection === "yes"}
                      onChange={(e) =>
                        formik?.setFieldValue("sensorSelection", "yes")
                      }
                    />
                    <label htmlFor="sensorYes" className="">
                      Yes
                    </label>
                  </div>
                  <div className="row ml-5">
                    <input
                      type="radio"
                      id="sensorNo"
                      name="sensorSelection"
                      value="no"
                      className=""
                      checked={formik?.values.sensorSelection === "no"}
                      onChange={() => {
                        formik?.setFieldValue("sensorSelection", "no");
                      }}
                    />
                    <label htmlFor="sensorNo" className="">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedArea === "hazardous" ||
            (selectedArea === "safe" &&
              formik?.values.sensorSelection === "yes") ? (
            <>
              {sensors?.map((sensor, index) => (
                <div key={index}>
                  <div className="d-flex align-items-center">
                    <div className="col-md-3">
                      <b>
                        <label>{sensor.label}</label>
                      </b>
                    </div>
                    <div className="row d-flex px-2 py-2 ml-5">
                      <div className="row">
                        <input
                          type="radio"
                          id={`sensorselected-yes-${index}`}
                          name={`sensor[${index}].selected`}
                          value="yes"
                          checked={
                            formik.values.sensor[index].selected === "yes"
                          }
                          onChange={(e) =>
                            handleSensorChange(index, e.target.value)
                          }
                        />
                        <label htmlFor={`sensorselected-yes-${index}`}>
                          Yes
                        </label>
                      </div>
                      <div className="row ml-5">
                        <input
                          type="radio"
                          id={`sensorselected-no-${index}`}
                          name={`sensor[${index}].selected`}
                          value="no"
                          checked={
                            formik.values.sensor[index].selected === "no"
                          }
                          onChange={(e) =>
                            handleSensorChange(index, e.target.value)
                          }
                        />
                        <label htmlFor={`sensorselected-no-${index}`}>No</label>
                      </div>
                    </div>
                  </div>

                  {formik?.values?.sensor[index]?.selected === "yes" && (
                    <div className="d-flex justify-content-between">
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor="">Sensor Sub Type</label>
                          <Select
                            styles={customStyles}
                            isClearable
                            options={sensorsubtypeoptions}
                            name={`sensor[${index}].sensorsubtype`}
                            placeholder="Sensor Sub Type"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].sensorsubtype`,
                                option ? option.value : ""
                              )
                            }
                            value={
                              formik.values.sensor[index].sensorsubtype
                                ? {
                                  label:
                                    formik.values.sensor[index].sensorsubtype,
                                  value:
                                    formik.values.sensor[index].sensorsubtype,
                                }
                                : null
                            }
                          />
                        </div>
                        <div className="col mb-15">
                          <label htmlFor="">Sensor Type</label>
                          <Select
                            styles={customStyles}
                            isClearable
                            options={sensorRangeOptions}
                            name={`sensor[${index}].sensorrange`}
                            placeholder="Sensor Type"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].sensorrange`,
                                option ? option.value : ""
                              )
                            }
                            value={
                              formik.values.sensor[index].sensorrange
                                ? {
                                  label:
                                    formik.values.sensor[index].sensorrange,
                                  value:
                                    formik.values.sensor[index].sensorrange,
                                }
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor={`quantity-${index}`}>Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Quantity"
                            name={`sensor[${index}].quantity`}
                            id={`quantity-${index}`}
                            value={formik.values.sensor[index].quantity || ""}
                            onChange={(e) => {
                              e.preventDefault();
                              formik?.setFieldValue(
                                `sensor[${index}].quantity`,
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor={`material-${index}`}>Material</label>
                          <CreatableSelect
                            isClearable
                            styles={customStyles}
                            id="sensor[${index}].material"
                            name={`sensor[${index}].material`}
                            options={sensorMaterialOptions}
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].material`,
                                option ? option.value : ""
                              )
                            }
                            value={
                              formik.values.sensor[index].material
                                ? {
                                  label: formik.values.sensor[index].material,
                                  value: formik.values.sensor[index].material,
                                }
                                : null
                            }
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor={`sensor[${index}].headMaterial`}>
                            Head Material
                          </label>
                          <Select
                            styles={customStyles}
                            isClearable
                            options={headMaterialOptions}
                            name={`sensor[${index}].headMaterial`}
                            placeholder="Head Material"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].headMaterial`,
                                option ? option.value : ""
                              )
                            }
                            value={
                              formik.values.sensor[index].headMaterial
                                ? {
                                  label:
                                    formik.values.sensor[index].headMaterial,
                                  value:
                                    formik.values.sensor[index].headMaterial,
                                }
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <div className="col-md-12 p-3 mb-15">
                <div>
                  <label htmlFor="">Temperature Transmitter type</label>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      value="Display"
                      checked={selectedTemperatureType === "Display"}
                      onChange={(e) => {
                        handleTemperatureType(e);
                        formik.setFieldValue(
                          "temptransmitter",
                          "4-20 mA LCD Display Type Enabled Heart protocol Smarttype SIL-2 Enabled"
                        );
                      }}
                    />
                    <label htmlFor="digital">Display</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      checked={selectedTemperatureType === "Not Display"}
                      value="Not Display"
                      onChange={(e) => {
                        handleTemperatureType(e);
                        formik.setFieldValue("temptransmitter", "");
                      }}
                    />
                    <label htmlFor="analog">Non Display</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      value="N"
                      checked={selectedTemperatureType === "N"}
                      onChange={handleTemperatureType}
                    />
                    <label htmlFor="none">None</label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-15">
                  {(selectedTemperatureType === "Display" ||
                    selectedTemperatureType === "Not Display") && (
                      <div className="col-md-6">
                        <label htmlFor="temperatureTransmitter">
                          Temperature Transmitter
                        </label>
                        {selectedTemperatureType === "Display" ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Temperature Transmitter"
                            name="temptransmitter"
                            id="temptransmitter"
                            readOnly
                            value={formik.values.temptransmitter}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        ) : (
                          <Select
                            styles={customStyles}
                            isClearable
                            name="temptransmitter"
                            placeholder="Temperature Transmitter"
                            id="temptransmitter"
                            options={tempTransmitterOptions}
                            onChange={(option) =>
                              formik?.setFieldValue(
                                "temptransmitter",
                                option ? option.value : ""
                              )
                            }
                            value={
                              formik.values.temptransmitter
                                ? {
                                  label: formik.values.temptransmitter,
                                  value: formik.values.temptransmitter,
                                }
                                : null
                            }
                            onBlur={formik?.handleBlur}
                          />
                        )}
                        {formik.touched.temptransmitter &&
                          formik.errors.temptransmitter && (
                            <div className="error">
                              {formik.errors.temptransmitter}
                            </div>
                          )}
                      </div>
                    )}

                  {selectedTemperatureType === "Not Display" &&
                    formik?.values?.areaClassification === "hazardous" ? (
                    <div className="col-md-6">
                      <label htmlFor="certificationType" className="tooltip">
                        Certification Type &nbsp;
                        <BsInfoCircleFill className="info" />
                        <span className="tooltip-text">
                          Certification Required - IJB - Zone - Gas Group - Temp
                          Code - IP Rating
                        </span>
                      </label>
                      <input
                        className="mb-15 form-control"
                        name="certificationType"
                        id="certificationType"
                        placeholder="Certification Type"
                        value={`${formik.values.certificationrequired} - ${formik.values.ijb} - ${formik.values.zone} - ${formik.values.gasgroup} - ${formik.values.tempCode} - ${formik.values.ipRating}`}
                        onChange={(option) =>
                          formik &&
                          formik?.setFieldValue("certificationType", option)
                        }
                        onBlur={formik?.handleBlur}
                        disabled
                      />
                    </div>
                  ) : null}
                  {selectedTemperatureType === "Not Display" &&
                    formik?.values?.areaClassification === "safe" ? (
                    <div className="col-md-6">
                      <label htmlFor="certificationType" className="tooltip">
                        Certification Type <BsInfoCircleFill className="info" />
                        <span className="tooltip-text">
                          Certification Type - IP Rating
                        </span>
                      </label>
                      <input
                        className="mb-15 form-control"
                        name="certificationType"
                        id="certificationType"
                        placeholder="Certification Type"
                        value={`${formik.values.ipRating}`}
                        onChange={(option) =>
                          formik &&
                          formik?.setFieldValue("certificationType", option)
                        }
                        onBlur={formik?.handleBlur}
                        disabled
                      />
                    </div>
                  ) : null}
                  {selectedTemperatureType === "Display" && (
                    <div className="col-md-6">
                      {selectedInstrument === "yes" && (
                        <div className="ml-1 row form-check d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="same_as_temp_transmitter"
                            className="same_as form-check-input my-0 py-0"
                            onChange={sameAsCheckboxforTempTransmitter}
                          />
                          <label htmlFor="same_as" className="form-check-label">
                            Same as Heater Terminal Box
                          </label>
                        </div>
                      )}
                      <div className="mb-15">
                        <label htmlFor="tempTransIp">IP</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransIp"
                          placeholder=" IP"
                          id="tempTransIp"
                          options={getIPRatingOptions(
                            formik.values.areaClassification
                          )}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "tempTransIp",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempTransIp
                              ? {
                                label: formik.values.tempTransIp,
                                value: formik.values.tempTransIp,
                              }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransIJB">TT</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          placeholder="Select TT"
                          name="tempTransIJB"
                          id="tempTransIJB"
                          options={ijbOptions}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "tempTransIJB",
                              option ? option.value : ""
                            )
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik.values.tempTransIJB
                              ? {
                                label: formik.values.tempTransIJB,
                                value: formik.values.tempTransIJB,
                              }
                              : null
                          }
                        />
                        {formik &&
                          formik?.touched.ijb &&
                          formik &&
                          formik?.errors.ijb ? (
                          <div className="error">
                            {formik?.errors.tempTransIJB}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransTempCode">Temp. Code</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransTempCode"
                          placeholder="Select Temp. Code"
                          id="tempTransTempCode"
                          options={tempCodeOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransTempCode",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik?.values?.tempTransTempCode
                              ? {
                                label: formik?.values?.tempTransTempCode,
                                value: formik?.values?.tempTransTempCode,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransCertificationRequired">
                          Certification Required
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransCertificationRequired"
                          placeholder="Select Certification"
                          id="tempTransCertificationRequired"
                          options={certificationOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransCertificationRequired",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempTransCertificationRequired
                              ? {
                                label:
                                  formik.values
                                    .tempTransCertificationRequired,
                                value:
                                  formik.values
                                    .tempTransCertificationRequired,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransZone">Zone</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransZone"
                          placeholder="Select Zone"
                          id="tempTransZone"
                          options={zoneOptions}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "tempTransZone",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempTransZone
                              ? {
                                label: formik.values.tempTransZone,
                                value: formik.values.tempTransZone,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransGasGroup">Gas Group</label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransGasGroup"
                          placeholder="Select Gas Group"
                          id="tempTransGasGroup"
                          options={gasGroupOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransGasGroup",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempTransGasGroup
                              ? {
                                label: formik.values.tempTransGasGroup,
                                value: formik.values.tempTransGasGroup,
                              }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>

                      <div>
                        <label htmlFor="tempTransBoxMaterial">
                          Enclosure Material
                        </label>
                        <Select
                          styles={customStyles}
                          isClearable
                          name="tempTransBoxMaterial"
                          placeholder="Select Material"
                          id="tempTransBoxMaterial"
                          options={headMaterialOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransBoxMaterial",
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik.values.tempTransBoxMaterial
                              ? {
                                label: formik.values.tempTransBoxMaterial,
                                value: formik.values.tempTransBoxMaterial,
                              }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <hr />
    </div>
  );
}
export default Screen2;
