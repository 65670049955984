import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight, FaCheck, FaEdit, FaTrash } from "react-icons/fa";
import { IoMdFolderOpen } from "react-icons/io";
import { Check, Edit, MoveDown, ViewArray } from "@mui/icons-material";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

const CustomerListing = ({ formik, setCurrent, setFormData }) => {
  const [customers, setCustomers] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const calculationId = localStorage.getItem("calculationId");

  const headers = [
    {
      name: "Customer Name",
      field: "customer_name",
      sortable: true,
      classKey: "",
    },
    { name: "Project", field: "project_name", sortable: true, classKey: "" },
    { name: "Status", field: "status", sortable: true, classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/customers");
      if (response.status === 200) {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i]["action"] = (
            <>
              <FaArrowRight
                color="green"
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleGetCalculation(response.data.data[i].id);
                }}
              />
            </>
          );
        }
        setCustomers(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleGetCalculation = (customerId) => {
    if (calculationId) {
      localStorage.removeItem("calculationId");
    }
    localStorage.setItem("customerId", customerId);
    navigate(`/customer/heater-calculator/${customerId}`);
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  // Add click functionality to the customer name
  const customerNameBodyTemplate = (rowData) => {
    return (
      <span
        style={{ cursor: "pointer", }}
        onClick={() => handleGetCalculation(rowData.id)}
      >
        {rowData.customer_name}
      </span>
    );
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Customers</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={customers}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters?.global?.value}
                  emptyMessage="No customers found"
                >
                  <Column
                    field="customer_name"
                    header="Customer Name"
                    body={customerNameBodyTemplate} // Add click functionality to the customer name
                    sortable
                  />
                  <Column
                    field="project_name"
                    header="Project"
                    sortable
                  />
                  <Column
                    field="status"
                    header="Status"
                    sortable
                  />
                  <Column
                    field="action"
                    header="Action"

                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerListing;