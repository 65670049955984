import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useLoader } from "../Loader/LoaderProvider";
import Loader from "../Loader/Loader";

// Create a context for permissions
const PermissionsContext = createContext([]);

// Permissions provider component
const PermissionsProvider = ({ children }) => {
  // Get user data from local storage
  const { userId } = JSON.parse(localStorage.getItem("userData")) || {};
  const hasUserId = userId; // Check if userId exists in localStorage

  const { setIsLoading } = useLoader();

  // State to store user's permissions
  const [authPermissions, setAuthPermissions] = useState([]);
  const [permissionsFetched, setPermissionsFetched] = useState(!hasUserId); // Set permissionsFetched to true if userId doesn't exist

  useEffect(() => {
    // Fetch user permissions from the backend only if userId exists
    const fetchUserPermissions = async () => {
      try {
        setIsLoading(true); // Set isLoading to true before fetching data
        if (hasUserId) {
          const response = await axiosPrivate.get(
            `/user-has-permissions/user/${userId}`
          );
          const permissionsData = response?.data?.data || {};
          if (permissionsData?.length) {
            // Extract and format permissions

            const permissionsArray = permissionsData?.map(
              (permission) => `${permission?.name}-${permission?.action}`
            );
            setAuthPermissions(permissionsArray);
          }
        }
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      } finally {
        setPermissionsFetched(true); // Set permissionsFetched to true after fetching permissions
        setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
      }
    };

    fetchUserPermissions();
  }, [userId, hasUserId, setIsLoading]); // Add hasUserId as a dependency

  // Memoize the value object to prevent unnecessary re-renders
  const contextValue = {
    authPermissions,
    setAuthPermissions,
    setPermissionsFetched,
  };

  // Conditionally render Loader component based on permissionsFetched state
  if (!permissionsFetched) {
    return <Loader />;
  }

  // Provide permissions context to children components
  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
};

// Custom hook to consume permissions context
export const usePermissions = () => {
  return useContext(PermissionsContext);
};

export default PermissionsProvider;
