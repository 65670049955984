import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BOM from "../Bom/BOM";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../utils/Toaster";
import { Accordion } from "react-bootstrap";

function Screen9({ formik }) {
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { values, setFieldValue, handleBlur, touched, errors } = formik;
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionClick = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  // Fetch all price from master
  const fetchPricesFromMaster = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/bom-prices");
      if (response.status === 200) {
        setPrices(response.data?.data);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("multi stack vessel form data", formik.values.multiStackVesselFormData);

  const fetchNetWeight = async () => {
    try {
      // Now fetch the net weight
      const response = await axiosPrivate.post("/fetchAllWeights", otherNetWeight);
      const netWeightData = response.data?.data;

      netWeightData.forEach((data, index) => {
        const netWeight = data.netWeight;
        const netWeightInch = data.netWeight.flangeWeightForInch;
        const groundWeight = data.groundWeight;
        console.log("ground weight for baffle Data:", groundWeight.baffle);
        const pricesData = data.prices.data;
        const finalCosting = data.prices.data.totalOfAll.finalCosting;
        console.log("Final Costing Data:", finalCosting);
        const finalUnitBundle = data.prices.data.totalOfAll.unitBundle;

        updateNetWeightAndGroundWeight(netWeight, netWeightInch, groundWeight, index);
        updatePrices(pricesData, finalCosting, finalUnitBundle, index);
      });
    } catch (error) {
      console.error("Error in fetchNetWeight:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        console.error("Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchNetWeight();
      await fetchPricesFromMaster();

      // Set costing completion state to true

    };

    fetchData();
  }, []);

  const trClass = {
    borderCollapse: "collapse",
    height: "22px",
  };

  const tdClass = {
    borderCollapse: "collapse",
    border: "0.5px solid black",
    height: "22px",
    padding: "0px 4px",
    minWidth: "20px",
  };

  const tableClass = {
    width: "100%",
    // border: "0.5px solid black",
    borderCollapse: "collapse",
  };

  const tableParentDiv = {
    display: "flex",
    margin: "10px",
    gap: "10px",
  };

  const printPDF = async (elementId) => {
    try {
      const input = document.getElementById(elementId);
      if (!input) {
        console.error("Element not found");
        return;
      }

      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 size width in mm
      const pageHeight = 295; // A4 size height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("download.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };


  const powerelement = () => {
    const totalnumberofelements = formik?.values?.totalnumberofelements || 0;
    const power = formik?.values?.power || 0;
    const powerperelement = totalnumberofelements
      ? Math.round(power / totalnumberofelements)
      : 0;
    formik.setFieldValue("powerperelement", powerperelement);
  };

  const calculateHeatingElementAddValue = (
    thickness,
    distance,
    totalnumberofelements
  ) => {
    return Math.round(
      ((5 * ((thickness + 30 + distance) / 5) * 2 * totalnumberofelements) /
        1000 /
        totalnumberofelements /
        2) *
      1000
    );
  };

  const setHeatingElementValues = (values) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };



  const heatingElement = () => {
    const {
      fixingofheatingelements,
      totalnumberofelements,
      heatingElementThickness,
      distbwheaterflangetoterminalbox,
      heatersheathtubedia,
    } = formik.values;
    const totalElements = parseInt(totalnumberofelements) || 0;
    const thickness = parseFloat(heatingElementThickness) || 0;
    const distance = parseInt(distbwheaterflangetoterminalbox) || 0;

    const commonValues = {
      "SLEEVES": {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      "TIG+SLEEVES": {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      "FERRULE": {
        heatingElementType: "FOR",
        heatingElementValue: heatersheathtubedia,
        heatingElementUnit: "MM",
        heatingElememtAddUnit: "DIA",
        heatingElementAddValue: "HEATING ELEMENT",
        heatingElementAddValueUnit: "TING ELEMENT",
        heatingElementTubeSheet: "False",
      },
      "BUSH": {
        heatingElementType: "BUSH",
        heatingElementValue: " '' ",
        heatingElementUnit: " ",
        heatingElememtAddUnit: "BUSH",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "False",
      },
      "Direct Welding": {
        heatingElementType: heatersheathtubedia,
        heatingElementValue: "DIA",
        heatingElementUnit: "FUSED",
        heatingElememtAddUnit: "DIRECT",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Grooving for Direct Fusing",
      },
      "NUT": {
        heatingElementType: "3",
        heatingElementValue: "NUT",
        heatingElementUnit: " ",
        heatingElememtAddUnit: " ",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Collar Welding",
      },
    };

    const defaultValues = {
      heatingElementType: " ",
      heatingElementValue: " ",
      heatingElementUnit: " ",
      heatingElememtAddUnit: " ",
      heatingElementAddValue: " ",
      heatingElementAddValueUnit: " ",
      heatingElementTubeSheet: "False",
    };

    setHeatingElementValues(
      commonValues[fixingofheatingelements] || defaultValues
    );
  };
  // Function  to calculate the values for sending the payload for tie rod with sleeves
  const calculateBomLengths = (maxActiveHotLength, maxActiveColdLength) => {
    const HotLengthCalculatedForBom = 10 * Math.round(((maxActiveHotLength * 2) + 10) / 10);

    const TotalDevelopedLengthForBom = HotLengthCalculatedForBom + (2 * maxActiveColdLength);

    // Update Formik fields
    setFieldValue("hotLengthCalculatedForBom", HotLengthCalculatedForBom);
    setFieldValue("totalDevelopedLengthForBom", TotalDevelopedLengthForBom);
  };
  useEffect(() => {
    calculateBomLengths(
      formik.values.maxactivehotlength,
      formik.values.maxactivecoldlength,
    );
  }, [formik.values.maxactivehotlength, formik.values.maxactivecoldlength,]);

  const isCompleteObject = (obj) => {
    return Object.values(obj).every(value => value !== undefined && value !== null && value !== '');
  };

  const netWeightPayload = {
    type: "flangeWeightForInch",
    data: [
      // 1 MATERIAL
      {
        key: "materialWeight",
        fixingOfHeatingElement: formik?.values?.fixingofheatingelements,
        heatingElementAddValue: Math.round(((5 * ((formik.values.heatingElementThickness + 30 + formik.values.distbwheaterflangetoterminalbox) / 5) * 2 * formik?.values?.totalnumberofelements) /
          1000 /
          formik?.values?.totalnumberofelements /
          2) *
          1000),
        heaterTubeDia: formik?.values?.heatersheathtubedia,
        elements: (formik?.values?.totalnumberofelements * 2),
      },
      {
        flangeSize: formik.values.flangeSize,
        key: "dishEnd",
        heading: "pipeod",
        thickness: formik.values.shellThickness,
      },
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletNozzle",
        flangeSize: formik.values.nozzle[0].size,
        schedule: formik.values.nozzle[0].schedules,
      },
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletFlange",
        flangeSize: formik.values.nozzle[0].size,
        vesselRangeType: formik.values.nozzle[0].flangeType,
        flangeRating: formik.values.nozzle[0].flangeRating,
      },
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletNozzle",
        flangeSize: formik.values.nozzle[1].size,
        schedule: formik.values.nozzle[1].schedules,
      },
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletFlange",
        flangeSize: formik.values.nozzle[1].size,
        vesselRangeType: formik.values.nozzle[1].flangeType,
        flangeRating: formik.values.nozzle[1].flangeRating,
      },
      formik?.values?.nozzle[2]?.vent && {
        key: "ventNozzle",
        flangeSize: formik.values.nozzle[2].size,
        schedule: formik.values.nozzle[2].schedules,
      },
      formik?.values?.nozzle[2]?.vent && {
        key: "ventFlange",
        flangeSize: formik.values.nozzle[2].size,
        vesselRangeType: formik.values.nozzle[2].flangeType,
        flangeRating: formik.values.nozzle[2].flangeRating,
      },
      formik?.values?.nozzle[3]?.drain && {
        key: "drainNozzle",
        flangeSize: formik.values.nozzle[3].size,
        schedule: formik.values.nozzle[3].schedules,
      },
      formik?.values?.nozzle[3]?.drain && {
        key: "drainFlange",
        flangeSize: formik.values.nozzle[3].size,
        vesselRangeType: formik.values.nozzle[3].flangeType,
        flangeRating: formik.values.nozzle[3].flangeRating,
      },
      formik?.values?.nozzle[4]?.process && {
        key: "processNozzle",
        flangeSize: formik.values.nozzle[4].size,
        schedule: formik.values.nozzle[4].schedules,
      },
      formik?.values?.nozzle[4]?.process && {
        key: "processFlange",
        flangeSize: formik.values.nozzle[4].size,
        vesselRangeType: formik.values.nozzle[4].flangeType,
        flangeRating: formik.values.nozzle[4].flangeRating,
      },
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyNozzle",
        flangeSize: formik.values.nozzle[5].size,
        schedule: formik.values.nozzle[5].schedules,
      },
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyFlange",
        flangeSize: formik.values.nozzle[5].size,
        vesselRangeType: formik.values.nozzle[5].flangeType,
        flangeRating: formik.values.nozzle[5].flangeRating,
      },
      {
        key: "baffle",
        heading: "pipeod",
        flangeSize: formik.values.flangeSize,
        bafflePlatethickness: formik.values.baffleplatethickness,
        shellThickness: formik.values.shellThickness,
        numberOfBaffles: formik.values.numberofHotBaffles,
        baffleType: formik.values.baffletype,
      },
      {
        key: "tieRodWithSleeves",
        hotLengthCalculatedForBom: formik.values.hotLengthCalculatedForBom,
        totalDevelopedLengthForBom: formik.values.totalDevelopedLengthForBom,
        numberOfHotBaffles: formik.values.numberofHotBaffles,
        tieRodDia: formik.values.tieroddia,
      },
      {
        key: "lugSupport",
        type: "getLugWeight",
        heading: "pipeod",
        flangeSize: formik.values.flangeSize,
        insulationThickness: formik.values.insulation.insulationThickness,
        position: formik.values.installationPosition,
      },
      {
        heading: "pipeod",
        key: "junctionBox",
        flangeSize: formik.values.flangeSize,
      },
      {
        key: "terBoxFabCost",
        baffleBom: formik.values.baffleOD,
        flangeSize: formik.values.flangeSize,
      },
      {
        key: "sideAttachedJb",
        baffleBom: formik.values.baffleOD,
        flangeSize: formik.values.flangeSize,
        stack: formik.values.multistackvessel,
      },
      formik.values.areaClassification === "hazardous" &&
      {
        key: "terBoxBasePlate",
        terminalBoxSize: formik.values.terminalBoxSize,

        // flangeSize: formik.values.flangeSize,
        // flpYesOrNo: formik?.values?.terminalBoxIfFlpOrNonFlp,
      },
      {
        key: "flpJbWeight",
        flpyesorno: formik?.values?.terminalBoxIfFlpOrNonFlp,
        flangeSize: formik.values.flangeSize,
      },
      {
        key: "insulationCladdingCylinderWt",
        od: formik?.values?.pipeOD,
        vesselLength: formik?.values?.maxactivehotlength,
        claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
        claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
      },
      {
        key: "claddingFacingPlateWt",
        od: formik?.values?.pipeOD,
        claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
        claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
      },
      {
        key: "claddingWastageWt",
        wastage: formik?.values?.insulation.wastage,
      },
      {
        key: "claddingTotalWt",
      },
      {
        key: "glassWoolWt",
        od: formik?.values?.pipeOD,
        vesselLength: formik?.values?.maxactivehotlength,
        insulationThickness: formik?.values?.insulation.insulationThickness,
        insulationDensity: formik?.values?.insulation.insulationDensity,
        wastage: formik?.values?.insulation.wastage,
      },
      {
        key: "baffleBomValue",
        numberOfHotBaffles: Number(formik.values.numberofHotBaffles),
        bafflePlateThickness: formik.values.baffleplatethickness,
      },
    ].filter(isCompleteObject), // Removing any incomplete objects
  };

  const netWeightPayloadforstacktwo = (index) => {
    let multiStackData = formik?.values?.multiStackVesselFormData?.[index];

    let data = {
      type: "flangeWeightForInch",
      data: [
        {
          key: "materialWeight",
          fixingOfHeatingElement: formik?.values?.fixingofheatingelements,
          heatingElementAddValue: Math.round(((5 * ((formik.values.heatingElementThickness + 30 + formik.values.distbwheaterflangetoterminalbox) / 5) * 2 * formik?.values?.totalnumberofelements) /
            1000 /
            formik?.values?.totalnumberofelements /
            2) *
            1000),
          heaterTubeDia: formik?.values?.heatersheathtubedia,
          elements: (formik?.values?.totalnumberofelements * 2),
        },
        {
          flangeSize: formik.values.flangeSize,
          key: "dishEnd",
          heading: "pipeod",
          thickness: formik.values.shellThickness,
        },
        multiStackData?.nozzle?.[0]?.inletNozzle && {
          key: "inletNozzle",
          flangeSize: multiStackData.nozzle[0].size,
          schedule: multiStackData.nozzle[0].schedules,
        },
        multiStackData?.nozzle?.[0]?.inletNozzle && multiStackData?.nozzle?.[0]?.counterFlange && {
          key: "inletFlange",
          flangeSize: multiStackData.nozzle[0].size,
          vesselRangeType: formik.values.nozzle[0].flangeType,
          flangeRating: multiStackData.nozzle[0].flangeRating,
        },
        multiStackData?.nozzle?.[1]?.outletNozzle && {
          key: "outletNozzle",
          flangeSize: multiStackData.nozzle[1].size,
          schedule: multiStackData.nozzle[1].schedules,
        },
        multiStackData?.nozzle?.[1]?.outletNozzle && multiStackData?.nozzle?.[1]?.counterFlange && {
          key: "outletFlange",
          flangeSize: multiStackData.nozzle[1].size,
          vesselRangeType: formik.values.nozzle[1].flangeType,
          flangeRating: multiStackData.nozzle[1].flangeRating,
        },
        formik?.values?.nozzle[2]?.vent && {
          key: "ventNozzle",
          flangeSize: formik.values.nozzle[2].size,
          schedule: formik.values.nozzle[2].schedules,
        },
        formik?.values?.nozzle[2]?.vent && {
          key: "ventFlange",
          flangeSize: formik.values.nozzle[2].size,
          vesselRangeType: formik.values.nozzle[2].flangeType,
          flangeRating: formik.values.nozzle[2].flangeRating,
        },
        formik?.values?.nozzle[3]?.drain && {
          key: "drainNozzle",
          flangeSize: formik.values.nozzle[3].size,
          schedule: formik.values.nozzle[3].schedules,
        },
        formik?.values?.nozzle[3]?.drain && {
          key: "drainFlange",
          flangeSize: formik.values.nozzle[3].size,
          vesselRangeType: formik.values.nozzle[3].flangeType,
          flangeRating: formik.values.nozzle[3].flangeRating,
        },
        formik?.values?.nozzle[4]?.process && {
          key: "processNozzle",
          flangeSize: formik.values.nozzle[4].size,
          schedule: formik.values.nozzle[4].schedules,
        },
        formik?.values?.nozzle[4]?.process && {
          key: "processFlange",
          flangeSize: formik.values.nozzle[4].size,
          vesselRangeType: formik.values.nozzle[4].flangeType,
          flangeRating: formik.values.nozzle[4].flangeRating,
        },
        formik?.values?.nozzle[5]?.dummy && {
          key: "dummyNozzle",
          flangeSize: formik.values.nozzle[5].size,
          schedule: formik.values.nozzle[5].schedules,
        },
        formik?.values?.nozzle[5]?.dummy && {
          key: "dummyFlange",
          flangeSize: formik.values.nozzle[5].size,
          vesselRangeType: formik.values.nozzle[5].flangeType,
          flangeRating: formik.values.nozzle[5].flangeRating,
        },
        {
          key: "baffle",
          heading: "pipeod",
          flangeSize: formik.values.flangeSize,
          bafflePlatethickness: formik.values.baffleplatethickness,
          shellThickness: formik.values.shellThickness,
          numberOfBaffles: multiStackData?.baffle?.numberofHotBaffles,
          baffleType: formik.values.baffletype,
        },
        {
          key: "tieRodWithSleeves",
          hotLengthCalculatedForBom: formik.values.hotLengthCalculatedForBom,
          totalDevelopedLengthForBom: formik.values.totalDevelopedLengthForBom,
          numberOfHotBaffles: formik.values.numberofHotBaffles,
          tieRodDia: formik.values.tieroddia,
        },
        {
          key: "lugSupport",
          type: "getLugWeight",
          heading: "pipeod",
          flangeSize: formik.values.flangeSize,
          insulationThickness: formik.values.insulation.insulationThickness,
        },
        {
          heading: "pipeod",
          key: "junctionBox",
          flangeSize: formik.values.flangeSize,
        },
        {
          key: "terBoxFabCost",
          baffleBom: formik.values.baffleOD,
          flangeSize: formik.values.flangeSize,
        },
        {
          key: "sideAttachedJb",
          baffleBom: formik.values.baffleOD,
          flangeSize: formik.values.flangeSize,
          stack: formik.values.multistackvessel,
        },
        formik.values.areaClassification === "hazardous" &&
        {
          key: "terBoxBasePlate",
          // flangeSize: formik.values.flangeSize,
          // flpYesOrNo: formik?.values?.terminalBoxIfFlpOrNonFlp,
        },
        {
          key: "flpJbWeight",
          flpyesorno: formik?.values?.terminalBoxIfFlpOrNonFlp,
          flangeSize: formik.values.flangeSize,
        },
        {
          key: "insulationCladdingCylinderWt",
          od: formik?.values?.pipeOD,
          vesselLength: formik?.values?.maxactivehotlength,
          claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
          claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
        },
        {
          key: "claddingFacingPlateWt",
          od: formik?.values?.pipeOD,
          claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
          claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
        },
        // Cladding Wastage Wt.
        {
          key: "claddingWastageWt",
          wastage: formik?.values?.insulation.wastage,
        },
        // Cladding Total Wt.
        {
          key: "claddingTotalWt",
        },
        // Glass Wool Wt
        {
          key: "glassWoolWt",
          od: formik?.values?.pipeOD,
          vesselLength: formik?.values?.maxactivehotlength,
          insulationThickness: formik?.values?.insulation.insulationThickness,
          insulationDensity: formik?.values?.insulation.insulationDensity,
          wastage: formik?.values?.insulation.wastage,
        },
        {
          key: "baffleBomValue",
          numberOfHotBaffles: multiStackData?.baffle?.numberofHotBaffles,
          bafflePlateThickness: formik.values.baffleplatethickness,
        },
      ].filter(isCompleteObject), // Removing any undefined values
    };

    return data;
  };


  const pricePayload = {
    type: "pricesPayload",
    data: [
      {
        totalOfAll: {
          manufacturingCost: formik?.values?.manufacturingCost
            ? Number(formik?.values?.manufacturingCost)
            : 0,
          margin: formik?.values?.margin ? Number(formik?.values?.margin) : 0,
          contingency: formik?.values?.contingency
            ? Number(formik?.values?.contingency)
            : 0,
        },
      },
      {
        key: "materialCost",
        name: "FIXINGOFHEATINGELEMENT",
        fixingOfHeatingElement: formik?.values?.fixingofheatingelements,
        elements: (formik?.values?.totalnumberofelements * 2),
        ...(formik.values.fixingofheatingelements === "SLEEVES" ||
          formik.values.fixingofheatingelements === "TIG+SLEEVES" ||
          formik.values.fixingofheatingelements === "BUSH"
          ? { material: formik?.values?.materialofsleevesbush, }
          : {}),
      },
      {
        key: "heatingElement",
        name: "TUBE",
        totalDevelopedLengthForBom: formik.values.totalDevelopedLengthForBom,
        coldLength: Number(formik.values.maxactivecoldlength),
        elements: formik?.values?.fixingofheatingelements === "NUT" ? (formik?.values?.nutsize * 3) : formik?.values?.totalnumberofelements,
        heatingprice: [
          {
            name: "TUBEDIA",
            size: formik?.values?.heatersheathtubedia,
          },
          {
            name: "POWDER",
            size: formik?.values?.heatersheathtubedia,
          },
        ],

      },
      {
        key: "tubeSheet",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        altPrice: formik.values.tubeSheetCost,
        isAct: formik.values.tubeSheetCostType
      },
      // tubbe sheet drilling new
      {
        key: "tubeSheetDrilling",
        name: "FIXINGOFHEATINGELEMENT",
        fixingofheatingelements: formik?.values?.fixingofheatingelements,
        ...(formik?.values?.fixingofheatingelements === "SLEEVES" ||
          formik?.values?.fixingofheatingelements === "TIG+SLEEVES"
          ? { material: formik?.values?.materialofsleevesbush }
          : {}),
        holesandnut: formik.values?.fixingofheatingelements === "NUT"
          ? Number(formik?.values?.nutsize)
          : Number(formik?.values?.totalnumberofelements * 2),
      },
      // Shell Ring Price Payload
      {
        key: "shellRing",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        altPrice: formik.values.shellFlangeCost,
        isAct: formik.values.shellFlangeCostType
      },
      // Shell Vessel Price Payload
      {
        key: "shellVessel",
        name: "PIPE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        altPrice: formik.values.vesselCost,
        isAct: formik.values.vesselCostType
      },
      // Dish End Price Payload
      {
        key: "dishEnd",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
      },
      // Inlet Nozzle Price Payload
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[0].material,
        material: formik.values.nozzle[0].materialCode,
      },
      // Inlet Flange Price Payload
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[0].material,
        material: formik.values.nozzle[0].materialCode,
      },
      // Outlet Nozzle Price Payload
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[1].material,
        material: formik.values.nozzle[1].materialCode,
      },
      // Outlet Flange Price Payload
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[1].material,
        material: formik.values.nozzle[1].materialCode,
      },
      // Vent Nozzle Price Payload
      formik?.values?.nozzle[2]?.vent && {
        key: "ventNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[2].material,
        material: formik.values.nozzle[2].materialCode,
      },
      // Vent Flange Price Payload
      formik?.values?.nozzle[2]?.vent && {
        key: "ventFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[2].material,
        material: formik.values.nozzle[2].materialCode,
      },
      // Drain Nozzle Price Payload
      formik?.values?.nozzle[3]?.drain && {
        key: "drainNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[3].material,
        material: formik.values.nozzle[3].materialCode,
      },
      // Drain Flange Price Payload
      formik?.values?.nozzle[3]?.drain && {
        key: "drainFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[3].material,
        material: formik.values.nozzle[3].materialCode,
      },
      // Process Nozzle Price Payload
      formik?.values?.nozzle[4]?.process && {
        key: "processNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[4].material,
        material: formik.values.nozzle[4].materialCode,
      },
      // Process Flange Price Payload
      formik?.values?.nozzle[4]?.process && formik?.values?.nozzle[4]?.counterFlange && {
        key: "processFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[4].material,
        material: formik.values.nozzle[4].materialCode,
      },
      // Dummy Nozzle Price Payload
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyNozzle",
        name: "PIPE",
        material_category: formik.values.nozzle[5].material,
        material: formik.values.nozzle[5].materialCode,
      },
      // Dummy Flange Price Payload
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[5].material,
        material: formik.values.nozzle[5].materialCode,
      },
      // Baffle Price Payload
      formik?.values?.bafflematerial != "" &&
      {
        name: "PLATE",
        // name: formik.values.bafflematerial,
        material: formik?.values?.bafflematerial,
        key: "baffle",
        type: 1,
        altPrice: formik.values.baffleCost,
        isAct: formik.values.baffleCostType
      },
      // Tie Rod with Sleeves Price Payload
      formik.values.tiematerial != "" &&
      {
        // name: formik.values.tiematerial,
        name: "PLATE",
        material: formik.values.tiematerial,
        key: "tieRodWithSleeves",
        type: 1,
      },
      // Lug Support Price Payload
      formik.values.sadlemoc != "" &&
      {
        // name: formik.values.sadlemoc,
        name: "PLATE",
        material: formik.values.sadlemoc,
        key: "lugSupport",
        size: formik.values.flangeSize,
        type: 1,
      },
      // Junction Box Price Payload
      formik.values.instrumentjunctionboxmaterial != "" &&
      {
        name: "JUNCTIONBOX",
        material: formik.values.instrumentjunctionboxmaterial,
        key: "junctionBox",
        type: 1,
      },
      // Ter. Box BASE Plate Price Payload
      formik.values.areaClassification === "hazardous" &&
      {
        key: "terBoxBasePlate",
        material: formik.values.heaterterminalboxmaterial,
        name: "JUNCTIONBOX",
        // price: formik.values.price.terminalBoxBasePlate
      },

      // Cable Gland payload

      {
        name: "cableGland",
        key: "cableGland",
        heaterterminalboxijb: formik?.values?.heaterterminalboxijb,
        stack: formik?.values?.multistackvessel,
        voltage: formik?.values?.voltage,
        power: formik?.values?.power,
        cableGland: formik?.values?.cableGland,
      },
      // Thermowell Price Payload
      {
        key: "thermoWell",
        price: formik.values.price.priceOfThermowell
      },
      // Safety ThermoCouple Price Payload
      {
        key: "safetyThermocouple",
        price: formik.values.price.safetyThermocouple
      },
      // Process ThermoCouple Price Payload
      {
        key: "processThermocouple",
        price: formik.values.price.processThermocouple
      },
      // Connection Price Payload
      {
        key: "connection",
        elements: formik?.values?.fixingofheatingelements === "NUT" ? 3 * formik?.values?.nutsize : formik?.values?.totalnumberofelements,
        individualElementPower: formik?.values?.power / formik?.values?.numberofelementsconnected,
        voltage: formik?.values?.voltage,
        power: formik?.values?.power,
        bank: formik?.values?.bank,
      },
      // HARDWARE
      {
        key: "hardware",
        flangeSize: formik?.values?.flangeSize,
        inletSize: formik?.values?.nozzle[0]?.size,
        name: "FLANGE",
        material_category: formik?.values?.FlangeMaterial,
        material: formik?.values?.FlangeMaterialCode,
        class: formik?.values?.FlangeClass,
        type: formik?.values?.flangeSelection,

      },
      // Gaskets Price Payload
      {
        key: "gaskets",
        price: formik.values.price.priceOfGaskets
      },
      // Insulation Price payload to fetch the price
      {
        name: "insulation",
        key: "insulationPrice",
        glassWoolRate: formik?.values?.insulationPrice?.glassWoolRate,
        claddingSheetRate: formik?.values?.insulationPrice?.claddingSheetRate,
        labourCharges: formik?.values?.insulationPrice?.labourCharges,
        miscCharges: formik?.values?.insulationPrice?.miscCharges,
        insulation: formik?.values?.insulation.insulationThickness,
        altPrice: formik.values.insulationCost,
        isAct: formik.values.insulationCostType
      },
      // Radiography Cost price
      {
        key: "radiographyCost",
        size: formik?.values?.flangeSize,
        radiography: formik.values.radiography?.value,
        altPrice: formik.values.radiographyCost,
        isAct: formik.values.radiographyCostType
      },
      // Painting Cost Price Payload
      {
        key: "paintingPrice",
        price: formik.values.price.paintingCost
      },
      // miscellaneous price payload
      {
        key: "miscellaneousPrice",
        price: formik.values.miscellaneous
      },
      // Powder Individual price
      {
        key: "powderIndPrice",
        name: "TUBE",
        material_category: "POWDER",
        material: formik?.values?.sheathmaterial,
        size: formik?.values?.heatersheathtubedia,
      },
      // Tube Individual Price
      {
        key: "tubeIndPrice",
        name: "TUBE",
        material_category: "TUBEDIA",
        size: formik?.values?.heatersheathtubedia,
        material: formik?.values?.sheathmaterial,
      },
      // fabrication cost
      {
        key: "fabricationPlateIndPrice1",
        name: "PLATE",
        material: formik.values.bafflematerial
      },
      // fabrication cost
      {
        key: "fabricationPlateIndPrice2",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
      },

      // Sleeves Individual Price
      {
        key: "sleevesIndPrice",
        name: "FIXINGOFHEATINGELEMENT",
        fixingOfHeatingElement: "SLEEVES",
        material: formik.values.materialofsleevesbush,
      },
      // NUT Individual Price
      {
        key: "nutIndPrice",
        name: "FIXINGOFHEATINGELEMENT",
        fixingOfHeatingElement: formik.values.fixingofheatingelements,
      }


    ].filter(isCompleteObject),
  };

  const pricePayloadMulti = (index) => {
    let multiStackData = formik?.values?.multiStackVesselFormData?.[index];

    let data = {
      type: "pricesPayload",
      data: [
        {
          totalOfAll: {
            manufacturingCost: formik?.values?.manufacturingCost
              ? Number(formik?.values?.manufacturingCost)
              : 0,
            margin: formik?.values?.margin ? Number(formik?.values?.margin) : 0,
            contingency: formik?.values?.contingency
              ? Number(formik?.values?.contingency)
              : 0,
          },
        },
        {
          key: "materialCost",
          name: "FIXINGOFHEATINGELEMENT",
          fixingOfHeatingElement: formik?.values?.fixingofheatingelements,
          elements: (formik?.values?.totalnumberofelements * 2),
          ...(formik.values.fixingofheatingelements === "SLEEVES" ||
            formik.values.fixingofheatingelements === "TIG+SLEEVES" ||
            formik.values.fixingofheatingelements === "BUSH"
            ? { material: formik?.values?.materialofsleevesbush, }
            : {}),
        },
        {
          key: "heatingElement",
          name: "TUBE",
          totalDevelopedLengthForBom: formik.values.totalDevelopedLengthForBom,
          coldLength: Number(formik.values.maxactivecoldlength),
          elements: formik?.values?.fixingofheatingelements === "NUT" ? (formik?.values?.nutsize * 3) : formik?.values?.totalnumberofelements,
          heatingprice: [
            {
              name: "TUBEDIA",
              size: formik?.values?.heatersheathtubedia,
            },
            {
              name: "POWDER",
              size: formik?.values?.heatersheathtubedia,
            },
          ],
        },
        {
          key: "tubeSheet",
          name: "FLANGE",
          material_category: formik.values.FlangeMaterial,
          material: formik.values.FlangeMaterialCode,
          altPrice: formik.values.tubeSheetCost,
          isAct: formik.values.tubeSheetCostType
        },
        {
          key: "tubeSheetDrilling",
          name: "FIXINGOFHEATINGELEMENT",
          fixingofheatingelements: formik?.values?.fixingofheatingelements,
          ...(formik?.values?.fixingofheatingelements === "SLEEVES" ||
            formik?.values?.fixingofheatingelements === "TIG+SLEEVES"
            ? { material: formik?.values?.materialofsleevesbush }
            : {}),
          holesandnut: formik.values?.fixingofheatingelements === "NUT"
            ? Number(formik?.values?.nutsize)
            : Number(formik?.values?.totalnumberofelements * 2),
        },
        {
          key: "shellRing",
          name: "FLANGE",
          material_category: formik.values.FlangeMaterial,
          material: formik.values.FlangeMaterialCode,
          altPrice: formik.values.shellFlangeCost,
          isAct: formik.values.shellFlangeCostType
        },
        {
          key: "shellVessel",
          name: "PIPE",
          material_category: formik.values.FlangeMaterial,
          material: formik.values.FlangeMaterialCode,
          altPrice: formik.values.vesselCost,
          isAct: formik.values.vesselCostType
        },
        {
          key: "dishEnd",
          name: "FLANGE",
          material_category: formik.values.FlangeMaterial,
          material: formik.values.FlangeMaterialCode,
        },
        multiStackData?.nozzle?.[0]?.inletNozzle && {
          key: "inletNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[0].material,
          material: multiStackData.nozzle[0].materialCode,
        },
        multiStackData?.nozzle?.[0]?.inletNozzle && {
          key: "inletFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[0].material,
          material: multiStackData.nozzle[0].materialCode,
        },
        multiStackData?.nozzle?.[1]?.outletNozzle && {
          key: "outletNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[1].material,
          material: multiStackData.nozzle[1].materialCode,
        },
        multiStackData?.nozzle?.[1]?.outletNozzle && {
          key: "outletFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[1].material,
          material: multiStackData.nozzle[1].materialCode,
        },
        multiStackData?.nozzle?.[2]?.vent && {
          key: "ventNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[2].material,
          material: multiStackData.nozzle[2].materialCode,
        },
        multiStackData?.nozzle?.[2]?.vent && {
          key: "ventFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[2].material,
          material: multiStackData.nozzle[2].materialCode,
        },
        multiStackData?.nozzle?.[3]?.drain && {
          key: "drainNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[3].material,
          material: multiStackData.nozzle[3].materialCode,
        },
        multiStackData?.nozzle?.[3]?.drain && {
          key: "drainFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[3].material,
          material: multiStackData.nozzle[3].materialCode,
        },
        multiStackData?.nozzle?.[4]?.process && {
          key: "processNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[4].material,
          material: multiStackData.nozzle[4].materialCode,
        },
        multiStackData?.nozzle?.[4]?.process && multiStackData?.nozzle?.[4]?.counterFlange && {
          key: "processFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[4].material,
          material: multiStackData.nozzle[4].materialCode,
        },
        multiStackData?.nozzle?.[5]?.dummy && {
          key: "dummyNozzle",
          name: "PIPE",
          material_category: multiStackData.nozzle[5].material,
          material: multiStackData.nozzle[5].materialCode,
        },
        multiStackData?.nozzle?.[5]?.dummy && {
          key: "dummyFlange",
          name: "FLANGE",
          material_category: multiStackData.nozzle[5].material,
          material: multiStackData.nozzle[5].materialCode,
        },
        formik?.values?.bafflematerial != "" && {
          name: "PLATE",
          material: formik?.values?.bafflematerial,
          key: "baffle",
          type: 1,
          altPrice: formik.values.baffleCost,
          isAct: formik.values.baffleCostType
        },
        formik.values.tiematerial != "" && {
          name: "PLATE",
          material: formik.values.tiematerial,
          key: "tieRodWithSleeves",
          type: 1,
        },
        formik.values.sadlemoc != "" && {
          name: "PLATE",
          material: formik.values.sadlemoc,
          key: "lugSupport",
          size: formik.values.flangeSize,
          type: 1,
        },
        formik.values.instrumentjunctionboxmaterial != "" && {
          name: "JUNCTIONBOX",
          material: formik.values.instrumentjunctionboxmaterial,
          key: "junctionBox",
          type: 1,
        },
        formik.values.areaClassification === "hazardous" &&
        {
          key: "terBoxBasePlate",
          price: formik.values.price.terminalBoxBasePlate
        },
        {
          name: "cableGland",
          key: "cableGland",
          heaterterminalboxijb: formik?.values?.heaterterminalboxijb,
          stack: formik?.values?.multistackvessel,
          voltage: formik?.values?.voltage,
          power: formik?.values?.power,
          cableGland: formik?.values?.cableGland,
        },
        {
          key: "thermoWell",
          price: formik.values.price.priceOfThermowell
        },
        {
          key: "safetyThermocouple",
          price: formik.values.price.safetyThermocouple
        },
        {
          key: "processThermocouple",
          price: formik.values.price.processThermocouple
        },
        {
          key: "connection",
          elements: formik?.values?.fixingofheatingelements === "NUT" ? 3 * formik?.values?.nutsize : formik?.values?.totalnumberofelements,
          individualElementPower: formik?.values?.power / formik?.values?.numberofelementsconnected,
          voltage: formik?.values?.voltage,
          power: formik?.values?.power,
          bank: formik?.values?.bank,
        },
        {
          key: "hardware",
          flangeSize: formik?.values?.flangeSize,
          inletSize: formik?.values?.nozzle[0]?.size,
          name: "FLANGE",
          material_category: formik?.values?.FlangeMaterial,
          material: formik?.values?.FlangeMaterialCode,
          class: formik?.values?.FlangeClass,
          type: formik?.values?.flangeSelection,
        },
        {
          key: "gaskets",
          price: formik.values.price.priceOfGaskets
        },
        {
          name: "insulation",
          key: "insulationPrice",
          glassWoolRate: formik?.values?.insulationPrice?.glassWoolRate,
          claddingSheetRate: formik?.values?.insulationPrice?.claddingSheetRate,
          labourCharges: formik?.values?.insulationPrice?.labourCharges,
          miscCharges: formik?.values?.insulationPrice?.miscCharges,
          insulation: formik?.values?.insulation.insulationThickness,
          altPrice: formik.values.insulationCost,
          isAct: formik.values.insulationCostType
        },
        {
          key: "radiographyCost",
          size: formik?.values?.flangeSize,
          radiography: formik.values.radiography?.value,
          altPrice: formik.values.radiographyCost,
          isAct: formik.values.radiographyCostType
        },
        {
          key: "paintingPrice",
          price: formik.values.price.paintingCost
        },
        {
          key: "miscellaneousPrice",
          price: formik.values.miscellaneous
        },
        {
          key: "powderIndPrice",
          name: "TUBE",
          material_category: "POWDER",
          material: formik?.values?.sheathmaterial,
          size: formik?.values?.heatersheathtubedia,
        },
        {
          key: "tubeIndPrice",
          name: "TUBE",
          material_category: "TUBEDIA",
          size: formik?.values?.heatersheathtubedia,
          material: formik?.values?.sheathmaterial,
        },
        {
          key: "fabricationPlateIndPrice1",
          name: "PLATE",
          material: formik.values.bafflematerial
        },
        {
          key: "sleevesIndPrice",
          name: "FIXINGOFHEATINGELEMENT",
          fixingOfHeatingElement: "SLEEVES",
          material: formik.values.materialofsleevesbush,
        },
        {
          key: "nutIndPrice",
          name: "FIXINGOFHEATINGELEMENT",
          fixingOfHeatingElement: formik.values.fixingofheatingelements,
        }
      ].filter(isCompleteObject), // Removing any incomplete objects
    };

    return data;
  };

  const generatePayload = (formik, netWeightPayload, netWeightPayloadforstacktwo, pricePayload, pricePayloadMulti, multiStackVesselCount) => {
    // Base payload template
    const basePayload = [
      {
        key: "tubeSheet",
        type: "getFlangeWeight",
        flange_rating: formik?.values?.FlangeClass,
        nps: formik?.values?.flangeSize,
      },
      {
        key: "shellRing",
        type: "getFlangeWeightInKg",
        flange_type: formik?.values?.vesselFlangeType,
        pipe_size: formik?.values?.flangeSize,
        flange_rating: formik?.values?.FlangeClass?.toString(),
      },
      {
        key: "shellVessel",
        type: "getPipeWeight",
        sch: parseInt(formik?.values?.schedule),
        nps: parseFloat(formik?.values?.flangeSize),
        vesselLength: (formik.values.bundleimmersionlength + 150) / 1000
      },
      null, // Placeholder for netWeightPayload
      null, // Placeholder for pricePayload
    ];

    const isCompleteObject = (obj) => {
      return Object.values(obj).every(value => value !== undefined && value !== null);
    };
    // If there's only 1 vessel, return the payload with netWeightPayload inserted
    // If there's only 1 vessel, return the payload with netWeightPayload and pricePayload inserted
    if (multiStackVesselCount === 1) {
      return [
        basePayload.map((item, i) => {
          if (item === null) {
            return i === 3 ? netWeightPayload : pricePayload;
          }
          return item;
        }).filter(isCompleteObject),
      ];
    }

    // Generate payloads for multiple vessels
    const payloads = Array.from({ length: multiStackVesselCount }, (_, index) => {
      const currentNetWeightPayload = index === 0 ? netWeightPayload : netWeightPayloadforstacktwo(index);
      const currentPricePayload = index === 0 ? pricePayload : pricePayloadMulti(index);
      return basePayload.map((item, i) => {
        if (item === null) {
          return i === 3 ? currentNetWeightPayload : currentPricePayload;
        }
        if (item.key === "shellVessel") {
          return {
            ...item,
            sch: parseInt(formik?.values?.multiStackVesselFormData?.[index]?.schedule || formik?.values?.schedule),
            nps: parseFloat(formik?.values?.multiStackVesselFormData?.[index]?.flangeSize || formik?.values?.flangeSize),
          };
        }
        return item;
      }).filter(isCompleteObject);
    });

    return payloads;
  };

  // Usage
  const multiStackVesselCount = formik.values.multistackvessel || 1; // Default to 1 if not provided

  const otherNetWeight = generatePayload(formik, netWeightPayload, netWeightPayloadforstacktwo, pricePayload, pricePayloadMulti, multiStackVesselCount);


  useEffect(() => {
    powerelement();
    heatingElement();
  }, [formik.values.power, formik.values.totalnumberofelements]);
  const updateNetWeightAndGroundWeight = (netWeight, netWeightInch, groundWeight, index) => {
    // Material Net Weight
    formik.setFieldValue(
      "netWeight.material", netWeight?.flangeWeightForInch.materialWeight
    );
    // ---TubeSheet
    formik.setFieldValue(
      "netWeight.tubeSheet", netWeight?.tubeSheet
    );

    // ---Shell Ring
    formik.setFieldValue(
      "netWeight.shellRing", netWeight?.shellRing
    );

    // ---Shell/Vessel
    formik.setFieldValue(
      "netWeight.shellVessel", netWeight?.shellVessel
    );

    // ---Baffle
    // const calculateBaffleNetWeight = (
    //   flangeSize,
    //   bafflePlatethickness,
    //   numberOfbaffles,
    //   shellThickness,
    //   baffleType,
    //   flangeWeightForInch
    // ) => {
    //   const indexValue = parseFloat(flangeWeightForInch?.baffle);

    //   const AA18 =
    //     (3.14 *
    //       (Math.pow(indexValue - 10 - 2 * shellThickness, 2) *
    //         bafflePlatethickness *
    //         numberOfbaffles *
    //         8)) /
    //     1000000 /
    //     4;
    //   const result =
    //     (3.14 / 4) *
    //     AA18 *
    //     (baffleType.toLowerCase() === "rod baffle"
    //       ? 1.5
    //       : baffleType.toLowerCase() === "strip baffle"
    //         ? 1.7
    //         : baffleType.toLowerCase() === "guide flow baffle"
    //           ? 2.25
    //           : baffleType.toLowerCase() === "wire mesh"
    //             ? 2
    //             : 1);

    //   return result.toFixed(2);
    // };

    // Calculate baffle net weight
    // const baffleNetWeight = calculateBaffleNetWeight(
    //   formik?.values?.flangeSize,
    //   formik?.values?.baffleplatethickness,
    //   formik?.values?.numberofHotBaffles,
    //   formik?.values?.shellThickness,
    //   formik?.values?.baffletype,
    //   data?.flangeWeightForInch
    // );

    // Set the calculated baffle net weight
    formik.setFieldValue(`netWeight.baffle[${index}]`, netWeightInch.baffle);

    // ---Dish End
    formik.setFieldValue("netWeight.dishEnd", netWeightInch.dishEnd
    );

    console.log("test nozzle", formik.values.netWeight.inletNozzle);

    // ---Inlet Nozzle
    formik.setFieldValue(
      `netWeight.inletNozzle[${index}]`,
      parseFloat(netWeightInch?.inletNozzle) || 0
    );

    // ---Inlet Flange
    formik.setFieldValue(
      `netWeight.inletFlange[${index}]`,
      netWeightInch?.inletFlange
    );


    formik.setFieldValue(
      `netWeight.inletFlange[${index}]`,
      netWeightInch?.inletFlange
    );

    // ---Outlet Nozzle
    formik.setFieldValue(
      `netWeight.outletNozzle[${index}]`,
      netWeightInch?.outletNozzle
    );

    // ---Outlet Flange
    formik.setFieldValue(
      `netWeight.outletFlange[${index}]`,
      netWeightInch?.outletFlange
    );

    // ---Drain Nozzle
    formik.setFieldValue(
      "netWeight.drainNozzle",
      netWeightInch?.drainNozzle
    );

    // ---Drain Flange
    formik.setFieldValue(
      "netWeight.drainFlange",
      netWeightInch?.drainFlange
    );

    // ---Vent Nozzle
    formik.setFieldValue(
      "netWeight.ventNozzle",
      netWeightInch?.ventNozzle
    );

    // ---Vent Flange
    formik.setFieldValue(
      "netWeight.ventFlange",
      netWeightInch?.ventFlange
    );

    // ---Process Nozzle
    formik.setFieldValue(
      "netWeight.processNozzle",
      netWeightInch?.processNozzle
    );

    // ---Process Flange
    formik.setFieldValue(
      "netWeight.processFlange",
      netWeightInch?.processFlange
    );

    // ---Dummy Nozzle
    formik.setFieldValue(
      "netWeight.dummyNozzle",
      netWeightInch?.dummyNozzle
    );

    // ---Dummy Flange
    formik.setFieldValue(
      "netWeight.dummyFlange",
      netWeightInch?.dummyFlange
    );

    // Tie rod with sleeves
    // formik.setFieldValue(
    //   "netWeight.tieRodWithSleeves",
    //   (
    //     ((4 * (parseFloat(formik?.values?.developedlength) / 2 - 100) -
    //       parseFloat(formik?.values?.maxactivehotlength) /
    //       2 /
    //       parseFloat(formik?.values?.numberofHotBaffles)) *
    //       (parseFloat(formik?.values?.tieroddia) + 4) *
    //       (parseFloat(formik?.values?.tieroddia) + 4) *
    //       8 *
    //       3.14) /
    //     4 /
    //     1000000
    //   ).toFixed(2)
    // );
    formik.setFieldValue(
      "netWeight.tieRodWithSleeves", (netWeightInch?.tieRodWithSleeves)
    );


    // ---Junction Box
    formik.setFieldValue(
      "netWeight.junctionBox", netWeightInch?.junctionBox
    );

    // ---Terminal Box
    formik.setFieldValue(
      "netWeight.terminalBoxBasePlate", netWeightInch.terBoxBasePlate
    );
    formik.setFieldValue(
      "netWeight.lugsSupport", netWeightInch?.lugSupport
    );

    // ------------------------------------------
    // GROUND WEIGHT
    // =============
    // Material Ground Weight
    formik.setFieldValue(
      "groundWeight.material", groundWeight?.materialWeight
    );
    // ---TubeSheet
    formik.setFieldValue(
      "groundWeight.tubeSheet", groundWeight.tubeSheet
    );

    // // ---Shell Ring
    formik.setFieldValue(
      "groundWeight.shellRing", groundWeight.shellRing
    );

    // // ---Shell/Vessel
    formik.setFieldValue(
      "groundWeight.shellVessel", groundWeight.shellVessel
    );

    // // ---Baffle
    formik.setFieldValue(
      `groundWeight.baffle[${index}]`, groundWeight.baffle
    );

    // // ---Dish End
    formik.setFieldValue(
      "groundWeight.dishEnd", groundWeight.dishEnd
    );

    // // ---Inlet Nozzle
    formik.setFieldValue(
      `groundWeight.inletNozzle[${index}]`,
      parseFloat(groundWeight.inletNozzle) || 0
    );

    // // ---Inlet Nozzle
    formik.setFieldValue(
      `groundWeight.inletFlange[${index}]`, groundWeight.inletFlange
    );

    // // ---Outlet Nozzle
    formik.setFieldValue(
      `groundWeight.outletNozzle[${index}]`, groundWeight.outletNozzle
    );

    // // ---Outlet Flange
    formik.setFieldValue(
      `groundWeight.outletFlange[${index}]`, groundWeight.outletFlange
    );

    // // ---Drain Nozzle
    formik.setFieldValue(
      "groundWeight.drainNozzle", groundWeight.drainNozzle
    );

    // // ---Drain Flange
    formik.setFieldValue(
      "groundWeight.drainFlange", groundWeight.drainFlange
    );

    // // ---Vent Nozzle
    formik.setFieldValue(
      "groundWeight.ventNozzle", groundWeight.ventNozzle
    );

    // // ---Vent Flange
    formik.setFieldValue(
      "groundWeight.ventFlange", groundWeight.ventFlange
    );

    // // ---Dummy Nozzle
    formik.setFieldValue(
      "groundWeight.dummyNozzle", groundWeight.dummyNozzle
    );

    // // ---Dummy Flange
    formik.setFieldValue(
      "groundWeight.dummyFlange", groundWeight.dummyFlange
    );

    // // Tie rod with sleeves
    formik.setFieldValue(
      "groundWeight.tieRodWithSleeves", groundWeight.tieRodWithSleeves
    );
    formik.setFieldValue(
      "groundWeight.lugsSupport", groundWeight.lugSupport
    );
    // // ---Junction Box
    formik.setFieldValue(
      "groundWeight.junctionBox", groundWeight.junctionBox
    );

    // // ---Terminal Box base plate
    formik.setFieldValue(
      "groundWeight.terminalBoxBasePlate", groundWeight.terBoxBasePlate
    );


    // totalCosting();
    // fetchPrices();
  };

  // update prices
  function updatePrices(pricesData, finalCosting, finalUnitBundle, index) {
    const setRoundedFieldValue = (field, value) => {
      if (!isNaN(value) && value !== null && value !== undefined) {
        formik.setFieldValue(field, Math.round(value));
      } else {
        console.error(`Invalid value for ${field}:`, value);
      }
    };

    const setPriceField = (field, value) => {
      setRoundedFieldValue(`price.${field}`, value);
    };

    setPriceField("material", pricesData?.materialCost);

    // Tubesheet
    setPriceField("tubeSheet", pricesData?.tubeSheet);

    // tube sheet drilling
    setPriceField("tubesheetDrilling", pricesData?.tubeSheetDrilling);


    // Shell Ring
    setPriceField("shellRing", pricesData.shellRing
    );

    // Shell Vessel
    setPriceField("shellVessel", pricesData?.shellVessel);

    // Dish End
    setPriceField("dishEnd", pricesData?.dishEnd);

    // Heating Element
    setPriceField("heatingElement", pricesData?.heatingElement);

    // Inlet Flange
    if (formik?.values?.nozzle[0]?.inletNozzle === true) {
      setPriceField(`inletFlange[${index}]`, parseFloat(pricesData?.inletFlange) || 0);
    } else {
      setPriceField(`inletFlange[${index}]`, 0);
    }

    // Inlet Nozzle
    if (formik?.values?.nozzle[0]?.inletNozzle === true) {
      setPriceField(`inletNozzle[${index}]`, pricesData?.inletNozzle);
    } else {
      setPriceField(`inletNozzle[${index}]`, 0);
    }

    // Outlet Flange
    if (formik?.values?.nozzle[1]?.outletNozzle === true) {
      setPriceField(`outletFlange[${index}]`, pricesData?.outletFlange);
    } else {
      setPriceField(`outletFlange[${index}]`, 0);
    }

    // Outlet Nozzle
    if (formik?.values?.nozzle[1]?.outletNozzle === true) {
      setPriceField(`outletNozzle[${index}]`, pricesData?.outletNozzle);
    } else {
      setPriceField(`outletNozzle[${index}]`, 0);
    }

    // Drain Flange
    if (formik?.values?.nozzle[3]?.drain === true) {
      setPriceField("drainFlange", pricesData?.drainFlange);
    } else {
      setPriceField("drainFlange", 0);
    }

    // Drain Nozzle
    if (formik?.values?.nozzle[3]?.drain === true) {
      setPriceField("drainNozzle", pricesData?.drainNozzle);
    } else {
      setPriceField("drainNozzle", 0);
    }

    // Vent Flange
    if (formik?.values?.nozzle[2]?.vent === true) {
      setPriceField("ventFlange", pricesData?.ventFlange);
    } else {
      setPriceField("ventFlange", 0);
    }

    // Vent Nozzle
    if (formik?.values?.nozzle[2]?.vent === true) {
      setPriceField("ventNozzle", pricesData?.ventNozzle);
    } else {
      setPriceField("ventNozzle", 0);
    }

    // Process Flange
    if (formik?.values?.nozzle[4]?.process === true && formik?.values?.nozzle[4]?.counterFlange === true) {
      setPriceField("processFlange", pricesData?.processFlange);
    } else {
      setPriceField("processFlange", 0);
    }

    // Process Nozzle
    if (formik?.values?.nozzle[4]?.process === true) {
      setPriceField("processNozzle", pricesData?.processNozzle);
    } else {
      setPriceField("processNozzle", 0);
    }

    // Dummy Flange
    if (formik?.values?.nozzle[5]?.dummy === true) {
      setPriceField("dummyFlange", pricesData?.dummyFlange);
    } else {
      setPriceField("dummyFlange", 0);
    }

    // Dummy Nozzle
    if (formik?.values?.nozzle[5]?.dummy === true) {
      setPriceField("dummyNozzle", pricesData?.dummyNozzle);
    } else {
      setPriceField("dummyNozzle", 0);
    }


    // Baffle
    setPriceField(`baffle[${index}]`, pricesData?.baffle);

    // Tie Rod With Sleeve
    setPriceField("tieRodWithSleeves", pricesData?.tieRodWithSleeves);

    // Lugs Support
    setPriceField("lugsSupport", pricesData?.lugSupport);
    // Junction box price
    setPriceField("junctionBox", pricesData?.junctionBox);
    // Terminal Box Base Plate
    setPriceField("terminalBoxBasePlate", formik?.values?.terminalBoxCost);
    // Cable Gland
    setPriceField("cableGland", pricesData?.cableGland);
    // Connection Price
    setPriceField("priceOfConnections", pricesData?.connection);
    // Hardware Price
    setPriceField("priceOfHardware", pricesData?.hardware);
    setPriceField("indPrice.powderIndPrice", pricesData?.powderIndPrice);
    setPriceField("indPrice.tubeIndPrice", pricesData?.tubeIndPrice);
    setPriceField("indPrice.fabricationPlateIndPrice1", pricesData?.fabricationPlateIndPrice1);
    setPriceField("indPrice.fabricationPlateIndPrice2", pricesData?.fabricationPlateIndPrice2);
    setPriceField("indPrice.sleevesIndPrice", pricesData?.sleevesIndPrice);
    setPriceField("indPrice.nutIndPrice", pricesData?.nutIndPrice);
    console.log("pricesData", formik.values.price.indPrice.nutIndPrice);

    // safetyThermocouple
    const seathTcRtdThPrice =
      formik?.values?.sensor[0].quantity || formik?.values?.sensor[1].quantity
        ? (parseInt(formik?.values?.sensor[1].quantity) +
          parseInt(formik?.values?.sensor[0].quantity)) *
        formik?.values?.rateofsafety
        : 0;
    setPriceField("safetyThermocouple", seathTcRtdThPrice);

    const rateOfProcessTcRtdTh = formik?.values?.sensor[2].quantity
      ? parseFloat(formik?.values?.sensor[2].quantity) *
      parseFloat(formik?.values?.rateofprocess)
      : 0;
    setPriceField("processThermocouple", rateOfProcessTcRtdTh);
    setPriceField("insulationCost", pricesData?.insulationPrice);
    setPriceField("radiographyCost", pricesData?.radiographyCost);
    // Final costing for unit bundle
    setRoundedFieldValue(`finalCosting.unitMaterialCost[${index}]`, pricesData?.unitMaterialCost);
    setRoundedFieldValue(`finalCosting.bundleMaterialCost[${index}]`, pricesData?.bundleMaterialCost);
    setRoundedFieldValue(`unitBundle.COGS.unit[${index}]`, finalUnitBundle.COGS.unit);
    setRoundedFieldValue(`unitBundle.COGS.bundle[${index}]`, finalUnitBundle.COGS.bundle);

    // Selling price of unit bundle
    setRoundedFieldValue(`unitBundle.sellingPrice.unit[${index}]`, finalUnitBundle.sellingPrice.unit);
    setRoundedFieldValue(`unitBundle.sellingPrice.bundle[${index}]`, finalUnitBundle.sellingPrice.bundle);

    // Quoted price for unit bundle
    setRoundedFieldValue(`unitBundle.quotedPrice.unit[${index}]`, finalUnitBundle.quotedPrice.unit);
    setRoundedFieldValue(`unitBundle.quotedPrice.bundle[${index}]`, finalUnitBundle.quotedPrice.bundle);

    // Final costing for right side
    setRoundedFieldValue(`finalCosting.unitCogs[${index}]`, finalCosting.unitCogs);
    setRoundedFieldValue(`finalCosting.bundleCogs[${index}]`, finalCosting.bundleCogs);
    setRoundedFieldValue(`finalCosting.unitSellingPrice[${index}]`, finalCosting.unitSellingPrice);
    setRoundedFieldValue(`finalCosting.bundleSellingPrice[${index}]`, finalCosting.bundleSellingPrice);
    setRoundedFieldValue(`finalCosting.unitQuotedPrice[${index}]`, finalCosting.unitQuotedPrice);
    setRoundedFieldValue(`finalCosting.bundleQuotedPrice[${index}]`, finalCosting.bundleQuotedPrice);
    // totalCosting();
    // COGSCost();
  }

  return (
    <div>
      <Accordion className="w-100">
        {formik?.values?.multiStackVesselFormData?.map((item, index) => {
          const eventKey = (index + 1)?.toString(); // Start from "1" since we skip the first item
          return (
            <Accordion.Item key={eventKey} eventKey={eventKey} className="mb-3 shadow-md rounded">
              <Accordion.Header
                className={`p-3 border border-1 border-bottom shadow-md m-0 d-flex w-100 ${activeKey === eventKey ? "bg-gray text-white" : ""}`}
                style={{ backgroundColor: "#eee" }}
                onClick={() => handleAccordionClick(eventKey)}
              >
                <div className="d-flex justify-content-between w-100">
                  <span>Stack {index + 1}</span>
                  <button
                    className="btn btn-outline-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      printPDF(`yes-vessel-${index}`);
                    }}
                  >
                    Print PDF
                  </button>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div id={`yes-vessel-${index}`} style={{ color: "black", padding: " 20px 20px" }}>
                  <BOM
                    formik={formik}
                    trClass={trClass}
                    tdClass={tdClass}
                    tableClass={tableClass}
                    tableParentDiv={tableParentDiv}
                    netWeightPayload={netWeightPayload}
                    pricePayload={pricePayload}
                    otherNetWeight={otherNetWeight}
                    index={index}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <hr />
    </div>
  );
};

export default Screen9;
