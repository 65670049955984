export const sensorsubtypeoptions = [
    { label: "RTD", value: "RTD" },
    { label: "Thermostat", value: "Thermostat" },
    { label: "K-type Thermocouple", value: "K-type Thermocouple" },
    { label: "J-type Thermocouple", value: "J-type Thermocouple" },
];

export const sensorRangeOptions = [
    { label: "Simplex", value: "Simplex" },
    { label: "Duplex", value: "Duplex" },
];

export const sensorMaterialOptions = [
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "INCOLOY800", label: "INCOLOY800" },
    { value: "INCOLOY825", label: "INCOLOY825" },
    { value: "INCOLOY840", label: "INCOLOY840" },
];

export const headMaterialOptions = [
    { label: "SS304", value: "SS304" },
    { label: "SS316", value: "SS316" },
    { label: "SS316L", value: "SS316L" },
    {
        label: "Aluminium Die Casted (Epoxy Coated)",
        value: "Aluminium Die Casted (Epoxy Coated)",
    },
];

export const terminalBoxMaterialOptions = [
    { value: "Carbon Steel", label: "Carbon Steel" },
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "ALUMINIUM", label: "ALUMINIUM" },
];

export const anySpecificationOption = [
    { value: "U STAMP", label: "U STAMP" },
    { value: "U2 STAMP", label: "U2 STAMP" },
    { value: "CE + PED", label: "CE + PED" },
    { value: "CE (Electric)", label: "CE (Electric)" },
    { value: "DOSH", label: "DOSH" },
    { value: "MIGAS", label: "MIGAS" },
    { value: "GOST", label: "GOST" },
    { value: "CCOE", label: "CCOE" },
    { value: "UL", label: "UL" },
    { value: "CCC", label: "CCC" },
    { value: "SELO", label: "SELO" },
    { value: "NEMA", label: "NEMA" },
    { value: "CSA", label: "CSA" },
    { value: "BIS", label: "BIS" },
    { value: "IBR", label: "IBR" },
    { value: "Not Applicable", label: "Not Applicable" },
];

export const tempCodeOptions = [
    { label: "T1 (450°C)", value: "T1 (450°C)" },
    { label: "T2 (300°C)", value: "T2 (300°C)" },
    { label: "T3 (200°C)", value: "T3 (200°C)" },
    { label: "T4 (135°C)", value: "T4 (135°C)" },
    { label: "T5 (100°C)", value: "T5 (100°C)" },
    { label: "T6 (85°C)", value: "T6 (85°C)" },
];

export const gasGroupOptions = [
    { label: "II A", value: "II A" },
    { label: "II B", value: "II B" },
    { label: "II C", value: "II C" },
    { label: "II A/B", value: "II A/B" },
];

export const certificationOptions = [
    { label: "ATEX Ex'd'", value: "ATEX Ex'd'" },
    { label: "ATEX Ex'e'", value: "ATEX Ex'e'" },
    { label: "IEC Ex'd'", value: "IEC Ex'd'" },
    { label: "PESO", value: "PESO" },
    { label: "ECAS", value: "ECAS" },
    { label: "TRCU", value: "TRCU" },
];

export const zoneOptions = [
    { label: "Zone 1", value: "Zone 1" },
    { label: "Zone 2", value: "Zone 2" },
];

export const ijbOptions = [
    { label: "Ex'd", value: "Ex'd" },
    { label: "Ex'e", value: "Ex'e" },
];

export const thermowellMaterialOptions = [
    { value: "GR11", label: "GR11" },
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "SS321H", label: "SS321H" },
    { value: "INCOLOY800", label: "INCOLOY800" },
    { value: "CS", label: "CS" },
];

export const tempTransmitterOptions = [
    {
        label: "DIN rail Mountain",
        value: "DIN rail Mountain",
    },
    { label: "Head Mountain", value: "Head Mountain" },
];

export const noOfBankOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
];

export const frequency = [
    { label: "50 Hz", value: "50 Hz" },
    { label: "60 Hz", value: "60 Hz" },
];

export const connectionType = [
    { label: "Single Phase", value: "Single Phase" },
    {
        label: "Delta Connection",
        value: "Delta Connection",
    },
    { label: "Star Connection", value: "Star Connection" },
];

export const multiStackVesselOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
];

export const installationPositionOptions = [
    { label: "Horizontal", value: "Horizontal" },
    { label: "Vertical", value: "Vertical" },
];

export const standardOptions = [
    {
        value: "ASME Sec. VIII Div. 1 Latest ed",
        label: "ASME Sec. VIII Div. 1 Latest ed",
    },
    {
        value: "ASME Sec. VIII Div. 2 Latest ed",
        label: "ASME Sec. VIII Div. 2 Latest ed",
    },
];

export const areaClassificationOptions = [
    { value: "Safe Area", label: "Safe Area" },
    { value: "Hazardous Area", label: "Hazardous Area" },
]

export const distances = {
    "T1 (450°C)": 100,
    "T2 (300°C)": 100,
    "T3 (200°C)": 125,
    "T4 (135°C)": 150,
    "T5 (100°C)": 300,
    "T6 (85°C)": 325,
};

export const yesNoOption = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
];

export default {
    ...sensorsubtypeoptions,
    ...sensorRangeOptions,
    ...sensorMaterialOptions,
    ...headMaterialOptions,
    ...terminalBoxMaterialOptions,
    ...anySpecificationOption,
    ...tempCodeOptions,
    ...gasGroupOptions,
    ...certificationOptions,
    ...zoneOptions,
    ...ijbOptions,
    ...thermowellMaterialOptions,
    ...tempTransmitterOptions,
    ...noOfBankOptions,
    ...frequency,
    ...connectionType,
    ...multiStackVesselOptions,
    ...installationPositionOptions,
    ...standardOptions,
    ...areaClassificationOptions,
    ...distances,
    ...yesNoOption,
};