import React, { useEffect } from "react";
import logo2 from "../../../assets/Logos/logo.png";

const ControlPanel = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const tableParentDiv = {
    display: "flex",
    margin: "10px 0px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
  };

  const trClass = {
    height: "22px",
    borderCollapse: "collapse",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
    borderCollapse: "collapse",
  };

  const thClass = {
    ...tdClass,
    backgroundColor: "#CCCCCC",
    fontWeight: "600",
  };

  const thickBorder = {
    border: "1px solid black",
  };

  const rightThickBorder = {
    borderRight: "1px solid black",
  };

  useEffect(() => {
    if (values.installedCapacity && values.voltage) {
      const calculatedValue = (
        (values.installedCapacity * 1000) /
        (1.732 * values.voltage)
      ).toFixed(2);
      setFieldValue("cp_currentRating", calculatedValue);
    }
  }, [values.installedCapacity, values.voltage, setFieldValue]);

  const renderBundleData = () => {
    return values.bundleTableData.map((bundle, bundleIndex) => (
      <div key={bundleIndex}>
        <strong>VESSEL-{bundleIndex + 1}</strong> (
        {bundle.bundle.map((bank, bankIndex) => (
          <span key={bankIndex}>
            {bankIndex > 0 && " + "}
            1 * {bank.bank}KW
          </span>
        ))}
        )
      </div>
    ));
  };

  return (
    <div>
      <div
        style={{
          border: "1px solid black",
          margin: "0 0 10px  0 ",
          position: "relative",
        }}
      >
        <img
          src={logo2}
          alt="abc"
          style={{ height: "auto", width: "200px", margin: "10px " }}
        />
        <h1
          style={{
            fontSize: "25px",
            position: "absolute",
            top: "25%",
            left: "38%",
            margin: "10px 0",
          }}
        >
          DATA SHEET OF {formik.values.installedCapacity}KW TCP
        </h1>
      </div>
      <div style={{ marginBottom: "5px" }}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Project </td>
            <td style={tdClass}>{formik.values.projectName || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Item </td>
            <td style={tdClass}>{formik.values.installedCapacity} kW Thyristor Control Panel and Heater</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Document No. </td>
            <td style={tdClass}>224102-GDUGSU-DS-00004</td>
          </tr>
        </table>
      </div>
      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={thClass} colSpan={3} align="center">
              PANEL GENERAL & CONSTRUCTION
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">TYPE TEST APPLICBALE FOR PANEL</td>
            <td style={tdClass}>IEC-61439</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">MAKE OF PANEL </td>
            <td style={tdClass}>{formik?.values?.cp_enclosureMake}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">MATERIAL</td>
            <td style={tdClass}>{formik.values.cp_controlPanelMOC}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">RATED SHORT CIRCUIT WITHSTAND CURRENT </td>
            <td style={tdClass}>{formik.values.cp_breakingCapacity}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">RATED INSULATION VOLTAGE FOR {formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"}</td>
            <td style={tdClass}>{formik?.values?.voltage === 690 ? "1000V" : "800V"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">RATED OPERATIONAL VOLTAGE FOR {formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"} </td>
            <td style={tdClass}>{formik?.values?.voltage}V</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">IMPULSE WITHSTAND VOLTAGE</td>
            <td style={tdClass}>{formik?.values?.voltage === 690 ? "12KV" : "8KV"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold"> TOTAL HARMONIC WITHSTAND CURRENT</td>
            <td style={tdClass}>5%</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">AMBIENT TEMPERATURE</td>
            <td style={tdClass}>{formik?.values.ambienttemperature} °C </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">TAG NUMBER</td>
            <td style={tdClass}></td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">DESIGN LIFE</td>
            <td style={tdClass}>20 YEARS</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">WEIGHT OF THE PANEL </td>
            <td style={tdClass}></td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">TOTAL HARMONIC DISTORTION </td>
            <td style={tdClass}>5%</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">PANEL ACCESSORIES DETAILS</td>
            <td style={tdClass}>
              6'' FAN WITH FILTER 100WATT SPACE HEATER 6A/16A POWER SOCKET
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">DOORS</td>
            <td style={tdClass}>{formik.values.cp_enclosureSheathThickness || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">SIDE & REAR PANEL</td>
            <td style={tdClass}>{formik.values.cp_enclosureSheathThickness || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">MOUNTING PLATE</td>
            <td style={tdClass}>2.0MM (GI)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">GLAND PLATE </td>
            <td style={tdClass}>3MM (GI)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold"> IP RATING</td>
            <td style={tdClass}>{formik.values.cp_ipRating}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">LOCK SYSTEM</td>
            <td style={tdClass}>3 POINT LOCK</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Dimensions (W x H x D) </td>
            <td style={tdClass}>{formik?.values?.cp_enclosureSizeHeight || "N/A"} x
              {formik?.values?.cp_enclosureSizeWidth || "N/A"} x
              {formik?.values?.cp_enclosureSizeDepth || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">BASE FRAME</td>
            <td style={tdClass}>ISMC 75/100</td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            borderCollapse: "collapse",
          }}
        >
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={3} align="center">
                PAINT SHADE
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">EXTERNAL</td>
              <td style={tdClass}>{formik.values.cp_paintShadeMOC}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">INTERNAL</td>
              <td style={tdClass}>{formik.values.cp_paintShadeMOC}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">MOUNTING PLATE</td>
              <td style={tdClass}>GALVANAISED IRON</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">BASE FRAME </td>
              <td style={tdClass}>BLACK (RAL 9005)</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">PAINT THICKNESS</td>
              <td style={tdClass}>ABOVE 60 MICRONS</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={3} align="center">
                BUSBAR
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">FOR INCOMING/HORIZONTAL
                BUS BAR</td>
              <td style={tdClass}>AS PER RATING / TINNED COPPER BUSBAR</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">FOR OUTGOING CABLE TERMINATION </td>
              <td style={tdClass}>AS PER RATING / TINNED COPPER BUSBAR</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">FOR EARTH BUS BAR</td>
              <td style={tdClass}>AS PER RATING / TINNED COPPER BUSBAR</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">PHASE COLOR CODE</td>
              <td style={tdClass}>L1-RED, L2-YELLOW, L3-BLUE, L4-BLACK
                HEAT SHRINK SLEEVE</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">EARTH COLOR CODE</td>
              <td style={tdClass}>YELLOW GREEN HEAT SHRINK SLEEVE</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={{ ...trClass, maxHeight: "25px" }}>
              <td style={thClass} colSpan={3} align="center">
                CABLE ENTRY
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">INCOMING</td>
              <td style={tdClass}>{formik?.values?.cp_incomerBusDust === "yes" ? "TOP BUSDUCT ENTRY" : formik.values.cp_cableEntry}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">OUTGOING</td>
              <td style={tdClass}>{formik.values.cp_cableEntry}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">CONTROL CABLES</td>
              <td style={tdClass}>{formik.values.cp_cableEntry}</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                NAME PLATES
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">INTERNAL & EXTERNAL :</td>
              <td style={tdClass}>
                ANODIZED ALUMINIUM LABELS WITH BLACK BACKGROUND AND WHITE
                LETTERS
              </td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                PROTECTION DETAILS
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">{formik.values.installedCapacity >= 400 ? "ACB/MCCB" : "MCCB"}</td>
              <td style={tdClass}>LSIG PROTECTION</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">ELR</td>
              <td style={tdClass}>EARTH LEAKAGE PROTECTION</td>
            </tr>
          </table>
          {formik?.values?.cp_thyristorPanelSelection === "yes" && (
            <table style={tableClass}>
              <tr style={trClass}>
                <td style={thClass} colSpan={2} align="center">
                  THYRISTOR  DETAILS
                </td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass} className="font-weight-bold">THYRISTOR FIRING TYPE</td>
                <td style={tdClass}>{formik.values.cp_thyristorFiringType}</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass} className="font-weight-bold">THYRISTOR LEG</td>
                <td style={tdClass}>{formik?.values?.cp_thyristorLeg}</td>
              </tr>
            </table>
          )}
        </div>
      </div>
      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={thClass} colSpan={2} align="center">
              PANEL WIRING
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">CT CIRCUIT </td>
            <td style={tdClass}>
              2.5 SQMM L1-RED, L2-YELLOW, L3-BLUE, L4-BLACK
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold"> PT CIRCUIT </td>
            <td style={tdClass}>
              2.5 SQMM L1-RED, L2-YELLOW, L3-BLUE, L4-BLACK
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">AC CONTROL CIRCUIT</td>
            <td style={tdClass}>1.5 SQMM GREY</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">POTENTIAL FREE CONTACT WIRING</td>
            <td style={tdClass}>1.5 SQMM GREY</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">RS485(Shielded cable)</td>
            <td style={tdClass}>1 SQMM RED/BLACK</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">24V DC POWER CIRCUIT </td>
            <td style={tdClass}>2.5 SQMM BLACK & RED</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">24V DC CONTROL CIRCUIT </td>
            <td style={tdClass}>2.5 SQMM BLACK & RED</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold"> GROUNDING CIRCUIT</td>
            <td style={tdClass}>2.5 SQMM YELLOW/GREEN</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">CABLE TYPE </td>
            <td style={tdClass}>PVC FRLS CABLES</td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            borderCollapse: "collapse",
          }}
        >
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                SYSTEM RATING
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">SYSTEM</td>
              <td style={tdClass}>3 PHASE, 3 WIRE SYSTEM</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold"> RATED VOLTAGE</td>
              <td style={tdClass}>{formik?.values?.voltage || "N/A"}V</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">RATED FREQUENCY</td>
              <td style={tdClass}>{formik?.values?.frequency || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">CONTROL VOLTAGE </td>
              <td style={tdClass}>{formik.values.cp_controlSupplyVoltage}</td>
            </tr>
            {/* <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">CONTROL VOLTAGE</td>
              <td style={tdClass}>125V DC ( FROM FIELD for ACB only )</td>
            </tr> */}
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold">CONTROL HEATER RATING </td>
              <td style={tdClass}>
                {values.installedCapacity}KW (
                {values.bundleHeatLoad.map((load, index) => (
                  <span key={index}>
                    {index === 0 ? "" : " + "}
                    {load}KW
                  </span>
                ))}
                )
                {renderBundleData()}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} className="font-weight-bold"> RATED CURRENT</td>
              <td style={tdClass}>{formik.values.cp_currentRating}A</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
