import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../utils/Toaster";

function Screen1({ formik, isPageComplete, handleRefresh, updateMultiStackVesselFormData }) {
  const { id } = useParams();
  const [totalCount, setTotalCount] = useState(1);
  const [actualCount, setActualCount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [handleCustomerChange, setHandleCustomerChange] = useState(null);

  const handleInputValue = (value, count) => {
    if (value === "") return count > 0 ? count - 1 : count;
    return count + (value !== undefined ? 1 : 0);
  };

  const formatDate = (dateString, format = "DD-MM-YYYY") => {
    if (!dateString) return "";

    // Split at 'T' and take only the date part
    const [datePart] = dateString.split("T");

    const date = new Date(datePart);
    if (isNaN(date.getTime())) return ""; // Handle invalid dates

    const day = date.getUTCDate().toString().padStart(2, "0"); // Use UTC to prevent timezone shifts
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    switch (format) {
      case "YYYY-MM-DD":
        return `${year}-${month}-${day}`;
      case "DD-MM-YYYY":
        return `${day}-${month}-${year}`;
      case "MM-DD-YYYY":
        return `${month}-${day}-${year}`;
      default:
        return `${day}-${month}-${year}`;
    }
  };

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("customers");
      if (response.status === 200) {
        const formattedData = response.data?.data?.map((customer) => ({
          label: customer.customer_name,
          value: customer.id,
        }));
        setCustomers(formattedData);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerDetails = async (customerId) => {
    try {
      const response = await axiosPrivate.get(`customers/${customerId}`);
      if (response.status === 200) {
        const customerData = response.data.data;
        const calculationId = customerData.calculation_id;
        localStorage.setItem("calculationId", calculationId);
        localStorage.setItem("customerDetails", JSON.stringify(customerData));
        localStorage.setItem(
          "selectedCustomer",
          JSON.stringify({
            label: customerData.customer_name,
            value: customerId,
          })
        );

        formik.setValues({
          ...formik.values,
          customer: customerData.customer_name || "",
          projectName: customerData.project_name || "",
          itemDescription: customerData.item_description || "",
          customerName: customerData.customer_name || "",
          address: customerData.address || "",
          contactNo: customerData.contact_no || "",
          email: customerData.email || "",
          contactPersonName: customerData.contact_person_name || "",
          enqNo: customerData.enq_no || "",
          enqDate: formatDate(customerData.enq_date, "DD-MM-YYYY"),
          offerNo: customerData.offer_no || "",
          offerDate: formatDate(customerData.offer_date, "DD-MM-YYYY"),
          offerRevNo: customerData.offer_rev_no || "",
          tagNo: customerData.tag_no || "",
        });
        setSelectedCustomer({
          label: customerData.customer_name,
          value: customerId,
        });
      }
      handleRefresh(true);
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    fetchCustomers();
    if (id) {
      fetchCustomerDetails(id);
    } else {
      const savedCustomerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const savedSelectedCustomer = JSON.parse(
        localStorage.getItem("selectedCustomer")
      );

      if (savedCustomerDetails && savedSelectedCustomer) {
        setCustomerDetails(savedCustomerDetails);
        setSelectedCustomer(savedSelectedCustomer);

        formik.setValues({
          ...formik.values,
          customer: savedSelectedCustomer.label,
          projectName: savedCustomerDetails.project_name || "",
          itemDescription: savedCustomerDetails.item_description || "",
          customerName: savedCustomerDetails.customer_name || "",
          address: savedCustomerDetails.address || "",
          contactNo: savedCustomerDetails.contact_no || "",
          email: savedCustomerDetails.email || "",
          contactPersonName: savedCustomerDetails.contact_person_name || "",
          enqNo: savedCustomerDetails.enq_no || "",
          enqDate: formatDate(savedCustomerDetails.enq_date, "DD-MM-YYYY"),
          offerNo: savedCustomerDetails.offer_no || "",
          offerDate: formatDate(savedCustomerDetails.offer_date, "DD-MM-YYYY"),
          offerRevNo: savedCustomerDetails.offer_rev_no || "",
          tagNo: savedCustomerDetails.tag_no || "",
        });
      }
    }
  }, [id]);

  useEffect(() => {
    let count = handleInputValue(formik.values.projectName, 0);
    setActualCount(count);
  }, [formik.values.projectName]);

  useEffect(() => {
    isPageComplete(1, totalCount, actualCount);
  }, [actualCount, totalCount, isPageComplete]);

  useEffect(() => {
    if (formik) {

      updateMultiStackVesselFormData(formik?.values?.multistackvessel)

    }
  }, [])

  const renderInputField = (
    label,
    id,
    value,
    type = "text",
    className = "mr-5"
  ) => (
    <div className={className}>
      <label htmlFor={id} className="mr-3">
        {label}
      </label>
      <input
        type={type}
        id={id}
        className="form-control form-control-sm"
        readOnly
        value={value}
        style={{ width: "250px" }}
      />
    </div>
  );

  return (
    <div className="d-flex justify-content-center">
      <div>
        <h5>Customer Details</h5>

        {selectedCustomer && (
          <div className="mt-5">
            <div className="row">
              {renderInputField(
                "Project Name",
                "projectName",
                formik.values.projectName
              )}
              {renderInputField(
                "Item Description",
                "itemDescription",
                formik.values.itemDescription
              )}
            </div>
            <div className="row mt-3">
              {renderInputField(
                "Customer",
                "customerName",
                formik.values.customerName
              )}
              {renderInputField("Address", "address", formik.values.address)}
            </div>
            <div className="row mt-3">
              {renderInputField(
                "Contact No.",
                "contactNo",
                formik.values.contactNo
              )}
              {renderInputField("Email", "email", formik.values.email)}
            </div>
            <div className="row mt-3">
              {renderInputField(
                "Contact Person Name",
                "contactPersonName",
                formik.values.contactPersonName
              )}
              {renderInputField("Enq No.", "enqNo", formik.values.enqNo)}
            </div>
            <div className="row mt-3">
              {renderInputField("Enq Date", "enqDate", formik.values.enqDate)}
              {renderInputField("Offer No.", "offerNo", formik.values.offerNo)}
            </div>
            <div className="row mt-3">
              {renderInputField(
                "Offer Date",
                "offerDate",
                formik.values.offerDate,
              )}
              {renderInputField(
                "Offer Rev. No",
                "offerRevNo",
                formik.values.offerRevNo
              )}
            </div>
            <div className="row mt-3">
              {renderInputField("Tag No.", "tagNo", formik.values.tagNo)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Screen1;
