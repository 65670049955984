import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../../styles/CustomStylesForSelect";
import { PriceCategoryOptions } from "../../utils/DropdownOptions";

const SheathMaterialsEdit = (props) => {
  const { id } = useParams();
  const [item, setItem] = useState({
    name: "",
    material_category: "",
    material: "",
  });
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchSheathMaterialData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/sheath-material/${id}`);
      if (response.status === 200) {
        const data = response.data.data[0];
        setItem({
          name: data?.name || "",
          material_category: data?.material_category || "",
          material: data?.material || "",
        });
      }
    } catch (error) {
      showErrorToast("Error fetching sheathMaterial data");
      navigate("/sheathMaterial");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSheathMaterialData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!item.name || !item.material) {
      showErrorToast("All fields are required");
      return;
    }

    setIsLoading(true);

    try {
      await axiosPrivate.put(`/sheath-material/${id}`, {
        name: item.name,
        material_category: item.material_category,
        material: item.material,
        material_code: item.material_code,
      });
      showSuccessToast("SheathMaterial updated successfully");
      navigate("/sheathMaterial");
    } catch (error) {
      showErrorToast("Error updating sheathMaterial");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit SheathMaterial</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mb-30">
                        <div className="col-md-4">
                          <label htmlFor="name">Name</label>
                          <Select
                            name="name"
                            id="name"
                            className="mr-3"
                            options={PriceCategoryOptions}
                            styles={customStyles}
                            placeholder="Select Name"
                            value={
                              item.name && {
                                value: item.name,
                                label: item.name,
                              }
                            }
                            onChange={(option) =>
                              setItem({
                                ...item,
                                name: option?.value ?? option,
                              })
                            }
                            isDisabled={false}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="material_category">Material Category</label>
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Enter Material Category"
                            value={item.material_category}
                            onChange={(e) =>
                              setItem({ ...item, material_category: e.target.value })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="material">Material</label>
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Enter Material"
                            value={item.material}
                            onChange={(e) =>
                              setItem({ ...item, material: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/sheathMaterial">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SheathMaterialsEdit;