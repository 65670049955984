const materialSelection = {
  heatersheathtubedia: 0,
  sheathmaterial: "",
  sheathtubethickness: "",
  sheathtubetype: "",
  distbwheaterflangetoterminalbox: "",
  maxactivehotlength: "",
  maxactivecoldlength: 0,
  wattdensity: 0,
  totalsurfacearea: "",
  heatTransferCoefficient: "",
  distancebwionozzels: "",
  materialofsleevesbush: "",
  numberofHotBaffles: 0,
  baffleBomValue: "",
  coldBaffle: 0,
  baffleplatethickness: 0,
  baffletype: "",
  bafflematerial: "",
  heatersparecapacity: "",
  material: "",
  bafflecut: 0,
  tieroddia: "",
  tiematerial: "",
  bundleimmersionlength: 0,
  fixingofheatingelements: "",
  individualelementmaxwattage: "",
  developedlength: "",
  totalnumberofelements: "",
  numberofelementsconnected: "",
  numberofelementsspare: "",
  wattdensityactual: 0,
  individualelementrating: "",
  nutAssembly: "",
  tierodquantity: 0,
  nutsize: 0,
  nutElements: 0,
  //Bom Extra Fields
  heatingElementType: "",
  heatingElementValue: "",
  heatingElementUnit: "",
  heatingElememtAddUnit: "",
  heatingElementAddValue: "",
  heatingElementAddValueUnit: "",
  flangeThickness: "",
  heatingElementThickness: "",
  heatingElementTubeSheet: "",
  sheathtemp: "",
  approxvessellength: "",
  totalDevelopedLengthForBom: "",
  hotLengthCalculatedForBom: "",
};

export default materialSelection;
