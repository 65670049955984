import "./App.css"; // Import CSS file for styling
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Layouts/Main"; // Import Main layout component

// Import pages
import DashBoard from "./Pages/DashBoard/DashBoard";
import UsersList from "./Pages/Users/UsersList";
import AddEditUsers from "./Pages/Users/AddEditUser";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import Profile from "./Pages/Profile/Profile";
import UserPermission from "./Pages/Users/UserPermission";
import Roles from "./Pages/Roles/Roles";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import AddEditRole from "./Pages/Roles/AddEditRole";
import RolePermission from "./Pages/Roles/RolePermission";
import AuthMain from "./Layouts/AuthMain";
import Flow from "./Pages/Roles/Flow";
import NotFound from "./components/NotFound/NotFound";
import Forbidden from "./components/Forbidden/Forbidden";
import UnderMaintenance from "./Pages/UnderMaintenance/UnderMaintenance";
import AddUnit from "./Pages/Units/AddUnit";
import UnitsList from "./Pages/Units/UnitsList";
import ProcessFluidsList from "./Pages/ProcessFluids/ProcessFluidsList.jsx";
import ProcessFluidsAdd from "./Pages/ProcessFluids/ProcessFluidsAdd.jsx";
import SchedulesList from "./Pages/Schedules/SchedulesList.jsx";
import SchedulesAdd from "./Pages/Schedules/SchedulesAdd.jsx";
import Calculator from "./Pages/Calculator/Calculator.jsx";
import CustomerList from "./Pages/Customers/CustomerList.jsx";
import Folder from "./Pages/Folders/Folder.jsx";
import GanntChart from "./components/GanttChart/Gantt.js";
import CustomerListing from "./Pages/Calculator/CustomerListing.jsx";
import ProcessFluidEdit from "./Pages/ProcessFluids/ProcessFluidsEdit.jsx";
import SchedulesEdit from "./Pages/Schedules/SchedulesEdit.jsx";

// Price master
import PriceList from "./Pages/Price/PriceList.jsx";
import PriceEdit from "./Pages/Price/PriceEdit.jsx";
import PriceAdd from "./Pages/Price/PriceAdd.jsx";

// Material master
import SheathMaterialList from "./Pages/Sheath Material/SheathMaterialList.jsx";
import SheathMaterialAdd from "./Pages/Sheath Material/SheathMaterialAdd.jsx";
import SheathMaterialEdit from "./Pages/Sheath Material/SheathMaterialEdit.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main layout routes */}
        <Route
          path="/"
          element={<Main />} //there is outlet in Main which will render pages according to path*/
        >
          <Route path="" element={<DashBoard />} />
          {/* Dashboard */}
          <Route path="profile" element={<Profile />} />
          {/* Users routes */}
          <Route path="users">
            <Route path="" element={<UsersList />} />
            <Route path="add" element={<AddEditUsers />} />
            <Route path="edit/:id" element={<AddEditUsers />} />
            <Route path="permissions/:id" element={<UserPermission />} />
          </Route>

          {/* Roles routes */}
          <Route path="roles">
            <Route path="" element={<Roles />} />
            <Route path="add" element={<AddEditRole />} />
            <Route path="edit/:id" element={<AddEditRole />} />
            <Route path="permissions/:id" element={<RolePermission />} />
            <Route path="flow" element={<Flow />} />
          </Route>
          {/* Units Routes  */}
          <Route path="units">
            <Route path="" element={<UnitsList />} />
            <Route path="add" element={<AddUnit />} />
          </Route>
          {/* Sch Routes */}
          <Route path="schedules">
            <Route path="" element={<SchedulesList />} />
            <Route path="add" element={<SchedulesAdd />} />
            <Route path="edit/:id" element={<SchedulesEdit />} />
          </Route>
          {/* Sheath Material Master Routes */}
          <Route path="sheathMaterial">
            <Route path="" element={<SheathMaterialList />} />
            <Route path="add" element={<SheathMaterialAdd />} />
            <Route path="edit/:id" element={<SheathMaterialEdit />} />
          </Route>
          {/* Price Routes */}
          <Route path="price">
            <Route path="" element={<PriceList />} />
            <Route path="add" element={<PriceAdd />} />
            <Route path="edit/:id" element={<PriceEdit />} />
          </Route>
          {/* Customers Routes */}
          <Route path="customers">
            <Route path="" element={<CustomerList />} />
            <Route path="drive/:id" element={<Folder />} />
          </Route>
          <Route path="process-fluids">
            <Route path="" element={<ProcessFluidsList />} />
            <Route path="add" element={<ProcessFluidsAdd />} />
            <Route path="edit/:id" element={<ProcessFluidEdit />} />
          </Route>
          <Route path="customer">
            <Route path="" element={<CustomerListing />} />
            <Route path="heater-calculator/:id" element={<Calculator />} />
          </Route>
          {/* 404 route */}
          <Route path="/*" element={<NotFound />} />
          {/* 403 route */}
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/create-timeline" element={<GanntChart />} />
        </Route>
        {/* Auth layout routes */}
        <Route path="/" element={<AuthMain />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="request-password" element={<ForgotPassword />} />
          <Route path="maintenance" element={<UnderMaintenance />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
