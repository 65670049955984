const getFlangeSizeFor8 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
        { max: 3, size: "N/A" },
        { max: 15, size: 6 },
        { max: 27, size: 8 },
        { max: 48, size: 10 },
        { max: 69, size: 12 },
        { max: 84, size: 14 },
        { max: 112, size: 16 },
        { max: 147, size: 18 },
        { max: 183, size: 20 },
        { max: 271, size: 24 },
        { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
        if (numberOfElements <= range.max) {
            return range.size;
        }
    }
};

const getFlangeSizeFor11 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
        { max: 3, size: "N/A" },
        { max: 11, size: 6 },
        { max: 20, size: 8 },
        { max: 37, size: 10 },
        { max: 53, size: 12 },
        { max: 65, size: 14 },
        { max: 90, size: 16 },
        { max: 115, size: 18 },
        { max: 145, size: 20 },
        { max: 217, size: 24 },
        { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
        if (numberOfElements <= range.max) {
            return range.size;
        }
    }
};

const getFlangeSizeFor12_5 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
        { max: 3, size: "N/A" },
        { max: 9, size: 6 },
        { max: 17, size: 8 },
        { max: 32, size: 10 },
        { max: 46, size: 12 },
        { max: 57, size: 14 },
        { max: 80, size: 16 },
        { max: 100, size: 18 },
        { max: 125, size: 20 },
        { max: 160, size: 24 },
        { max: 175, size: 26 },
        { max: 215, size: 28 },
        { max: 240, size: 30 },
        { max: 270, size: 32 },
        { max: 290, size: 34 },
        { max: 310, size: 36 },
        { max: 355, size: 38 },
        { max: 390, size: 40 },
        { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
        if (numberOfElements <= range.max) {
            return range.size;
        }
    }
};

const getFlangeSizeFor13_5 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
        { max: 3, size: "N/A" },
        { max: 8, size: 6 },
        { max: 16, size: 8 },
        { max: 29, size: 10 },
        { max: 42, size: 12 },
        { max: 51, size: 14 },
        { max: 72, size: 16 },
        { max: 92, size: 18 },
        { max: 115, size: 20 },
        { max: 142, size: 24 },
        { max: 168, size: 26 },
        { max: 195, size: 28 },
        { max: 222, size: 30 },
        { max: 248, size: 32 },
        { max: 275, size: 34 },
        { max: 305, size: 36 },
        { max: 335, size: 38 },
        { max: 370, size: 40 },
        { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
        if (numberOfElements <= range.max) {
            return range.size;
        }
    }
};

const getFlangeSizeFor16 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
        { max: 3, size: "N/A" },
        { max: 6, size: 6 },
        { max: 11, size: 8 },
        { max: 21, size: 10 },
        { max: 30, size: 12 },
        { max: 38, size: 14 },
        { max: 51, size: 16 },
        { max: 67, size: 18 },
        { max: 85, size: 20 },
        { max: 105, size: 24 },
        { max: 132, size: 26 },
        { max: 157, size: 28 },
        { max: 182, size: 30 },
        { max: 205, size: 32 },
        { max: 230, size: 34 },
        { max: 260, size: 36 },
        { max: 290, size: 38 },
        { max: 320, size: 40 },
        { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
        if (numberOfElements <= range.max) {
            return range.size;
        }
    }
};

// Function map based on heatersheathtubedia values
const flangeSizeFunctions = {
    8: getFlangeSizeFor8,
    10: getFlangeSizeFor8,
    11: getFlangeSizeFor11,
    12: getFlangeSizeFor12_5,
    12.5: getFlangeSizeFor12_5,
    13.5: getFlangeSizeFor13_5,
    16: getFlangeSizeFor16,
};

export {
    getFlangeSizeFor8,
    getFlangeSizeFor11,
    getFlangeSizeFor12_5,
    getFlangeSizeFor13_5,
    getFlangeSizeFor16,
    flangeSizeFunctions,
};