import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { customStyles } from "../../styles/CustomStylesForSelect";
import { PriceCategoryOptions } from "../../utils/DropdownOptions";

const SheathMaterialsAdd = () => {
  const [item, setItem] = useState({
    name: "",
    material_category: "",
    material: "",
    material_code: "",
  });
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!item.name || (!item.material && !item.material_category)) {
      showErrorToast("Name and either Material or Material Category are required");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .post("/sheath-material", {
        name: item.name,
        material_category: item.material_category,
        material: item.material,
        material_code: item.material_code,
      })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("SheathMaterial added successfully");
        navigate("/sheathMaterial");
      })
      .catch((error) => {
        console.error(`Error adding SheathMaterial: ${error}`);
        showErrorToast("Error adding SheathMaterial");
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add Material</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <div className="d-flex mb-30">
                          <div className="col-md-4">
                            <label htmlFor="name">Name</label>
                            <Select
                              name="name"
                              id="name"
                              className="mr-3"
                              options={PriceCategoryOptions}
                              styles={customStyles}
                              placeholder="Select Name"
                              value={
                                item.name && {
                                  value: item.name,
                                  label: item.name,
                                }
                              }
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  name: option?.value ?? option,
                                })
                              }
                              isDisabled={false}
                            />
                          </div>
                          {item.name !== "PLATE" && item.name !== "JUNCTIONBOX" && (
                            <div className="col-md-4">
                              <label htmlFor="material_category">Material Category</label>
                              <input
                                type="text"
                                className="form-control mr-3"
                                placeholder="Enter Material Category"
                                value={item.material_category}
                                onChange={(e) =>
                                  setItem({ ...item, material_category: e.target.value })
                                }
                              />
                            </div>
                          )}
                          <div className="col-md-4">
                            <label htmlFor="material">Material</label>
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Enter Material"
                              value={item.material}
                              onChange={(e) =>
                                setItem({ ...item, material: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Link to="/sheathMaterial">
                            <button
                              type="button"
                              className="btn btn-secondary mr-3"
                            >
                              Close
                            </button>
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SheathMaterialsAdd;