import Select from "react-select";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useLoader } from "../../../context/Loader/LoaderProvider.js";
import { showErrorToast, showSuccessToast } from "../../../utils/Toaster.js";
import axiosPrivate from "../../../hooks/axiosPrivate.js";
import {
  materialOptions,
  heaterSheathTubeDiaOptions,
  sheathTubeThicknessOptions,
  sheathTubeTypeOptions,
  flangeToTerminalBoxOptions,
  bafflePlateThicknessOptions,
  baffleTypeOptions,
  fixingOfHeatingElementOptions,
  tieRodDiaOptions,
} from "../../../utils/DropdownOptions.jsx";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import { BsInfoCircleFill } from "react-icons/bs";

function Screen3({ formik, isPageComplete, handleFlangeThickness }) {
  const [sheathMaterialDropdown, setSheathMaterialDropdown] = useState([]);
  const [baffleMaterialDropdown, setbaffleMaterialDropdown] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [totalCount, setTotalCount] = useState(9);
  const [actualCount, setActualCount] = useState(0);
  const [selectedNutAssembly, setSelectedNutAssembly] = useState("");
  const [fixingOfHeatingElements, setFixingOfHeatingElements] = useState(
    formik.values.fixingofheatingelements || ""
  );
  const [baffleType, setBaffleType] = useState(formik.values.baffletype || "");

  const { values, setFieldValue } = formik;

  const handleInputValue = (value, count) => {
    if (value === "") return count > 0 ? count - 1 : count;
    if (value === undefined) return count;
    return count + 1;
  };

  const handleFixingChange = (option) => {
    setFixingOfHeatingElements(option ? (option ? option.value : "") : "");
    setFieldValue(
      "fixingofheatingelements",
      option ? (option ? option.value : "") : ""
    );
  };


  const handleNutAssembly = (e) => {
    const nutAssembly = e.target.value;
    setFieldValue("nutAssembly", nutAssembly);
    setSelectedNutAssembly(nutAssembly);
  };

  const validateInputs = () => {
    const isNutSelected = values.fixingofheatingelements === "NUT";
    const requiredFields = isNutSelected
      ? [values.nutsize]
      : [
        values.installedCapacity,
        values.wattdensity,
        values.heatersheathtubedia,
        values.maxactivehotlength,
      ];

    const missingFields = requiredFields
      .map((field, index) => ({
        field: field,
        name: isNutSelected
          ? "NUT Size"
          : [
            "Power",
            "Watt Density",
            "Element Diameter",
            "Max Hot Active Length",
          ][index],
      }))
      .filter(({ field }) => field === "" || isNaN(parseFloat(field)))
      .map(({ name }) => name);

    if (missingFields.length > 0) {
      showErrorToast(
        `Please fill all the required details: ${missingFields.join(", ")}`
      );
      return false;
    }

    return true;
  };

  const handleTotalElements = (noOfElementsConnected, spareElements) => {
    const totalNoOfElements =
      spareElements > 0
        ? noOfElementsConnected + spareElements
        : noOfElementsConnected;
    setFieldValue("totalnumberofelements", totalNoOfElements);
    return totalNoOfElements;
  };

  const handleConnectedElements = () => {
    const power = (parseFloat(values?.bundleHeatLoad[0])) * 1000;
    const wattDensity = parseFloat(values.wattdensity);
    const flangeDiameter = parseFloat(values.heatersheathtubedia);
    const hotActiveLength = parseFloat(values.maxactivehotlength);
    const nutSize = parseFloat(values.nutsize);

    if (values.fixingofheatingelements === "NUT") {
      if (isNaN(nutSize)) {
        setFieldValue("numberofelementsconnected", "");
        return NaN;
      }
      const elementsConnected = nutSize * 3;
      setFieldValue("numberofelementsconnected", elementsConnected);
      return elementsConnected;
    }

    if (
      isNaN(power) ||
      isNaN(wattDensity) ||
      isNaN(flangeDiameter) ||
      isNaN(hotActiveLength)
    ) {
      setFieldValue("numberofelementsconnected", "");
      return NaN;
    }

    const elementsConnected =
      ((power / wattDensity) * 3.98 * 8) /
      (flangeDiameter * hotActiveLength * 2);
    const roundedTotal = Math.ceil(elementsConnected / 3) * 3;
    setFieldValue("numberofelementsconnected", roundedTotal);
    return roundedTotal;
  };

  const handleSpareElements = (noOfElementsConnected) => {
    const inbuildSpare =
      values.inbuildspare !== "" ? parseFloat(values.inbuildspare) : 0;

    if (isNaN(inbuildSpare) || isNaN(noOfElementsConnected)) {
      setFieldValue("numberofelementsspare", "");
      return NaN;
    }

    let spareElements = 0;
    if (inbuildSpare > 0) {
      if (values.fixingofheatingelements === "NUT") {
        spareElements =
          Math.ceil((noOfElementsConnected * inbuildSpare) / 100 / 3) * 3;
      } else {
        spareElements = Math.ceil((noOfElementsConnected * inbuildSpare) / 100);
      }
    }

    setFieldValue("numberofelementsspare", spareElements);
    return spareElements;
  };

  const calculateElements = (e) => {
    e?.preventDefault();

    if (!validateInputs()) return;

    const noOfElementsConnected = handleConnectedElements();
    if (!isNaN(noOfElementsConnected)) {
      const spareElements = handleSpareElements(noOfElementsConnected);
      if (!isNaN(spareElements)) {
        const totalNoOfElements = handleTotalElements(
          noOfElementsConnected,
          spareElements
        );

        const bundleHeatLoad = parseFloat(values.bundleheatload);
        if (
          !isNaN(bundleHeatLoad) &&
          !isNaN(totalNoOfElements) &&
          totalNoOfElements > 0
        ) {
          const IndElementMaxWattage = (
            bundleHeatLoad / noOfElementsConnected
          ).toFixed(3);
          setFieldValue("individualelementmaxwattage", IndElementMaxWattage);
        } else {
          setFieldValue("individualelementmaxwattage", "");
        }
      }
    }
  };

  const handleManualInput = (field, value) => {
    setFieldValue(field, value);
    if (field === "numberofelementsspare") {
      calculateElements();
    }
  };

  const handleImmersionLength = () => {

    const maxActiveHotLength = parseFloat(values.maxactivehotlength) || 0;
    const maxActiveColdLength = parseFloat(values.maxactivecoldlength) || 0;

    if (isNaN(maxActiveHotLength) || isNaN(maxActiveColdLength)) {
      setFieldValue("bundleimmersionlength", "");
      return;
    }

    const immersionLength = Math.round(maxActiveHotLength + maxActiveColdLength);

    if (values.maxactivecoldlength === "" || values.maxactivehotlength === "") {
      setFieldValue("bundleimmersionlength", null);
    } else {
      setFieldValue("bundleimmersionlength", immersionLength);
      setFieldValue("approxvessellength", immersionLength + 150);
    }
  };



  const handleDevelopedLength = () => {
    const maxActiveHotLength = parseFloat(values.maxactivehotlength) || 0;
    const maxActiveColdLength = parseFloat(values.maxactivecoldlength) || 0;
    const distbwheaterflangetoterminalbox = parseFloat(values.distbwheaterflangetoterminalbox) || 0;
    const blrfValue = parseFloat(formik?.values?.blrfValue) || 0;

    console.log("maxActiveHotLength:", maxActiveHotLength);
    console.log("maxActiveColdLength:", maxActiveColdLength);
    console.log("distbwheaterflangetoterminalbox:", distbwheaterflangetoterminalbox);
    console.log("blrfValue:", blrfValue);

    if (isNaN(maxActiveHotLength) || isNaN(maxActiveColdLength)) {
      setFieldValue("developedlength", "");
      return;
    }

    let developedLength = maxActiveHotLength + maxActiveColdLength;
    console.log("After adding hot and cold lengths:", developedLength);

    if (!isNaN(distbwheaterflangetoterminalbox)) {
      developedLength += distbwheaterflangetoterminalbox;
      console.log("After adding distbwheaterflangetoterminalbox:", developedLength);
    }

    developedLength *= 2;
    console.log("After multiplying by 2:", developedLength);

    if (!isNaN(blrfValue)) {
      developedLength += 2 * (blrfValue + 50);
      console.log("After adding blrfValue:", developedLength);
    }

    setFieldValue("developedlength", Math.round(developedLength));
    console.log("Final developedLength:", Math.round(developedLength));
  };

  const getSheathMaterialDropdown = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        setSheathMaterialDropdown(
          response.data?.data
            .filter((item) => item.name === "TUBE")
            .map((item) => {
              return { value: item.material, label: item.material };
            })
        );
        setbaffleMaterialDropdown(
          response.data?.data
            .filter((item) => item.name === "PLATE")
            .map((item) => {
              return { value: item.material, label: item.material };
            })
        );
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showErrorToast(error.message);
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const wattdensityactual = () => {
    const {
      installedCapacity,
      numberofelementsconnected,
      heatersheathtubedia,
      maxactivehotlength,
      multistackvessel
    } = values;

    const missingFields = [];

    if (!installedCapacity) missingFields.push("Installed Capacity");
    if (!numberofelementsconnected) missingFields.push("Number of Elements Connected");
    if (!heatersheathtubedia) missingFields.push("Heater Sheath Tube Diameter");
    if (!maxactivehotlength) missingFields.push("Max Active Hot Length");

    if (missingFields.length > 0) {
      showErrorToast(
        `Please ensure the following values are selected to calculate watt density actual: ${missingFields.join(", ")}`
      );
      return;
    }

    const power = (parseFloat(values?.bundleHeatLoad[0])) * 1000;
    const wattDensityActual =
      (power * 100) /
      (numberofelementsconnected * 3.14 * heatersheathtubedia * maxactivehotlength * 2);

    setFieldValue("wattdensityactual", (wattDensityActual.toFixed(4)));
  };

  const handleSpareChanges = (e) => {
    const spareElements = parseInt(e.target.value) || 0;
    const connectedElements = values.numberofelementsconnected || 0;
    const totalElements = spareElements + connectedElements;
    setFieldValue("totalnumberofelements", totalElements);
  };

  useEffect(() => {
    let count = 0;
    const fields = [
      "heatersheathtubedia",
      "maxactivehotlength",
      "maxactivecoldlength",
      "sheathmaterial",
      "fixingofheatingelements",
      "distancebwionozzels",
      "totalnumberofelements",
      "numberofelementsconnected",
      "bundleimmersionlength",
    ];
    fields.forEach((field) => {
      count = handleInputValue(values?.[field], count);
    });
    setActualCount(count);
  }, [values]);

  useEffect(() => {
    isPageComplete(3, totalCount, actualCount);
  }, [actualCount, totalCount]);

  useEffect(() => {
    handleDevelopedLength();
  }, [
    values.maxactivehotlength,
    values.maxactivecoldlength,
    values.distbwheaterflangetoterminalbox,
    formik?.values?.blrfValue,
  ]);

  useEffect(() => {
    getSheathMaterialDropdown();
  }, []);

  useEffect(() => {
    wattdensityactual();
  }, [values.numberofelementsconnected]);

  const handleBafflePlateThicknessChange = (option) => {
    const value = option ? option.value : "";
    formik.setFieldValue("baffleplatethickness", value);

    formik.values.multiStackVesselFormData.forEach((item, index) => {
      formik.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.baffleplatethickness`,
        value
      );
    });
  };

  const handleBaffleCutChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("bafflecut", value);

    formik.values.multiStackVesselFormData.forEach((item, index) => {
      formik.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.bafflecut`,
        value
      );
    });
  };

  const handleBaffleTypeChange = (option) => {
    setBaffleType(option ? (option ? option.value : "") : "");
    setFieldValue("baffletype", option ? (option ? option.value : "") : "");
    formik.values.multiStackVesselFormData.forEach((item, index) => {
      setFieldValue(
        `multiStackVesselFormData[${index}].baffle.baffletype`,
        option ? (option ? option.value : "") : ""
      );
    });
  };

  const handleTierodDiaChange = (option) => {
    const value = option ? option.value : "";
    formik.setFieldValue("tieroddia", value);

    formik.values.multiStackVesselFormData.forEach((item, index) => {
      formik.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.tieroddia`,
        value
      );
    });
  };
  const handleTieRodQtyChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue("tierodquantity", value);

    formik.values.multiStackVesselFormData.forEach((item, index) => {
      formik.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.tierodquantity`,
        value
      );
    });
  };

  const calculateBaffleResult = () => {
    const { netWeight, numberofHotBaffles, baffleplatethickness } = formik.values;

    if (!netWeight?.baffle[0] || !numberofHotBaffles || !baffleplatethickness) {
      return 0;
    }

    const result = Math.floor(
      Math.sqrt(
        (4 * netWeight.baffle[0] * 1000000) /
        (8 * numberofHotBaffles * baffleplatethickness * Math.PI)
      )
    );
    formik.setFieldValue("baffleBomValue", result);
  };

  const handleButtonClick = (e) => {
    calculateElements(e);
    handleImmersionLength();
  };



  return (
    <div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Element Selection</b>
          </label>
        </div>

        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="heatersheathtubedia">Heater Sheath Tube DIA</label>
            <span style={{ color: "red" }}>*</span>
            <Select
              isClearable
              styles={customStyles}
              id="heatersheathtubedia"
              name="heatersheathtubedia"
              type="number"
              options={heaterSheathTubeDiaOptions}
              onBlur={formik.handleBlur}
              onChange={(option) => {
                formik.setFieldValue(
                  "heatersheathtubedia",
                  option ? (option ? option.value : "") : ""
                );
                formik.values.multiStackVesselFormData.forEach((item, index) => {
                  formik.setFieldValue(
                    `multiStackVesselFormData[${index}].heatersheathtubedia`,
                    option ? (option ? option.value : "") : ""
                  );
                });
                // const flangeSize = flangeSizeMapping[option ? option ? option.value : "" : ""];
                // if (flangeSize !== undefined) {
                //   formik.setFieldValue("flangeSize", flangeSize);
                // }
              }}
              value={
                formik.values.heatersheathtubedia
                  ? {
                    value: formik.values.heatersheathtubedia,
                    label: formik.values.heatersheathtubedia,
                  }
                  : null
              }
            />
            {formik.touched.heatersheathtubedia &&
              formik.errors.heatersheathtubedia ? (
              <div className="error">{formik.errors.heatersheathtubedia}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathmaterial"> Sheath Material </label>
            <Select
              styles={customStyles}
              isClearable
              id="sheathmaterial"
              name="sheathmaterial"
              options={sheathMaterialDropdown}
              onChange={(selectedOption) => {
                formik.setFieldValue(
                  "sheathmaterial",
                  selectedOption ? selectedOption.value : ""
                );
                formik.setFieldValue(
                  "tiematerial",
                  selectedOption ? selectedOption.value : ""
                );
                formik.setFieldValue(
                  "bafflematerial",
                  selectedOption ? selectedOption.value : ""
                );
              }}
              value={
                formik.values.sheathmaterial
                  ? {
                    value: formik.values.sheathmaterial,
                    label: formik.values.sheathmaterial,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathmaterial && formik.errors.sheathmaterial ? (
              <div className="error">{formik.errors.sheathmaterial}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtubethickness">
              Sheathing Tube Thickness (mm)
            </label>
            <Select
              styles={customStyles}
              isClearable
              id="sheathtubethickness"
              name="sheathtubethickness"
              options={sheathTubeThicknessOptions}
              onChange={(option) =>
                formik.setFieldValue(
                  "sheathtubethickness",
                  option ? (option ? option.value : "") : ""
                )
              }
              value={
                formik.values.sheathtubethickness
                  ? {
                    value: formik.values.sheathtubethickness,
                    label: formik.values.sheathtubethickness,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathtubethickness &&
              formik.errors.sheathtubethickness ? (
              <div className="error">{formik.errors.sheathtubethickness}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtubetype"> Sheathing Tube Type </label>
            <Select
              isClearable
              styles={customStyles}
              id="sheathtubetype"
              name="sheathtubetype"
              options={sheathTubeTypeOptions}
              onChange={(option) =>
                formik.setFieldValue(
                  "sheathtubetype",
                  option ? (option ? option.value : "") : ""
                )
              }
              value={
                formik.values.sheathtubetype
                  ? {
                    value: formik.values.sheathtubetype,
                    label: formik.values.sheathtubetype,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathtubetype && formik.errors.sheathtubetype ? (
              <div className="error">{formik.errors.sheathtubetype}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="distbwheaterflangetoterminalbox">
              Dist.B/W Heater Flange to Ter. Box
            </label>
            <span style={{ color: "red" }}>*</span>
            <CreatableSelect
              isClearable
              styles={customStyles}
              name="distbwheaterflangetoterminalbox"
              id="distbwheaterflangetoterminalbox"
              placeholder="Distance"
              options={flangeToTerminalBoxOptions}
              onChange={(option) =>
                formik.setFieldValue(
                  "distbwheaterflangetoterminalbox",
                  (option ? option.value : "")
                )
              }
              value={
                formik.values.distbwheaterflangetoterminalbox
                  ? {
                    value: formik.values.distbwheaterflangetoterminalbox,
                    label: formik.values.distbwheaterflangetoterminalbox,
                  }
                  : null
              }
            />
            {formik.touched.distbwheaterflangetoterminalbox &&
              formik.errors.distbwheaterflangetoterminalbox ? (
              <div className="error">
                {formik.errors.distbwheaterflangetoterminalbox}
              </div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="maxactivehotlength"> Max. Active Length </label>
            <span style={{ color: "red" }}>*</span>
            <input
              type="text"
              className="form-control"
              placeholder="Max. Active Hot Length"
              name="maxactivehotlength"
              value={formik.values.maxactivehotlength}
              onChange={(e) => {
                e.preventDefault();
                // handleImmersionLength();
                formik.setFieldValue("maxactivehotlength", e.target.value);
                formik.values.multiStackVesselFormData.forEach((item, index) => {
                  formik.setFieldValue(
                    `multiStackVesselFormData[${index}].maxactivehotlength`,
                    e.target.value
                  );
                });
              }}
              // onBlur={formik.handleBlur}
              onBlur={() => {
                handleImmersionLength();
                formik.setFieldValue(
                  "distancebwionozzels",
                  formik.values.maxactivehotlength
                );
              }}
              required
            />
            {formik.touched.maxactivehotlength &&
              formik.errors.maxactivehotlength ? (
              <div className="error">{formik.errors.maxactivehotlength}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="maxactivecoldlength"> Max. Cold Length </label>
            <span style={{ color: "red" }}>*</span>
            <input
              type="number"
              className="form-control"
              placeholder="Max. Active Cold Length"
              name="maxactivecoldlength"
              value={formik.values.maxactivecoldlength}
              onChange={(e) => {
                handleImmersionLength();
                e.preventDefault();
                formik.setFieldValue("maxactivecoldlength", e.target.value);
              }}
              onBlur={(e) => {
                handleImmersionLength();
                formik.setFieldValue(
                  "maxactivecoldlength",
                  e.target.value
                );
                formik.handleBlur(e);
              }}
              required
            />
            {formik.touched.maxactivecoldlength &&
              formik.errors.maxactivecoldlength ? (
              <div className="error">{formik.errors.maxactivecoldlength}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6">
            <label htmlFor="wattdensity">
              Watt.Density(Recommended/Actual)
            </label>
            <div className="d-flex">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Watt Density(Recommended)"
                  name="wattdensity"
                  id="wattdensity"
                  value={formik.values.wattdensity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.wattdensity && formik.errors.wattdensity ? (
                  <div className="error">{formik.errors.wattdensity}</div>
                ) : null}
              </div>
              <div className="ml-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Watt Density(Actual)"
                  name="wattdensityactual"
                  id="wattdensityactual"
                  value={formik?.values?.wattdensityactual}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.wattdensityactual &&
                  formik.errors.wattdensityactual ? (
                  <div className="error">{formik.errors.wattdensityactual}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <button
              onClick={handleButtonClick}
              className="btn btn-primary bg-primary form-control mt-25"
            >
              <h6>Calculate</h6>
            </button>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="inbuildspare" className="tooltip">
              Heater Spare Capacity (%) <BsInfoCircleFill className="info" />
              <span className="tooltip-text">Heater Spare Capacity</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Heater Spare Capacity"
              name="inbuildspare"
              id="inbuildspare"
              maxLength={100}
              value={formik && formik?.values.inbuildspare}
              onChange={formik && formik?.handleChange}
              onBlur={formik && formik?.handleblur}
              readOnly
            />
            {formik.touched.inbuildspare && formik.errors.inbuildspare ? (
              <div className="error">{formik.errors.inbuildspare}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="individualelementmaxwattage">
              Ind. Element Max Wattage{""} (KW)
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ind. Element Max Wattage"
              name="individualelementmaxwattage"
              value={parseFloat(
                formik.values.individualelementmaxwattage
              ).toFixed(2)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly
            />
            {formik.touched.individualelementmaxwattage &&
              formik.errors.individualelementmaxwattage ? (
              <div className="error">
                {formik.errors.individualelementmaxwattage}
              </div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="developedlength"> Total Straight Length </label>
            <input
              type="text"
              className="form-control"
              placeholder="Total Straight Length"
              name="developedlength"
              value={Math.round(formik.values.developedlength)}
              onChange={formik.handleChange}
              readOnly
              onBlur={formik.handleBlur}
            />
            {formik.touched.developedlength && formik.errors.developedlength ? (
              <div className="error">{formik.errors.developedlength}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="totalnumberofelements" className="tooltip">
              Total No. of Elements <BsInfoCircleFill className="info" />
              <span className="tooltip-text">
                If Spare Elements is more then 0 then Spare Elements else No Of
                Elements Connected
              </span>
            </label>
            <input
              readOnly
              type="text"
              className="form-control"
              placeholder="Total No. of Elements"
              name="totalnumberofelements"
              value={formik.values.totalnumberofelements}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.totalnumberofelements &&
              formik.errors.totalnumberofelements ? (
              <div className="error">{formik.errors.totalnumberofelements}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofelementsconnected">
              No. of Elements Connected
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="No. of Elements Connected"
              name="numberofelementsconnected"
              value={formik.values.numberofelementsconnected}
              onChange={(e) =>
                handleManualInput("numberofelementsconnected", e.target.value)
              }
              readOnly
              onBlur={formik.handleBlur}
            />
            {formik.touched.numberofelementsconnected &&
              formik.errors.numberofelementsconnected ? (
              <div className="error">
                {formik.errors.numberofelementsconnected}
              </div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofelementsspare">No. of Elements Spare</label>
            <input
              name="numberofelementsspare"
              type="text"
              className="form-control"
              placeholder="No. of Elements Spare"
              value={formik.values.numberofelementsspare}
              onChange={(e) => {
                e.preventDefault();
                formik.setFieldValue("numberofelementsspare", e.target.value);
                handleSpareChanges(e);
              }}
              onBlur={(e) => {
                formik.setFieldValue("numberofelementsspare", e.target.value);
                formik.handleBlur(e);
              }}
            />
            {formik.touched.numberofelementsspare &&
              formik.errors.numberofelementsspare ? (
              <div className="error">{formik.errors.numberofelementsspare}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="bundleimmersionlength">
              Bundle Immersion Length
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Bundle Immersion Length"
              name="bundleimmersionlength"
              value={formik.values.bundleimmersionlength}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.bundleimmersionlength &&
              formik.errors.bundleimmersionlength ? (
              <div className="error">{formik.errors.bundleimmersionlength}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Baffles and Tie Rod</b>
          </label>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofHotBaffles">No. of Hot Baffles</label>
            <input
              name="numberofHotBaffles"
              type="number"
              id="numberofHotBaffles"
              className="form-control"
              placeholder="No. of Hot Baffles"
              value={formik.values.numberofHotBaffles}
              onChange={(e) => {
                e.preventDefault();
                calculateBaffleResult(e);
                formik.setFieldValue("numberofHotBaffles", e.target.value);
              }
              }
            />
            {formik.touched.numberofHotBaffles && formik.errors.numberofHotBaffles ? (
              <div className="error">{formik.errors.numberofHotBaffles}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="coldBaffle">No. of Cold Baffles</label>
            <input
              name="coldBaffle"
              type="number"
              id="coldBaffle"
              className="form-control"
              placeholder="No. of Cold Baffles"
              value={formik?.values?.coldBaffle}
              onChange={
                formik?.handleChange
              }
              disabled
            />
            {formik.touched.coldBaffle && formik.errors.coldBaffle ? (
              <div className="error">{formik.errors.coldBaffle}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="baffleplatethickness">Baffle Plate Thickness</label>
            <Select
              isClearable
              styles={customStyles}
              id="baffleplatethickness"
              name="baffleplatethickness"
              options={bafflePlateThicknessOptions}
              onChange={(option) => {
                handleBafflePlateThicknessChange(option);
                calculateBaffleResult();
              }}
              value={
                formik.values.baffleplatethickness
                  ? {
                    value: formik.values.baffleplatethickness,
                    label: formik.values.baffleplatethickness,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.baffleplatethickness &&
              formik.errors.baffleplatethickness ? (
              <div className="error">{formik.errors.baffleplatethickness}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="baffletype">Baffle Type</label>
            <Select
              isClearable
              styles={customStyles}
              id="baffletype"
              name="baffletype"
              options={baffleTypeOptions}
              onBlur={formik.handleBlur}
              onChange={handleBaffleTypeChange}
              value={
                baffleType ? { value: baffleType, label: baffleType } : null
              }
            />
            {formik.touched.baffletype && formik.errors.baffletype ? (
              <div className="error">{formik.errors.baffletype}</div>
            ) : null}
          </div>
          {formik?.values?.baffletype === "Segmental" && (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="bafflecut">Baffles Cut</label>
                <input
                  name="bafflecut"
                  type="text"
                  id="bafflecut"
                  className="form-control"
                  placeholder="Enter Baffle Cut"
                  value={formik.values.bafflecut || ""}
                  onChange={handleBaffleCutChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.bafflecut && formik.errors.bafflecut ? (
                  <div className="error">{formik.errors.bafflecut}</div>
                ) : null}
              </div>
            </>
          )}

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="bafflematerial">Baffle Material</label>
            <Select
              isClearable
              styles={customStyles}
              id="bafflematerial"
              name="bafflematerial"
              options={baffleMaterialDropdown}
              onChange={(option) => {
                formik.setFieldValue(
                  "bafflematerial",
                  option ? (option ? option.value : "") : ""
                );
              }}
              value={
                formik.values.bafflematerial
                  ? {
                    value: formik.values.bafflematerial,
                    label: formik.values.bafflematerial,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.bafflematerial && formik.errors.bafflematerial ? (
              <div className="error">{formik.errors.bafflematerial}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="tieroddia"> Tie Rod Dia </label>
            <Select
              isClearable
              styles={customStyles}
              id="tieroddia"
              name="tieroddia"
              options={tieRodDiaOptions}
              onBlur={formik.handleBlur}
              onChange={handleTierodDiaChange}
              value={
                formik.values.tieroddia
                  ? {
                    value: formik.values.tieroddia,
                    label: formik.values.tieroddia,
                  }
                  : null
              }
            />
            {formik.touched.tieroddia && formik.errors.tieroddia ? (
              <div className="error">{formik.errors.tieroddia}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="tieroddia"> Tie Rod Quantity </label>
            <input
              type="text"
              name="tierodquantity"
              id="tierodquantity"
              className="form-control"
              placeholder="Tie Rod Quantity"
              value={formik.values.tierodquantity}
              onChange={handleTieRodQtyChange}
            />
            {formik.touched.tierodquantity && formik.errors.tierodquantity ? (
              <div className="error">{formik.errors.tierodquantity}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-20">
            <label htmlFor="tiematerial"> Tie Rod Material </label>
            <Select
              isClearable
              styles={customStyles}
              id="tiematerial"
              name="tiematerial"
              options={baffleMaterialDropdown}
              onChange={(option) =>
                formik.setFieldValue(
                  "tiematerial",
                  option ? (option ? option.value : "") : ""
                )
              }
              value={
                formik.values.tiematerial
                  ? {
                    value: formik.values.tiematerial,
                    label: formik.values.tiematerial,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.tiematerial && formik.errors.tiematerial ? (
              <div className="error">{formik.errors.tiematerial}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Element Fixtures</b>
          </label>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="distancebwionozzels">
              Distance Between I/O Nozzels
            </label>
            <input
              name="distancebwionozzels"
              type="text"
              id="distancebwionozzels"
              className="form-control"
              placeholder="Distance Between I/O Nozzels"
              value={formik.values.distancebwionozzels}
              onChange={(e) => {
                e.preventDefault();
                formik.setFieldValue("distancebwionozzels", e.target.value);
              }}
            />
            {formik.touched.distancebwionozzels &&
              formik.errors.distancebwionozzels ? (
              <div className="error">{formik.errors.distancebwionozzels}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="fixingofheatingelements">
              Fixing of Heating Elements
            </label>
            <Select
              isClearable
              styles={customStyles}
              id="fixingofheatingelements"
              name="fixingofheatingelements"
              options={fixingOfHeatingElementOptions}
              onChange={handleFixingChange}
              value={
                fixingOfHeatingElements
                  ? {
                    value: fixingOfHeatingElements,
                    label: fixingOfHeatingElements,
                  }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.fixingofheatingelements &&
              formik.errors.fixingofheatingelements ? (
              <div className="error">
                {formik.errors.fixingofheatingelements}
              </div>
            ) : null}
          </div>

          {(fixingOfHeatingElements === "SLEEVES" ||
            fixingOfHeatingElements === "TIG+SLEEVES" ||
            fixingOfHeatingElements === "BUSH") && (
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15 ">
                <label htmlFor="materialofsleevesbush">
                  Material of {fixingOfHeatingElements}
                </label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialofsleevesbush"
                  name="materialofsleevesbush"
                  options={materialOptions}
                  onChange={(option) =>
                    formik.setFieldValue(
                      "materialofsleevesbush",
                      option ? (option ? option.value : "") : ""
                    )
                  }
                  value={
                    formik.values.materialofsleevesbush
                      ? {
                        value: formik.values.materialofsleevesbush,
                        label: formik.values.materialofsleevesbush,
                      }
                      : null
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.materialofsleevesbush &&
                  formik.errors.materialofsleevesbush ? (
                  <div className="error">
                    {formik.errors.materialofsleevesbush}
                  </div>
                ) : null}
              </div>
            )}

          {(fixingOfHeatingElements === "SLEEVES" ||
            fixingOfHeatingElements === "TIG+SLEEVES" ||
            fixingOfHeatingElements === "BUSH") && (
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="sleevethickness">
                  {fixingOfHeatingElements} Thickness (mm)
                </label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="sleevethickness"
                  name="sleevethickness"
                  options={bafflePlateThicknessOptions}
                  onChange={(option) =>
                    formik.setFieldValue(
                      "sleevethickness",
                      option ? (option ? option.value : "") : ""
                    )
                  }
                  value={
                    formik.values.sleevethickness
                      ? {
                        value: formik.values.sleevethickness,
                        label: formik.values.sleevethickness,
                      }
                      : null
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.sleevethickness &&
                  formik.errors.sleevethickness ? (
                  <div className="error">{formik.errors.sleevethickness}</div>
                ) : null}
              </div>
            )}

          {fixingOfHeatingElements === "NUT" && (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="nutsize">NUT Size</label>
              <input
                type="number"
                className="form-control"
                placeholder="NUT Size"
                name="nutsize"
                value={formik.values.nutsize}
                onChange={(e) => {
                  e.preventDefault();
                  formik.setFieldValue("nutsize", e.target.value);
                }}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.nutsize && formik.errors.nutsize ? (
                <div className="error">{formik.errors.nutsize}</div>
              ) : null}
            </div>
          )}
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtemp">Sheath Temp</label>
            <input
              name="sheathtemp"
              type="text"
              id="sheathtemp"
              className="form-control"
              placeholder="Enter sheathtemp"
              value={formik.values.sheathtemp}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Screen3;
