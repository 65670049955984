import axios from "axios";
import { axiosPublic } from "./axiosPublic";

// Create an Axios instance for making private requests to the backend
const axiosPrivate = axios.create({
  baseURL:process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let userData; // Variable to store user data retrieved from local storage

// Function to update user data from local storage before sending a request
async function checkUpdateToken() {
  return new Promise((resolve, reject) => {
    userData = JSON.parse(localStorage.getItem("userData") || "{}"); // Retrieve user data from local storage
    resolve(true); // Resolve the promise to indicate successful retrieval
  });
}

const handleLogout = async () => {
  try {
    await axiosPublic.post("logout", { user_id: userData?.userId });
  } catch (error) {
    console.error(error);
  }
};

// Request interceptor to add authorization headers before sending the request
axiosPrivate.interceptors.request.use(
  async (config) => {
    await checkUpdateToken(); // Ensure user data is up to date
    // Add authorization header with bearer token from user data
    config.headers = {
      Authorization: `Bearer ${userData?.token}`,
      Accept: "application/json",
    };
    return config; // Return updated request configuration
  },
  (error) => {
    Promise.reject(error); // Reject the request with the encountered error
  }
);

// Response interceptor to handle 401 Unauthorized errors
axiosPrivate.interceptors.response.use(
  (response) => {
    return response; // Return the response as-is for successful requests
  },
  async function (error) {
    const originalRequest = error.config; // Retrieve the original request configuration
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to prevent infinite loop
      await refreshAccessToken(); // Refresh the access token
      const newData = JSON.parse(localStorage.getItem("userData") || "{}"); // Retrieve updated user data
      userData = newData; // Update the user data variable
      // Update default authorization header and original request header with new access token
      axiosPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newData?.token}`;
      originalRequest.headers.Authorization = `Bearer ${newData?.token}`;
      return axiosPrivate(originalRequest); // Retry the original request with the new token
    }
    else if(error?.response?.status === 403) {
       window.location.href = "/forbidden";
    }
    return Promise.reject(error); // Reject the request with the encountered error
  }
);

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  return new Promise((resolve, reject) => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}"); // Retrieve user data from local storage
    if (userData.refresh_token !== undefined) {
      // Send a request to the refresh-token endpoint with the refresh token
      axiosPublic
        .post("refresh-token", { refresh_token: userData.refresh_token })
        .then(
          (response) => {
            localStorage.setItem("userData", JSON.stringify(response?.data)); // Update user data in local storage with new tokens
            resolve(response?.data?.token); // Resolve the promise with the new access token
          },
          (error) => {
            console.error(error);
            if (error?.response?.status !== 201) {
              // Remove user data from local storage if refresh token is invalid or expired
              handleLogout();
              localStorage.removeItem("userData");
              localStorage.removeItem("userDetails");
              // Redirect the user to the login page
              window.location.href = "/login";
            }
            reject(error?.message); // Reject the promise with the encountered error
          }
        );
    } else {
      // Remove user data from local storage if refresh token is undefined
      localStorage.removeItem("userData");
      localStorage.removeItem("userDetails");
      reject("Refresh token not found"); // Reject the promise if refresh token is not found
    }
  });
};

export default axiosPrivate; // Export the configured Axios instance for private requests
