import { useFormik } from 'formik';
import React, {
    Component,
    Fragment,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import classes from './SignUp.module.css';
import slider1 from '../../assets/images/slider1.svg';
import slider2 from '../../assets/images/slider2.svg';
import slider3 from '../../assets/images/slider3.svg';
import logo from '../../assets/images/payments/tempsens.png'


const plantsData = [
  { plant: "--select plant--", code: "" },
  {
    id: "6543",
    plant: "Unit 1",
    plantCode: "2010",
  },
  {
    id: "6546",
    plant: "Unit 2",
    plantCode: "2020",
  },
  {
    id: "6547",
    plant: "Unit 4",
    plantCode: "2040",
  },
  {
    id: "6540",
    plant: "Marathon",
    plantCode: "3010",
  },

  {
    id: "6345",
    plant: "AST",
    plantCode: "4010",
  },
];

const Register = () => {
	const navigate = useNavigate();
	// const { setAlertMessage } = useContext(AlertContext);
	const [formValue, setFormSetValue] = useState({
		name: '',
		email: '',
		password: '',
        role:'',

	});

	const inputChange = (eve) => {
		// alert('')
		const { name, value } = eve.target;
		setFormSetValue((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const validationRules = {
 	};


	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			password: '',
            role:''
		},
		validationSchema: Yup.object(validationRules),
		// onSubmit: async (values, { setSubmitting, setFieldError }) => {
		// 	var values = {
		// 		name: formValue.name,
		// 		email: formValue.email,
		// 		password: formValue.password,
        //         role:'staff'

		// 	};
		// 	axiosPrivate
		// 		.post('register', values)
		// 		.then((response) => {
		// 			setAlertMessage({ message: 'New user created successfully' });
		// 			navigate('/admin/users');
		// 		})
		// 		.catch((error) => {
		// 			if (error.response) {
		// 				if (error.response.status == 422) {
		// 					for (let i = 0; i < error.response.data.errors.length; i++) {
		// 						setFieldError(
		// 							error.response.data.errors[i].param,
		// 							error.response.data.errors[i].msg
		// 						);
		// 					}
		// 				}
		// 			}
		// 		});
		// 	  alert(JSON.stringify(values, null, 2));
		// },
	});

	return (
		<Fragment>
			<div className="auth_left">
				<form onSubmit={formik.handleSubmit}>
					<div className="card">
						<div className="text-center mb-5">
							<img width={50} height={50} src={logo}></img>
						</div>
 						<div className="card-body">
							<div className="card-title">Create new account</div>

                            <div >
 								<input
 									name="role"
									type="hidden"
 								/>
 							</div>

							<div className="form-group">
								<label className="form-label">Name</label>
								<input
									id="name"
									name="name"
									type="text"
									className="form-control"
									placeholder="Enter name"
									// onChange={formik.handleChange}
									onChange={(eve) => inputChange(eve)}
									// onBlur={formik.handleBlur}
									value={formValue.name}
                                    required
								/>
								{formik.touched.name && formik.errors.name ? (
									<div className={classes.invalidDataError}>
										{formik.errors.name}
									</div>
								) : null}
							</div>
							<div className="form-group">
								<label className="form-label">Email address</label>
								<input
									id="email"
									name="email"
									type="email"
									className="form-control"
									placeholder="Enter email"
									onChange={(eve) => inputChange(eve)}
									value={formValue.email}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div className={classes.invalidDataError}>
										{formik.errors.email}
									</div>
								) : null}
							</div>
							<div className="form-group">
								<label className="form-label">Password</label>
								<input
									id="password"
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									onChange={(eve) => inputChange(eve)}
									value={formValue.password}
                                    required
								/>
								{formik.touched.password && formik.errors.password ? (
									<div className={classes.invalidDataError}>
										{formik.errors.password}
									</div>
								) : null}
							</div>
							<div className="form-group">
								<label className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input" />
									<span className="custom-control-label">
										Agree the <a href="#">terms and policy</a>
									</span>
								</label>
							</div>
							<div className="form-footer">
								{/* <Link  to="/Register"> */}
								<button className="btn btn-primary btn-block" type="submit">
									Click new account
								</button>{' '}
								{/* </Link> */}
							</div>
						</div>
						<div className="text-center text-muted">
							Already have account? <Link to="/signin">Sign In</Link>
						</div>
					</div>
				</form>
			</div>
			<div className="auth_right">
				<div
					className="carousel slide"
					data-ride="carousel"
					data-interval={3000}
				>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img src={slider1} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Fully Responsive</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src={slider2} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Quality Code and Easy Customizability</h4>
								<p>
									There are many variations of passages of Lorem Ipsum
									available.
								</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src={slider3} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Cross Browser Compatibility</h4>
								<p>
									Overview We're a group of women who want to learn JavaScript.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Register;
