import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const SheathMaterialsList = () => {
  const [sheathMaterials, setSheathMaterials] = useState([]);
  const [distinctNames, setDistinctNames] = useState([]);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [schId, setSchId] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    material_category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    material: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        setSheathMaterials(response.data?.data);
        const names = [...new Set(response.data?.data.map(item => item.name))];
        setDistinctNames(names.map(name => ({ label: name, value: name })));
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (schId) => {
    try {
      const response = await axiosPrivate.delete(`/sheath-material/${schId}`);
      if (response.status === 200) {
        setSheathMaterials(sheathMaterials.filter((sch) => sch.id !== schId));
        showSuccessToast("SheathMaterial deleted successfully");
      }
    } catch (error) {
      showErrorToast(`Error deleting sheathMaterial: ${error.message}`);
    } finally {
      setSchId(null);
    }
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const onColumnFilterChange = (event, field) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <Link to="/sheathMaterial/add" className="text-decoration-none">
          <button type="button" className="btn btn-primary">
            <i className="fe fe-plus mr-2" />
            Add
          </button>
        </Link>
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/sheathMaterial/edit/${rowData.id}`}>
          <FaEdit
            color="green"
            style={{ cursor: "pointer", fontSize: "20px" }}
          />
        </Link>
        <FaTrash
          color="red"
          style={{ cursor: "pointer", fontSize: "20px", marginLeft: "10px" }}
          onClick={() => {
            setSchId(rowData.id);
            setDeleteConfirmationVisible(true);
          }}
        />
      </div>
    );
  };

  const header = renderHeader();
  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) :
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Materials Master</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={sheathMaterials}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters?.global?.value}
                  emptyMessage="No sheathMaterials found"
                >
                  <Column
                    body={serialNumberBodyTemplate}
                    header="S.No"
                    headerStyle={{ width: "10%" }}
                  />
                  <Column
                    field="name"
                    header="Name"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <Dropdown
                        value={filters.name?.value || ""}
                        options={distinctNames}
                        onChange={(e) => onColumnFilterChange(e, "name")}
                        placeholder="Search by Name"
                        showClear
                      />
                    }
                  />
                  <Column
                    field="material_category"
                    header="Material Category"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <InputText
                        value={filters.material_category?.value || ""}
                        onChange={(e) =>
                          onColumnFilterChange(e, "material_category")
                        }
                        placeholder="Search by Category"
                      />
                    }
                  />
                  <Column
                    field="material"
                    header="Material"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <InputText
                        value={filters.material?.value || ""}
                        onChange={(e) => onColumnFilterChange(e, "material")}
                        placeholder="Search by Material"
                      />
                    }
                  />
                  <Column
                    field="action"
                    header="Actions"
                    body={actionBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            draggable={false}
            visible={deleteConfirmationVisible}
            onHide={() => setDeleteConfirmationVisible(false)}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setDeleteConfirmationVisible(false);
                    setSchId(null);
                  }}
                />
                <Button
                  label="Delete"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(schId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this Material?</p>
          </Dialog>
        </div>
      }
    </>
  );
};

export default SheathMaterialsList;