const nozzleSelection = {
  nozzle: [
    // 0
    {
      name: "Inlet Nozzle",
      inletNozzle: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      orientation: 0,
      elevation: 0,
      // materialGrade: "",
      quantity: 0,
    },
    // 1
    {
      name: "Outlet Nozzle",
      outletNozzle: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      orientation: 0,
      elevation: 0,
      // materialGrade: "",
      quantity: 0,
    },
    // 2
    {
      name: "Vent",
      vent: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      orientation: 0,
      elevation: 0,
      // materialGrade: "",
      quantity: 0,
    },
    // 3
    {
      name: "Drain",
      drain: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      orientation: 0,
      elevation: 0,
      // materialGrade: "",
      quantity: 0,
    },

    // 4
    {
      name: "Process",
      process: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      orientation: 0,
      elevation: 0,
      // materialGrade: "",
      quantity: 0,
    },

    // 5
    {
      name: "Dummy",
      dummy: false,
      size: "",
      schedules: "",
      flangeRating: "",
      flangeType: "",
      counterFlange: false,
      material: "",
      materialCode: "",
      // materialGrade: "",
      quantity: 0,
      orientation: 0,
      elevation: 0,
    },
  ],
};

export default nozzleSelection;
