import Select from "react-select";
import React, { useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate.js";
import { showErrorToast } from "../../../utils/Toaster.js";
import {
  flangeRatingOptions,
  sadleMOCOptions,
  radiographyOptions,
  vesselFlangeTypeOptions,
  insulationThicknessOptions,
} from "../../../utils/DropdownOptions.jsx";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import { BsInfoCircleFill } from "react-icons/bs";
import { Accordion } from "react-bootstrap";

function Screen5({ formik, isPageComplete, handleFlangeThickness }) {
  const [totalCount, setTotalCount] = useState(4);
  const [actualCount, setActualCount] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [materialCode, setMaterialCode] = useState([]);
  const [materialCodeMapping, setMaterialCodeMapping] = useState([]);
  const [vesselMaterialCode, setVesselMaterialCode] = useState([]);
  const [reducers, setReducers] = useState([]);
  const [loadingMaterials, setLoadingMaterials] = useState(true);

  const handleInputValue = (value, count) => {
    if (value === "" || value === undefined) {
      return count > 0 ? count - 1 : count;
    } else {
      return count + 1;
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await axiosPrivate.get("/material-grade");
      if (response.status === 200) {
        const data = response?.data?.data;
        if (data.length > 0) {
          const materialCodeMapping = data.reduce((acc, item) => {
            if (!acc[item.materials]) {
              acc[item.materials] = new Set();
            }
            acc[item.materials].add(item.materials_code);
            return acc;
          }, {});
          const uniqueMaterials = Array.from(
            new Set(data.map((item) => item.materials))
          );
          if (uniqueMaterials.length > 0) {
            const materialsOptions = uniqueMaterials.map((material) => ({
              value: material.toUpperCase(),
              label: material.toUpperCase(),
            }));
            setMaterials(materialsOptions);
            setMaterialCodeMapping(materialCodeMapping);
          }
        } else {
          setMaterials([]);
          setMaterialCodeMapping([]);
        }
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setLoadingMaterials(false);
    }
  };

  // Fetch vessel material codes and reducers based on form values
  const fetchVesselMaterialCodeAndReducers = async () => {
    try {
      const data = {
        particulars: formik?.values?.vesselMaterial,
      };

      const response = await axiosPrivate.post(
        "/get/material-designations",
        data
      );
      if (response.status === 200) {
        const responseData = response.data.data;
        setVesselMaterialCode(() => {
          return responseData
            ?.filter((item) => item?.forgings !== "" && item?.forgings !== "-")
            ?.map((item) => {
              return { value: item?.forgings, label: item?.forgings };
            });
        });

        setReducers(() => {
          return responseData
            ?.filter((item) => item?.reducers !== "" && item?.reducers !== "-")
            ?.map((item) => {
              return { value: item?.reducers, label: item?.reducers };
            });
        });
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  // Handle material selection change
  const handleMaterialSelect = (selectedMaterial) => {
    if (!selectedMaterial) {
      setMaterialCode([]);
      return;
    }
    const materialCodes = Array.from(
      materialCodeMapping[selectedMaterial.value] || []
    ).map((materialCode) => ({
      value: materialCode,
      label: materialCode,
    }));
    setMaterialCode(materialCodes);
  };

  // Effect to fetch materials when the component mounts
  useEffect(() => {
    fetchMaterials();
  }, []);

  // Effect to calculate actual count
  useEffect(() => {
    if (formik.values.vesselSelection === "yes") {
      let count = 0;
      count = handleInputValue(formik?.values?.vesselFlangeType, count);
      count = handleInputValue(formik?.values?.vesselMaterial, count);
      count = handleInputValue(formik?.values?.vesselMaterialCode, count);
      count = handleInputValue(formik?.values?.vesselpipematerial, count);
      setActualCount(count);
    } else {
      setActualCount(0); // Reset count when vesselSelection is "no"
    }
  }, [
    formik?.values?.vesselSelection,
    formik?.values?.vesselFlangeType,
    formik?.values?.vesselMaterialCode,
    formik?.values?.vesselpipematerial,
  ]);

  // Effect to fetch vessel material codes and reducers when conditions are met
  useEffect(() => {
    if (formik.values?.vesselSelection === "yes" && !loadingMaterials) {
      if (formik.values?.vesselMaterial) {
        fetchVesselMaterialCodeAndReducers();
      }
    }
  }, [formik?.values?.vesselMaterial, loadingMaterials]);

  useEffect(() => {
    if (formik.values.vesselSelection === "yes") {
      isPageComplete(5, totalCount, actualCount);
    } else {
      isPageComplete(5, 3, 3);
    }
  }, [actualCount, totalCount, formik.values.vesselSelection]);

  useEffect(() => {
    formik.setFieldValue("vesselFlangePipeSize", formik.values.flangeSize);
    formik.setFieldValue("FlangeRating", formik.values.FlangeClass);
  }, []);

  const vesselpipematerial = (() => {
    if (!formik?.values?.vesselMaterial) {
      return [{ value: "N/A", label: "N/A" }];
    }

    const materials = [];

    if (formik.values.vesselMaterial === "CS + LTCS") {
      materials.push(
        { value: "ASTM A106-GrB", label: "ASTM A106-GrB" },
        { value: "ASTM A366-Gr11 Cl2", label: "ASTM A366-Gr11 Cl2" }
      );
    }

    if (formik.values.vesselMaterial === "SS") {
      materials.push(
        { value: "ASTM A312-304", label: "ASTM A312-304" },
        { value: "ASTM A312-304L", label: "ASTM A312-304L" },
        { value: "ASTM A312-316", label: "ASTM A312-316" },
        { value: "ASTM A312-316L", label: "ASTM A312-316L" },
        { value: "ASTM A312-321", label: "ASTM A312-321" },
        { value: "ASTM A312-321H", label: "ASTM A312-321H" },
        { value: "ASTM A312-310", label: "ASTM A312-310" }
      );
    }

    if (formik.values.vesselMaterial === "ALLOY") {
      materials.push({ value: "ASME SB-407", label: "ASME SB-407" });
    }

    return materials;
  })();

  const getSelectedOption = () => {
    return formik.values.radiography || null;
  };

  const handleSelectChange = (selectedOption) => {
    formik.setFieldValue("radiography", selectedOption);
  };

  return (
    <div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        {formik?.values?.multistackvessel === 1 ? (
          <div
            className="w-100 px-4 row justify-content-center mb-30"
            style={{ gap: "20px" }}
          >
            <label htmlFor="" style={{ fontSize: "20px", fontWeight: "bold" }}>
              Vessel Selection
            </label>
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <input
                type="radio"
                id="vesselYes"
                name="vesselSelection"
                checked={formik.values.vesselSelection === "yes"}
                onChange={() => {
                  formik.setFieldValue("vesselSelection", "yes");
                  handleFlangeThickness();
                }}
                value={formik.values.vesselSelection}
              />
              <label htmlFor="vesselYes">Yes</label>
              <input
                type="radio"
                id="vesselNo"
                name="vesselSelection"
                checked={formik.values.vesselSelection === "no"}
                onChange={() => {
                  formik.setFieldValue("vesselSelection", "no");
                  formik.setFieldValue("maxactivecoldlength", 200);
                }}
                value={formik.values.vesselSelection}
              />
              <label htmlFor="vesselNo">No</label>
            </div>
          </div>
        ) : null}

        {formik.values.vesselSelection === "yes" && (
          <>
            <div className="d-flex w-100 row">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="" className="tooltip">
                  Vessel Flange Size <BsInfoCircleFill className="info" />
                  <span className="tooltip-text">
                    Flange Selection: Flange Size
                  </span>
                </label>
                <Select
                  isClearable
                  styles={customStylesForDisabled}
                  name="flangeSize"
                  id="flangeSize"
                  value={
                    formik?.values?.flangeSize
                      ? {
                        value: formik?.values?.flangeSize,
                        label: formik?.values?.flangeSize,
                      }
                      : null
                  }
                  onChange={(option) =>
                    formik.setFieldValue(
                      "flangeSize",
                      option ? option.value : ""
                    )
                  }
                  isDisabled
                  placeholder="Select from Flange Selection"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="MaterialClass" className="tooltip">
                  Vessel Flange Rating <BsInfoCircleFill className="info" />
                  <span className="tooltip-text">
                    Flange Selection: Flange Class
                  </span>
                </label>
                <Select
                  isClearable
                  styles={customStylesForDisabled}
                  name="FlangeRating"
                  id="FlangeRating"
                  options={flangeRatingOptions}
                  isDisabled
                  placeholder="Select Flange Rating"
                  value={
                    formik?.values?.FlangeRating
                      ? {
                        value: formik?.values?.FlangeRating,
                        label: formik?.values?.FlangeRating,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselFlangeType">Vessel Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  name="vesselFlangeType"
                  id="vesselFlangeType"
                  options={vesselFlangeTypeOptions}
                  placeholder="Range Type"
                  value={
                    formik?.values?.vesselFlangeType
                      ? vesselFlangeTypeOptions.find(
                        (option) =>
                          option.value === formik.values.vesselFlangeType
                      )
                      : null
                  }
                  onChange={(option) => {
                    formik?.setFieldValue(
                      "vesselFlangeType",
                      option ? option.value : ""
                    );
                    formik?.setFieldValue(
                      "nozzle[0].flangeType",
                      option ? option.value : ""
                    );
                    formik?.setFieldValue(
                      "nozzle[1].flangeType",
                      option ? option.value : ""
                    );
                    formik?.setFieldValue(
                      "nozzle[2].flangeType",
                      option ? option.value : ""
                    );
                    formik?.setFieldValue(
                      "nozzle[3].flangeType",
                      option ? option.value : ""
                    );
                    formik?.setFieldValue(
                      "nozzle[4].flangeType",
                      option ? option.value : ""
                    );
                    formik.values.multiStackVesselFormData.forEach(
                      (item, index) => {
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[0].flangeType`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[1].flangeType`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[2].flangeType`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[3].flangeType`,
                          option ? option.value : ""
                        );
                      }
                    );
                    handleFlangeThickness();
                  }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="sadlemoc">Sadle Moc</label>
                <Select
                  isClearable
                  styles={customStyles}
                  name="sadlemoc"
                  id="sadlemoc"
                  options={sadleMOCOptions}
                  placeholder="Select Sadle Moc"
                  value={
                    formik.values.sadlemoc
                      ? {
                        value: formik.values.sadlemoc,
                        label: formik.values.sadlemoc,
                      }
                      : null
                  }
                  onChange={(option) =>
                    formik.setFieldValue("sadlemoc", option ? option.value : "")
                  }
                />
              </div>
            </div>
            <div className="d-flex w-100 row">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselMaterial"> Vessel Material </label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="vesselMaterial"
                  name="vesselMaterial"
                  placeholder=" Vessel Material"
                  options={materials}
                  value={
                    formik?.values?.vesselMaterial
                      ? {
                        value: formik?.values?.vesselMaterial,
                        label: formik?.values?.vesselMaterial,
                      }
                      : null
                  }
                  onChange={(option) => {
                    if (option ? option.value : "") {
                      formik.setFieldValue(
                        "vesselMaterial",
                        option ? option.value : ""
                      );
                      formik.setFieldValue(
                        "nozzle[0].material",
                        option ? option.value : ""
                      );
                      formik.setFieldValue(
                        "nozzle[1].material",
                        option ? option.value : ""
                      );
                      formik.setFieldValue(
                        "nozzle[2].material",
                        option ? option.value : ""
                      );
                      formik.setFieldValue(
                        "nozzle[3].material",
                        option ? option.value : ""
                      );
                      formik.setFieldValue(
                        "nozzle[4].material",
                        option ? option.value : ""
                      );
                      formik.values.multiStackVesselFormData.forEach(
                        (item, index) => {
                          formik.setFieldValue(
                            `multiStackVesselFormData[${index}].nozzle[0].material`,
                            option ? option.value : ""
                          );
                          formik.setFieldValue(
                            `multiStackVesselFormData[${index}].nozzle[1].material`,
                            option ? option.value : ""
                          );
                          formik.setFieldValue(
                            `multiStackVesselFormData[${index}].nozzle[2].material`,
                            option ? option.value : ""
                          );
                          formik.setFieldValue(
                            `multiStackVesselFormData[${index}].nozzle[3].material`,
                            option ? option.value : ""
                          );
                        }
                      );
                      handleMaterialSelect(option);
                    } else {
                      formik.setFieldValue("vesselMaterial", "");
                      formik.setFieldValue("nozzle[0].material", "");
                      formik.setFieldValue("nozzle[1].material", "");
                      formik.setFieldValue("nozzle[2].material", "");
                      formik.setFieldValue("nozzle[3].material", "");
                      formik.setFieldValue("nozzle[4].material", "");
                      handleMaterialSelect(null);
                    }
                    formik.setFieldValue("vesselpipematerial", "");
                    formik.setFieldValue("vesselMaterialCode", "");
                    formik.setFieldValue("reducers", "");
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselpipematerial">Vessel Pipe Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  name="vesselpipematerial"
                  id="vesselpipematerial"
                  options={vesselpipematerial}
                  placeholder="Select Vessel Pipe Material"
                  value={
                    formik?.values?.vesselpipematerial
                      ? {
                        value: formik?.values?.vesselpipematerial,
                        label: formik?.values?.vesselpipematerial,
                      }
                      : null
                  }
                  onChange={(option) => {
                    formik.setFieldValue(
                      "vesselpipematerial",
                      option ? option.value : ""
                    );
                    formik.setFieldValue("vesselMaterialCode", "");
                    formik.setFieldValue("reducers", "");
                  }}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselMaterialCode">
                  Vessel Flange Material
                </label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="vesselMaterialCode"
                  name="vesselMaterialCode"
                  placeholder=" Vessel Flange Material"
                  options={materialCode}
                  value={
                    formik?.values?.vesselMaterialCode
                      ? {
                        value: formik?.values?.vesselMaterialCode,
                        label: formik?.values?.vesselMaterialCode,
                      }
                      : null
                  }
                  onChange={(option) => {
                    formik.setFieldValue(
                      "vesselMaterialCode",
                      option ? option.value : ""
                    );
                    formik.setFieldValue(
                      "nozzle[0].materialCode",
                      option ? option.value : ""
                    );
                    formik.setFieldValue(
                      "nozzle[1].materialCode",
                      option ? option.value : ""
                    );
                    formik.setFieldValue(
                      "nozzle[2].materialCode",
                      option ? option.value : ""
                    );
                    formik.setFieldValue(
                      "nozzle[3].materialCode",
                      option ? option.value : ""
                    );
                    formik.setFieldValue(
                      "nozzle[4].materialCode",
                      option ? option.value : ""
                    );
                    formik.values.multiStackVesselFormData.forEach(
                      (item, index) => {
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[0].materialCode`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[1].materialCode`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[2].materialCode`,
                          option ? option.value : ""
                        );
                        formik.setFieldValue(
                          `multiStackVesselFormData[${index}].nozzle[3].materialCode`,
                          option ? option.value : ""
                        );
                      }
                    );
                    formik.setFieldValue("reducers", "");
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="reducers">
                  Reducers/Dish End/Elbow/Buttwelded End
                </label>
                <Select
                  isClearable
                  styles={customStyles}
                  name="reducers"
                  id="reducers"
                  options={reducers}
                  placeholder="Select"
                  value={
                    formik?.values?.reducers
                      ? {
                        value: formik?.values?.reducers,
                        label: formik?.values?.reducers,
                      }
                      : null
                  }
                  onChange={(option) =>
                    formik?.setFieldValue(
                      "reducers",
                      option ? option.value : ""
                    )
                  }
                />
              </div>
            </div>
            <div className="d-flex w-100 row">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="orientation" className="tooltip">
                  Orientation (Drawing) <BsInfoCircleFill className="info" />
                  <span className="tooltip-text">
                    Technical Calculation: Heater Installation Position
                  </span>
                </label>
                <Select
                  isClearable
                  styles={customStylesForDisabled}
                  name="installationPosition"
                  id="installationPosition"
                  placeholder="Orientation"
                  isDisabled
                  value={
                    formik.values.installationPosition
                      ? {
                        value: formik.values.installationPosition,
                        label: formik.values.installationPosition,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="vesselFlangePipeSize" className="tooltip">
                      Pipe Size &nbsp;
                      <BsInfoCircleFill className="mb-1 info" />
                      <span className="tooltip-text">Vessel Flange Size</span>
                    </label>
                    <input
                      type="text"
                      id="vesselFlangePipeSize"
                      readOnly
                      name="vesselFlangePipeSize"
                      onChange={formik.handleChange}
                      value={formik.values.vesselFlangePipeSize}
                      className="form-control"
                      placeholder="Enter Vessel Flange Pipe Size"
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="Sch" className="tooltip">
                      Schedule <BsInfoCircleFill className="mb-1 info" />
                      <span className="tooltip-text">
                        Flange Selection: Schedule
                      </span>
                    </label>
                    <input
                      type="text"
                      id="Sch"
                      readOnly
                      name="Sch"
                      onChange={formik.handleChange}
                      value={formik?.values?.schedule}
                      className="form-control"
                      placeholder="Enter Schedule"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="KettleSize">Kettle Size</label>
                <input
                  type="text"
                  id="KettleSize"
                  name="KettleSize"
                  onChange={formik.handleChange}
                  value={formik.values.KettleSize}
                  className="form-control"
                  placeholder="Enter Kettle Size"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="radiography">Radiography</label>
                <Select
                  isClearable
                  styles={customStyles}
                  name="radiography"
                  id="radiography"
                  options={radiographyOptions}
                  placeholder="Select Radiography"
                  value={getSelectedOption()}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
            <div className="d-flex w-100 row">
              <Accordion defaultActiveKey="0" className="w-100 mx-2">
                <Accordion.Item eventKey="0" className="mb-3 shadow-md rounded">
                  <Accordion.Header
                    style={{ color: "var(--primary-color)" }}
                    className={`p-3 border border-1 border-bottom shadow-md m-0 d-flex w-100 bg-gray`}
                  >
                    Insulation
                  </Accordion.Header>
                  <Accordion.Body className="py-4 px-3">
                    <div className="d-flex row">
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor="insulation.insulationThickness">
                          Insulation Thickness (mm)
                        </label>
                        <Select
                          isClearable
                          styles={customStyles}
                          type="number"
                          placeholder="Enter Insulation Thickness"
                          name="insulation.insulationThickness"
                          id="insulation.insulationThickness"
                          options={insulationThicknessOptions}
                          value={
                            formik?.values?.insulation?.insulationThickness
                              ? {
                                value:
                                  formik?.values?.insulation
                                    ?.insulationThickness,
                                label:
                                  formik?.values?.insulation
                                    ?.insulationThickness,
                              }
                              : null
                          }
                          onChange={(option) =>
                            formik.setFieldValue(
                              "insulation.insulationThickness",
                              option ? option.value : ""
                            )
                          }
                          onBlur={formik?.handleBlur}
                        />
                        {formik.touched.insulation?.insulationThickness &&
                          formik.errors.insulation?.insulationThickness ? (
                          <div className="error">
                            {formik.errors.insulation?.insulationThickness}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor="insulation.insulationDensity">
                          Insulation Density (Kg/M3)
                        </label>
                        <input
                          type="number"
                          name="insulation.insulationDensity"
                          id="insulation.insulationDensity"
                          className="form-control"
                          value={formik.values.insulation.insulationDensity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Insulation Density"
                        />
                        {formik.touched.insulation?.insulationDensity &&
                          formik.errors.insulation?.insulationDensity ? (
                          <div className="error">
                            {formik.errors.insulation?.insulationDensity}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor="insulation.claddingSheetThickness">
                          Cladding Sheet Thickness
                        </label>
                        <input
                          type="number"
                          name="insulation.claddingSheetThickness"
                          id="insulation.claddingSheetThickness"
                          className="form-control"
                          value={
                            formik.values.insulation.claddingSheetThickness
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Cladding Sheet Thickness"
                        />
                        {formik.touched.insulation?.claddingSheetThickness &&
                          formik.errors.insulation?.claddingSheetThickness ? (
                          <div className="error">
                            {formik.errors.insulation?.claddingSheetThickness}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor="insulation.claddingSheetDensity">
                          Cladding Sheet Density
                        </label>
                        <input
                          type="number"
                          name="insulation.claddingSheetDensity"
                          id="insulation.claddingSheetDensity"
                          className="form-control"
                          value={formik.values.insulation.claddingSheetDensity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Cladding Sheet Density"
                        />
                        {formik.touched.insulation?.claddingSheetDensity &&
                          formik.errors.insulation?.claddingSheetDensity ? (
                          <div className="error">
                            {formik.errors.insulation?.claddingSheetDensity}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex row">
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor="insulation.wastage">Wastage (%)</label>
                        <input
                          type="number"
                          name="insulation.wastage"
                          id="insulation.wastage"
                          className="form-control"
                          value={formik.values.insulation.wastage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Wastage"
                          min="0"
                          max="100"
                        />
                        {formik.touched.insulation?.wastage &&
                          formik.errors.insulation?.wastage ? (
                          <div className="error">
                            {formik.errors.insulation?.wastage}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </>
        )}
      </div>
      <hr />
    </div>
  );
}

export default Screen5;
