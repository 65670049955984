export const sensorsubtypeoptions = [
  { label: "RTD", value: "RTD" },
  { label: "Thermostat", value: "Thermostat" },
  { label: "K-type Thermocouple", value: "K-type Thermocouple" },
  { label: "J-type Thermocouple", value: "J-type Thermocouple" },
];

export const sensorRangeOptions = [
  { label: "Simplex", value: "Simplex" },
  { label: "Duplex", value: "Duplex" },
];

export const sensorMaterialOptions = [
  { value: "SS304", label: "SS304" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  { value: "INCOLOY800", label: "INCOLOY800" },
  { value: "INCOLOY825", label: "INCOLOY825" },
  { value: "INCOLOY840", label: "INCOLOY840" },
];

export const headMaterialOptions = [
  { label: "SS304", value: "SS304" },
  { label: "SS316", value: "SS316" },
  { label: "SS316L", value: "SS316L" },
  {
    label: "Aluminium Die Casted (Epoxy Coated)",
    value: "Aluminium Die Casted (Epoxy Coated)",
  },
];

export const materialOptions = [
  { value: "CS", label: "CS" },
  { value: "SS304", label: "SS304" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  { value: "SS321H", label: "SS321H" },
  { value: "INCOLOY800", label: "INCOLOY800" },
  { value: "GR11", label: "GR11" },
  { value: "MS", label: "MS" },
];

export const baffleMaterialOptions = [
  { value: "CS", label: "CS" },
  { value: "SS304", label: "SS304" },
  { value: "SS304L", label: "SS304L" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  { value: "SS321H", label: "SS321H" },
  { value: "INCOLOY 800", label: "INCOLOY 800" },
  { value: "GR11", label: "GR11" },
  { value: "MS", label: "MS" },
];

export const thermowellMaterialOptions = [
  { value: "GR11", label: "GR11" },
  { value: "SS304", label: "SS304" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  { value: "SS321H", label: "SS321H" },
  { value: "INCOLOY800", label: "INCOLOY800" },
  { value: "CS", label: "CS" },
];

export const tempTransmitterOptions = [
  {
    label: "DIN rail Mountain",
    value: "DIN rail Mountain",
  },
  { label: "Head Mountain", value: "Head Mountain" },
];

export const sizeOptions = [
  { value: 1, label: "1" },
  { value: 1.5, label: "1-1/2" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2-1/2" },
  { value: 3, label: "3" },
  { value: 3.5, label: "3-1/2" },
  { value: 4, label: "4" },
  { value: 6, label: "6" },
  { value: 8, label: "8" },
  { value: 10, label: "10" },
  { value: 12, label: "12" },
  { value: 14, label: "14" },
  { value: 16, label: "16" },
  { value: 18, label: "18" },
  { value: 20, label: "20" },
  { value: 24, label: "24" },
  { value: 26, label: "26" },
  { value: 28, label: "28" },
  { value: 30, label: "30" },
  { value: 32, label: "32" },
  { value: 34, label: "34" },
  { value: 36, label: "36" },
  { value: 38, label: "38" },
  { value: 40, label: "40" },
  { value: 42, label: "42" },
  { value: 48, label: "48" },
];

export const flangeTypeOptions = [
  { value: "NA", label: "NA" },
  { value: "BLRF", label: "BLRF" },
  { value: "SORF", label: "SORF" },
  { value: "WNRF", label: "WNRF" },
  { value: "RTJF", label: "RTJF" },
  { value: "Lap Joint", label: "Lap Joint" },
  { value: "Threaded", label: "Threaded" },
  { value: "Socket Weld", label: "Socket Weld" },
];

export const flangeRatingOptions = [
  { value: 150, label: "150" },
  { value: 300, label: "300" },
  { value: 450, label: "450" },
  { value: 600, label: "600" },
  { value: 900, label: "900" },
  { value: 1200, label: "1200" },
  { value: 1500, label: "1500" },
  { value: 2500, label: "2500" },
];

export const ipRatingOptions = [
  { value: "IP-42", label: "IP-42" },
  { value: "IP-54", label: "IP-54" },
  { value: "IP-55", label: "IP-55" },
  { value: "IP-65", label: "IP-65" },
  { value: "IP-66", label: "IP-66" },
  { value: "IP-68", label: "IP-68" },
];

export const controlPanelSupplyVoltageOptions = [
  { value: "240 VAC", label: "240 VAC" },
  { value: "220 VDC", label: "220 VDC" },
  { value: "230 VAC", label: "230 VAC" },
  { value: "125 VDC", label: "125 VDC" },
  { value: "110 VAC", label: "110 VAC" },
  { value: "110 VDC", label: "110 VDC" },
  { value: "24 VDC", label: "24 VDC" },
];

export const controlPanelBreakingCapaciy = [
  { value: "25kA For 1 sec", label: "25kA For 1 sec" },
  { value: "36kA For 1 sec", label: "36kA For 1 sec" },
  { value: "50kA For 1 sec", label: "50kA For 1 sec" },
  { value: "65kA For 1 sec", label: "65kA For 1 sec" },
];

export const typeOfControllingOptions = [
  { value: "Contactor", label: "Contactor" },
  { value: "Thyristor", label: "Thyristor" },
  { value: "SSR Controller", label: "SSR Controller" },
  {
    value: "Thyristor + Contactor",
    label: "Thyristor + Contactor",
  },
];

export const controlPanelCableEntry = [
  { value: "Top", label: "Top" },
  { value: "Bottom", label: "Bottom" },
];

export const controlPanelBusBarMaterial = [
  { value: "Copper", label: "Copper" },
  { value: "Aluminium", label: "Aluminium" },
];

export const controlPanelPackingType = [
  { value: "Vacuum", label: "Vacuum" },
  { value: "Wooden", label: "Wooden" },
  { value: "Sea Worthy", label: "Sea Worthy" },
];

export const enclosureMake = [
  { value: "Rittal", label: "Rittal" },
  { value: "ABB", label: "ABB" },
  { value: "Pyrotech", label: "Pyrotech" },
  { value: "Reputed", label: "Reputed" },
];

export const exclosureCompartment = [
  { value: "Compartment", label: "Compartment" },
  { value: "Non Compartment", label: "Non Compartment" },
];

export const exclosureSheathThickness = [
  { value: "1.6mm", label: "1.6mm" },
  { value: "2mm", label: "2mm" },
];

export const controlPanelSILValues = [
  { value: "SIL 2", label: "SIL 2" },
  { value: "SIL 3", label: "SIL 3" },
];

export const specialTypeProtectionRelaay = [
  { value: "N/A", label: "N/A" },
  { value: "Numeric Relay", label: "Numeric Relay" },
  { value: "Master Trip Relay", label: "Master Trip Relay" },
  { value: "TCS Relay", label: "TCS Relay" },
  { value: "Synchronous Relay", label: "Synchronous Relay" },
  { value: "ARC Fault Protection Relay", label: "ARC Fault Protection Relay" },
  { value: "Transformer Protection Relay", label: "Transformer Protection Relay" },
];

export const modeOfCommunicationOptions = [
  { value: "Hardware", label: "Hardware" },
  { value: "Ethernet", label: "Ethernet" },
  { value: "MODEBUS RS485 RTU", label: "MODEBUS RS485 RTU" },
  { value: "MODEBUS RS485 TCP", label: "MODEBUS RS485 TCP" },
];

export const costTypeOptions = [
  { label: "Actual", value: true },
  { label: "Costing", value: false },
];

export const miscellaneousNameOptions = [
  { value: "Pressure Transmitter", label: "Pressure Transmitter" },
  { value: "OTHER INSTRUMENTS CHARGES", label: "OTHER INSTRUMENTS CHARGES" },
  { value: "JUNCTION BOX CHARGES", label: "JUNCTION BOX CHARGES" },
  { value: "LOCAL PUSH BUTTON CHARGES", label: "LOCAL PUSH BUTTON CHARGES" },
  { value: "DESIGN CALCULATION CHARGES", label: "DESIGN CALCULATION CHARGES" },
  { value: "INSPECTION CHARGES", label: "INSPECTION CHARGES" },
  {
    value: "ERECTION & CCOMMISSIONING CHARGES",
    label: "ERECTION & CCOMMISSIONING CHARGES",
  },
  { value: "SPARES FOR  HEATER", label: "SPARES FOR  HEATER" },
  { value: "SPARES FOR  CONTROL PANEL", label: "SPARES FOR  CONTROL PANEL" },
  { value: "PACKING (EXPORT / LOCAL)", label: "PACKING (EXPORT / LOCAL)" },
  { value: "OTHER MISC. CHARGES", label: "OTHER MISC. CHARGES" },
];

export const sadleMOCOptions = [
  { value: "CS", label: "CS" },
  { value: "SS304", label: "SS304" },
  { value: "SS304L", label: "SS304L" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  { value: "INCOLOY800", label: "INCOLOY800" },
  { value: "INCOLOY825", label: "INCOLOY825" },
  { value: "INCOLOY840", label: "INCOLOY840" },
];

export const radiographyOptions = [
  { value: 100, label: "Full 100%" },
  { value: 10, label: "Spot 10%" },
  { value: 0, label: "None" },
];

export const vesselFlangeTypeOptions = [
  { value: "sorf", label: "SORF" },
  { value: "wnrf", label: "WNRF" },
  { value: "rtjf", label: "RTJF" },
  { value: "lap_joint", label: "Lap Joint" },
  { value: "threaded", label: "Threaded" },
];

export const gasketMaterialOptions = [
  { value: "NEOPRENE", label: "NEOPRENE" },
  {
    value: "CS SPIRAL WOUND GRAPHITE FILLED",
    label: "CS SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "SS304 SPIRAL WOUND GRAPHITE FILLED",
    label: "SS304 SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "SS304L SPIRAL WOUND GRAPHITE FILLED",
    label: "SS304L SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "SS316 SPIRAL WOUND GRAPHITE FILLED",
    label: "SS316 SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "SS316L SPIRAL WOUND GRAPHITE FILLED",
    label: "SS316L SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "SS321 SPIRAL WOUND GRAPHITE FILLED",
    label: "SS321 SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "INCOLOY800 SPIRAL WOUND GRAPHITE FILLED",
    label: "INCOLOY800 SPIRAL WOUND GRAPHITE FILLED",
  },
  {
    value: "INCONEL600 SPIRAL WOUND GRAPHITE FILLED",
    label: "INCONEL600 SPIRAL WOUND GRAPHITE FILLED",
  },
];

export const terminalBoxSize = [
  { value: "MHT_Ex'd_100", label: "MHT_Ex'd_100" },
  { value: "MHT_Ex'd_125", label: "MHT_Ex'd_125" },
  { value: "MHT_Ex'd_150", label: "MHT_Ex'd_150" },
  { value: "MHT_Ex'd_200", label: "MHT_Ex'd_200" },
  { value: "MHT_Ex'd_250", label: "MHT_Ex'd_250" },
  { value: "MHT_Ex'd_300", label: "MHT_Ex'd_300" },
  { value: "MHT_Ex'd_350", label: "MHT_Ex'd_350" },
  { value: "MHT_Ex'd_400", label: "MHT_Ex'd_400" },
  { value: "MHT_Ex'd_450", label: "MHT_Ex'd_450" },
  { value: "MHT_Ex'd_500", label: "MHT_Ex'd_500" },
  { value: "MHT_Ex'd_550", label: "MHT_Ex'd_550" },
  { value: "MHT_Ex'd_600", label: "MHT_Ex'd_600" },
  { value: "MHT_Ex'd_650", label: "MHT_Ex'd_650" },
];

export const yesNoOption = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const flangeSelection = [
  { value: "BLRF", label: "BLRF" },
  { value: "BLFF", label: "BLFF" },
  { value: "BLRTJ", label: "BLRTJ" },
];

export const pipeTypeOptions = [
  { value: "SEAMLESS", label: "SEAMLESS" },
  { value: "WELDED", label: "WELDED" },
];

export const heaterSheathTubeDiaOptions = [
  { value: 8, label: "8" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 12.5, label: "12.5" },
  { value: 12.7, label: "12.7" },
  { value: 13.5, label: "13.5" },
  { value: 16, label: "16" },
];

export const sheathTubeThicknessOptions = [
  { value: 0.5, label: "0.5" },
  { value: 0.6, label: "0.6" },
  { value: 0.7, label: "0.7" },
  { value: 0.8, label: "0.8" },
  { value: 0.9, label: "0.9" },
  { value: 1, label: "1" },
  { value: 1.1, label: "1.1" },
  { value: 1.2, label: "1.2" },
  { value: 1.25, label: "1.25" },
  { value: 1.5, label: "1.5" },
  { value: 1.6, label: "1.6" },
  { value: 2, label: "2" },
];

export const sheathTubeTypeOptions = [
  { value: "Welded(ERW)", label: "Welded(ERW)" },
  { value: "Seamless", label: "Seamless" },
  { value: "Welded Seamless", label: "Welded Seamless" },
];

export const flangeToTerminalBoxOptions = [
  { value: 0, label: "0" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 125, label: "125" },
  { value: 150, label: "150" },
  { value: 200, label: "200" },
  { value: 250, label: "250" },
  { value: 300, label: "300" },
  { value: 350, label: "350" },
];
export const distances = {
  "T1 (450°C)": 100,
  "T2 (300°C)": 100,
  "T3 (200°C)": 125,
  "T4 (135°C)": 150,
  "T5 (100°C)": 300,
  "T6 (85°C)": 325,
};

export const bafflePlateThicknessOptions = [
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const baffleTypeOptions = [
  { value: "Segmental", label: "Segmental" },
  { value: "Rod Baffle", label: "Rod Baffle" },
  { value: "Strip Baffle", label: "Strip Baffle" },
  { value: "Guide Flow Baffle", label: "Guide Flow Baffle" },
  { value: "Wire Mesh", label: "Wire Mesh" },
];

export const tieRodDiaOptions = [
  { value: 8, label: "8" },
  { value: 10, label: "10" },
  { value: 12, label: "12" },
  { value: 16, label: "16" },
  { value: 20, label: "20" },
];

export const fixingOfHeatingElementOptions = [
  { value: "SLEEVES", label: "SLEEVES" },
  { value: "Direct Welding", label: "Direct Welding" },
  { value: "BUSH", label: "BUSH" },
  { value: "NUT", label: "NUT" },
  { value: "FERRULE", label: "FERRULE" },
  { value: "CASING PIPE", label: "CASING PIPE" },
  { value: "TIG+SLEEVES", label: "TIG+SLEEVES" },
  { value: "DIRECT BRAZ", label: "DIRECT BRAZ" },
  { value: "Seal Weld", label: "Seal Weld" },
  { value: "Strength Weld", label: "Strength Weld" },
];

export const terminalBoxMaterialOptions = [
  { value: "CS", label: "CS" },
  { value: "SS304", label: "SS304" },
  { value: "SS316", label: "SS316" },
  { value: "SS316L", label: "SS316L" },
  { value: "SS321", label: "SS321" },
  // { value: "INCOLOY800", label: "INCOLOY800" },
  // { value: "INCOLOY825", label: "INCOLOY825" },
  // { value: "INCOLOY840", label: "INCOLOY840" },
  { value: "ALUMINIUM", label: "ALUMINIUM" },
];

export const anySpecificationOption = [
  { value: "U STAMP", label: "U STAMP" },
  { value: "U2 STAMP", label: "U2 STAMP" },
  { value: "CE + PED", label: "CE + PED" },
  { value: "CE (Electric)", label: "CE (Electric)" },
  { value: "DOSH", label: "DOSH" },
  { value: "MIGAS", label: "MIGAS" },
  { value: "GOST", label: "GOST" },
  { value: "CCOE", label: "CCOE" },
  { value: "UL", label: "UL" },
  { value: "CCC", label: "CCC" },
  { value: "SELO", label: "SELO" },
  { value: "NEMA", label: "NEMA" },
  { value: "CSA", label: "CSA" },
  { value: "BIS", label: "BIS" },
  { value: "IBR", label: "IBR" },
  { value: "Not Applicable", label: "Not Applicable" },
];

export const tempCodeOptions = [
  { label: "T1 (450°C)", value: "T1 (450°C)" },
  { label: "T2 (300°C)", value: "T2 (300°C)" },
  { label: "T3 (200°C)", value: "T3 (200°C)" },
  { label: "T4 (135°C)", value: "T4 (135°C)" },
  { label: "T5 (100°C)", value: "T5 (100°C)" },
  { label: "T6 (85°C)", value: "T6 (85°C)" },
];

export const gasGroupOptions = [
  { label: "II A", value: "II A" },
  { label: "II B", value: "II B" },
  { label: "II C", value: "II C" },
  { label: "II A/B", value: "II A/B" },
];

export const certificationOptions = [
  { label: "ATEX Ex'd'", value: "ATEX Ex'd'" },
  { label: "IEC Ex'd'", value: "IEC Ex'd'" },
  { label: "PESO", value: "PESO" },
  { label: "ECAS", value: "ECAS" },
  { label: "TRCU", value: "TRCU" },
];

export const zoneOptions = [
  { label: "Zone 1", value: "Zone 1" },
  { label: "Zone 2", value: "Zone 2" },
];

export const ijbOptions = [
  { label: "Ex'd", value: "Ex'd" },
  { label: "Ex'e", value: "Ex'e" },
];

export const noOfBankOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const frequency = [
  { label: "50 Hz", value: "50 Hz" },
  { label: "60 Hz", value: "60 Hz" },
];

export const connectionType = [
  { label: "Single Phase", value: "Single Phase" },
  {
    label: "Delta Connection",
    value: "Delta Connection",
  },
  { label: "Star Connection", value: "Star Connection" },
];

export const multiStackVesselOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const installationPositionOptions = [
  { label: "Horizontal", value: "Horizontal" },
  { label: "Vertical", value: "Vertical" },
];

export const standardOptions = [
  {
    value: "ASME Sec. VIII Div. 1 Latest ed",
    label: "ASME Sec. VIII Div. 1 Latest ed",
  },
  {
    value: "ASME Sec. VIII Div. 2 Latest ed",
    label: "ASME Sec. VIII Div. 2 Latest ed",
  },
];

export const areaClassificationOptions = [
  { value: "Safe Area", label: "Safe Area" },
  { value: "Hazardous Area", label: "Hazardous Area" },
];

export const thyristorLegOptions = [
  { value: "1 Leg", label: "1 Leg" },
  { value: "2 Leg", label: "2 Leg" },
  { value: "3 Leg", label: "3 Leg" },
];
export const orientationOptions = [
  { value: "0", label: "0" },
  { value: 90, label: "90" },
  { value: 180, label: "180" },
  { value: 270, label: "270" },
];

export const elevationOptions = [
  { value: 150, label: "150" },
  { value: 200, label: "200" },
  { value: 250, label: "250" },
  { value: 300, label: "300" },
];

export const insulationThicknessOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 75, label: "75" },
  { value: 100, label: "100" },
  { value: 150, label: "150" },
  { value: 200, label: "200" },
];


// Price Master Dropdown Fields
export const PriceCategoryOptions = [
  { value: "FLANGE", label: "FLANGE" },
  { value: "PIPE", label: "PIPE" },
  { value: "PLATE", label: "PLATE" },
  { value: "JUNCTIONBOX", label: "JUNCTIONBOX" },
  { value: "TUBE", label: "TUBE" },
  { value: "FIXINGOFHEATINGELEMENT", label: "FIXINGOFHEATINGELEMENT" },
];

export const priceUnitOptions = [
  { value: "KG", label: "KG" },
  { value: "MTR", label: "MTR" },
  { value: "PCS", label: "PCS" },
  { value: "SET", label: "SET" },
  { value: "NO", label: "NO" },
  { value: "MTR2", label: "MTR2" },
];

export const sensors = [
  {
    label: "Skin Sensor",
    options: [{ label: "Skin Sensor", value: "Skin Sensor" }],
  },
  {
    label: "Tube Sheet Sensor",
    options: [{ label: "Tube Sheet Sensor", value: "Tube Sheet Sensor" }],
  },
  {
    label: "Process Sensor",
    options: [{ label: "Process Sensor", value: "Process Sensor" }],
  },
];

export default {
  sensorsubtypeoptions: [...sensorsubtypeoptions],
  sensorRangeOptions: [...sensorRangeOptions],
  sensorMaterialOptions: [...sensorMaterialOptions],
  headMaterialOptions: [...headMaterialOptions],
  materialOptions: [...materialOptions],
  thermowellMaterialOptions: [...thermowellMaterialOptions],
  tempTransmitterOptions: [...tempTransmitterOptions],
  sizeOptions: [...sizeOptions],
  flangeTypeOptions: [...flangeTypeOptions],
  flangeRatingOptions: [...flangeRatingOptions],
  ipRatingOptions: [...ipRatingOptions],
  controlPanelSupplyVoltageOptions: [...controlPanelSupplyVoltageOptions],
  controlPanelBreakingCapaciy: [...controlPanelBreakingCapaciy],
  typeOfControllingOptions: [...typeOfControllingOptions],
  controlPanelCableEntry: [...controlPanelCableEntry],
  controlPanelBusBarMaterial: [...controlPanelBusBarMaterial],
  controlPanelPackingType: [...controlPanelPackingType],
  enclosureMake: [...enclosureMake],
  exclosureCompartment: [...exclosureCompartment],
  exclosureSheathThickness: [...exclosureSheathThickness],
  controlPanelSILValues: [...controlPanelSILValues],
  costTypeOptions: [...costTypeOptions],
  sadleMOCOptions: [...sadleMOCOptions],
  radiographyOptions: [...radiographyOptions],
  vesselFlangeTypeOptions: [...vesselFlangeTypeOptions],
  gasketMaterialOptions: [...gasketMaterialOptions],
  terminalBoxSize: [...terminalBoxSize],
  yesNoOption: [...yesNoOption],
  flangeSelection: [...flangeSelection],
  heaterSheathTubeDiaOptions: [...heaterSheathTubeDiaOptions],
  sheathTubeThicknessOptions: [...sheathTubeThicknessOptions],
  sheathTubeTypeOptions: [...sheathTubeTypeOptions],
  flangeToTerminalBoxOptions: [...flangeToTerminalBoxOptions],
  bafflePlateThicknessOptions: [...bafflePlateThicknessOptions],
  baffleTypeOptions: [...baffleTypeOptions],
  tieRodDiaOptions: [...tieRodDiaOptions],
  fixingOfHeatingElementOptions: [...fixingOfHeatingElementOptions],
  terminalBoxMaterialOptions: [...terminalBoxMaterialOptions],
  anySpecificationOption: [...anySpecificationOption],
  tempCodeOptions: [...tempCodeOptions],
  gasGroupOptions: [...gasGroupOptions],
  certificationOptions: [...certificationOptions],
  zoneOptions: [...zoneOptions],
  ijbOptions: [...ijbOptions],
  noOfBankOptions: [...noOfBankOptions],
  frequency: [...frequency],
  connectionType: [...connectionType],
  multiStackVesselOptions: [...multiStackVesselOptions],
  installationPositionOptions: [...installationPositionOptions],
  standardOptions: [...standardOptions],
  areaClassificationOptions: [...areaClassificationOptions],
  thyristorLegOptions: [...thyristorLegOptions],
  elevationOptions: [...elevationOptions],
  orientationOptions: [...orientationOptions],
  miscellaneousNameOptions: [...miscellaneousNameOptions],
  // Prices Master Dropdown Exports
  PriceCategoryOptions: [...PriceCategoryOptions],
  priceUnitOptions: [...priceUnitOptions],
  sensors: [...sensors],
};
//Orientation options for nozzle
