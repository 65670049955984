import React from "react";
import styles from "./MaintenancePage.module.css"; // Import CSS file for custom styling
import MaintenanceIcon from "../../assets/images/maintenance.png";

const UnderMaintenance = () => {
  return (
    <div className={styles.maintenance}>
      <article className={styles.article}>
        <img src={MaintenanceIcon} alt="Maintenance Icon" />
        <h1 className={styles.heading}>We'll be back soon!</h1>
        <div>
          <p className={styles.text}>
            Sorry for the inconvenience. We're performing some maintenance at
            the moment.We'll be back up shortly!
          </p>
          <p>Tempsens Pvt. Ltd.</p>
        </div>
      </article>
    </div>
  );
};

export default UnderMaintenance;
