import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { TiShoppingCart } from "react-icons/ti";
import { GoWorkflow, GoOrganization } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { usePermissions } from "../context/permissions/PermissionsProvider";
import { LuLayoutTemplate } from "react-icons/lu";
import { PiUserList } from "react-icons/pi";
import { PiFactory } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { MdPinch } from "react-icons/md";
import { GrDocumentConfig } from "react-icons/gr";
import { SiMaterialdesignicons } from "react-icons/si";
import { FaIndianRupeeSign } from "react-icons/fa6";


import {
  CalculateOutlined,
  CalendarViewWeekRounded,
  WifiProtectedSetupSharp,
} from "@mui/icons-material";

const SideBar = () => {
  // State to track whether the Masters list is open or closed
  const [isMastersOpen, setIsMastersOpen] = useState(false);
  const { authPermissions } = usePermissions(); // Get permissions from context
  const [activeTab, setActiveTab] = useState("/customer");
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  // Function to toggle the Masters list
  const toggleMasters = () => {
    setIsMastersOpen((prev) => !prev);
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };



  return (
      <div id="header_top" className="header_top false">
        <div className="container">
          <div className="hleft" style={{ overflow: "scroll" }}>
            <div>
              {/* Dashboard Link */}
              <ul>
                <li className={activeTab === "/" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2${
                      activeTab === "/" ? "active " : ""
                    }`}
                    to="/"
                    onClick={() => handleTabClick("/")}
                  >
                    <RxDashboard size={20} />
                    <span className="sidebar_text">Dashboard</span>
                  </Link>
                </li>

                <li className={activeTab === "/customer" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2${
                      activeTab === "/customer" ? "active " : ""
                    }`}
                    to="/customer"
                    onClick={() => handleTabClick("/customer")}
                  >
                    <CalculateOutlined size={20} />
                    <span className="sidebar_text">Calculator</span>
                  </Link>
                </li>
              </ul>

              {/* Masters List */}
              {Number(userData?.role_id) !== 2 ? (
                <ul className={`dropdown ${isMastersOpen ? "show" : ""}`}>
                  <li>
                    <span onClick={toggleMasters}>
                      <div
                        aria-current="page"
                        className="list-a nav-link icon app_inbox d-flex flex-column"
                        style={{ color: "white" }}
                      >
                        <GrDocumentConfig size={20} />
                        <span className="sidebar_text">Masters</span>
                        <i
                          className={`caret-left ${
                            isMastersOpen ? "active" : ""
                          }`}
                        ></i>
                      </div>
                    </span>
                    {/* Nested Masters List */}
                    <ul className={`collapse ${isMastersOpen ? "show" : ""}`}>
                      {authPermissions?.includes("Roles-List") ? (
                        <li
                          className={activeTab === "/roles" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/roles" ? "active" : ""
                            }`}
                            to="/roles"
                            onClick={() => handleTabClick("/roles")}
                          >
                            <PiUserList size={20} />
                            <span className="sidebar_text">Roles</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Users-List") ? (
                        <li
                          className={activeTab === "/users" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/users" ? "active" : ""
                            }`}
                            to="/users"
                            onClick={() => handleTabClick("/users")}
                          >
                            <PiUsersThree size={20} />
                            <span className="sidebar_text">Users</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Customers-List") ? (
                      <li
                        className={
                          activeTab === "/customers" ? "active-box" : ""
                        }
                      >
                        <Link
                          className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                            activeTab === "/customers" ? "active" : ""
                          }`}
                          to="/customers"
                          onClick={() => handleTabClick("/customers")}
                        >
                          <PiUsersThree size={20} />
                          <span className="sidebar_text">Customers</span>
                        </Link>
                      </li>
                    ) : null}
                      {authPermissions?.includes("Units-List") ? (
                        <li
                          className={activeTab === "/units" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/units" ? "active" : ""
                            }`}
                            to="/units"
                            onClick={() => handleTabClick("/units")}
                          >
                            <MdPinch size={20} />
                            <span className="sidebar_text">Units</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Schedules-List") ? (
                        <li
                          className={
                            activeTab === "/schedules" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/schedules" ? "active" : ""
                            }`}
                            to="/schedules"
                            onClick={() => handleTabClick("/schedules")}
                          >
                            <CalendarViewWeekRounded size={20} />
                            <span className="sidebar_text">Schedules</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Process Fluids-List") ? (
                        <li
                          className={
                            activeTab === "/process-fluids" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/process-fluids" ? "active" : ""
                            }`}
                            to="/process-fluids"
                            onClick={() => handleTabClick("/process-fluids")}
                          >
                            <WifiProtectedSetupSharp size={20} />
                            <span className="sidebar_text">Process Fluids</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Sheath Material-List") ? (
                        <li
                          className={
                            activeTab === "/sheathMaterial" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/sheathMaterial" ? "active" : ""
                            }`}
                            to="/sheathMaterial"
                            onClick={() => handleTabClick("/sheathMaterial")}
                          >
                            <SiMaterialdesignicons size={20} />
                            <span className="sidebar_text">Sheath Material</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Prices-List") ? (
                        <li
                          className={
                            activeTab === "/price" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/price" ? "active" : ""
                            }`}
                            to="/price"
                            onClick={() => handleTabClick("/price")}
                          >
                            <FaIndianRupeeSign size={20} />
                            <span className="sidebar_text">Prices</span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
   );
};

export default SideBar;
