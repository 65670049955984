
const bom = {
  groundWeight: {
    material: 0,
    // heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessel: 0,
    dishEnd: 0,

    inletNozzle: [],
    inletFlange: [],

    outletNozzle: [],
    outletFlange: [],

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,

    baffle: [],
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
  },
  netWeight: {
    material: 0,
    // heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessle: 0,
    dishEnd: 0,

    inletNozzle: [],
    inletFlange: [],

    outletNozzle: [],
    outletFlange: [],

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,

    baffle: [],
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
  },
  price: {
    material: 0,
    heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessel: 0,
    dishEnd: 0,

    inletNozzle: [],
    inletFlange: [],

    outletNozzle: [],
    outletFlange: [],

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,
    baffle: [],
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
    cableGland: 0,
    insulationCost: 0,
    radiographyCost: 0,
    paintingCost: 0,
    terminalBoxCost: 0,
    // baffleCost: 0,
    // SAFETYTHERMOCOUPLE

    safetyThermocouple: 0,
    processThermocouple: 0,
    priceOfGaskets: 0,
    priceOfHardware: 0,
    priceOfThermowell: 0,
    priceOfConnections: 0,
  },

  finalCosting: {
    unitMaterialCost: [],
    bundleMaterialCost: [],
    unitCogs: [],
    bundleCogs: [],
    unitSellingPrice: [],
    bundleSellingPrice: [],
    unitQuotedPrice: [],
    bundleQuotedPrice: [],
  },

  unitBundle: {
    COGS: {
      unit: [],
      bundle: [],
    },
    sellingPrice: {
      unit: [],
      bundle: [],
    },
    quotedPrice: {
      unit: [],
      bundle: [],
    },
  },
  indPrice: {
    powderIndPrice: 0,
    tubeIndPrice: 0,
    fabricationPlateIndPrice1: 0,
    fabricationPlateIndPrice2: 0,
    sleevesIndPrice: 0,
    nutIndPrice: 0,
  }
};

export default bom;

// switch (formik.values.fixingofheatingelements) {
//   case "Sleeves":
//     return data.heatingelement * 2 * gweightofheatingelement;
//   case "NUT":
//     return formik?.values?.nutsize * 3;
//   case "BUSH":
//     return formik?.values?.totalnumberofelements * 200;
//   case "Direct":
//     return formik?.values?.totalnumberofelements * 500;
//   case "Tig + Sleeves":
//     return (
//       data.heatingelement * 2 * gweightofheatingelement +
//       formik?.values?.totalnumberofelements * 500
//     );
//   default:
//     return "No Inforcement";
// }

// formik?.values?.fixingofheatingelements.toLowerCase() === "Sleeves"
//   ? data.fixingheatingelementprice * formik?.values?.groundWeight?.material
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "NUT"
//   ? formik?.values?.nutsize * 3
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "BUSH"
//   ? formik?.values?.totalnumberofelements * 200
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Direct"
//   ? formik?.values?.totalnumberofelements * 500
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Tig + Sleeves"
//   ? data.heatingelement * 2 * gweightofheatingelement +
//     formik?.values?.totalnumberofelements * 500
//   : "No Increment";
