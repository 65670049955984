import React, { useEffect, useState } from "react";
import Select from "react-select";
import axiosPrivate from "../../../hooks/axiosPrivate.js";
import { showErrorToast } from "../../../utils/Toaster.js";
import { useLoader } from "../../../context/Loader/LoaderProvider.js";
import CreatableSelect from "react-select/creatable";
import dropdownOptions, {
  elevationOptions,
  orientationOptions,
} from "../../../utils/DropdownOptions.jsx";
import {
  sizeOptions,
  flangeTypeOptions,
  flangeRatingOptions,
  sensorRangeOptions,
  connectionType,
  noOfBankOptions,
  bafflePlateThicknessOptions,
  tieRodDiaOptions,
  baffleTypeOptions,
  heaterSheathTubeDiaOptions,
} from "../../../utils/DropdownOptions.jsx";
import { BsInfoCircleFill } from "react-icons/bs";
import BankBundleTable from "../../datatable/BankBundleTable.jsx";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import NonEditableBankBundleTable from "../../datatable/NonEditableBankBundleTable.jsx";
import { Accordion } from "react-bootstrap";

const MultiStackpage = ({
  formik,
  handleFlangeThickness,
  updateMultiStackVesselFormData,
}) => {
  const { isLoading, setIsLoading } = useLoader();
  const { values, setFieldValue, handleChange, handleBlur } = formik;
  const [units, setUnits] = useState([]);
  const totalBank = parseInt(formik?.values.bank) || 0;
  const bundleHeatLoad =
    parseFloat(formik?.values.installedCapacity) /
    (parseInt(formik?.values.multistackvessel) || "N/A");

  const [sheathMaterialDropdown, setSheathMaterialDropdown] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [materialCode, setMaterialCode] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [nozzles, setNozzles] = useState(() => {
    const savedNozzles = localStorage.getItem("nozzles");
    return savedNozzles
      ? JSON?.parse(savedNozzles)
      : [{ id: 1, name: "Inlet" }];
  });
  const [materialCodeMapping, setMaterialCodeMapping] = useState({});
  const [materials, setMaterials] = useState([]);

  const selectedKettleSize = formik.values.KettleSize;
  const selectedFlangeSize = formik.values.flangeSize;
  const sizeToFilterBy = selectedKettleSize || selectedFlangeSize;
  const filteredSizeOptions = sizeToFilterBy
    ? sizeOptions.filter(
      (option) => parseFloat(option.value) <= parseFloat(sizeToFilterBy)
    )
    : sizeOptions;

  const handleAccordionClick = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  const handleMaterialSelect = (selectedMaterial) => {
    const materialCodes = Array?.from(
      materialCodeMapping[selectedMaterial.value] || []
    ).map((materialCode) => ({
      value: materialCode,
      label: materialCode,
    }));
    setMaterialCode(materialCodes);
  };

  //   const handleChange = (nozzleIndex, field, value, multiStackIndex) => {
  //     const newNozzles = [...formik.values.multiStackVesselFormData[index].nozzle];
  //     newNozzles[nozzleIndex][field] = value;
  //     formik.setFieldValue("nozzle", newNozzles);
  //   };

  const multiStackHandleChange = (
    multiStackIndex,
    nozzleIndex,
    field,
    value
  ) => {
    // Clone the current form data for the specified multiStackIndex
    const updatedMultiStackData = [...formik.values.multiStackVesselFormData];

    // Update the specific nozzle field
    updatedMultiStackData[multiStackIndex].nozzle[nozzleIndex][field] = value;

    // Set the updated form data back to Formik
    formik.setFieldValue(
      "multiStackVesselFormData",
      updatedMultiStackData ? updatedMultiStackData : ""
    );
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNozzles((prevNozzles) => ({
      ...prevNozzles,

      [name]: checked,
    }));
  };

  // const handleimmersionlength = (index) => {
  //   const maxactivehotlength = parseFloat(
  //     formik?.values?.multiStackVesselFormData[index]?.maxactivehotlength
  //   );
  //   const maxactivecoldlength = parseFloat(
  //     formik?.values?.multiStackVesselFormData[index]?.maxactivecoldlength
  //   );

  //   if (isNaN(maxactivehotlength) || isNaN(maxactivecoldlength)) {
  //     formik.setFieldValue("bundleimmersionlength", "");
  //     return;
  //   }

  //   const immersionlength = maxactivehotlength + maxactivecoldlength;

  //   if (maxactivecoldlength === "" || maxactivehotlength === "") {
  //     formik.setFieldValue(
  //       `multiStackVesselFormData[${index}].bundleimmersionlength`,
  //       null
  //     );
  //   } else {
  //     formik.setFieldValue(
  //       `multiStackVesselFormData[${index}].bundleimmersionlength`,
  //       immersionlength
  //     );
  //   }
  // };

  const fetchMaterials = async () => {
    try {
      const response = await axiosPrivate.get("/material-grade");
      if (response.status === 200) {
        const data = response?.data?.data;

        if (data.length > 0) {
          const materialCodeMapping = data.reduce((acc, item) => {
            if (!acc[item.materials]) {
              acc[item.materials] = new Set();
            }
            acc[item.materials].add(item.materials_code);
            return acc;
          }, {});

          const uniqueMaterials = Array.from(
            new Set(data.map((item) => item.materials))
          );
          if (uniqueMaterials.length > 0) {
            const materialsOptions = uniqueMaterials.map((material) => ({
              value: material,
              label: material,
            }));
            setMaterials(materialsOptions);
            setMaterialCodeMapping(materialCodeMapping);
          }
        } else {
          setMaterials([]);
          setMaterialCodeMapping([]);
        }
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const getSheathMaterialDropdown = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/sheath-material ");
      if (response.status === 200) {
        setSheathMaterialDropdown(
          response.data?.data.map((item) => {
            return { value: item.name, label: item.name };
          })
        );
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showErrorToast(error.message);
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEqualDivide = (event, index) => {
    const isChecked = event.target.checked;
    formik?.setFieldValue(
      `multiStackVesselFormData[${index}].divideEqually`,
      isChecked
    );
    if (formik?.values?.bundleTableData) {
      formik?.values?.bundleTableData.forEach((row, rowIndex) => {
        if (row.bundle) {
          row.bundle.forEach((bundle, colIndex) => {
            const fieldName = `bundleTableData[${rowIndex}].bundle[${colIndex}].bank`;
            formik?.setFieldValue(
              fieldName,
              isChecked
                ? (
                  parseFloat(formik?.values?.installedCapacity) /
                  parseInt(formik?.values?.multistackvessel) /
                  formik?.values?.bank
                ).toFixed(2)
                : ""
            );
          });
        }
      });
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axiosPrivate.get("units");
      if (response?.status === 200) setUnits(response.data?.data || []);
    } catch (error) {
      showErrorToast(error?.message || "Error fetching units");
    }
  };

  const wattdensityactual = () => {
    const {
      installedCapacity,
      numberofelementsconnected,
      heatersheathtubedia,
      maxactivehotlength,
      multistackvessel,
    } = values;

    const missingFields = [];

    if (!installedCapacity) missingFields.push("Installed Capacity");
    if (!numberofelementsconnected)
      missingFields.push("Number of Elements Connected");
    if (!heatersheathtubedia) missingFields.push("Heater Sheath Tube Diameter");
    if (!maxactivehotlength) missingFields.push("Max Active Hot Length");

    if (missingFields.length > 0) {
      showErrorToast(
        `Please ensure the following values are selected to calculate watt density actual: ${missingFields.join(
          ", "
        )}`
      );
      return;
    }

    formik?.values?.multiStackVesselFormData?.forEach((item, index) => {
      const power = (values.bundleHeatLoad[index + 1]) * 1000;
      console.log("power for multi stack vessel", power);

      const wattDensityActual =
        (power * 100) /
        (numberofelementsconnected *
          3.14 *
          heatersheathtubedia *
          maxactivehotlength *
          2);

      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].wattdensityactual`,
        wattDensityActual.toFixed(4)
      );
    });
  };

  useEffect(() => {
    fetchMaterials();
    fetchUnits();
    getSheathMaterialDropdown();
    // empty sensors when sensorSelection is "no" in first stack
    if (formik?.values?.sensorSelection === "no") {
      formik?.values?.multiStackVesselFormData?.map((item, index) => {
        formik?.setFieldValue(
          `multiStackVesselFormData[${index}].sensor`,
          formik?.initialValues?.multiStackVesselFormData[index].sensor
        );
      });
    }
  }, []);

  // useEffect(
  //   () => {
  //     handleimmersionlength();
  //     // handleDevelopedLength();
  //   },
  //   [
  //     formik?.values?.maxactivehotlength,
  //     formik?.values?.maxactivecoldlength,
  //     formik?.values?.wattdensity,
  //     formik?.values?.distbwheaterflangetoterminalbox,
  //   ],
  //   []
  // );

  // useEffect(()=>{
  //   if(formik){
  //     updateMultiStackVesselFormData(formik?.values?.multistackvessel)
  //   }
  // },[]);

  useEffect(() => {
    axiosPrivate
      .get("schedules")
      .then((response) => {
        if (response.status === 200) {
          setSchedules(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   updateMultiStackVesselFormData(formik?.values?.multistackvessel);
  // }, [formik?.values?.multistackvessel]);

  useEffect(() => {
    formik?.values?.multiStackVesselFormData?.forEach((item, index) => {
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].sheathmaterial`,
        formik?.values?.sheathmaterial
      );
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.bafflematerial`,
        formik?.values?.sheathmaterial
      );
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.tiematerial`,
        formik?.values?.sheathmaterial
      );
    });
  }, [formik?.values?.sheathmaterial]);

  // Update only bafflematerial when it is updated
  useEffect(() => {
    formik?.values?.multiStackVesselFormData?.forEach((item, index) => {
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.bafflematerial`,
        formik?.values?.bafflematerial
      );
    });
  }, [formik?.values?.bafflematerial]);

  // Update only tiematerial when it is updated
  useEffect(() => {
    formik?.values?.multiStackVesselFormData?.forEach((item, index) => {
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].baffle.tiematerial`,
        formik?.values?.tiematerial
      );
    });
  }, [formik?.values?.tiematerial]);

  useEffect(() => {
    formik?.values?.multiStackVesselFormData?.forEach((item, index) => {
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].maxactivecoldlength`,
        formik?.values?.maxactivecoldlength
      );
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].developedlength`,
        formik?.values?.developedlength
      );
      formik?.setFieldValue(
        `multiStackVesselFormData[${index}].bundleimmersionlength`,
        formik.values.bundleimmersionlength
      );
    });
  }, [
    formik?.values?.maxactivecoldlength,
    formik?.values?.developedlength,
    formik.values.bundleimmersionlength,
  ]);

  useEffect(() => {
    wattdensityactual();
  }, [values.numberofelementsconnected]);

  useEffect(() => {
    values.multiStackVesselFormData?.forEach((item, index) => {
      const inlettemp = parseFloat(item?.temp?.inlettemp) || 0;
      const outlettemp = parseFloat(item?.temp?.outlettemp) || 0;
      const tempchange = outlettemp - inlettemp;
      setFieldValue(
        `multiStackVesselFormData[${index}].temp.tempchange`,
        tempchange
      );
    });
  }, [values.multiStackVesselFormData, setFieldValue]);

  useEffect(() => { }, [formik?.values?.multiStackVesselFormData]);

  console.log("helllo there ", formik.values.multiStackVesselFormData);


  return (
    <div>
      <Accordion className="w-100">
        {formik?.values?.multistackvessel &&
          formik?.values?.multiStackVesselFormData?.map((item, index) => {
            const eventKey = (index + 1)?.toString(); // Start from "1" since we skip the first item
            return (
              <Accordion.Item
                key={eventKey}
                eventKey={eventKey}
                className="mb-3 shadow-md rounded"
              >
                <Accordion.Header
                  className={`p-3 border border-1 border-bottom shadow-md m-0 d-flex w-100 ${activeKey === eventKey ? "bg-gray text-white" : ""
                    }`}
                  style={{ backgroundColor: "#eee" }}
                  onClick={() => handleAccordionClick(eventKey)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <h2 className="p-0 m-0">{`Stack Vessel ${index + 1}`}</h2>
                    {/* <div
                      className="h-100 mt-2"
                      onClick={() => {
                        const updatedData = [
                          ...formik.values.multiStackVesselFormData,
                        ]; // Create a copy of the array
                        updatedData.splice(index, 1); // Remove the item at the specified index
                        formik.setFieldValue(
                          "multiStackVesselFormData",
                          updatedData
                        ); // Update the form value with the new array
                        formik.setFieldValue(
                          "multistackvessel",
                          formik?.values?.multiStackVesselFormData.length
                        );
                      }}
                    >
                      <MdDeleteForever size={30} />
                    </div> */}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="p-4">
                  <div className="w-100 row px-4 col-md-12 col-sm-12">
                    {/* --- Technical Calculation */}
                    <div className="mb-10 mt-10">
                      <h3 className="mb-2 m-0 p-0 ">Technical Calculation</h3>
                    </div>
                    <div className="d-flex w-100 row">
                      {/* Inlet Temperature */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData.${index}.temp.inlettemp`}
                        >
                          Inlet Temperature
                        </label>
                        <input
                          type="number"
                          name={`multiStackVesselFormData.${index}.temp.inlettemp`}
                          id={`multiStackVesselFormData.${index}.temp.inlettemp`}
                          placeholder="Enter Inlet Temperature"
                          className="form-control"
                          value={
                            index === 0
                              ? formik?.values?.startingTemp
                              : values.multiStackVesselFormData[index]?.temp
                                ?.inlettemp || ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={index === 0}
                        />
                      </div>
                      {/* Outlet Temperature */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData.${index}.temp.outlettemp`}
                        >
                          Outlet Temperature
                        </label>
                        <input
                          type="number"
                          name={`multiStackVesselFormData.${index}.temp.outlettemp`}
                          id={`multiStackVesselFormData.${index}.temp.outlettemp`}
                          placeholder="Enter Outlet Temperature"
                          className="form-control"
                          value={
                            index === 0
                              ? formik?.values?.outletTemp
                              : values.multiStackVesselFormData[index]?.temp
                                ?.outlettemp || ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={index === 0}
                        />
                      </div>
                      {/* Temperature Change */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData.${index}.temp.tempchange`}
                        >
                          Temperature Change
                        </label>
                        <input
                          type="number"
                          name={`multiStackVesselFormData.${index}.temp.tempchange`}
                          id={`multiStackVesselFormData.${index}.temp.tempchange`}
                          placeholder="Temperature Change"
                          className="form-control"
                          disabled
                          value={
                            index === 0
                              ? formik?.values?.tempChange
                              : values.multiStackVesselFormData[index]?.temp
                                ?.tempchange || ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {/* Total Bank */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].bank`}
                        >
                          Total Bank
                        </label>
                        <Select
                          isClearable
                          styles={customStylesForDisabled}
                          placeholder="Total No. of bank"
                          name={`multiStackVesselFormData[${index}].bank`}
                          id={`multiStackVesselFormData[${index}].bank`}
                          options={noOfBankOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              `multiStackVesselFormData[${index}].bank`,
                              option ? option.value : ""
                            )
                          }
                          isDisabled={index === 0}
                          value={
                            index === 0
                              ? formik?.values?.startingTemp
                              : formik?.values?.multiStackVesselFormData[index]
                                ?.bank
                                ? {
                                  label:
                                    index === 0
                                      ? formik?.values?.startingTemp
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.bank,
                                  value:
                                    index === 0
                                      ? formik?.values?.startingTemp
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.bank,
                                }
                                : null
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex w-100 row">
                      {/* Installed Capacity */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label htmlFor={`bundleHeatLoad[${index}]`}>
                          Installed Capacity
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name={`bundleHeatLoad[${index}]`}
                          id={`bundleHeatLoad[${index}]`}
                          value={formik?.values?.bundleHeatLoad[index]}
                          onChange={(e) => {
                            e.preventDefault();
                            formik.setFieldValue(
                              `bundleHeatLoad[${index}]`,
                              parseFloat(e.target.value)
                            );
                          }}
                          readOnly
                          disabled={formik?.values.divideEqually}
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                      {/* Inlet Pressure */}
                      <div className="col-md-3">
                        <label htmlFor="inletPressure">Inlet Pressure</label>
                        <div className="mb-15 d-flex row">
                          <div className="col">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Inlet Pressure"
                              name={`multiStackVesselFormData[${index}].inletPressure`}
                              id={`multiStackVesselFormData[${index}].inletPressure`}
                              value={
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.inletPressure
                              }
                              onChange={(e) => {
                                const inletPressure =
                                  parseFloat(e.target.value) || 0;
                                const outletPressure =
                                  parseFloat(
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.outletPressure
                                  ) || 0;

                                formik?.setFieldValue(
                                  `multiStackVesselFormData[${index}].inletPressure`,
                                  inletPressure
                                );

                                // Calculate and update allowed pressure drop
                                formik?.setFieldValue(
                                  `multiStackVesselFormData[${index}].allowedPressureDrop`,
                                  (inletPressure - outletPressure).toFixed(2)
                                );
                              }}
                            />
                          </div>
                          <Select
                            styles={customStyles}
                            isClearable
                            name={`multiStackVesselFormData[${index}].inletPressureUnit`}
                            id={`multiStackVesselFormData[${index}].inletPressureUnit`}
                            placeholder="Unit"
                            options={units?.map((unit) => ({
                              label: unit.name,
                              value: unit.name,
                            }))}
                            value={
                              formik?.values?.multiStackVesselFormData[index]
                                ?.inletPressureUnit
                                ? {
                                  label:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                  value:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                }
                                : null
                            }
                            onChange={(option) => {
                              formik?.setFieldValue(
                                `multiStackVesselFormData[${index}].inletPressureUnit`,
                                option ? option.value : ""
                              );
                            }}
                            onBlur={formik?.handleBlur}
                            className="col"
                          />
                        </div>
                      </div>

                      {/* Outlet Pressure */}
                      <div className="col-md-3">
                        <label htmlFor="outletPressure" className="tooltip">
                          Outlet Pressure
                          <BsInfoCircleFill className="mb-1 info" />
                          <span className="tooltip-text">
                            Outlet Pressure Unit = Inlet Pressure Unit
                          </span>
                        </label>
                        <div className="row d-flex">
                          <div className="mb-15 col">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Outlet Pressure"
                              name={`multiStackVesselFormData[${index}].outletPressure`}
                              id={`multiStackVesselFormData[${index}].outletPressure`}
                              value={
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.outletPressure
                              }
                              onChange={(e) => {
                                const outletPressure =
                                  parseFloat(e.target.value) || 0;
                                const inletPressure =
                                  parseFloat(
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressure
                                  ) || 0;

                                formik?.setFieldValue(
                                  `multiStackVesselFormData[${index}].outletPressure`,
                                  outletPressure
                                );

                                // Calculate and update allowed pressure drop
                                formik?.setFieldValue(
                                  `multiStackVesselFormData[${index}].allowedPressureDrop`,
                                  (inletPressure - outletPressure).toFixed(2)
                                );
                              }}
                            />
                          </div>
                          <Select
                            isClearable
                            placeholder="Unit"
                            isDisabled
                            id={`multiStackVesselFormData[${index}].outletPressureUnit`}
                            name={`multiStackVesselFormData[${index}].outletPressureUnit`}
                            styles={customStylesForDisabled}
                            value={
                              formik?.values?.multiStackVesselFormData[index]
                                ?.inletPressureUnit
                                ? {
                                  label:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                  value:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                }
                                : null
                            }
                            className="col"
                          />
                        </div>
                      </div>

                      {/* Allowed Pressure Drop */}
                      <div className="col-md-3">
                        <label htmlFor="allowedPressureDrop">
                          Allowed Pressure Drop
                        </label>
                        <div className="row d-flex">
                          <div className="col">
                            <input
                              type="number"
                              className="form-control mb-15"
                              name={`multiStackVesselFormData[${index}].allowedPressureDrop`}
                              id={`multiStackVesselFormData[${index}].allowedPressureDrop`}
                              placeholder="Allowed Pressure"
                              value={
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.allowedPressureDrop
                              }
                              readOnly // Prevent manual entry
                            />
                          </div>
                          <Select
                            isClearable
                            styles={customStylesForDisabled}
                            isDisabled
                            id={`multiStackVesselFormData[${index}].allowedPressureDropUnit`}
                            name={`multiStackVesselFormData[${index}].allowedPressureDropUnit`}
                            value={
                              formik?.values?.multiStackVesselFormData[index]
                                ?.inletPressureUnit
                                ? {
                                  label:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                  value:
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.inletPressureUnit,
                                }
                                : null
                            }
                            className="col"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex w-100 row">
                      {/* Heat Duty (KW) */}
                      {/* <div className="col-md-3">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].heatdutykw`}
                        >
                          Heat Duty (KW)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Heat Duty (KW)"
                          name={`multiStackVesselFormData[${index}].heatDutyKW`}
                          id={`multiStackVesselFormData[${index}].heatdutykw`}
                          value={
                            index === 0
                              ? formik?.values?.heatDutyKW
                              : formik?.values?.multiStackVesselFormData[index]
                                ?.heatDutyKW
                          }
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          disabled={index === 0}
                        />
                      </div> */}

                      {/* Connection */}
                      <div className="col-md-3">
                        <label htmlFor="connection" className="tooltip">
                          Connection&nbsp;
                          <BsInfoCircleFill className="info" />
                          <span className="tooltip-text">
                            Technical Caluclation: Connection
                          </span>
                        </label>
                        <Select
                          isClearable
                          styles={customStylesForDisabled}
                          name="connection"
                          isDisabled={true}
                          id="connection"
                          placeholder="Select Connection"
                          options={connectionType}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(`connection`, option.value)
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik?.values?.connection
                              ? {
                                label: formik?.values?.connection,
                                value: formik?.values?.connection,
                              }
                              : null
                          }
                        />
                      </div>

                      {/* Voltage and Voltage tps */}
                      <div className="col-md-3 d-flex justify-content-between">
                        <div className="ml-0 pl-0">
                          <label
                            htmlFor={`multiStackVesselFormData[${index}].voltage`}
                          >
                            Voltage(V)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Voltage(V)"
                            name={`multiStackVesselFormData[${index}].voltage`}
                            id={`multiStackVesselFormData[${index}].voltage`}
                            value={formik?.values?.voltage}
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            disabled
                          />
                        </div>
                        <div className="mr-0 pr-0">
                          <label
                            htmlFor={`multiStackVesselFormData[${index}].voltage`}
                          >
                            Voltage(V) (TPS)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Star Connection"
                            name={`multiStackVesselFormData[${index}].voltage_calculation`}
                            id={`multiStackVesselFormData[${index}].voltage_calculation`}
                            value={
                              formik?.values?.multiStackVesselFormData[index]
                                ?.voltage_calculation
                            }
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Watt.Density(Recommended/Actual) */}
                      <div className="col-md-3">
                        <label htmlFor="wattdensity">
                          Watt.Density(Recommended/Actual)
                        </label>
                        <div className="row d-flex">
                          <div className="mb-15 col">
                            <input
                              type="number"
                              className="form-control col"
                              placeholder="Watt Density(Recommended)"
                              name={`multiStackVesselFormData[${index}].wattdensity`}
                              id={`multiStackVesselFormData[${index}].wattdensity`}
                              value={
                                index === 0
                                  ? formik?.values?.wattdensity
                                  : formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.wattdensity
                              }
                              disabled={index === 0}
                              onChange={(e) => {
                                e.preventDefault();
                                formik?.setFieldValue(
                                  `multiStackVesselFormData[${index}].wattdensity`,
                                  e.target.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          <input
                            type="number"
                            className="form-control col"
                            placeholder="Watt Density(Actual)"
                            name={`multiStackVesselFormData[${index}].wattdensityactual`}
                            id={`multiStackVesselFormData[${index}].wattdensityactual`}
                            value={
                              index === 0
                                ? formik?.values?.wattdensityactual
                                : formik?.values?.multiStackVesselFormData[
                                  index
                                ]?.wattdensityactual
                            }
                            disabled={index === 0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex w-100 row">
                      <div className="col-md-12 m-0 mx-2 p-0">
                        <NonEditableBankBundleTable formik={formik} />
                      </div>
                    </div>

                    {formik?.values?.sensorSelection === "yes" && (
                      <>
                        <div className="mb-10 mt-5">
                          <h3 className="mb-2 m-0 p-0 ">Sensor Selection</h3>
                        </div>

                        {/* Skin Sensor */}
                        <div className="d-flex w-100 row">
                          <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                            <label htmlFor="">
                              <b>Skin Sensor</b>
                            </label>
                            <div className="row d-flex px-2 py-2">
                              <div className="row">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[0].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[0].selected`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].selected`,
                                      true
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[0].selected ===
                                      "yes"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[0]?.selected === true
                                  }
                                  onBlur={formik.handleBlur}
                                  disabled={index === 0}
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="row ml-4">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[0].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[0].selected`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].selected`,
                                      false
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[0]?.selected ===
                                      "no"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[0]?.selected === false
                                  }
                                  onBlur={formik?.handleBlur}
                                  disabled={index === 0}
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          </div>
                          {(index === 0 &&
                            formik?.values?.sensor[0]?.selected === "yes") ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.sensor[0]?.selected ? (
                            <>
                              {/* Sensor Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[0].sensortype`}
                                >
                                  Sensor Type
                                </label>
                                <Select
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={sensorRangeOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[0].sensortype`}
                                  placeholder="Sensor Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].sensortype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[0].sensorrange) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[0]?.sensortype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[0]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.sensortype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[0]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.sensortype,
                                      }
                                      : null
                                  }
                                  isDisabled={index === 0}
                                />
                              </div>
                              {/* Sensor Sub Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor="">Sensor Sub Type</label>
                                <Select
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={
                                    dropdownOptions?.sensorsubtypeoptions
                                  }
                                  name={`multiStackVesselFormData[${index}].sensor[0].sensorsubtype`}
                                  placeholder="Sensor Sub Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].sensorsubtype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[0]
                                        ?.sensorsubtype) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[0]?.sensorsubtype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[0]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.sensorsubtype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[0]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.sensorsubtype,
                                      }
                                      : null
                                  }
                                  isDisabled={index === 0}
                                />
                              </div>
                              {/* Quantity */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor={`quantity-${index}`}>
                                  Quantity
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Quantity"
                                  name={`multiStackVesselFormData[${index}].sensor[0].quantity`}
                                  id={`multiStackVesselFormData[${index}].sensor[0].quantity`}
                                  value={
                                    index === 0
                                      ? formik?.values?.sensor[0]?.quantity
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[0]?.quantity || ""
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    formik?.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].quantity`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={index === 0}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[0].material`}
                                >
                                  Material
                                </label>
                                <CreatableSelect
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  id={`multiStackVesselFormData[${index}].sensor[0].material`}
                                  name={`multiStackVesselFormData[${index}].sensor[0].material`}
                                  options={
                                    dropdownOptions?.sensorMaterialOptions
                                  }
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].material`,
                                      option ? option.value : ""
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[0].material) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[0]?.material
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[0]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.material,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[0]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.material,
                                      }
                                      : null
                                  }
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Head Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[0].headMaterial`}
                                >
                                  Head Material
                                </label>
                                <Select
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  options={dropdownOptions?.headMaterialOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[0].headMaterial`}
                                  placeholder="Head Material"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[0].headMaterial`,
                                      option ? option.value : ""
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[0].headMaterial) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[0]?.headMaterial
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[0]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.headMaterial,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[0]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[0]?.headMaterial,
                                      }
                                      : null
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>

                        {/* Tube Sheet Sensor */}
                        <div className="d-flex w-100 row">
                          <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                            <label htmlFor="">
                              <b>Tube Sheet Sensor </b>
                            </label>
                            <div className="row d-flex px-2 py-2">
                              <div className="row">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[1].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[1].selected`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].selected`,
                                      true
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[1].selected ===
                                      "yes"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[1]?.selected === true
                                  }
                                  disabled={index === 0}
                                  onBlur={formik.handleBlur}
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="row ml-4">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[1].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[1].selected`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].selected`,
                                      false
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[1].selected ===
                                      "no"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[1]?.selected === false
                                  }
                                  disabled={index === 0}
                                  onBlur={formik?.handleBlur}
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          </div>
                          {(index === 0 &&
                            formik?.values?.sensor[1].selected === "yes") ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.sensor[1]?.selected === true ? (
                            <>
                              {/* Sensor Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[1].sensortype`}
                                >
                                  Sensor Type
                                </label>
                                <Select
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={sensorRangeOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[1].sensortype`}
                                  placeholder="Sensor Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].sensortype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[1].sensorrange) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[1]?.sensortype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[1]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.sensortype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[1]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.sensortype,
                                      }
                                      : null
                                  }
                                  isDisabled={index === 0}
                                />
                              </div>
                              {/* Sensor Sub Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor="">Sensor Sub Type</label>
                                <Select
                                  isClearable
                                  isDisabled={index === 0}
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={
                                    dropdownOptions?.sensorsubtypeoptions
                                  }
                                  name={`multiStackVesselFormData[${index}].sensor[1].sensorsubtype`}
                                  placeholder="Sensor Sub Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].sensorsubtype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[1]
                                        ?.sensorsubtype) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[1]?.sensorsubtype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[1]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.sensorsubtype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[1]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.sensorsubtype,
                                      }
                                      : null
                                  }
                                />
                              </div>
                              {/* Quantity */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor={`quantity-${index}`}>
                                  Quantity
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Quantity"
                                  name={`multiStackVesselFormData[${index}].sensor[1].quantity`}
                                  id={`multiStackVesselFormData[${index}].sensor[1].quantity`}
                                  value={
                                    index === 0
                                      ? formik?.values?.sensor[1]?.quantity
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[1]?.quantity || ""
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    formik?.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].quantity`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={index === 0}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[1].material`}
                                >
                                  Material
                                </label>
                                <CreatableSelect
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  id={`multiStackVesselFormData[${index}].sensor[1].material`}
                                  name={`multiStackVesselFormData[${index}].sensor[1].material`}
                                  options={
                                    dropdownOptions?.sensorMaterialOptions
                                  }
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].material`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[1].material) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[1]?.material
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[1]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.material,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[1]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.material,
                                      }
                                      : null
                                  }
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Head Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[1].headMaterial`}
                                >
                                  Head Material
                                </label>
                                <Select
                                  isClearable
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  options={dropdownOptions?.headMaterialOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[1].headMaterial`}
                                  placeholder="Head Material"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[1].headMaterial`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[1].headMaterial) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[1]?.headMaterial
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[1]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.headMaterial,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[1]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[1]?.headMaterial,
                                      }
                                      : null
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>

                        {/* Process Sensor */}
                        <div className="d-flex w-100 row">
                          <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                            <label htmlFor="">
                              <b>Process Sensor </b>
                            </label>
                            <div className="row d-flex px-2 py-2">
                              <div className="row">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[2].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[2].selected`}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].selected`,
                                      true
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[2]?.selected ===
                                      "yes"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[2]?.selected === true
                                  }
                                  disabled={index === 0}
                                  onBlur={formik.handleBlur}
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="row ml-4">
                                <input
                                  type="radio"
                                  name={`multiStackVesselFormData[${index}].sensor[2].selected`}
                                  id={`multiStackVesselFormData[${index}].sensor[2].selected`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].selected`,
                                      false
                                    );
                                  }}
                                  checked={
                                    index === 0
                                      ? formik?.values?.sensor[2]?.selected ===
                                      "no"
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[2]?.selected === false
                                  }
                                  disabled={index === 0}
                                  onBlur={formik?.handleBlur}
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          </div>
                          {(index === 0 &&
                            formik?.values?.sensor[2]?.selected === "yes") ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.sensor[2]?.selected ? (
                            <>
                              {/* Sensor Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[2].sensortype`}
                                >
                                  Sensor Type
                                </label>
                                <Select
                                  isClearable
                                  isDisabled={index === 0}
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={sensorRangeOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[2].sensortype`}
                                  placeholder="Sensor Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].sensortype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[2].sensorrange) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[2]?.sensortype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[2]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.sensortype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[2]
                                              .sensorrange) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.sensortype,
                                      }
                                      : null
                                  }
                                />
                              </div>
                              {/* Sensor Sub Type */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor="">Sensor Sub Type</label>
                                <Select
                                  isClearable
                                  isDisabled={index === 0}
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  options={
                                    dropdownOptions?.sensorsubtypeoptions
                                  }
                                  name={`multiStackVesselFormData[${index}].sensor[2].sensorsubtype`}
                                  placeholder="Sensor Sub Type"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].sensorsubtype`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik?.values?.sensor[2]
                                        ?.sensorsubtype) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[2]?.sensorsubtype
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik?.values?.sensor[2]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.sensorsubtype,
                                        value:
                                          (index === 0 &&
                                            formik?.values?.sensor[2]
                                              ?.sensorsubtype) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.sensorsubtype,
                                      }
                                      : null
                                  }
                                />
                              </div>
                              {/* Quantity */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label htmlFor={`quantity-${index}`}>
                                  Quantity
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Quantity"
                                  name={`multiStackVesselFormData[${index}].sensor[2].quantity`}
                                  id={`multiStackVesselFormData[${index}].sensor[2].quantity`}
                                  value={
                                    index === 0
                                      ? formik?.values?.sensor[2]?.quantity
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.sensor[2]?.quantity || ""
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    formik?.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].quantity`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={index === 0}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[2].material`}
                                >
                                  Material
                                </label>
                                <CreatableSelect
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  id={`multiStackVesselFormData[${index}].sensor[2].material`}
                                  name={`multiStackVesselFormData[${index}].sensor[2].material`}
                                  options={
                                    dropdownOptions?.sensorMaterialOptions
                                  }
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].material`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[2].material) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[2]?.material
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[2]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.material,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[2]
                                              .material) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.material,
                                      }
                                      : null
                                  }
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {/* Head Material */}
                              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                                <label
                                  htmlFor={`multiStackVesselFormData[${index}].sensor[2].headMaterial`}
                                >
                                  Head Material
                                </label>
                                <Select
                                  styles={
                                    index === 0
                                      ? customStylesForDisabled
                                      : customStyles
                                  }
                                  isDisabled={index === 0}
                                  isClearable
                                  options={dropdownOptions?.headMaterialOptions}
                                  name={`multiStackVesselFormData[${index}].sensor[2].headMaterial`}
                                  placeholder="Head Material"
                                  onChange={(option) =>
                                    formik.setFieldValue(
                                      `multiStackVesselFormData[${index}].sensor[2].headMaterial`,
                                      option.value
                                    )
                                  }
                                  value={
                                    (index === 0 &&
                                      formik.values.sensor[2].headMaterial) ||
                                      formik?.values?.multiStackVesselFormData[
                                        index
                                      ]?.sensor[2]?.headMaterial
                                      ? {
                                        label:
                                          (index === 0 &&
                                            formik.values.sensor[2]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.headMaterial,
                                        value:
                                          (index === 0 &&
                                            formik.values.sensor[2]
                                              .headMaterial) ||
                                          formik?.values
                                            ?.multiStackVesselFormData[index]
                                            ?.sensor[2]?.headMaterial,
                                      }
                                      : null
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    )}

                    {/* --- Element Selection */}
                    <div className="mb-10 mt-5">
                      <h3 className="mb-2 m-0 p-0 ">Element Selection</h3>
                    </div>
                    <div className="d-flex w-100 row">
                      {/* Heater Sheath Tube DIA */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].heatersheathtubedia`}
                        >
                          Heater Sheath Tube DIA
                        </label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          isClearable
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].heatersheathtubedia`}
                          name={`multiStackVesselFormData[${index}].heatersheathtubedia`}
                          type="number"
                          options={heaterSheathTubeDiaOptions}
                          onBlur={formik.handleBlur}
                          onChange={(option) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].heatersheathtubedia`,
                              option ? option.value : ""
                            );
                          }}
                          isDisabled
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.heatersheathtubedia
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.heatersheathtubedia,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.heatersheathtubedia,
                              }
                              : null
                          }
                        />
                      </div>

                      {/* Sheath Material */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].sheathmaterial`}
                        >
                          Sheath Material
                        </label>
                        <Select
                          isDisabled
                          isClearable
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].sheathmaterial`}
                          name={`multiStackVesselFormData[${index}].sheathmaterial`}
                          options={sheathMaterialDropdown}
                          onChange={(option) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].sheathmaterial`,
                              option ? option.value : ""
                            );
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.tiematerial`,
                              option ? option.value : ""
                            );
                          }}
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.sheathmaterial
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.sheathmaterial,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.sheathmaterial,
                              }
                              : null
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {/* Max. Active Hot Length */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].maxactivehotlength`}
                        >
                          Max. Active Length
                        </label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Max. Active Hot Length"
                          name={`multiStackVesselFormData[${index}].maxactivehotlength`}
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.maxactivehotlength
                          }
                          onChange={formik.handleChange}
                          onBlur={() => {
                            formik.setFieldValue(
                              "distancebwionozzels",
                              formik?.values?.multiStackVesselFormData[index]
                                ?.maxactivehotlength
                            );
                          }}
                          required
                          disabled
                        />
                      </div>

                      {/* Max. Active Cold Length */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].maxactivecoldlength`}
                        >
                          Max. Cold Length
                        </label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Max. Active Cold Length"
                          name={`multiStackVesselFormData[${index}].maxactivecoldlength`}
                          value={parseFloat(
                            formik?.values?.multiStackVesselFormData[index]
                              ?.maxactivecoldlength
                          )}
                          onChange={(e) => {
                            e.preventDefault();
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].maxactivecoldlength`,
                              e.target.value
                            );
                            // handleimmersionlength(index);
                          }}
                          onBlur={(e) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].maxactivecoldlength`,
                              Number(e.target.value)
                            );
                            formik.handleBlur(e);
                          }}
                          required
                          disabled
                        />
                      </div>

                      {/* Total Straight Length */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].developedlength`}
                        >
                          Total Straight Length
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Total Straight Length"
                          name={`multiStackVesselFormData[${index}].developedlength`}
                          id={`multiStackVesselFormData[${index}].developedlength`}
                          value={Math.round(
                            formik?.values?.multiStackVesselFormData[index]
                              ?.developedlength
                          )}
                          onChange={formik.handleChange}
                          readOnly
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {/* Bundle Immersion Length */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].bundleimmersionlength`}
                        >
                          Bundle Immersion Length
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Bundle Immersion Length"
                          name={`multiStackVesselFormData[${index}].bundleimmersionlength`}
                          id={`multiStackVesselFormData[${index}].bundleimmersionlength`}
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.bundleimmersionlength
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* --- Baffles and Tie Rod */}
                    <div className="mb-10 mt-5">
                      <h3 className="mb-2 m-0 p-0 ">Baffles and Tie Rod</h3>
                    </div>
                    <div className="d-flex w-100 row">
                      {/* No. of Hot Baffles */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.numberofHotBaffles`}
                        >
                          No. of Hot Baffles
                        </label>
                        <input
                          name={`multiStackVesselFormData[${index}].baffle.numberofHotBaffles`}
                          type="number"
                          id={`multiStackVesselFormData[${index}].baffle.numberofHotBaffles`}
                          className="form-control"
                          placeholder="No. of Hot Baffles"
                          value={
                            index === 0
                              ? formik.values.numberofHotBaffles
                              : formik?.values?.multiStackVesselFormData[index]
                                ?.baffle?.numberofHotBaffles
                          }
                          disabled={index === 0}
                          onChange={formik.handleChange}
                        />
                      </div>

                      {/* Cold Baffle */}
                      <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-12 mb-15"
                      >
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.coldBaffle`}
                        >
                          No. of Cold Baffles
                        </label>
                        <input
                          name={`multiStackVesselFormData[${index}].baffle.coldBaffle`}
                          type="number"
                          id={`multiStackVesselFormData[${index}].baffle.coldBaffle`}
                          className="form-control"
                          placeholder="No. of Cold Baffles"
                          value={
                            formik.values.multiStackVesselFormData[index]
                              ?.baffle?.coldBaffle || ""
                          }
                          onChange={formik.handleChange}
                          disabled
                        />
                      </div>

                      {/* Baffle Plate Thickness */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.baffleplatethickness`}
                        >
                          Baffle Plate Thickness
                        </label>
                        <Select
                          isDisabled
                          isClearable
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].baffle.baffleplatethickness`}
                          name={`multiStackVesselFormData[${index}].baffle.baffleplatethickness`}
                          options={bafflePlateThicknessOptions}
                          onChange={(option) =>
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.baffleplatethickness`,
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.baffleplatethickness
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.baffleplatethickness,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.baffleplatethickness,
                              }
                              : null
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {/* Baffle Type */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.baffletype`}
                        >
                          Baffle Type
                        </label>
                        <Select
                          isClearable
                          isDisabled
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].baffle.baffletype`}
                          name={`multiStackVesselFormData[${index}].baffle.baffletype`}
                          options={baffleTypeOptions}
                          onBlur={formik.handleBlur}
                          onChange={(option) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.baffletype`,
                              option ? option.value : ""
                            );
                          }}
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.baffletype
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.baffletype,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.baffletype,
                              }
                              : null
                          }
                        />
                      </div>
                      {formik?.values?.multiStackVesselFormData[index]?.baffle
                        ?.baffletype === "Segmental" && (
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                            {/* Baffle Cut */}
                            <label
                              htmlFor={`multiStackVesselFormData[${index}].baffle.bafflecut`}
                            >
                              Baffles Cut
                            </label>
                            <input
                              disabled
                              name={`multiStackVesselFormData[${index}].baffle.bafflecut`}
                              type="number"
                              id={`multiStackVesselFormData[${index}].baffle.bafflecut`}
                              className="form-control"
                              placeholder="Enter Baffle Cut"
                              value={
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.baffle?.bafflecut
                              }
                              onChange={formik.handleChange}
                            />
                          </div>
                        )}

                      {/* Baffle Material */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.bafflematerial`}
                        >
                          Baffle Material
                        </label>
                        <Select
                          isClearable
                          isDisabled
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].baffle.bafflematerial`}
                          name={`multiStackVesselFormData[${index}].baffle.bafflematerial`}
                          options={dropdownOptions?.materialOptions}
                          onChange={(option) => {
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.bafflematerial`,
                              option ? option.value : ""
                            );
                          }}
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.bafflematerial
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.bafflematerial,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.bafflematerial,
                              }
                              : null
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {/* Tie Rod Dia */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.tieroddia`}
                        >
                          Tie Rod Dia
                        </label>
                        <Select
                          isClearable
                          isDisabled
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].baffle.tieroddia`}
                          name={`multiStackVesselFormData[${index}].baffle.tieroddia`}
                          options={tieRodDiaOptions}
                          onBlur={formik.handleBlur}
                          onChange={(option) =>
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.tieroddia`,
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.tieroddia
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.tieroddia,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.tieroddia,
                              }
                              : null
                          }
                        />
                      </div>

                      {/* Tie Rod Quantity */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.tierodquantity`}
                        >
                          Tie Rod Quantity
                        </label>
                        <input
                          type="text"
                          disabled
                          name={`multiStackVesselFormData[${index}].baffle.tierodquantity`}
                          id={`multiStackVesselFormData[${index}].baffle.tierodquantity`}
                          className="form-control"
                          placeholder="Tie Rod Quantity"
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.tierodquantity
                          }
                          onChange={formik.handleChange}
                        />
                      </div>

                      {/* Tie Rod Material */}
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-20">
                        <label
                          htmlFor={`multiStackVesselFormData[${index}].baffle.tiematerial`}
                        >
                          Tie Rod Material
                        </label>
                        <Select
                          isDisabled
                          isClearable
                          styles={customStylesForDisabled}
                          id={`multiStackVesselFormData[${index}].baffle.tiematerial`}
                          name={`multiStackVesselFormData[${index}].baffle.tiematerial`}
                          options={dropdownOptions?.materialOptions}
                          onChange={(option) =>
                            formik.setFieldValue(
                              `multiStackVesselFormData[${index}].baffle.tiematerial`,
                              option ? option.value : ""
                            )
                          }
                          value={
                            formik?.values?.multiStackVesselFormData[index]
                              ?.baffle?.tiematerial
                              ? {
                                value:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.tiematerial,
                                label:
                                  formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.baffle?.tiematerial,
                              }
                              : null
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    {/* --- Nozzle Selection */}
                    <div className="mb-10 mt-5">
                      <h3 className="mb-2 m-0 p-0">Nozzle Selection</h3>
                    </div>
                    <div className="d-flex w-100 row">
                      {/* inlet nozzle */}
                      <div className="col-12 justify-content-center">
                        <div className="d-flex align-items-center row">
                          <div className="d-flex col-lg-2 col-md-4 col-sm-6">
                            <input
                              type="checkbox"
                              id="inletVesselCounter"
                              name="inletVesselCounter"
                              value="inletVesselCounter"
                              className="mr-2"
                              disabled={index === 0}
                              checked={
                                index === 0
                                  ? formik?.values?.nozzle[0]?.inletNozzle
                                  : formik?.values?.multiStackVesselFormData[
                                    index
                                  ]?.nozzle[0]?.inletNozzle
                              }
                              onClick={() => {
                                formik.values.multiStackVesselFormData[
                                  index
                                ].nozzle[0].inletNozzle =
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[0].inletNozzle;
                                if (
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[0].inletNozzle
                                ) {
                                  formik.values.multiStackVesselFormData[
                                    index
                                  ].nozzle[0].counterFlange = false;
                                }
                              }}
                              onChange={handleCheckboxChange}
                            ></input>
                            <label
                              htmlFor="inletVesselCounter"
                              className="d-flex align-items-center font-weight-bold"
                            >
                              Inlet Nozzle
                            </label>
                          </div>
                          {(index === 0 &&
                            formik?.values?.nozzle[0]?.inletNozzle) ||
                            (formik?.values?.multiStackVesselFormData[index]
                              ?.nozzle &&
                              formik.values.multiStackVesselFormData[index]
                                .nozzle[0].inletNozzle) ? (
                            <>
                              <div className="d-flex mr-5 ">
                                <input
                                  type="checkbox"
                                  id="inletCounterFlange"
                                  name="inletCounterFlange"
                                  value="inletCounterFlange"
                                  className="mr-2"
                                  disabled={index === 0}
                                  checked={
                                    index === 0
                                      ? formik?.values?.nozzle[0]?.counterFlange
                                      : formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.nozzle &&
                                      formik?.values
                                        ?.multiStackVesselFormData[index]
                                        ?.nozzle[0]?.counterFlange
                                  }
                                  onClick={() => {
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[0].counterFlange =
                                      !formik.values.multiStackVesselFormData[
                                        index
                                      ].nozzle[0].counterFlange;
                                  }}
                                  onChange={handleCheckboxChange}
                                ></input>
                                <label
                                  htmlFor="inletCounterFlange"
                                  className="d-flex align-items-center font-weight-bold"
                                >
                                  Counter Flange
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>

                        {(index === 0 &&
                          formik?.values?.nozzle[0]?.inletNozzle) ||
                          formik?.values?.multiStackVesselFormData[index]
                            ?.nozzle[0]?.inletNozzle ? (
                          <div className="row mt-2">
                            {/* Size */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15 ">
                              <label htmlFor="size">Size</label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                placeholder="Select Size"
                                name={`nozzle[${0}].size`}
                                id="size"
                                options={filteredSizeOptions}
                                value={(() => {
                                  const flangeSize =
                                    (index === 0 &&
                                      formik?.values?.nozzle[0]?.size) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.size;

                                  const matchedOption =
                                    filteredSizeOptions.find(
                                      (option) => option.value === flangeSize
                                    );

                                  return matchedOption
                                    ? {
                                      label: matchedOption.label,
                                      value: matchedOption.value,
                                    }
                                    : null;
                                })()}
                                isDisabled={index === 0}
                                onChange={(selectedOption) => {
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "size",
                                    selectedOption ? selectedOption?.value : ""
                                  );
                                  handleFlangeThickness();
                                }}
                              // isClearable
                              />
                            </div>
                            {/* Schedules */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="schedules">Schedules</label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                placeholder="Select Schedule"
                                className="w-100"
                                name="schedules"
                                id="schedules"
                                options={schedules.map((sch) => ({
                                  label: sch.sch,
                                  value: sch.sch,
                                }))}
                                isDisabled={index === 0}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.schedules) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.schedules
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.schedules,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.schedules,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "schedules",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Flange Rating */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeRating">
                                Flange Rating
                              </label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                placeholder="Flange Rating"
                                className="w-100"
                                name={`nozzle[${0}].flangeRating`}
                                id="flangeRating"
                                options={flangeRatingOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.flangeRating,
                                    }
                                    : null
                                }
                                isDisabled={index === 0}
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "flangeRating",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Flange Type */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeType">Flange Type</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Type"
                                className="w-100"
                                name={`nozzle[${0}].flangeType`} // Ensure correct index is used (0 in this case)
                                id="flangeType"
                                options={flangeTypeOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.flangeType) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.flangeType
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.flangeType) ||
                                        formik?.values?.multiStackVesselFormData[
                                          index
                                        ]?.nozzle[0]?.flangeType.toUpperCase(),
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.flangeType,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "flangeType",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Material */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="material">Material</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Material"
                                className="w-100"
                                name={`nozzle[${0}].material`}  // Ensure correct index is used (0 in this case)
                                id="material"
                                options={materials}
                                onChange={(selectedOption) => {
                                  if (selectedOption?.value) {
                                    formik.setFieldValue(
                                      "nozzle[0].material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                    handleMaterialSelect(selectedOption);
                                    multiStackHandleChange(
                                      index,
                                      0,
                                      "material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                  }
                                  formik.setFieldValue(
                                    `nozzle[0].materialCode`,
                                    null
                                  );
                                }}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.material) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.material
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.material,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.material,
                                    }
                                    : null
                                }
                              />
                            </div>
                            {/* Material Code */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Material Code
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Material Code"
                                className="w-100"
                                name={`nozzle[${0}]?.materialCode`}
                                id="MaterialCode"
                                options={materialCode}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.materialCode) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.materialCode
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.materialCode,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.materialCode,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "materialCode",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                // isClearable
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Inlet Orientation  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Orientation( Deg.)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Orientation"
                                className="w-100"
                                name={`nozzle[${0}]?.orientation`}
                                id="inletOrientation"
                                options={orientationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.orientation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.orientation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.orientation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.orientation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "orientation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Inlet Elevation */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Elevation (mm)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Elevation"
                                className="w-100"
                                name={`nozzle[${0}]?.elevation`}
                                id="multitsackInletElevation"
                                options={elevationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[0]?.elevation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[0]?.elevation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.elevation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[0]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[0]?.elevation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    0,
                                    "elevation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* outlet Nozzle */}
                      <div className="col-12 justify-content-center mt-4">
                        <div className="d-flex align-items-center row">
                          <div className="d-flex col-lg-2 col-md-4 col-sm-6">
                            <input
                              type="checkbox"
                              id="outletNozzle"
                              name="outletNozzle"
                              value="outletNozzle"
                              className="mr-2"
                              disabled={index === 0}
                              checked={
                                (index === 0 &&
                                  formik?.values?.nozzle[1]?.outletNozzle) ||
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.nozzle[1]?.outletNozzle
                              }
                              onClick={() => {
                                formik.values.multiStackVesselFormData[
                                  index
                                ].nozzle[1].outletNozzle =
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[1].outletNozzle;
                                if (
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[1].outletNozzle
                                ) {
                                  formik.values.multiStackVesselFormData[
                                    index
                                  ].nozzle[1].counterFlange = false;
                                }
                              }}
                              onChange={handleCheckboxChange}
                            ></input>
                            <label
                              htmlFor="outletNozzle"
                              className="d-flex align-items-center font-weight-bold"
                            >
                              Outlet Nozzle
                            </label>
                          </div>
                          {(index === 0 &&
                            formik?.values?.nozzle[1]?.outletNozzle) ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.nozzle[1]?.outletNozzle ? (
                            <>
                              <div className="d-flex mr-5">
                                <input
                                  type="checkbox"
                                  id="outletCounterFlange"
                                  name="outletCounterFlange"
                                  value="outletCounterFlange"
                                  className="mr-2"
                                  checked={
                                    (index === 0 &&
                                      formik?.values?.nozzle[1]
                                        ?.counterFlange) ||
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[1]?.counterFlange
                                  }
                                  onClick={() => {
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[1].counterFlange =
                                      !formik.values.multiStackVesselFormData[
                                        index
                                      ].nozzle[1].counterFlange;
                                  }}
                                  onChange={handleCheckboxChange}
                                  disabled={index === 0}
                                ></input>
                                <label
                                  htmlFor="outletCounterFlange"
                                  className="d-flex align-items-center font-weight-bold"
                                >
                                  Counter Flange
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {(index === 0 &&
                          formik?.values?.nozzle[1]?.outletNozzle) ||
                          formik?.values?.multiStackVesselFormData[index]
                            ?.nozzle[1]?.outletNozzle ? (
                          <div className="row mt-2">
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="size">Size</label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                isDisabled={index === 0}
                                placeholder="Select Size"
                                name={`nozzle[${1}].size`}
                                id="size"
                                options={filteredSizeOptions}
                                value={(() => {
                                  const flangeSize =
                                    (index === 0 &&
                                      formik?.values?.nozzle[1]?.size) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.size;

                                  const matchedOption =
                                    filteredSizeOptions.find(
                                      (option) => option.value === flangeSize
                                    );

                                  return matchedOption
                                    ? {
                                      label: matchedOption.label,
                                      value: matchedOption.value,
                                    }
                                    : null;
                                })()}
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "size",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="schedules">Schedules</label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                isDisabled={index === 0}
                                placeholder="Select Schedule"
                                className="w-100"
                                name={`nozzle[${1}].schedules`}
                                id="schedules"
                                options={schedules.map((sch) => ({
                                  label: sch.sch,
                                  value: sch.sch,
                                }))}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.schedules) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.schedules
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.schedules,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.schedules,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "schedules",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeRating">
                                Flange Rating
                              </label>
                              <Select
                                isClearable
                                styles={
                                  index === 0
                                    ? customStylesForDisabled
                                    : customStyles
                                }
                                isDisabled={index === 0}
                                placeholder="Flange Rating"
                                className="w-100"
                                name={`nozzle[${1}].flangeRating`} // Ensure correct index is used (0 in this case)
                                id="flangeRating"
                                options={flangeRatingOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.flangeRating,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "flangeRating",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeType">Flange Type</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Type"
                                className="w-100"
                                name={`nozzle[${1}].flangeType`} // Ensure correct index is used (0 in this case)
                                id="flangeType"
                                options={flangeTypeOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.flangeType) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.flangeType
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.flangeType,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.flangeType,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "flangeType",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="material">Material</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Material"
                                className="w-100"
                                name={`nozzle[${1}].material`}
                                id="material"
                                options={materials}
                                onChange={(selectedOption) => {
                                  if (selectedOption?.value) {
                                    formik.setFieldValue(
                                      "nozzle[1].material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                    handleMaterialSelect(selectedOption);
                                    multiStackHandleChange(
                                      index,
                                      1,
                                      "material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                  }
                                  formik.setFieldValue(
                                    `nozzle[1].materialCode`,
                                    null
                                  );
                                }}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.material) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.material
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.material,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.material,
                                    }
                                    : null
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Material Code
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                id="materialCode"
                                name={`nozzle[${1}]?.materialCode`}
                                className="w-100"
                                placeholder="Material Code"
                                options={materialCode}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.materialCode) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.materialCode
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.materialCode,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.materialCode,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "materialCode",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                // isClearable
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Outlet Orientation  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Orientation( Deg.)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Orientation"
                                className="w-100"
                                name={`nozzle[${1}]?.orientation`}
                                id="inletOrientation"
                                options={orientationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.orientation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.orientation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.orientation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.orientation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "orientation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Outlet Elevation */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Elevation (mm)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Elevation"
                                className="w-100"
                                name={`nozzle[${1}]?.elevation`}
                                id="multitsackInletElevation"
                                options={elevationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[1]?.elevation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[1]?.elevation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.elevation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[1]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[1]?.elevation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    1,
                                    "elevation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* vent */}
                      <div className="col-12 justify-content-center mt-4">
                        <div className="d-flex align-items-center row">
                          <div className="d-flex col-lg-2 col-md-4 col-sm-6">
                            <input
                              type="checkbox"
                              id="vent"
                              name="vent"
                              value="vent"
                              className="mr-2"
                              checked={
                                (index === 0 &&
                                  formik?.values?.nozzle[2]?.vent) ||
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.nozzle[2]?.vent
                              }
                              disabled={index === 0}
                              onClick={() => {
                                formik.values.multiStackVesselFormData[
                                  index
                                ].nozzle[2].vent =
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[2].vent;
                                if (
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[2].vent
                                ) {
                                  formik.values.multiStackVesselFormData[
                                    index
                                  ].nozzle[2].counterFlange = false;
                                }
                              }}
                              onChange={handleCheckboxChange}
                            ></input>
                            <label
                              htmlFor="vent"
                              className="d-flex align-items-center font-weight-bold"
                            >
                              Vent
                            </label>
                          </div>
                          {(index === 0 && formik?.values?.nozzle[2]?.vent) ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.nozzle[2]?.vent ? (
                            <>
                              <div className="d-flex mr-5">
                                <input
                                  type="checkbox"
                                  id="ventCounterFlange"
                                  name="ventCounterFlange"
                                  value="ventCounterFlange"
                                  className="mr-2"
                                  checked={
                                    (index === 0 &&
                                      formik?.values?.nozzle[2]
                                        ?.counterFlange) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.counterFlange
                                  }
                                  disabled={index === 0}
                                  onClick={() => {
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[2].counterFlange =
                                      !formik.values.multiStackVesselFormData[
                                        index
                                      ].nozzle[2].counterFlange;
                                  }}
                                  onChange={handleCheckboxChange}
                                ></input>
                                <label
                                  htmlFor="ventCounterFlange"
                                  className="d-flex align-items-center font-weight-bold"
                                >
                                  Counter Flange
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {(index === 0 && formik?.values?.nozzle[2]?.vent) ||
                          formik?.values?.multiStackVesselFormData[index]
                            ?.nozzle[2]?.vent ? (
                          <div className="row mt-2">
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="size">Size</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Size"
                                name={`nozzle[${2}].size`}
                                id="size"
                                options={filteredSizeOptions}
                                value={(() => {
                                  const flangeSize =
                                    (index === 0 &&
                                      formik?.values?.nozzle[2]?.size) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.size;

                                  const matchedOption =
                                    filteredSizeOptions.find(
                                      (option) => option.value === flangeSize
                                    );

                                  return matchedOption
                                    ? {
                                      label: matchedOption.label,
                                      value: matchedOption.value,
                                    }
                                    : null;
                                })()}
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "size",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="schedules">Schedules</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Schedule"
                                className="w-100"
                                name={`nozzle[${2}].schedules`}
                                id="schedules"
                                options={schedules.map((sch) => ({
                                  label: sch.sch,
                                  value: sch.sch,
                                }))}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeRating,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "schedules",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeRating">
                                Flange Rating
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Rating"
                                className="w-100"
                                name={`nozzle[${2}].flangeRating`} // Ensure correct index is used (0 in this case)
                                id="flangeRating"
                                options={flangeRatingOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeRating,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "flangeRating",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeType">Flange Type</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Type"
                                className="w-100"
                                name={`nozzle[${2}].flangeType`} // Ensure correct index is used (0 in this case)
                                id="flangeType"
                                options={flangeTypeOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.flangeType) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.flangeType
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeType,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.flangeType,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "flangeType",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="material">Material</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Material"
                                className="w-100"
                                name={`nozzle[${2}].material`} // Ensure correct index is used (0 in this case)
                                id="material"
                                options={materials}
                                onChange={(selectedOption) => {
                                  if (selectedOption?.value) {
                                    formik.setFieldValue(
                                      "nozzle[2].material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                    handleMaterialSelect(selectedOption);
                                    multiStackHandleChange(
                                      index,
                                      2,
                                      "material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                  }
                                  formik.setFieldValue(
                                    `nozzle[2].materialCode`,
                                    null
                                  );
                                }}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.material) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.material
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.material,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.material,
                                    }
                                    : null
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Material Code
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                id="materialCode"
                                name={`nozzle[${2}]?.materialCode`}
                                className="w-100"
                                placeholder="Material Code"
                                options={materialCode}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.materialCode) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.materialCode
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.materialCode,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.materialCode,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "materialCode",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Vent Orientation  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Orientation( Deg.)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Orientation"
                                className="w-100"
                                name={`nozzle[${2}]?.orientation`}
                                id="inletOrientation"
                                options={orientationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.orientation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.orientation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.orientation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.orientation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "orientation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Vent Elevation */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Elevation (mm)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Elevation"
                                className="w-100"
                                name={`nozzle[${2}]?.elevation`}
                                id="multitsackInletElevation"
                                options={elevationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[2]?.elevation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[2]?.elevation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.elevation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[2]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[2]?.elevation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    2,
                                    "elevation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* Drain */}
                      <div className="col-12 justify-content-center mt-4">
                        <div className="d-flex align-items-center row">
                          <div className="d-flex col-lg-2 col-md-4 col-sm-6">
                            <input
                              type="checkbox"
                              id="drain"
                              name="drain"
                              value="drain"
                              className="mr-2"
                              checked={
                                (index === 0 &&
                                  formik?.values?.nozzle[3]?.drain) ||
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.nozzle[3]?.drain
                              }
                              disabled={index === 0}
                              onClick={() => {
                                formik.values.multiStackVesselFormData[
                                  index
                                ].nozzle[3].drain =
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[3].drain;
                                if (
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[3].drain
                                ) {
                                  formik.values.multiStackVesselFormData[
                                    index
                                  ].nozzle[3].counterFlange = false;
                                }
                              }}
                              onChange={handleCheckboxChange}
                            ></input>
                            <label
                              htmlFor="drain"
                              className="d-flex align-items-center font-weight-bold"
                            >
                              Drain
                            </label>
                          </div>
                          {(index === 0 && formik?.values?.nozzle[3]?.drain) ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.nozzle[3]?.drain ? (
                            <>
                              <div className="d-flex mr-5">
                                <input
                                  type="checkbox"
                                  id="drainCounterFlange"
                                  name="drainCounterFlange"
                                  value="drainCounterFlange"
                                  className="mr-2"
                                  checked={
                                    (index === 0 &&
                                      formik?.values?.nozzle[3]
                                        ?.counterFlange) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.counterFlange
                                  }
                                  disabled={index === 0}
                                  onClick={() => {
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[3].counterFlange =
                                      !formik.values.multiStackVesselFormData[
                                        index
                                      ].nozzle[3].counterFlange;
                                  }}
                                  onChange={handleCheckboxChange}
                                ></input>
                                <label
                                  htmlFor="drainCounterFlange"
                                  className="d-flex align-items-center font-weight-bold"
                                >
                                  Counter Flange
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {(index === 0 && formik?.values?.nozzle[3]?.drain) ||
                          formik?.values?.multiStackVesselFormData[index]
                            ?.nozzle[3]?.drain ? (
                          <div className="row mt-2">
                            <div className="col-lg-2 col-md-4 col-sm-12">
                              <label htmlFor="size">Size</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Size"
                                name={`nozzle[${3}].size`}
                                id="size"
                                options={filteredSizeOptions}
                                value={(() => {
                                  const flangeSize =
                                    (index === 0 &&
                                      formik?.values?.nozzle[3]?.size) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.size;

                                  const matchedOption =
                                    filteredSizeOptions.find(
                                      (option) => option.value === flangeSize
                                    );

                                  return matchedOption
                                    ? {
                                      label: matchedOption.label,
                                      value: matchedOption.value,
                                    }
                                    : null;
                                })()}
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "size",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="schedules">Schedules</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Schedule"
                                className="w-100"
                                name={`nozzle[${3}].schedules`}
                                id="schedules"
                                options={schedules.map((sch) => ({
                                  label: sch.sch,
                                  value: sch.sch,
                                }))}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.schedules) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.schedules
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.schedules,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.schedules,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "schedules",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeRating">
                                Flange Rating
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Rating"
                                className="w-100"
                                name={`nozzle[${3}].flangeRating`} // Ensure correct index is used (0 in this case)
                                id="flangeRating"
                                options={flangeRatingOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.flangeRating,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "flangeRating",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeType">Flange Type</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Flange Type"
                                className="w-100"
                                name={`nozzle[${3}].flangeType`} // Ensure correct index is used (0 in this case)
                                id="flangeType"
                                options={flangeTypeOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.flangeType) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.flangeType
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.flangeType,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.flangeType,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "flangeType",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="material">Material</label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                placeholder="Select Material"
                                className="w-100"
                                name={`nozzle[${3}].material`} // Ensure correct index is used (0 in this case)
                                id="material"
                                options={materials}
                                onChange={(selectedOption) => {
                                  if (selectedOption?.value) {
                                    formik.setFieldValue(
                                      "nozzle[3].material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                    handleMaterialSelect(selectedOption);
                                    multiStackHandleChange(
                                      index,
                                      3,
                                      "material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                  }
                                  formik.setFieldValue(
                                    `nozzle[3].materialCode`,
                                    null
                                  );
                                }}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.material) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.material
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.material,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.material,
                                    }
                                    : null
                                }
                              />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Material Code
                              </label>
                              <Select
                                isClearable
                                isDisabled
                                styles={customStylesForDisabled}
                                id="materialCode"
                                name={`nozzle[${3}]?.materialCode`}
                                className="w-100"
                                placeholder="Material Code"
                                options={materialCode}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.materialCode) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.materialCode
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.materialCode,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.materialCode,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "materialCode",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Drain Orientation  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Orientation( Deg.)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Orientation"
                                className="w-100"
                                name={`nozzle[${3}]?.orientation`}
                                id="inletOrientation"
                                options={orientationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.orientation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.orientation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.orientation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.orientation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "orientation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Drain Elevation */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Elevation (mm)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Elevation"
                                className="w-100"
                                name={`nozzle[${3}]?.elevation`}
                                id="multitsackInletElevation"
                                options={elevationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[3]?.elevation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[3]?.elevation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.elevation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[3]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[3]?.elevation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    3,
                                    "elevation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* Process */}
                      <div className="col-12 justify-content-center mt-4">
                        <div className="d-flex align-items-center row">
                          {/* Process Checkbox  */}
                          <div className="d-flex col-lg-2 col-md-4 col-sm-6">
                            <input
                              type="checkbox"
                              id="process"
                              name="process"
                              value="process"
                              className="mr-2"
                              checked={
                                (index === 0 &&
                                  formik?.values?.nozzle[4]?.process) ||
                                formik?.values?.multiStackVesselFormData[index]
                                  ?.nozzle[4]?.process
                              }
                              disabled={index === 0}
                              onClick={() => {
                                formik.values.multiStackVesselFormData[
                                  index
                                ].nozzle[4].process =
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[4].process;
                                if (
                                  !formik.values.multiStackVesselFormData[index]
                                    .nozzle[4].process
                                ) {
                                  formik.values.multiStackVesselFormData[
                                    index
                                  ].nozzle[4].counterFlange = false;
                                }
                              }}
                              onChange={handleCheckboxChange}
                            ></input>
                            <label
                              htmlFor="process"
                              className="d-flex align-items-center font-weight-bold"
                            >
                              Process
                            </label>
                          </div>
                          {(index === 0 &&
                            formik?.values?.nozzle[4]?.process) ||
                            formik?.values?.multiStackVesselFormData[index]
                              ?.nozzle[4]?.process ? (
                            <>
                              {/* Process Counter Flange  */}
                              <div className="d-flex mr-5">
                                <input
                                  type="checkbox"
                                  id="processCounterFlange"
                                  name="processCounterFlange"
                                  value="processCounterFlange"
                                  className="mr-2"
                                  checked={
                                    (index === 0 &&
                                      formik?.values?.nozzle[4]
                                        ?.counterFlange) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.counterFlange
                                  }
                                  disabled={index === 0}
                                  onClick={() => {
                                    formik.values.multiStackVesselFormData[
                                      index
                                    ].nozzle[4].counterFlange =
                                      !formik.values.multiStackVesselFormData[
                                        index
                                      ].nozzle[4].counterFlange;
                                  }}
                                  onChange={handleCheckboxChange}
                                ></input>
                                <label
                                  htmlFor="processCounterFlange"
                                  className="d-flex align-items-center font-weight-bold"
                                >
                                  Counter Flange
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>

                        {(index === 0 && formik?.values?.nozzle[4]?.process) ||
                          formik?.values?.multiStackVesselFormData[index]
                            ?.nozzle[4]?.process ? (
                          <div className="row mt-2">
                            {/* Process Size  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="size">Size</label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Select Size"
                                name={`multiStackVesselFormData[${index}].nozzle[${4}].size`}
                                id="size"
                                options={filteredSizeOptions}
                                value={(() => {
                                  const flangeSize =
                                    (index === 0 &&
                                      formik?.values?.nozzle[4]?.size) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.size;

                                  const matchedOption =
                                    filteredSizeOptions.find(
                                      (option) => option.value === flangeSize
                                    );

                                  return matchedOption
                                    ? {
                                      label: matchedOption.label,
                                      value: matchedOption.value,
                                    }
                                    : null;
                                })()}
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "size",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Process Schedules  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="schedules">Schedules</label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Select Schedule"
                                className="w-100"
                                name={`multiStackVesselFormData[${index}].nozzle[${4}].schedules`}
                                id="schedules"
                                options={schedules.map((sch) => ({
                                  label: sch.sch,
                                  value: sch.sch,
                                }))}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.schedules) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.schedules
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.schedules,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.schedules) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.schedules,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "schedules",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Process Flange Rating  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeRating">
                                Flange Rating
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Flange Rating"
                                className="w-100"
                                name={`multiStackVesselFormData[${index}].nozzle[${4}].flangeRating`} // Ensure correct index is used (0 in this case)
                                id="flangeRating"
                                options={flangeRatingOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.flangeRating) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.flangeRating
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.flangeRating,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.flangeRating) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.flangeRating,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "flangeRating",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Process Flange Type  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="flangeType">Flange Type</label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Flange Type"
                                className="w-100"
                                name={`multiStackVesselFormData[${index}].nozzle[${4}].flangeType`}
                                id="flangeType"
                                options={flangeTypeOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.flangeType) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.flangeType
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.flangeType,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.flangeType) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.flangeType,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "flangeType",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                              />
                            </div>
                            {/* Process Material  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="material">Material</label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Select Material"
                                className="w-100"
                                name={`multiStackVesselFormData[${index}].nozzle[${4}].material`} // Ensure correct index is used (0 in this case)
                                id="material"
                                options={materials}
                                onChange={(selectedOption) => {
                                  if (selectedOption?.value) {
                                    formik.setFieldValue(
                                      "nozzle[4].material",
                                      selectedOption
                                        ? selectedOption?.value
                                        : ""
                                    );
                                    handleMaterialSelect(selectedOption);
                                    multiStackHandleChange(
                                      index,
                                      4,
                                      "material",
                                      selectedOption ? selectedOption.value : ""
                                    );
                                  }
                                  formik.setFieldValue(
                                    `nozzle[4].materialCode`,
                                    null
                                  );
                                }}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.material) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.material
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.material,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.material) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.material,
                                    }
                                    : null
                                }
                              />
                            </div>
                            {/* Process Material Code  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Material Code
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                id="materialCode"
                                name={`nozzle[${4}]?.materialCode`}
                                className="w-100"
                                placeholder="Material Code"
                                options={materialCode}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.materialCode) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.materialCode
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.materialCode,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.materialCode) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.materialCode,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "materialCode",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                // isClearable
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Process Orientation  */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Orientation( Deg.)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Orientation"
                                className="w-100"
                                name={`nozzle[${4}]?.orientation`}
                                id="inletOrientation"
                                options={orientationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.orientation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.orientation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.orientation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.orientation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.orientation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "orientation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {/* Process Elevation */}
                            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                              <label htmlFor="MaterialCode">
                                Elevation (mm)
                              </label>
                              <Select
                                isClearable
                                styles={customStylesForDisabled}
                                isDisabled
                                placeholder="Elevation"
                                className="w-100"
                                name={`nozzle[${4}]?.elevation`}
                                id="multitsackInletElevation"
                                options={elevationOptions}
                                value={
                                  (index === 0 &&
                                    formik?.values?.nozzle[4]?.elevation) ||
                                    formik?.values?.multiStackVesselFormData[
                                      index
                                    ]?.nozzle[4]?.elevation
                                    ? {
                                      label:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.elevation,
                                      value:
                                        (index === 0 &&
                                          formik?.values?.nozzle[4]
                                            ?.elevation) ||
                                        formik?.values
                                          ?.multiStackVesselFormData[index]
                                          ?.nozzle[4]?.elevation,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  multiStackHandleChange(
                                    index,
                                    4,
                                    "elevation",
                                    selectedOption ? selectedOption?.value : ""
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        {formik?.values?.multistackvessel > 0 &&
          formik?.values?.multistackvessel < 5 && (
            <div className="d-flex align-items-center justify-content-end w-100">
              {/* <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    formik?.values?.multistackvessel > 0 &&
                    formik?.values?.multistackvessel < 5
                  ) {
                    formik?.setFieldValue(
                      "multistackvessel",
                      formik?.values?.multistackvessel + 1
                    );
                  }
                }}
              >
                Add New Stack Vessel {formik?.values?.multistackvessel}
              </button> */}
            </div>
          )}
      </Accordion>

      <hr />
    </div>
  );
};

export default MultiStackpage;

{
  /* <BankBundleTable
formik={formik}
// currentIndex={index}/
divideEqually={
  formik?.values?.multiStackVesselFormData[index]
    ?.divideEqually
}
setDivideEqually={`multiStackVesselFormData[${index}].divideEqually`}
totalBank={
  formik?.values?.multiStackVesselFormData[index]
    ?.bank
}
installedCapacity={
  formik?.values?.multiStackVesselFormData[index]
    ?.installedCapacity
}
setInstalledCapacity={`bundleHeatLoad[${index}]`}
bundleTableData={
  formik?.values?.multiStackVesselFormData[index]
    ?.bundleTableData
}
setBundleTableData={`multiStackVesselFormData[${index}].bundleTableData`}
heatDutyKW={
  formik?.values?.multiStackVesselFormData[index]
    ?.heatDutyKW
}
voltage={
  formik?.values?.multiStackVesselFormData[index]
    ?.voltage
}
setVoltageCalculation={`multiStackVesselFormData[${index}].voltage_calculation`}
designmargin={formik?.values?.designmargin}
multiStackVessel={formik?.values?.multistackvessel}
calculateButtonVisibility={true}
calculateCalculatedCapacity={false}
calculateBundleHeatLoad={false}
/> */
}
