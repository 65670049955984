import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function PriceList() {
  const [itemList, setItemList] = useState([]);
  const [price, setPrice] = useState([]);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [priceId, setPriceId] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.CONTAINS },
    material_category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    material: { value: null, matchMode: FilterMatchMode.CONTAINS },
    size: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    price: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Fetch distinct values for dropdowns
  const distinctNames = [...new Set(itemList.map((item) => item.name))];
  const distinctMaterialCategories = [...new Set(itemList.map((item) => item.material_category))];
  const distinctSizes = [...new Set(itemList.map((item) => item.size))];
  const distinctTypes = [...new Set(itemList.map((item) => item.type))];
  const distinctUnits = [...new Set(itemList.map((item) => item.unit))];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/bom-prices");
      if (response.status === 200) {
        setItemList(
          response.data.data.map((dataItem) => {
            return {
              id: dataItem.id ? dataItem.id : "-",
              name: dataItem.name ? dataItem.name : "-",
              material_category: dataItem.material_category ? dataItem.material_category : "-",
              material: dataItem.material ? dataItem.material : "-",
              size: dataItem.size ? dataItem.size : "-",
              class: dataItem.class ? dataItem.class : "-",
              type: dataItem.type ? dataItem.type : "-",
              unit: dataItem.unit ? dataItem.unit : "-",
              price: dataItem.price ? dataItem.price : "-",
            };
          })
        );
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (priceId) => {
    try {
      const response = await axiosPrivate.delete(`/bom-prices/${priceId}`);
      if (response.status === 200) {
        setPrice(price.filter((price) => price.id !== priceId));
        showSuccessToast("Item deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(`Error deleting price: ${error.message}`);
    } finally {
      setPriceId(null);
    }
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const onColumnFilterChange = (event, field) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const globalValue = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <Link to="/price/add" className="text-decoration-none">
          <button type="button" className="btn btn-primary">
            <i className="fe fe-plus mr-2" />
            Add
          </button>
        </Link>
        <InputText
          type="search"
          value={globalValue || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/price/edit/${rowData.id}`}>
          <FaEdit
            color="green"
            style={{ cursor: "pointer", fontSize: "20px" }}
          />
        </Link>
        <FaTrash
          color="red"
          style={{ cursor: "pointer", fontSize: "20px", marginLeft: "10px" }}
          onClick={() => {
            setPriceId(rowData.id);
            setDeleteConfirmationVisible(true);
          }}
        />
      </div>
    );
  };

  const header = renderHeader();
  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Price</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={itemList}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e?.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters?.global?.value}
                  emptyMessage="No price found"
                >
                  <Column
                    body={serialNumberBodyTemplate}
                    header="S.No"
                    headerStyle={{ width: "10%" }}
                  />
                  <Column
                    field="name"
                    header="Name"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <Dropdown
                        value={filters.name?.value || ""}
                        options={distinctNames.map((name) => ({ label: name, value: name }))}
                        onChange={(e) => onColumnFilterChange(e, "name")}
                        placeholder="Search by name"
                        showClear
                      />
                    }
                  />
                  <Column
                    field="material_category"
                    header="Material Category"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <Dropdown
                        value={filters.material_category?.value || ""}
                        options={distinctMaterialCategories.map((category) => ({
                          label: category,
                          value: category,
                        }))}
                        onChange={(e) => onColumnFilterChange(e, "material_category")}
                        placeholder="Search by material category"
                      />
                    }
                  />
                  <Column
                    field="material"
                    header="Material"
                    filter
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filterElement={
                      <InputText
                        value={filters.material?.value || ""}
                        onChange={(e) => onColumnFilterChange(e, "material")}
                        placeholder="Search by material"
                      />
                    }
                  />
                  <Column
                    field="size"
                    header="Size"
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filter
                    filterElement={
                      <Dropdown
                        value={filters.size?.value || ""}
                        options={distinctSizes.map((size) => ({ label: size, value: size }))}
                        onChange={(e) => onColumnFilterChange(e, "size")}
                        placeholder="Search by size"
                        showClear
                      />
                    }
                  />
                  <Column
                    field="type"
                    header="Type"
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filter
                    filterElement={
                      <Dropdown
                        value={filters.type?.value || ""}
                        options={distinctTypes.map((type) => ({ label: type, value: type }))}
                        onChange={(e) => onColumnFilterChange(e, "type")}
                        placeholder="Search by type"
                        showClear
                      />
                    }
                  />
                  <Column
                    field="unit"
                    header="Unit"
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filter
                    filterElement={
                      <Dropdown
                        value={filters.unit?.value || ""}
                        options={distinctUnits.map((unit) => ({ label: unit, value: unit }))}
                        onChange={(e) => onColumnFilterChange(e, "unit")}
                        placeholder="Search by unit"
                        showClear
                      />
                    }
                  />
                  <Column
                    field="price"
                    header="Price"
                    showFilterMatchModes={false}
                    filterMenuStyle={{
                      width: '20rem', // Adjust width if needed
                    }}
                    filter
                    filterElement={
                      <InputText
                        value={filters.price?.value || ""}
                        onChange={(e) => onColumnFilterChange(e, "price")}
                        placeholder="Search by price"
                      />
                    }
                  />
                  <Column
                    field="action"
                    header="Actions"
                    body={actionBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            draggable={false}
            visible={deleteConfirmationVisible}
            onHide={() => setDeleteConfirmationVisible(false)}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setDeleteConfirmationVisible(false);
                    setPriceId(null);
                  }}
                />
                <Button
                  label="Delete"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(priceId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this item?</p>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default PriceList;