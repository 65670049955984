const costComponent = {
  flangepipesize: "",
  flangePrice: "",
  flangeTotalCost: "",
  flangeWeight: "",
  vesselWeight: "",
  vesselMaterialCC: "",
  vesselPrice: "",
  vesselTotalPrice: "",
  skinSensor: "",
  skinSensorQuantity: "",
  skinSensorTotalCost: "",
  tubeSensor: "",
  tubeSensorQuantity: "",
  tubeSensorTotalCost: "",
  proceessSensor: "",
  proceessSensorQuantity: "",
  proceessSensorTotalCost: "",
  baffleWeight: "",
  bafflePrice: "",
  baffleTotalCost: "",
  inletNozzlePrice: "",
  outletNozzlePrice: "",
  drainNozzlePrice: "",
  ventNozzlePrice: "",
  processNozzlePrice: "",
  dummyNozzlePrice: "",
  inletLength: "",
  inletPrice: "",
  outletLength: "",
  outletPrice: "",
  drainLength: "",
  drainPrice: "",
  ventLength: "",
  ventPrice: "",
  processLength: "",
  processPrice: "",
  dummyLength: "",
  dummyPrice: "",
  heating_element_price: "",
  MGOPrice: "",
  ElementPrice: "",
  HeatingElementTotalCost: "",
  HeatingElementFixturePrice: "",
  HeatingElementFixtureTotalCost: "",
  TerminalBoxPrice: "",
  connectionPrice: "",
  lugSupport: "",
  insulation: "",
  gasKits: "",
  paint: "",
  other: "",
  atex: "",
  // tubesheetcost: "",
  tubesheetcostprice: "", // Added for actual cost input
  vesselcost: "",
  vesselcostprice: "", // Added for price cost input
  shellflangecost: "",
  shellflangecostprice: "", // Added for price cost input
  radiographycost: "",
  radiographycostprice: "", // Added for price cost input
  paintingcost: "",
  paintingcostprice: "", // Added for price cost input
  // ------------------------------------------------------

  tubeSheetCostType: false,
  tubeSheetCost: 0,

  vesselCostType: false,
  vesselCost: 0,

  shellFlangeCostType: false,
  shellFlangeCost: 0,

  radiographyCostType: false,
  radiographyCost: 0,

  paintingCost: 0,

  baffleCostType: false,
  baffleCost: 0,

  terminalBoxCostType: false,
  terminalBoxCost: 0,

  insulationCostType: false,
  insulationCost: 0,

  insulationPrice: {
    glassWoolRate: 0,
    claddingSheetRate: 0,
    labourCharges: 0,
    miscCharges: 0,
    claddingCostInsulation: 0,
    glassWoolCostInsulation: 0,

  },

  material: {
    SS316: null,
    SS316L: null,
    SS304H: null,
    SS321H: null,
    SS321: null,
    _22SR_MgO: null,
    Tateho_MgO: null,
    MS: null,
    PressureParts_CS: null,
    SS304: null,
    GR11: null,
    INCOLOY800: null,
    LTCS: null,
    Duplex: null,
    SuperDuplex: null,
    NonPressureParts_CS: null,
    NonPressureParts_SS304: null,
    NonPressureParts_SS316: null,
  },

  //   RATE OF SHEATH TC / RTD / TH -
  // RATE OF SHEATH TT -
  // "RATE OF PROCESS TT- 		"
  // PRICE OF ALL GASKETS -

  // priceOfGaskets: null,
  // priceOfHardware: null,
  // priceOfConnections: null,
  // priceOfThermowell: null,
  priceOfTerminalBoxBasePlate: null,

  rateOfProcess: null,
  rateofsafety: 0, // process thermo couple
  rateofprocess: 0,
  rateOfSeathTT: null,

  miscellaneous: [
    {
      name: "",
      // material: null,
      // qty: null,
      // netWeight: null,
      // groundWeight: null,
      // unit: null,
      price: null,
    },
  ],

  // developLength: "",
  // oneSideColdLength: "",
  // secondSideColdLength: "",
  // hotLength: "",
  // numberOfElement: "",

  // MANUFACTURING COST @
  // MARGIN  @
  // CONTINGENCY  @

  manufacturingCost: 0,
  margin: 0,
  contingency: 0,
};

export default costComponent;
