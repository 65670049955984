import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";
import {
  flangeRatingOptions,
  heaterSheathTubeDiaOptions,
  priceUnitOptions,
  sheathTubeTypeOptions,
} from "../../utils/DropdownOptions";
import { customStyles } from "../../styles/CustomStylesForSelect";

function PriceAdd() {
  const [item, setItem] = useState({
    id: null,
    name: "",
    material_category: "",
    material: "",
    unit: null,
    price: null,
    size: null,
    type: null,
  });

  const { isLoading, setIsLoading } = useLoader();
  const [nameOptions, setNameOptions] = useState([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !item.name ||
      (!item.material && !item.material_category) ||
      !item.price
    ) {
      showErrorToast("Fill all fields");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .post("/bom-prices", {
        id: item.id,
        name: item.name,
        material_category: item.material_category,
        material: item.material,
        unit: item.unit,
        price: item.price,
        type: item.type,
        size: item.size,
      })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("Item added successfully");
        navigate("/price");
      })
      .catch((error) => {
        console.error(`Error adding Item: ${error}`);
        showErrorToast("Error adding Item");
        setIsLoading(false);
      });
  };

  const getMaterialsList = async () => {
    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        setNameOptions(
          [...new Set(data.map((dataItem) => dataItem.name))].map((name) => ({
            value: name,
            label: name,
          }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleNameChange = async (selectedName) => {
    setItem({
      ...item,
      name: selectedName.value,
      material_category: "",
      material: "",
    });

    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        const filteredData = data.filter(
          (dataItem) => dataItem.name === selectedName.value
        );

        setMaterialCategoryOptions(
          [
            ...new Set(
              filteredData.map((dataItem) => dataItem.material_category)
            ),
          ].map((material_category) => ({
            value: material_category,
            label: material_category,
          }))
        );

        setMaterialOptions(
          [...new Set(filteredData.map((dataItem) => dataItem.material))].map(
            (material) => ({
              value: material,
              label: material,
            })
          )
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleMaterialCategoryChange = async (selectedCategory) => {
    setItem({
      ...item,
      material_category: selectedCategory.value,
    });

    try {
      const response = await axiosPrivate.get("/sheath-material");
      if (response.status === 200) {
        const data = response.data.data;
        const filteredMaterials = data.filter(
          (material) =>
            material.material_category === selectedCategory.value &&
            material.name === item.name
        );

        setMaterialOptions(
          filteredMaterials.map((dataItem) => ({
            value: dataItem.material,
            label: dataItem.material,
          }))
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    getMaterialsList();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add Price</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="col">
                        <div className="w-100">
                          <div className="w-100 row mb-15">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="name">Name</label>
                              <Select
                                name="name"
                                id="name"
                                className="mr-3"
                                options={nameOptions}
                                styles={customStyles}
                                placeholder="Select Name"
                                onChange={(option) => handleNameChange(option)}
                                isDisabled={false}
                              />
                            </div>
                            {/* Material Category */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="material_category">
                                Material Category
                              </label>
                              <Select
                                name="material_category"
                                id="material_category"
                                className="mr-3"
                                options={materialCategoryOptions}
                                styles={customStyles}
                                placeholder="Select material category"
                                value={
                                  item.material_category && {
                                    value: item.material_category,
                                    label: item.material_category,
                                  }
                                }
                                onChange={(option) =>
                                  handleMaterialCategoryChange(option)
                                }
                                isDisabled={false}
                              />
                            </div>
                            {/* Material Code */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="material">Material</label>
                              <Select
                                name="material"
                                id="material"
                                className="mr-3"
                                options={materialOptions}
                                styles={customStyles}
                                placeholder="Select material"
                                value={
                                  item.material && {
                                    value: item.material,
                                    label: item.material,
                                  }
                                }
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    material: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="sub_type">Unit</label>
                              <Select
                                name="unit"
                                id="unit"
                                className="mr-3"
                                options={priceUnitOptions}
                                styles={customStyles}
                                value={
                                  item.unit && {
                                    value: item.unit,
                                    label: item.unit,
                                  }
                                }
                                placeholder="Select unit"
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    unit: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>

                          </div>
                          {item?.name == "TUBE" && <div className="row mb-30">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="type">Type</label>
                              <Select
                                name="type"
                                id="type"
                                className="mr-3"
                                options={sheathTubeTypeOptions}
                                styles={customStyles}
                                value={
                                  item.type
                                    ? {
                                      value: item.type,
                                      label: item.type,
                                    }
                                    : null
                                }
                                placeholder="Select Type"
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    type: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="size">Size</label>
                              <Select
                                name="size"
                                id="size"
                                className="mr-3"
                                options={heaterSheathTubeDiaOptions}
                                styles={customStyles}
                                value={
                                  item.size
                                    ? { value: item.size, label: `${item.size}` }
                                    : null
                                }
                                placeholder="Select Size"
                                onChange={(option) =>
                                  setItem({
                                    ...item,
                                    size: option?.value ?? option,
                                  })
                                }
                                isDisabled={false}
                              />
                            </div>
                          </div>}


                          <div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label htmlFor="price">Price</label>
                              <input
                                name="price"
                                type="text"
                                className="form-control"
                                placeholder="Item price"
                                value={item.price}
                                onChange={(e) =>
                                  setItem({ ...item, price: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/price">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceAdd;
