import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ProcessFluidsList = () => {
  const [processFluids, setProcessFluids] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [fluidId, setFluidId] = useState(null);

  // Define table headers
  const headers = [
    { name: "S.No", field: "serial_number", isCustom: true },
    { name: "Fluid Name", field: "name" },
    { name: "Initial Value", field: "initial_value" },
    { name: "Viscosity", field: "viscosity" },
    { name: "Molecular Weight", field: "molecular_weight" },
    { name: "Thermal Conductivity", field: "thermal_conductivity_in_c" },
    { name: "Density", field: "density_kg_m3" },
    { name: "Actions", field: "action", isCustom: true },
  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/process-fluids");
      if (response.status === 200) {
        setProcessFluids(response.data?.data);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (fluidId) => {
    try {
      const response = await axiosPrivate.delete(`/process-fluids/${fluidId}`);
      if (response.status === 200) {
        setProcessFluids(processFluids.filter((fluid) => fluid.id !== fluidId));
        showSuccessToast("Process fluid deleted successfully");
      }
    } catch (error) {
      showErrorToast(`Error deleting process fluid: ${error.message}`);
    } finally {
      setFluidId(null);
    }
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <Link to="/process-fluids/add" className="text-decoration-none">
          <button type="button" className="btn btn-primary">
            <i className="fe fe-plus mr-2" />
            Add
          </button>
        </Link>
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  // Custom templates for specific columns
  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/process-fluids/edit/${rowData.id}`}>
          <FaEdit
            color="green"
            style={{ cursor: "pointer", fontSize: "20px" }}
          />
        </Link>
        <FaTrash
          color="red"
          style={{
            cursor: "pointer",
            fontSize: "20px",
            marginLeft: "10px",
          }}
          onClick={() => {
            setFluidId(rowData.id);
            setDeleteConfirmationVisible(true);
          }}
        />
      </div>
    );
  };

  const valueBodyTemplate = (rowData, { field }) => {
    return rowData[field];
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Process Fluids</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={processFluids}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters?.global?.value}
                  emptyMessage="No process fluids found"
                >
                  {headers.map((header, index) => (
                    <Column
                      key={index}
                      field={header.field}
                      header={header.name}
                      body={
                        header.isCustom
                          ? header.field === "serial_number"
                            ? serialNumberBodyTemplate
                            : header.field === "action"
                              ? actionBodyTemplate
                              : null
                          : (rowData) =>
                            valueBodyTemplate(rowData, { field: header.field })
                      }
                      sortable={!header.isCustom}
                      bodyStyle={{
                        textAlign:
                          header.field === "left"
                      }}
                      headerStyle={{
                        width: header.field === "action" ? "15%" : "",
                      }}
                    />
                  ))}
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            draggable={false}
            visible={deleteConfirmationVisible}
            onHide={() => setDeleteConfirmationVisible(false)}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setDeleteConfirmationVisible(false);
                    setFluidId(null);
                  }}
                />
                <Button
                  label="Delete"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(fluidId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this process fluid?</p>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ProcessFluidsList;