import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "react-flow-renderer/dist/style.css";

import PermissionsProvider from "./context/permissions/PermissionsProvider.js";
import LoaderProvider from "./context/Loader/LoaderProvider.js";

ReactDOM.createRoot(document.getElementById("root")).render(
  <LoaderProvider>
    <PermissionsProvider>
      <App />
    </PermissionsProvider>
  </LoaderProvider>
);
