import React from "react";
const BOMControlPanel = ({ formik }) => {
  const { values } = formik;
  const tableParentDiv = {
    display: "flex",
    margin: "20px 20px 20px 20px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const trClass = {
    height: "22px",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  const thickBorder = {
    border: "1px solid black",
  };
  const currentMCCBRatingRanges = [
    { min: 0, max: 25, label: "25 A" },
    { min: 25, max: 40, label: "40 A" },
    { min: 40, max: 100, label: "100 A" },
    { min: 100, max: 160, label: "160 A" },
    { min: 160, max: 250, label: "250 A" },
    { min: 250, max: 400, label: "400 A" },
    { min: 400, max: 630, label: "630 A" },
    { min: 630, max: 800, label: "800 A" },
    { min: 800, max: 1000, label: "1000 A" },
    { min: 1000, max: 1250, label: "1250 A" },
    { min: 1250, max: 1600, label: "1600 A" },
    { min: 1600, max: 2000, label: "2000 A" },
    { min: 2000, max: 2500, label: "2500 A" },
    { min: 2500, max: 3200, label: "3200 A" },
    { min: 3200, max: 4000, label: "4000 A" }
  ];
  const currentContactorRating = [
    { min: 0, max: 6, label: "6 A" },
    { min: 6, max: 9, label: "9 A" },
    { min: 9, max: 12, label: "12 A" },
    { min: 12, max: 18, label: "18 A" },
    { min: 18, max: 25, label: "25 A" },
    { min: 25, max: 32, label: "32 A" },
    { min: 32, max: 38, label: "38 A" },
    { min: 38, max: 40, label: "40 A" },
    { min: 40, max: 50, label: "50 A" },
    { min: 50, max: 65, label: "65 A" },
    { min: 65, max: 80, label: "80 A" },
    { min: 80, max: 95, label: "95 A" },
    { min: 95, max: 120, label: "120 A" },
    { min: 120, max: 160, label: "160 A" },
    { min: 160, max: 200, label: "200 A" },
    { min: 200, max: 250, label: "250 A" },
    { min: 250, max: 300, label: "300 A" },
    { min: 300, max: 400, label: "400 A" },
    { min: 400, max: 500, label: "500 A" },
    { min: 500, max: 630, label: "630 A" }
  ];

  const thristorRating = [
    { min: 0, max: 30, label: "30 A" },
    { min: 30, max: 40, label: "40 A" },
    { min: 40, max: 60, label: "60 A" },
    { min: 60, max: 90, label: "90 A" },
    { min: 90, max: 120, label: "120 A" },
    { min: 120, max: 150, label: "150 A" },
    { min: 150, max: 180, label: "180 A" },
    { min: 180, max: 210, label: "210 A" },
    { min: 210, max: 300, label: "300 A" },
    { min: 300, max: 400, label: "400 A" },
    { min: 400, max: 450, label: "450 A" },
    { min: 450, max: 500, label: "500 A" },
    { min: 500, max: 600, label: "600 A" },
    { min: 600, max: 700, label: "700 A" },
    { min: 700, max: 800, label: "800 A" },
    { min: 800, max: 1000, label: "1000 A" },
    { min: 1000, max: 1100, label: "1100 A" },
    { min: 1100, max: 1200, label: "1200 A" },
    { min: 1200, max: 1400, label: "1400 A" },
  ]

  const KVRating = [
    { min: 0, max: 50, label: "500 VA" },
    { min: 50, max: 200, label: "1 KVA" },
    { min: 200, max: 400, label: "1.5 KVA" },
    { min: 400, label: "2 KVA" },
  ];

  function getCurrentRatingLabel(currentRating) {
    const range = currentMCCBRatingRanges.find(range => currentRating >= range.min && currentRating < range.max);
    return range ? range.label : "N/A";
  }

  function getThyristorRating(currentRating) {
    const range = thristorRating.find(range => currentRating >= range.min && currentRating < range.max);
    return range ? range.label : "N/A";
  }

  function getContactorRating(currentRating) {
    const range = currentContactorRating.find(range => currentRating >= range.min && currentRating < range.max);
    return range ? range.label : "N/A";
  }

  function getKVRating(installedCapacity) {
    const range = KVRating.find(range => {
      if (range.max !== undefined) {
        return installedCapacity >= range.min && installedCapacity < range.max;
      }
      return installedCapacity >= range.min;
    });
    return range ? range.label : "N/A";
  }

  const data = [
    {
      description: `${formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"} - LSIG RELEASE . ${values.cp_breakingCapacity} @ ${values.voltage} V`,
      modelNo: `${getCurrentRatingLabel(values.cp_currentRating)}, 3P`,
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 1,
      price: 100
    },
    {
      description: `${formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"} HANDLE`,
      modelNo: "",
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 1,
      price: 100
    },
    {
      description: `${formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"} C/O CONTACT`,
      modelNo: "",
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 2,
      price: 100
    },
    {
      description: `${formik.values.cp_currentRating > 600 ? "ACB" : "MCCB"} SPREADER`,
      modelNo: "",
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 2,
      price: 100
    },
    {
      description: `POWER CONTACTOR 3P - ${values.cp_controlSupplyVoltage} COIL`,
      modelNo: `${getContactorRating(values.cp_currentRating)},AC3`,
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 1,
      price: 100
    },
    {
      description: "POWER CONTACTOR ADD ON BLOCK",
      modelNo: "4 NO",
      make: "SCHNEIDER / L&T / SIEMENS / ABB",
      qty: 1,
      price: 100
    },
    {
      description: `THYRISTOR ${formik.values.cp_thyristorLeg}, ${formik.values.cp_thyristorFiringType}, ${formik.values.voltage} V, ${getThyristorRating(values.cp_currentRating)}, ${formik.values.frequency} ${formik.values.cp_currentRating > 90
        ? " , OTA + OTT with fan"
        : formik.values.cp_currentRating > 60
          ? " , OTA + OTT"
          : ""
        },       WITH 4-20mA FIRING INPUT`,
      modelNo: "-",
      make: "CD AUTOMATION / REPUTED",
      qty: 1,
      price: 100,
    },
    { description: "MCB - 10KA CURVE", modelNo: "4P,6A", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "MCB - 10KA CURVE", modelNo: "DP,6A", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 6, price: 100 },
    { description: `INDICATION LAMP - 22.5MM DIA - ${values.cp_controlSupplyVoltage}`, modelNo: "RED", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 5, price: 100 },
    { description: `INDICATION LAMP - 22.5MM DIA - ${values.cp_controlSupplyVoltage}`, modelNo: "AMBER", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 2, price: 100 },
    { description: `INDICATION LAMP - 22.5MM DIA - ${values.cp_controlSupplyVoltage}`, modelNo: "GREEN", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 2, price: 100 },
    { description: "PUSH BUTTON+ ADD ON BLOCK - 22.5MMDIA", modelNo: "RED", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "PUSH BUTTON+ ADD ON BLOCK - 22.5MMDIA", modelNo: "GREEN", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "PUSH BUTTON+ ADD ON BLOCK - 22.5MMDIA", modelNo: "BLACK", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "PUSH BUTTON+ ADD ON BLOCK - 22.5MMDIA", modelNo: "BLUE", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "EMENGENCY PB +ADD ON BLOCK", modelNo: "RED", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "SELECTOR SWITCH WITH OFF - AUTO/MANUAL", modelNo: "6A", make: "SALZER", qty: 1, price: 100 },
    { description: "ELR + CBCT - 300-12000MA", modelNo: "DIGITAL", make: "PROCK DVS", qty: 1, price: 100 },
    { description: "AUXILLARY REALY WITH BASE", modelNo: `4C/O, ${values.cp_controlSupplyVoltage}`, make: "PHOENIX", qty: 15, price: 100 },
    { description: "AUXILLARY REALY WITH BASE", modelNo: "4C/O, 24V DC", make: "PHOENIX", qty: 15, price: 100 },
    { description: "SIGNAL ISOLATOR", modelNo: "", make: "PHOENIX", qty: 1, price: 100 },
    { description: "CONTROL FUSE & BASE", modelNo: "", make: "SIEMENS", qty: 7, price: 100 },
    { description: `CURRENT TRF -${getCurrentRatingLabel(values.cp_currentRating)}/5A,CL-1.10VA`, modelNo: "RESIN CAST", make: "KALPA", qty: 3, price: 100 },
    { description: `CONTROL TRF -${values.voltage} V/${values.cp_controlSupplyVoltage}. ${getKVRating(values.installedCapacity)}`, modelNo: "RESIN CAST", make: "KALPA", qty: 1, price: 100 },
    { description: "", modelNo: "", make: "", qty: 0, price: 100 },
    { description: "AMMETER,CL-1 96SQMM + SS", modelNo: "ANALOG", make: "RISHAB", qty: 1, price: 100 },
    { description: "VOLTMETER,CL-1 96SQMM + SS", modelNo: "ANALOG", make: "RISHAB", qty: 1, price: 100 },
    { description: "MFM METER,CL-1 96SQMM WITH RS485", modelNo: "DIGITAL - 3P", make: "SCHNEIDER / L&T / SIEMENS / ABB", qty: 1, price: 100 },
    { description: "", modelNo: "", make: "", qty: 0, price: 100 },
    { description: `POWER SUPPLY - INPUT ${values.cp_controlSupplyVoltage}`, modelNo: "5A, 24V DC", make: "OMRON", qty: 1, price: 100 },
    { description: "TB+ACCESSORIES", modelNo: "AS REQUIRED", make: "ELEMEX", qty: 1, price: 100 },
    { description: "CTTB + END PLATES", modelNo: "AS REQUIRED", make: "ELEMEX", qty: 1, price: 100 },
    { description: "POTENTIOMETER", modelNo: "AS REQUIRED", make: "ELEMEX", qty: 1, price: 100 },
    { description: "POWER SOCKET", modelNo: "", make: "ANCHOR", qty: 1, price: 100 },
    { description: `PANEL LAMP - ${values.cp_controlSupplyVoltage}`, modelNo: "5W LED", make: "PHILIPS", qty: 1, price: 100 },
    { description: "DOOR LIMIT SWITCH", modelNo: "", make: "KAYCEE", qty: 1, price: 100 },
    { description: "HOOTER", modelNo: "", make: "ALAN", qty: 1, price: 100 },
    { description: `SPACE HEATER - ${values.cp_controlSupplyVoltage}`, modelNo: "100WATTS", make: "APT", qty: 1, price: 100 },
    { description: "THERMOSAT", modelNo: "", make: "APT", qty: 1, price: 100 },
    {
      description: "COOLING FAN WITH FILTER",
      modelNo: `8" ${values.cp_controlSupplyVoltage}`,
      make: "HCOOL",
      qty: values.multistackvessel,
      price: 100
    },
    { description: `ENCLOSURE (${values.cp_enclosureSizeHeight}H X ${values.cp_enclosureSizeWidth}W X ${values.cp_enclosureSizeDepth}D)MM , ${values.cp_ipRating}`, modelNo: `${values.cp_controlPanelMOC}, ${values.cp_paintShadeMOC}`, make: "", qty: 1, price: 100 },
    { description: `BUSBAR - 1RX40X10 TINNED ${values.cp_busBarMaterial}`, modelNo: `40X10 ${values.cp_busBarMaterial}`, make: "REPUTED", qty: 1, price: 100 },
    { description: `EARTH BUS - 1RX25X6 - TINNED ${values.cp_busBarMaterial}`, modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: `INST BUS - 1RX15 x 6 - TINNED ${values.cp_busBarMaterial}`, modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "BUSBAR SUPPORTER + SHROUDING", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "HEAT SHRINKABLE SLEEVES", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "PVC DUCT & DINRAIL", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "CONTROL CABLE FRLS", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "INST CABLE", modelNo: "AS REQUIRED", make: "REPUTED", qty: 1, price: 100 },
    { description: "NAME PLATE", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "LUGS, FERRULES & SPIRALS", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    { description: "HARDWARES & ACCESSOROIES", modelNo: "", make: "REPUTED", qty: 1, price: 100 },
    {
      description: "TEMP CONTROLLERS",
      modelNo: "DC 1010",
      make: "REPUTED",
      qty: Number(values?.sensor[0]?.quantity) + Number(values?.sensor[1]?.quantity),
      price: 100
    },
    { description: "PROCESS CONTROLLERS", modelNo: "DC 1010", make: "REPUTED", qty: `${values?.sensor[2]?.quantity}`, price: 100 },
  ];

  return (
    <div style={{ ...tableParentDiv }
    }>
      <table
        style={{
          ...tableClass
        }}
        cellSpacing={0}
      >
        <tbody style={{ margin: "10px 0px" }}>
          <tr style={trClass}>
            <td
              style={{ ...tdClass, ...thickBorder }}
              colSpan={8}
              bgcolor="#C5D9F1"
            >
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "24px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                ANNEXURE-1 BOQ
              </span>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} bgcolor="#EEECE1"></td>
            <td style={tdClass} colSpan={7} bgcolor="#EEECE1"></td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} bgcolor="#EEECE1"></td>
            <td style={tdClass} colSpan={7} bgcolor="#EEECE1"></td>
          </tr>
          <tr style={{ ...trClass, ...thickBorder }}>
            <th
              style={{
                ...tdClass,
                textAlign: "center",
                verticalAlign: "middle",
              }}
              bgcolor="#EEECE1"
            >
              SL No
            </th>
            <th style={tdClass} bgcolor="#EEECE1">
              Description
            </th>
            <th style={tdClass} bgcolor="#EEECE1">
              Model No
            </th>
            <th style={{
              ...tdClass,
              textAlign: "center",
              verticalAlign: "middle",
            }} colSpan={3} bgcolor="#EEECE1">
              Make
            </th>
            <th style={{
              ...tdClass,
              textAlign: "center",
              verticalAlign: "middle",
            }} bgcolor="#EEECE1">
              Qty
            </th>
            <th style={{
              ...tdClass,
              textAlign: "center",
              verticalAlign: "middle",
            }} bgcolor="#EEECE1">
              Price
            </th>
          </tr>
          {data.map((item, index) => (
            <tr key={index} style={trClass}>
              <td style={{ ...tdClass, fontWeight: "bold" }} align="center">
                {index + 1}
              </td>
              <td style={tdClass} width="50%">{item.description}</td>
              <td style={tdClass}>{item.modelNo}</td>
              <td style={tdClass} colSpan={3} align="center">
                {item.make}
              </td>
              <td style={tdClass} align="center">
                {item.qty}
              </td>
              <td style={tdClass} align="center">
                {item.price}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOMControlPanel;
